import { PlacementChoice } from '@/builders/pizza/dataTransformers/builderTypes';
import { groupBy } from 'remeda';
import { kebabCase } from '@/utils';
import Placement from '../Placement';

const normalizeText = (text: string) => text.normalize('NFD').replace(/[^ \w]/g, '');

const joinText = (array: any[] = [], joiner = ', ') => array.filter(Boolean).join(joiner) || '';

const joinIngredientByName = (array: any[] = [], joiner = ' and') => joinText(array)
  .replace(/,([^,]*)$/, `${joiner}$1`);

const getJoinedText = (text: string | null | undefined, joiner = '_') => text && normalizeText(text).toLowerCase().split(' ').join(joiner);

const computeComponentKey = (text: string, index: number): string => `${text}-${index}`;

export type NameAndPortion = { name: string, portion: string };
const joinByName = (items: any[] = []) => {
  const displayName = ({ name, portion }: NameAndPortion) => {
    const hasExtra = portion === 'extra';
    return (hasExtra ? `${name} (${portion})` : name);
  };

  return joinText(items.map((item) => (displayName(item))));
};

export type Placeable = { name?: string, placement?: PlacementChoice };

const joinToppingDescription = <T extends Placeable> (array: T[], includeWholePrefix: boolean = true) => {
  const arrayWithPlacements = array.map((element) => (element.placement
    ? element
    : { ...element, placement: Placement.WHOLE }));

  const { whole, left, right } = groupBy(arrayWithPlacements, placements => placements?.placement ?? '');

  const hasHalfPlacement = left || right;
  const wholeLabel = (hasHalfPlacement && includeWholePrefix) ? 'Whole: ' : '';

  return joinText([
    whole && `${wholeLabel}${joinByName(whole)}`,
    left && `Left: ${joinByName(left)}`,
    right && `Right: ${joinByName(right)}`
  ], '\n');
};

const formatTestId = (id: string) => `${kebabCase(id?.replace(/[^\w\s]/gi, '')).toLowerCase()}`;

const stripCharacters = (text: string | undefined | null) => text?.replace(/[^a-zA-Z0-9\s]/g, '');

export {
  getJoinedText,
  joinText,
  joinIngredientByName,
  computeComponentKey,
  joinByName,
  joinToppingDescription,
  formatTestId,
  stripCharacters
};
