/* eslint-disable no-extra-boolean-cast */
import gql from 'graphql-tag';
import {
  isCheese,
  isMeat,
  isSauce,
  isSize,
  isVeggie
} from '@/builders/pizza/identifiers';
import { CheckAvailabilityVariables } from '@/graphql/hooks/variables/useCheckAvailabilityVariables';
import checkAvailability, {
  AvailabilityItem
} from '../helpers/checkAvailability';
import { GetPizzaQueryResponse, Item } from '../types/PizzaBuilder';
import AvailabilityFragment from '../queries/fragments/availability';
import ItemFragment from '../queries/fragments/item';
import RulesFragment from '../queries/fragments/rules';
import { IdOrCode } from '@/../pages/menu/pizza/builder';
import { TypedQueryWithParserSinglePizza } from '@/graphql/types/TypedQuery';
import transformApiData from '@/builders/pizza/dataTransformers/pizzaOptionsTransformer';
import { PizzaOptions } from '@/builders/pizza/dataTransformers/builderTypes';

const getItemKey = (idOrCode: IdOrCode): string => {
  if (!!idOrCode.pizzaGlobalId) return `item(itemID:"${idOrCode.pizzaGlobalId}")`;
  if (!!idOrCode.productCode) return `item(productCode: "${idOrCode.productCode}")`;

  throw new Error('ID or product code must be provided');
};

function assertOrThrow(expression: unknown, errorMessage: string): void {
  if (expression) return;
  throw new Error(errorMessage);
}

type MinimumItemRequired = {
  available: boolean;
  availableInOtherOccasion: boolean;
  outOfStock: boolean;
  name: string;
};

export type PizzaBuilderOptions = PizzaOptions & {
  item: AvailabilityItem<Item> | MinimumItemRequired;
};

const parser = (
  data: GetPizzaQueryResponse,
  { occasion, storeTimeZone }: CheckAvailabilityVariables
): PizzaBuilderOptions => {
  const item = data?.Homepage?.menu?.item;
  if (!item) throw new Error('Pizza item must exist');

  assertOrThrow(item.modifiers, 'Modifiers must exist');
  assertOrThrow(item.modifiers.find(isSize), 'Size group must exist');
  assertOrThrow(item.modifiers.find(isSauce), 'Sauce group must exist');
  assertOrThrow(item.modifiers.find(isCheese), 'Cheese group must exist');
  assertOrThrow(item.modifiers.find(isMeat), 'Meat group must exist');
  assertOrThrow(item.modifiers.find(isVeggie), 'Veggie group must exist');

  const transformedData = transformApiData(item);

  return {
    ...transformedData,
    item: checkAvailability(item, occasion, storeTimeZone)
  };
};

const query = (idOrCode: IdOrCode): (() => TypedQueryWithParserSinglePizza) => {
  const key = getItemKey(idOrCode);
  const rawQuery = gql`
    query getPizza($storeID: String!) {
        Homepage: store(storeID: $storeID) {
          menu {
            ${key} {
                  ...ItemFragment
                  availability {
                      ...AvailabilityFragment
                  }

                  # this one is client only, GQL schema doesn't currently support it
                  totalCalories @client

                # need two: the original data from GQL, and the @client one
                  # that can override the data if desired
                  nutrition {
                      calories
                  }
                  nutrition @client {
                      calories
                  }

                  modifiers {
                      id
                      name
                      type

                      # need two: the original data from GQL, and the @client one
                      # that can override the data if desired
                      internalName
                      internalName @client

                      modifiers {
                          ...ItemFragment

                        # need two: the original data from GQL, and the @client one
                          # that can override the data if desired
                          nutrition {
                              calories
                          }
                          nutrition @client {
                              calories
                          }

                          modifiers {
                              ...ItemFragment
                              ...RulesFragment
                          }
                      }
                  }
              }
          }
        }
    }
    ${ItemFragment}
    ${AvailabilityFragment}
    ${RulesFragment}
  `;

  return () => ({
    query: rawQuery,
    parser
  });
};

export default query;
