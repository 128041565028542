import Portion from '../../../../common/Portion';
import {
  IngredientOptionWithPortions, Pizza, PizzaIngredient, PizzaIngredientOption
} from '../../dataTransformers/builderTypes';

// #Cheese selectors
const selectPizzaCheese = (pizza: Pizza): PizzaIngredient | null => pizza.cheese;

const selectCheeseOptions = (
  pizza: Pizza
): IngredientOptionWithPortions[] => pizza.pizzaOptions?.cheeses || [];

const selectRootCheeseById = (
  pizza: Pizza,
  cheeseId: string | undefined
): IngredientOptionWithPortions | undefined => {
  const recipeDefaultCheese = selectCheeseOptions(pizza).find(({ selected }) => selected);

  return selectCheeseOptions(pizza)
    .find((cheese) => cheese.id && cheeseId?.includes(cheese.id))
  || recipeDefaultCheese;
};

function selectPizzaCheeseOption(pizza: Pizza): PizzaIngredientOption | undefined {
  return selectRootCheeseById(pizza, pizza.cheese?.id)
    ?.portions
    .find(({ portion }) => portion === (pizza.cheese?.portion || Portion.REGULAR));
}

export {
  selectCheeseOptions,
  selectPizzaCheese,
  selectPizzaCheeseOption,
  selectRootCheeseById
};
