import { makeStyles } from '@material-ui/core';
import { mobileStartBreakpoint } from '../../../../materialUi/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '20px 17px 0px 15px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      paddingTop: '20px',
      paddingLeft: '24px',
      paddingRight: '28px'
    }
  },
  sizeDropdownAndCtaWrapper: {
    gridTemplateColumns: '1fr',
    margin: '16px 0',
    display: 'grid',
    gridRowGap: '24px',
    gridColumnGap: '24px'
  },
  sizeDropdownAndCtaButtonWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    gridColumn: '-2'
  },
  buttonContainer: {
    display: 'flex',
    marginTop: '32px'
  }
}));

export default useStyles;
