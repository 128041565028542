export const DEAL_LOVERS_CHECKOUT_WARNING = {
  cta: {
    add: "ADD DEAL LOVER'S ITEM",
    remove: 'REMOVE ITEM',
    removeAndCheckout: 'REMOVE ITEM AND CHECKOUT'
  },
  title: 'Deal minimum not met',
  content:
    "Please add another Deal Lover's Menu item to meet the 2 "
    + "item deal minimum, or continue to checkout and we'll remove the Deal Lover's Menu item from your cart."
};
