import { AsyncDispatch } from '@/localization/localizeActions';
import { selectors as profileSelectors } from '@/account/profile/profile.slice';
import { RootState } from '@/rootStateTypes';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { cartSelectors } from '@/cart/cartSelectors';
import { initializeAuthTokenHelpers } from '@/auth/authTokenHelpers';
import { AuthState } from '@/auth/userStates';
import telemetry from '@/telemetry';
import { setAuthState } from '@/header/actions';

export const LOGOUT = 'LOGOUT';

const logout = () => ({
  type: LOGOUT
});

export const handleLogout = (
  localizationToken: string | undefined
): AsyncDispatch => async (dispatch: any, getState: any) => {
  dispatch(setAuthState(AuthState.GUEST));
  dispatch(logout());
  const isLocalized = localizationSelectors.isLocalized(getState());

  telemetry.addCustomEvent('handleLogout - initializing authTokenHelpers');
  await initializeAuthTokenHelpers({
    getState,
    dispatch,
    localized: isLocalized,
    initLocalizationToken: localizationToken,
    userAuthentication: { authState: AuthState.GUEST },
    shouldCreateCart: false,
    shouldGetCart: false
  });
};

type GetCartItems = () => ReturnType<typeof cartSelectors.items>;
type IsLocalized = () => ReturnType<typeof localizationSelectors.isLocalized>;
type GetLocToken = () => ReturnType<typeof localizationSelectors.localizationToken>;
type GetCouponsByCode = () => ReturnType<typeof profileSelectors.getCouponsByCode>;

export interface OfSelectorsAndSuch {
  getCartItems: GetCartItems;
  isLocalized: IsLocalized;
  getLocToken: GetLocToken;
  getCouponsByCode: GetCouponsByCode;
}

export const getSelectors = (getState: () => RootState): OfSelectorsAndSuch => {
  const isLocalized = () => localizationSelectors.isLocalized(getState());
  const getCartItems = () => cartSelectors.items(getState());
  const getLocToken = () => localizationSelectors.localizationToken(getState());
  const getCouponsByCode = () => profileSelectors.getCouponsByCode(getState());

  return {
    isLocalized, getCartItems, getLocToken, getCouponsByCode
  };
};
