import AuthenticationEndpoints from '@/api/phdApiClient/Authentication/endpoints';
import { post } from '@/api/clientHelpers';
import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import { getPublicRuntimeConfig } from '@/api/phdApiClient/helpers/getPublicRuntimeConfig';
import telemetry from '@/telemetry';

interface CreateGuestProps {
  auth_token: string;
}

const createGuest = async (): Promise<CreateGuestProps> => {
  const url = getUrl(AuthenticationEndpoints.POST_GUEST);
  const { phdApiServerEnv, phdApiAuthChannel } = getPublicRuntimeConfig();

  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'x-server-env': phdApiServerEnv
  };
  const body = {
    channel_id: phdApiAuthChannel
  };

  const mockCreateGuest: any = async (): Promise<Response> => Promise.resolve(
    new Response(JSON.stringify({
      auth_token: 'mock_token'
    }))
  );

  try {
    const response = await post(url, headers, body, mockCreateGuest);

    if (response.status >= 300) {
      throw new Error(
        `Unexpected response: ${response.statusText} (status: ${response.status})`
      );
    }

    const responseBody = await response.json();

    if (!responseBody.auth_token) {
      throw new Error(
        `auth_token missing in response body (status: ${response.status})`
      );
    }

    return responseBody;
  } catch (error) {
    telemetry.addNoticeError((error as Error), { endpoint: url });

    throw error;
  }
};

export default createGuest;
