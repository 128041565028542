import React from 'react';
import {
  Cart,
  LoyaltyOfferOperation,
  useApplyLoyaltyOfferMutation,
  useRemovePromoCodeMutation,
  YumCart,
  PromotionDefinition,
  DiningOccasion,
  ItemQuantityRequirement,
  SubtotalRequirement,
  PromotionNotAppliedReasonType,
  PromotionRequirementType
} from '@pizza-hut-us-development/client-core';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { closeCartRail } from '@/clientCore/redux/rail/CartRailSlice';
import {
  findRedemptionWarningFromPromoOrCode,
  getPromotionDefinition,
  isRedemptionWarning,
  isResolvableWarning,
  isYumCart
} from '@/clientCore/cart/components/CartRail/components/CartContent/components/YumAddedCoupons/helpers';
import {
  openLocalizationRail, openModal, switchToCarryout, switchToDelivery
} from '@/localization/actions';
import telemetry from '@/telemetry';
import { toggleRail } from '@/rail/slices/Rail.slice';
import { RailType } from '@/rail/slices/Rail.slice.types';
import { formatPrice } from '../../CartItem/helpers/cartItemHelpers';
import { RedemptionQueryParams } from '@/clientCore/redemptionMenu/constants';
import styles from '../styles';

const useCodeRedemption = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const classes = styles.codeRedemption();

  const [removePromoCode] = useRemovePromoCodeMutation();
  const [applyLoyaltyOffer] = useApplyLoyaltyOfferMutation();

  const removePromotionFromCart = async (promotionId: string, cart: Cart) => {
    // Check if this is a standard promo, or if it has an associated loyaltyId
    let loyaltyId: string | undefined;
    // TODO: Remove typecast when CC type is updated
    const warningMatch = (cart as YumCart).warnings?.find((warning) => {
      if (!isRedemptionWarning(warning)) return false;
      return warning.promotionId === promotionId;
    });

    // Have to type-check again here because TS is dumb sometimes
    if (isRedemptionWarning(warningMatch)) {
      loyaltyId = warningMatch.loyaltyOffer?.loyaltyOfferId;
      // If there's a loyaltyId, we need to remove the promo via the loyalty endpoint
      if (loyaltyId) {
        try {
          await applyLoyaltyOffer({
            loyaltyOfferId: loyaltyId,
            operation: LoyaltyOfferOperation.REMOVE
          });
        } catch (err) {
          telemetry.addNoticeError(new Error('Error removing loyalty promotion from cart'));
        }
      } else {
        try {
          await removePromoCode(warningMatch.code);
        } catch (err) {
          telemetry.addNoticeError(new Error('Error removing promotion from cart'));
        }
      }
    }
  };

  const handleRedemptionWarnings = async (promotionId: string, cart?: Cart, existingDefinition?: PromotionDefinition): Promise<JSX.Element | null> => {
    if (!cart) return null;
    if (!isYumCart(cart)) return null;

    // Find a warning with this promotionId
    const associatedWarning = findRedemptionWarningFromPromoOrCode(cart, promotionId);

    if (associatedWarning) {
      const promotionDefinition = await getPromotionDefinition(promotionId, cart, dispatch, existingDefinition);
      const warningReasonTypes = associatedWarning.reasons.map((reason) => reason.__typename);

      // Unresolvable warnings
      // ie. User needs to perform an action that will kill the cart, and then need

      // Base modal details, used if none of the specific catches below trigger
      const modalDetails: ModalContent = {
        title: 'Coupon Not Applied',
        body: 'That offer is not available at this time',
        cta: {
          text: 'Ok'
        },
        onClose: () => removePromotionFromCart(promotionId, cart)
      };

      // Only available for logged in user
      if (warningReasonTypes.includes(PromotionNotAppliedReasonType.UserStatusRequirementNotMet)) {
        dispatch(openModal({
          ...modalDetails,
          body: 'Please sign in and try again',
          cta: {
            text: 'Sign in',
            callback: () => {
              dispatch(toggleRail(RailType.SIGN_IN));
            }
          },
          altCta: {
            text: 'Cancel'
          }
        }));
        return null;
      }
      // Only available for other occasion
      if (warningReasonTypes.includes(PromotionNotAppliedReasonType.DiningOccasionRequirementNotMet)) {
        const reqOccasion = cart.occasion === DiningOccasion.CARRYOUT ? DiningOccasion.DELIVERY : DiningOccasion.CARRYOUT;
        const formattedOccasion = reqOccasion.toLowerCase();

        dispatch(openModal({
          ...modalDetails,
          body: `This coupon is only valid for ${formattedOccasion} orders. Please swap to ${formattedOccasion} and try again.`,
          cta: {
            text: `Swap to ${formattedOccasion}`,
            callback: () => {
              dispatch(openLocalizationRail());
              dispatch(closeCartRail());
              if (cart.occasion === DiningOccasion.CARRYOUT) {
                dispatch(switchToDelivery());
              } else {
                dispatch(switchToCarryout());
              }
            }
          },
          altCta: {
            text: 'Cancel'
          }
        }));
        return null;
      }
      // Conflicting promotion already in cart
      if (warningReasonTypes.includes(PromotionNotAppliedReasonType.PromotionNotCombinable)) {
        dispatch(openModal({
          ...modalDetails,
          body: 'Sorry this offer can not be combined with another offer in your cart'
        }));
        return null;
      }
      // Conflicting item in cart
      if (warningReasonTypes.includes(PromotionNotAppliedReasonType.ItemExclusionRequirementNotMet)) {
        dispatch(openModal({
          ...modalDetails,
          body: 'Sorry this offer can not be combined with an item in your cart'
        }));
        return null;
      }
      // Catch all for generic unresolvable errors that may not have been caught above
      if (!isResolvableWarning(associatedWarning)) {
        dispatch(openModal(modalDetails));
        return null;
      }

      // Resolvable warnings
      // ie. User can add items to the cart, and the promotion will be applied

      const baseWarning = promotionDefinition?.privateMetafields.find((meta) => meta.key === 'errorMessage')?.value
        || 'Once you add the required items, your offer will be applied to the cart';

      // Requirement missing from cart
      if (warningReasonTypes.includes(PromotionNotAppliedReasonType.ItemPresenceRequirementNotMet)) {
        const handleAction = () => {
          router.push(`/redeem?red=${promotionId}&fl=${RedemptionQueryParams.REQUIREMENTS}`);
          dispatch(closeCartRail());
        };
        return (
          <span>
            Coupon incomplete:
            <div
              role="button"
              tabIndex={0}
              onClick={() => handleAction()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleAction();
              }}
              className={classes.ctaLink}
            >
              {` ${baseWarning}`}
            </div>
          </span>
        );
      }
      // More items in cart required
      if (warningReasonTypes.includes(PromotionNotAppliedReasonType.ItemQuantityRequirementNotMet)) {
        const quantReq = (promotionDefinition?.requirements.find((req) => req.type === 'ItemQuantityRequirement') as ItemQuantityRequirement)?.minimumTotalItems;
        const addItemCount = ` ${(quantReq - cart.items.length)}` || '';

        return <span>Coupon incomplete: {`Once you add${addItemCount} more items to the cart, your offer will be applied`}</span>;
      }
      // Minimum subtotal not met
      if (warningReasonTypes.includes(PromotionNotAppliedReasonType.SubtotalRequirementNotMet)) {
        const reqSubtotal = (promotionDefinition?.requirements.find((req) => req.type === 'SubtotalRequirement') as SubtotalRequirement)?.minimumSubtotal.amount;
        let minAmountText = 'required';
        if (reqSubtotal) {
          const formattedSubtotal = formatPrice(reqSubtotal);
          minAmountText = `of ${formattedSubtotal} required`;
        }

        return <span>Coupon incomplete: {`Your cart's subtotal is below the minimum amount ${minAmountText}`}</span>;
      }
      // Effect missing from cart
      if (warningReasonTypes.includes(PromotionNotAppliedReasonType.PromotionEffectTargetNotInCart)) {
        const handleAction = () => {
          router.push(`/redeem?red=${promotionId}&fl=${RedemptionQueryParams.EFFECTS}`);
          dispatch(closeCartRail());
        };
        return (
          <span>
            Coupon incomplete:
            <div
              role="button"
              tabIndex={0}
              onClick={() => handleAction()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleAction();
              }}
              className={classes.ctaLink}
            >
              {` ${baseWarning}`}
            </div>
          </span>
        );
      }
      // TEMP til Yum fix - PromotionIsInvalid can contain a valid reason we need to handle
      if (warningReasonTypes.includes(PromotionNotAppliedReasonType.PromotionIsInvalid)) {
        const associatedReason = associatedWarning.reasons.find((reason) => reason.__typename === PromotionNotAppliedReasonType.PromotionIsInvalid);
        if (associatedReason?.message === 'NO_DISCOUNTABLE_SUBTOTAL') {
          const reqSubtotal = (promotionDefinition?.requirements.find((req) => req.type === PromotionRequirementType.SubtotalRequirement) as SubtotalRequirement)?.minimumSubtotal.amount;
          let minAmountText = 'required';
          if (reqSubtotal) {
            const formattedSubtotal = formatPrice(reqSubtotal);
            minAmountText = `of ${formattedSubtotal} required`;
          }
          return <span>Coupon incomplete: {`Your cart's subtotal is below the minimum amount ${minAmountText}`}</span>;
        }
      }

      // Default message to display if no cases match
      return <span>Coupon incomplete: {baseWarning}</span>;
    }

    // Code was successfully applied
    return null;
  };
  return [
    handleRedemptionWarnings
  ];
};

export default useCodeRedemption;
