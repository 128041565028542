import gql from 'graphql-tag';
import DealQueryResult, { DealQuery } from '../../types/Deal';
import transformDealData from './transformDealData';
import ItemFragment from '../fragments/item';
import RulesFragment from '../fragments/rules';
import AvailabilityFragment from '../fragments/availability';
import { DealData, ParentDeal } from '@/builders/deals/slice/dealTypes';
import checkAvailability from '../../helpers/checkAvailability';
import { CheckAvailabilityVariables } from '@/graphql/hooks/variables/useCheckAvailabilityVariables';

const rawQuery = gql`
  query getDeal($storeID: String!, $itemID: ID!) {
    Homepage: store(storeID: $storeID) {
      menu {
        item(itemID: $itemID) {
          ...ItemFragment
          outOfStock
          sodiumWarning
          legalText
          availability {
            ...AvailabilityFragment
          }
          steps: modifiers {
            allergenDisclaimer
            ...ItemFragment
            ...RulesFragment
            customizationsIncluded
            recipes: modifiers {
              ...ItemFragment
              customizationsIncluded
              isCYO
            }
          }
          combinableDiscount
        }
      }
    }
  }
  ${ItemFragment}
  ${RulesFragment}
  ${AvailabilityFragment}
`;

export const parser = (
  data: DealQueryResult,
  { occasion, storeTimeZone }: CheckAvailabilityVariables
): { deal: DealData | ParentDeal } | Record<string, never> => {
  if (data?.Homepage?.menu) {
    const responseData = data.Homepage.menu.item;

    if (responseData) {
      const dealData = checkAvailability(responseData, occasion, storeTimeZone);

      return {
        deal: transformDealData(dealData)
      };
    }
    throw new Error('item is undefined inside deal data');
  }
  throw new Error('data is invalid');
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const query: DealQuery = { query: rawQuery, parser };
export default query;
