import { Deal } from '@/builders/deals/slice/dealTypes';
import { Product } from '@/domain/product/types';
import { CartItem } from '@/domain/cart/types';
import { BuiltPizza } from '@/builders/pizza/dataTransformers/builderTypes';

export enum AddableCartItemKinds {
  CART_ITEM = 'CART_ITEM',
  PRODUCT = 'PRODUCT',
  BUILT_PIZZA = 'BUILT_PIZZA',
  DEAL = 'DEAL'
}

export enum DealRuleTypes {
  SIZE = 'sizes'
}

export type AddableCartItem = BuiltPizza | Product | Deal | CartItem;

export type PhdApiV2CartModifier = {
  display_name: string | null | undefined;
  name: string | undefined;
  type: string | undefined;
  modifiers?: PhdApiV2CartModifier[];
  quantities: number[];
};

export type PhdApiV2CartPizza = {
  type: 'PIZZA';
  special_instructions: string | undefined;
  verified_age_gate: boolean;
  display_name: string | null | undefined;
  name: string | undefined;
  quantity: number;
  modifiers: PhdApiV2CartModifier[];
};

export type PhdApiV2CartDealStep = {
  type: 'STEP';
  display_name: string | null | undefined;
  name: string | null | undefined;
  description: string | undefined;
  modifiers: (PhdApiV2CartPizza | PhdApiV2CartProduct)[];
};

export type PhdApiV2CartItem = {
  type: string;
  verified_age_gate: boolean;
  display_name: string;
  name: string;
  quantity: number;
  modifiers: (PhdApiV2CartDealStep | PhdApiV2CartModifier)[];
};

export type PhdApiV2CartDeal = {
  type: 'DISCOUNT';
  verified_age_gate: boolean;
  display_name: string;
  name: string;
  quantity: number;
  modifiers: PhdApiV2CartDealStep[];
};

export interface PhdApiV2CartProduct {
  name: string;
  display_name: string;
  quantity: number;
  type: 'PRODUCT';
  verified_age_gate?: boolean;
  modifiers: PhdApiV2CartModifier[];
}

export type PhdApiV2CartItemRequest =
  | PhdApiV2CartPizza
  | PhdApiV2CartProduct
  | PhdApiV2CartDeal
  | PhdApiV2CartItem;
export type PhdApiV2CartItemRequestWithId = PhdApiV2CartItemRequest & {
  cart_item_id?: string;
};

export type PhdApiV2Cart = {
  items: PhdApiV2CartItemRequest[];
};

export type PhdApiV2SingleCartItem = {
  item: PhdApiV2CartItemRequestWithId;
};

export interface Orders {
  estimated_transmission_time: string;
  message: string;
  order_id: string;
  promised_time: string;
  status: string;
}

export interface OrderStatus {
  success: true;
}

export interface ReOrderRequest {
  verified_age_gate: boolean;
  localizationToken?: string;
}

export interface AddFavoriteToCartRequest extends ReOrderRequest {
  favorite_id: string;
}
export interface ReOrderPastOrderRequest extends ReOrderRequest {
  order_id: string;
}
