import { ApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useApolloClientClearOnRouteChange = (apolloClient: ApolloClient<any>): void => {
  const router = useRouter();
  let currentRoute = router.route;

  const handleRouteChangeStart = (url: string) => {
    const path = url.includes('?') ? url.substring(0, url.indexOf('?')) : url;
    if (currentRoute !== path) {
      apolloClient.clearStore();
      currentRoute = path;
    }
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useApolloClientClearOnRouteChange;
