import { UnexpectedUpdatePasswordError } from '@/api/phdApiClient/accountManagement/myAccount/updatePassword';

const updatePassword = typeof jest === 'undefined' ? new Promise(() => {}) : jest.fn();

export const success = (): void => {
  (updatePassword as jest.Mock).mockResolvedValue(null);
};

export const updatePasswordUnexpectedError = (): void => {
  (updatePassword as jest.Mock).mockResolvedValue(new UnexpectedUpdatePasswordError());
};

export default updatePassword;
