import gql from 'graphql-tag';
import LinkFragmentV3 from '../fragments/link.v3';
import ImageFragmentV3 from '../fragments/image.v3';
import {
  FooterParsedResponse, FooterQuery, FooterQueryResult
} from '@/graphql/types/Footer';
import NationalCategoryFragmentV3 from '../fragments/nationalCategory.v3';
import { matchingCustomerLocalization, sortByIndex } from '../categories';
import { categoryIsNotLineup } from '@/graphql/helpers/filterCategories';
import LinkFragment from '@/graphql/types/fragment/Link';
import { OrEmptyObj } from '@/utils';

const rawQuery = gql`
    query getFooter($storeID: String!, $envEnvironmentID: String!) {
        Homepage(envID: $envEnvironmentID, storeID: $storeID) {
          footer {
                menu: menuNavigation {
                    sectionName
                    subLinks: additionalLinks {
                        ...LinkFragmentV3
                    }
                }
                account: accountNavigation {
                    sectionName
                    createAccount: createLink {
                        ...LinkFragmentV3
                    }
                    manageAccount: manageLink {
                        ...LinkFragmentV3
                    }
                    signIn: signInLink {
                        ...LinkFragmentV3
                    }
                    signOut: signOutLink {
                        ...LinkFragmentV3
                    }
                }
                sections: sectionsNavigation {
                    sections {
                        title: sectionName
                        links {
                            ...LinkFragmentV3
                        }
                    }
                }
                cta: callToAction {
                    heading
                    appleStoreIcon {
                        ...ImageFragmentV3
                    }
                    appleStoreLink {
                        ...LinkFragmentV3
                    }
                    externalLinkIcon {
                        ...ImageFragmentV3
                    }
                    googlePlayStoreIcon {
                        ...ImageFragmentV3
                    }
                    googlePlayStoreLink {
                        ...LinkFragmentV3
                    }
                    fbIcon {
                        ...ImageFragmentV3
                    }
                    fbLink {
                        ...LinkFragmentV3
                    }
                    instagramIcon {
                        ...ImageFragmentV3
                    }
                    instagramLink {
                        ...LinkFragmentV3
                    }
                    twitterIcon {
                        ...ImageFragmentV3
                    }
                    twitterLink {
                        ...LinkFragmentV3
                    }
                    youtubeIcon {
                        ...ImageFragmentV3
                    }
                    youtubeLink {
                        ...LinkFragmentV3
                    }
                }
                legal {
                    links {
                        newFlag
                        link {
                            ...LinkFragmentV3
                        }
                        badgeText
                    }
                    disclaimers {
                        legalCopyright
                        legalStatement
                    }
                }
            }
          categories {
            ...NationalCategoryFragmentV3
          }
        }
    }
    ${ImageFragmentV3}
    ${LinkFragmentV3}
    ${NationalCategoryFragmentV3}
`;

interface FooterParserOptions { customerIsNational: boolean }

const parser = (
  data: FooterQueryResult, { customerIsNational }: FooterParserOptions
): OrEmptyObj<FooterParsedResponse> => {
  if (!data?.Homepage?.footer || !data?.Homepage?.categories) {
    return {};
  }

  const {
    menu, account, sections, cta, legal
  } = data.Homepage.footer;

  const sectionsLinks = (sectionLinks: LinkFragment[]) => [...(sectionLinks || [])]
    .filter((filtered) => filtered);

  return {
    footer: {
      cta,
      navigation: {
        menu,
        account,
        sections: {
          sections: sections.sections.map((section: { title: string; links: LinkFragment[] }) => ({
            meta: {
              title: section.title
            },
            links: {
              links: sectionsLinks(section.links)
            }
          }))
        }
      },
      legal: {
        links: {
          links: legal.links
        },
        disclaimers: legal.disclaimers
      }
    },
    categories: data.Homepage.categories
      .filter((category) => categoryIsNotLineup(category?.link?.link))
      .filter(matchingCustomerLocalization(customerIsNational))
      .sort(sortByIndex)
  };
};

const query: FooterQuery = { query: rawQuery, parser };
export default query;
