import getConfig from 'next/config';
import { OVERRIDE_CMS_ENV } from '../../../ReduxApplication/actionTypes';

const defaultCmsEnv = {
  id: getConfig()?.publicRuntimeConfig?.CMS_ENVIRONMENT_ID || null,
  override: false
};

const cmsEnv = (state = defaultCmsEnv, action) => {
  switch (action.type) {
    case OVERRIDE_CMS_ENV: {
      return {
        id: (action as any).cmsEnvId,
        override: true
      };
    }
    default:
      return state;
  }
};

export default cmsEnv;
