import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { openLocalizationRail, openModal } from '@/localization/actions';
import { redirectMapping } from '@/router/nextJsRouter';
import { RootState } from '@/rootStateTypes';
import { onInteractWithChangeLocalizedStoreModal } from '@/checkout/checkout.analytics';
import { cartSelectors } from '@/cart/cartSelectors';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import Routes from '@/router/routes';

const getQuantity = (state: RootState) => (state.domain.cart?.quantity
  || state.domain.cartLegacy.quantity);

  type UseOpenLocalizationWithWarningModalParams = {
    onCancel?: () => void;
    origin?: string;
    clientCore?:boolean;
  };

const useOpenLocalizationWithWarningModal = (
  { onCancel, origin, clientCore = false }: UseOpenLocalizationWithWarningModalParams
): (
  ) => void => {
  const dispatch = useDispatch();
  const router = useRouter();
  const quantity: number = useSelector(getQuantity);
  const items = useSelector(cartSelectors.items);
  const analytics = useAnalytics();
  // CC cart Items
  const ccCartItemsLength = useSelector(orderSelectors.cart)?.items?.length || 0;

  const getRouteToBasedOnCurrentRoute = (): string => {
    const currentRoutePath = router.pathname;

    if (currentRoutePath === Routes.CHECKOUTW2) {
      return redirectMapping[Routes.HOME];
    }

    return redirectMapping[currentRoutePath];
  };

  const openLocalizationModal = (openRailWithRoute: () => void) => {
    const title = 'Do you want to proceed?';
    const body = 'If you change to a new store the items in your cart will be removed.';
    dispatch(openModal({
      title,
      body,
      cta: {
        text: 'PROCEED',
        callback: () => {
          if (origin && !clientCore) {
            analytics.push(() => onInteractWithChangeLocalizedStoreModal(origin, 'Proceed', items));
          }
          openRailWithRoute();
        }
      },
      altCta: {
        text: 'CANCEL',
        callback: () => {
          if (origin && !clientCore) {
            analytics.push(() => onInteractWithChangeLocalizedStoreModal(origin, 'Cancel', items));
          }
          if (onCancel) onCancel();
        }
      }
    }));
  };

  return () => {
    const routeTo = getRouteToBasedOnCurrentRoute();
    const openRailWithRoute = () => dispatch(openLocalizationRail({ routeTo }));

    if (quantity > 0 || ccCartItemsLength > 0) {
      openLocalizationModal(openRailWithRoute);
    } else {
      openRailWithRoute();
    }
  };
};

export default useOpenLocalizationWithWarningModal;
