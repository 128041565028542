import { Occasion } from '../constants';

const makeMultipleOfFive = (promiseTime?: number) => ((promiseTime && promiseTime % 5 !== 0)
  ? promiseTime - (promiseTime % 5) + 5
  : promiseTime);

export const transformCarryoutPromiseTime = (promiseTime?: number): string => {
  return promiseTime ? `${promiseTime} mins` : ''
}

export const transformDeliveryPromiseTime = (promiseTime?: number, stale?: boolean): string => {
  const actualPromiseTime = makeMultipleOfFive(promiseTime);

  if (promiseTime && actualPromiseTime && (!stale && promiseTime > 0)) {
    return `${actualPromiseTime - 5} - ${actualPromiseTime + 5} min`;
  }

  return '30 - 40 min';
}

const transformPromiseTime = (occasion: string, promiseTime?: number, stale?: boolean) => {
  if (occasion === Occasion.CARRYOUT) return transformCarryoutPromiseTime(promiseTime);

  return transformDeliveryPromiseTime(promiseTime, stale);
};

export default transformPromiseTime;
