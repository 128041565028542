import React from 'react';
import Head from 'next/head';
import { SEO as SEOQuery } from '../graphql/queries';
import { logGraphqlError } from '../common/logger/logGraphqlError';
import getJsonLDSchema from './jsonLDSchema';
import { useVersionQuery } from '../graphql/hooks/useVersionQuery';
import useSeoLinksMetadata from './useSeoLinksMetadata';
import useDeepEffect from '@/common/useDeepEffect';
import { SeoPage } from '@/graphql/types/seo';

const jsonLD = getJsonLDSchema();

const extractTitleAndDescription = (
  response: SeoPage | undefined
): { title: string; description?: string } => {
  let title = 'Pizza Hut';
  let description;
  const page = response?.seo;

  if (page) {
    title = page.title || title;
    description = page.description;
  }
  return { title, description };
};

type SEOProps = {
  pageID?: string;
};

const SEO = ({ pageID = 'homepage' }: SEOProps): JSX.Element => {
  const { canonicalUrl, spanishSiteUrl } = useSeoLinksMetadata();

  const { data, loading } = useVersionQuery<SeoPage>({
    queryFn: SEOQuery,
    storeSpecific: true,
    itemId: undefined,
    options: {
      variables: {
        pageID
      }
    }
  });

  const page = data?.seo;

  useDeepEffect(() => {
    if (!page && !loading) {
      logGraphqlError('SEO', 'SEO data missing', `Page ID: ${pageID}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, loading]);

  if (loading) {
    return <></>;
  }

  const { title, description } = extractTitleAndDescription(data);

  return (
    <Head>
      <link rel="canonical" href={canonicalUrl} />
      <link rel="alternate" href={canonicalUrl} hrefLang="en-us" />
      <link rel="alternate" href={spanishSiteUrl} hrefLang="es-us" />
      <link rel="alternate" href={canonicalUrl} hrefLang="x-default" />
      {jsonLD}
      {description && <meta name="description" content={description} />}
      <title>{title}</title>
    </Head>
  );
};

export default SEO;
