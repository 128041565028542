import { customerStub } from '@/api/phdApiClient/__mocks__/myAccount/getCustomer.stub';
import { Profile, ProfileWithOldDataStructure } from '@/domain/Profile';
import oldCustomerDetailsMock from '@/api/phdApiClient/__mocks__/transformedCustomerInfo.stub';

const getCustomer = typeof (jest) === 'undefined' ? new Promise(() => {}) : jest.fn();

const successfulCustomerProfileLoginInfo = (): ClientResult<ProfileWithOldDataStructure> => {
  const mockGetCustomer = getCustomer as jest.Mock;
  const value = {
    error: false,
    result: { new: customerStub, old: oldCustomerDetailsMock }
  };
  mockGetCustomer.mockResolvedValue(value);
  return value;
};

const failedCustomerProfileLoginInfo = (): ClientResult<Profile> => {
  const mockGetCustomer = getCustomer as jest.Mock;
  const value = {
    error: true,
    result: undefined
  };
  mockGetCustomer.mockResolvedValue(value);
  return value;
};

export default getCustomer;

export {
  successfulCustomerProfileLoginInfo,
  failedCustomerProfileLoginInfo
};
