import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { AnalyticsDataModel, RootState } from '@/rootStateTypes';
import dataAnalytics from '@/dataAnalytics';
import logger from '@/common/logger';
import { BaseAnalytics } from '@/dataAnalytics/analyticsTypes';

type Push = (buildEvent: () => Partial<BaseAnalytics>) => void;
export type Analytics = {
  push: Push;
  analyticsDataModel: AnalyticsDataModel;
};
type UseAnalytics = () => Analytics;

const useAnalytics: UseAnalytics = () => {
  const analyticsDataModel = useSelector(
    (state: RootState) => state.analyticsDataModel
  );

  return useMemo(() => ({
    push: (buildEvent) => {
      try {
        const event = buildEvent();
        dataAnalytics.push(event, analyticsDataModel);
      } catch (error) {
        logger.error(error as Error);
      }
    },
    analyticsDataModel
  }), [analyticsDataModel]);
};

export default useAnalytics;
