import { ReactEventHandler } from 'react';
import { AnchorLinkProps } from '@/common/AnchorLink/AnchorLink';

export enum LinkTypes {
  RELATIVE_LINK = 'relativeLink',
  DEEP_LINK = 'deepLink'
}

export type MouseOrKeyboardEventHandler = ReactEventHandler<HTMLAnchorElement>;

export type UseAnchorLinkParams = Pick<
AnchorLinkProps,
| 'children'
| 'linkDisplayText'
| 'linkType'
| 'link'
| 'dataAnalyticsAction'
| 'onClick'
| 'handleAfterLocalizing'
| 'preventHref'
>;
export interface UseAnchorLinkReturn {
  relativeLink: string | undefined;
  handleOnKeyDown: (event: React.KeyboardEvent<HTMLAnchorElement>) => void;
  isRelativeLink: boolean;
  handleOnAction: (event: React.SyntheticEvent<HTMLAnchorElement>) => void;
  href: string | undefined;
  linkContent: JSX.Element | string | undefined;
}
export type UseAnchorLink = (
  params: UseAnchorLinkParams
) => UseAnchorLinkReturn;
