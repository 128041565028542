import { createStyles, makeStyles } from '@material-ui/core';
import colors from '@/common/colors';

const styles = makeStyles(() => createStyles({
  container: {
    position: 'relative'
  },
  title: {
    textTransform: 'none',
    letterSpacing: '0px'
  },
  storeWillOpen: {
    color: colors.black,
    letterSpacing: '0px',
    textTransform: 'none'
  },
  storeOpen: {
    color: colors.green,
    letterSpacing: '0px',
    textTransform: 'none'
  },
  storeClosed: {
    color: colors.red,
    letterSpacing: '0px',
    textTransform: 'none'
  },
  changeButton: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}));

export default styles;
