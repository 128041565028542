import React from 'react';
import {
  Button, CircularProgress, Grid, InputLabel, makeStyles, Select
} from '@material-ui/core';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import clsx from 'clsx';
import fontStyles from '../../../common/fontStyles';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import colors from '../../../common/colors';
import { FormChangeEvent } from '@/common/eventTypes';
import { SelectorButtonState } from '@/hooks/useButtonState';

export interface ButtonProps {
  text: string;
  ariaLabel: string;
  ariaLabelLoading?: string;
  size: 'primary' | 'secondary' | 'small';
  action: 'ADD_TO_CART' | 'ADD_TO_DEAL';
  color?: 'primary' | 'secondary';
  variant?: 'contained' | 'outlined';
}

interface SelectProps {
  id: string;
  name: string;
}

export interface SelectorButtonProps {
  onClick: (eventProps?: MouseEvent | React.MouseEvent<HTMLButtonElement>) => void;
  onSelectionChange: (
    event: FormChangeEvent,
    child: React.ReactNode
  ) => void;
  button: ButtonProps;
  select: SelectProps;
  quantity: number;
  showQuantity?: boolean;
  inputLabelText: string;
  state?: SelectorButtonState;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  button: {
    ...fontStyles.primaryCtaLabel,
    'text-transform': 'uppercase',
    height: '40px',
    width: '88px',
    padding: '6px 0 7px 0',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    border: `solid 1px ${colors.red}`,
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      width: '226px'
    }
  },
  buttonAddToOrder: {
    ...fontStyles.primaryCtaLabel,
    'text-transform': 'uppercase',
    height: '35px',
    width: '100%',
    padding: '0 2rem',
    border: `solid 1px ${colors.red}`,
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      padding: '0 3rem'
    }
  },
  primaryBtn: {
    height: '40px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      width: '226px'
    },
    fontSize: '16px'
  },
  secondaryBtn: {
    color: colors.red
  },
  smallBtn: {
    height: '32px',
    width: '95px',
    fontSize: '14px'
  },
  quantitySelect: {
    borderRadius: '8px 0 0 8px',
    border: `solid 1px ${colors.gray400}`,
    borderRight: '0',
    '&:focus': {
      borderRadius: '8px 0 0 8px'
    },
    height: '100%',
    padding: '0',
    boxSizing: 'border-box'
  },
  primaryQuantitySelect: {
    paddingLeft: '11px'
  },
  smallQuantitySelect: {
    paddingLeft: '16px'
  },
  primaryQuantityBtn: {
    height: '40px',
    width: '58px'
  },
  smallQuantityBtn: {
    height: '32px',
    width: '68px'
  },
  icon: {
    right: '8px',
    color: colors.gray900
  },
  primaryIcon: {
    right: '8px'
  },
  smallIcon: {
    right: '12px'
  },
  visuallyHidden: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: '0',
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap', /* added line */
    border: '0'
  },
  preventClick: {
    cursor: 'auto',
    pointerEvents: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  }
}));

const quantitySelectOptions = Array.from(Array(10).keys())
  .map((num) => {
    const quantity = num + 1;
    return <option key={quantity} value={quantity}>{quantity}</option>;
  });

const SelectorButton = (props: SelectorButtonProps): JSX.Element => {
  const {
    onSelectionChange,
    onClick,
    button: {
      ariaLabelLoading = 'Loading',
      ...button
    },
    select,
    quantity,
    showQuantity = true,
    inputLabelText,
    state = SelectorButtonState.DEFAULT
  } = props;
  const classes: Record<string, string> = useStyles();
  const preventOnClickCallback = state !== SelectorButtonState.DEFAULT && button.action === 'ADD_TO_CART';

  const ArrowDown = (): JSX.Element => (
    <KeyboardArrowDownOutlinedIcon className={`${classes.icon} ${classes[`${button.size}Icon`]} MuiSelect-icon`} />
  );

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!preventOnClickCallback) {
      onClick(e);
    }
  };

  const isLoading = state === SelectorButtonState.SHOW_LOADING_SPINNER;
  const preventClickCls = clsx(button.action === 'ADD_TO_CART' && state === SelectorButtonState.PREVENT_CLICK && classes.preventClick);
  const btnClass = showQuantity ? (clsx(classes.button, classes[`${button.size}Btn`]))
    : clsx(classes.buttonAddToOrder, classes[`${button.color}Btn`]);

  const handleChange = (e: React.ChangeEvent<{ value: string }>) => {
    onSelectionChange(e, null);
  };
  const labelId = `${select.id}-label`;

  return (
    <Grid className={classes.root}>
      {
        showQuantity ? (
          <>
            <InputLabel
              id={labelId}
              className={classes.visuallyHidden}
              htmlFor={select.id}
              aria-hidden
            >
              {inputLabelText}
            </InputLabel>
            <Select
              native
              className={classes[`${button.size}QuantityBtn`]}
              aria-label="quantity select"
              value={quantity}
              onChange={(e) => handleChange(e as React.ChangeEvent<{ value: string }>)}
              disableUnderline
              IconComponent={ArrowDown}
              inputProps={{
                name: select.name,
                id: select.id,
                className: `${classes.quantitySelect} ${classes[`${button.size}QuantitySelect`]}`,
                tabIndex: 0
              }}
            >
              {quantitySelectOptions}
            </Select>
          </>
        ) : <></>
      }

      <Button
        data-testid="add-to-cart-button"
        className={`${btnClass} ${preventClickCls}`}
        onClick={handleClick}
        variant={button.variant || 'contained'}
        color={button.color || 'primary'}
        role="button"
        aria-label={button.ariaLabel}
      >
        {isLoading && <CircularProgress aria-live="assertive" aria-label={ariaLabelLoading} size={24} />}
        {!isLoading && button.text}
      </Button>
    </Grid>
  );
};

export default SelectorButton;
