export default {
  status: 200,
  response: {
    address1: '678 Main Street',
    address2: '',
    address3: '',
    address4: '',
    allows_future_orders: true,
    city: 'Newark',
    country: 'US',
    future_order_days: 7,
    hours: [
      {
        days: [
          1,
          2,
          3,
          4,
          5,
          6,
          7
        ],
        duration: '15:00:00',
        interval_start_time: '08:00:00',
        name: 'Hours',
        occasion_id: 'CARRYOUT'
      },
      {
        days: [
          1,
          2,
          3,
          4,
          5,
          6,
          7
        ],
        duration: '14:00:00',
        interval_start_time: '10:00:00',
        name: 'Hours',
        occasion_id: 'DELIVERY'
      }
    ],
    landmark: '',
    metadata: {
      allow_free_alc_delv_promo: false,
      allow_free_pepsi_1L_delv_promo: false,
      allow_free_pepsi_20oz_delv_promo: false,
      apple_pay_enabled: false,
      call_booze_engine: false,
      can_partial_auth: false,
      is_age_gate_no_cash_on_delivery: true,
      no_curbside: false,
      pos_type: '2',
      show_sodium_alerts: false,
      store_concept: 'RBD',
      tip_percent_values: [
        '25',
        '20',
        '15'
      ]
    },
    phone_number: '315-333-1234',
    postal_code: '14513',
    show_unavailable_stock: true,
    start_of_day: '2020-08-17T08:00:00-05:00',
    state: 'NY',
    status: 'DC',
    store_id: '000006',
    store_number: '000006',
    timezone: 'America/New_York',
    taxes: [
      {
        application_stage: 1,
        name: 'Sales Tax',
        tax_id: 'desc1',
        tax_rate: -1
      },
      {
        application_stage: 1,
        name: 'desc2',
        tax_id: 'desc2',
        tax_rate: -1
      },
      {
        application_stage: 1,
        name: 'desc3',
        tax_id: 'desc3',
        tax_rate: -1
      },
      {
        application_stage: 1,
        name: 'desc4',
        tax_id: 'desc4',
        tax_rate: -1
      }
    ],
    payment_types: [
      {
        max_order: 30000,
        metadata: {},
        name: 'CASH',
        occasions: [
          'DELIVERY',
          'CARRYOUT'
        ],
        type: 'cash'
      },
      {
        max_order: 30000,
        metadata: {},
        name: 'CHECK',
        occasions: [
          'DELIVERY',
          'CARRYOUT'
        ],
        type: 'check'
      },
      {
        max_order: 30000,
        metadata: {
          accepted_cc_types: [
            'VISA',
            'MC',
            'DISC',
            'AMEX'
          ]
        },
        name: 'CREDITCARD',
        occasions: [
          'DELIVERY'
        ],
        type: 'creditcard'
      },
      {
        max_order: 30000,
        metadata: {},
        name: 'GIFTCARD',
        occasions: [
          'DELIVERY'
        ],
        type: 'giftcard'
      }
    ],
    occasions: [
      {
        apply_discount_to_net: true,
        max_order: 30000,
        name: 'Carryout',
        occasion_id: 'CARRYOUT',
        service_time: 15
      },
      {
        apply_discount_to_net: true,
        display_order: 1,
        max_order: 30000,
        min_order: 99,
        name: 'Delivery',
        occasion_id: 'DELIVERY',
        service_time: 30
      }
    ]
  }
};
