import { customerLoyaltyTransformed } from './customerLoyalty.stub';
import { CustomerLoyalty } from '@/domain/loyalty/CustomerLoyalty';

const customerLoyalty = typeof (jest) === 'undefined' ? new Promise(() => {}) : jest.fn();

const successfulCustomerLoyaltyInfo = (): ClientResult<CustomerLoyalty> => {
  const mockGetCustomerLoyalty = customerLoyalty as jest.Mock;
  const value = {
    error: false,
    result: customerLoyaltyTransformed
  };
  mockGetCustomerLoyalty.mockResolvedValue(value);
  return value;
};

const failedCustomerLoyaltyInfo = (): ClientResult<CustomerLoyalty> => {
  const mockGetCustomerLoyalty = customerLoyalty as jest.Mock;
  const value = {
    error: true,
    result: undefined
  };
  mockGetCustomerLoyalty.mockResolvedValue(value);
  return value;
};

export default customerLoyalty;

export {
  successfulCustomerLoyaltyInfo,
  failedCustomerLoyaltyInfo
};
