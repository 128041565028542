import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { openModal } from '@/localization/actions';
import { ShowModalWithGenericError, UseErrorModal } from './types';

export const showErrorModal: ShowModalWithGenericError = (
  dispatch, onTryAgain, onCancel
) => {
  const cta = {
    text: 'try again',
    callback: onTryAgain || (() => { })
  };
  const altCta = onCancel && {
    text: 'cancel',
    callback: onCancel
  };
  const modalContent: ModalContent = {
    title: 'We\'re sorry',
    body: 'Something went wrong. Please try again.',
    cta,
    altCta
  };
  dispatch(openModal(modalContent));
};

export const useErrorModal: UseErrorModal = ({
  onCancel, onTryAgain = () => {
  }
}) => {
  const dispatch = useDispatch();
  const show = () => {
    showErrorModal(dispatch, onTryAgain, onCancel);
  };
  return { show };
};

export const confirmRemoval = (
  dispatchRedux: Dispatch, itemType: string, itemName: string, cb: () => void, altCb?: () => void
): void => {
  const modalContent: ModalContent = {
    title: 'Are you sure?',
    body: `<strong>${itemName}</strong> will be removed.`,
    altCta: {
      text: 'Cancel',
      callback: altCb,
      reverseButtonsOrder: true
    },
    cta: {
      text: `Yes, Delete ${itemType}`,
      callback: cb,
      reverseButtonsOrder: true
    }
  };
  dispatchRedux(openModal(modalContent));
};
