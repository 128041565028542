import gql from 'graphql-tag';
import NutritionFragment from './nutrition';

const ItemFragment = gql`  
  fragment ItemFragment on Item {
    id
    name
    required
    type
    price
    imageURL
    selected
    priority
    glutenFree
    outOfStock
    maxAllowed
    splittable
    portion
    description
    defaultSelectedOption
    sodiumWarning

    # need two: the original data from GQL, and the @client one
      # that can override the data if desired
    isPanCrust
    isPanCrust @client

    nutrition {
      ...NutritionFragment
    }
  }
  ${NutritionFragment}
`;
export default ItemFragment;
