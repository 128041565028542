import { makeStyles, createStyles } from '@material-ui/core';
import fontStyles from '../fontStyles';
import colors from '@/common/colors';

export const useStyles = makeStyles(() => createStyles({
  inputLabel: {
    ...fontStyles.formFieldLabel,
    marginBottom: '8px'
  },
  tip: {
    color: colors.gray601,
    fontSize: '12px',
    marginLeft: '8px',
    lineHeight: '18px'
  }
}));
