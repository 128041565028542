import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Grid } from '@material-ui/core';
import styles from '../styles';

const CartItemSkeleton = () => {
  const classes = styles.cartItemSkeleton();

  return (
    <Grid
      container
      justifyContent="space-between"
      data-testid="cart-item-skeleton"
      alignItems="center"
      classes={{ root: classes.root }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ root: classes.item }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Skeleton animation="wave" variant="text" width={100} height={30} />
          </Grid>
          <Grid item>
            <Skeleton animation="wave" variant="text" width={30} height={20} />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item xs={10}>
            <Skeleton animation="wave" variant="text" width={200} height={20} />
            <Skeleton animation="wave" variant="text" width={200} height={20} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CartItemSkeleton;
