import { AnyAction, combineReducers } from 'redux';
import {
  LOAD_CART_INFORMATION, CLEAR_CART_INFORMATION, LOAD_CART_INFORMATION_FAILED
} from './legacyActionTypes';

const initSubtotal = '$0.00';
const initQuantity = 0;
const initHasAlcoholInCart = false;

const quantity = (state: number = initQuantity, action: AnyAction) => {
  switch (action.type) {
    case LOAD_CART_INFORMATION: {
      return action.cart.quantity;
    }
    case CLEAR_CART_INFORMATION: {
      return initQuantity;
    }
    default:
      return state;
  }
};

const subtotal = (state: string = initSubtotal, action: AnyAction) => {
  switch (action.type) {
    case LOAD_CART_INFORMATION: {
      return action.cart.subtotal;
    }
    case CLEAR_CART_INFORMATION: {
      return initSubtotal;
    }
    default:
      return state;
  }
};

const hasAlcoholInCart = (state: boolean = initHasAlcoholInCart, action: AnyAction) => {
  switch (action.type) {
    case LOAD_CART_INFORMATION: {
      return action.cart.hasAlcoholInCart;
    }
    case CLEAR_CART_INFORMATION: {
      return initHasAlcoholInCart;
    }
    default:
      return state;
  }
};

const error = (state = false, action: AnyAction) => {
  switch (action.type) {
    case LOAD_CART_INFORMATION_FAILED: {
      return true;
    }
    default:
      return state;
  }
};

const cartDomain = combineReducers({
  quantity,
  subtotal,
  hasAlcoholInCart,
  error
});

export default cartDomain;
