import { createSelector, createSlice } from '@reduxjs/toolkit';
import { StoreState } from '@/store';
import { SingleOrder } from '@/account/orders/orderHistory/order/Order.types';

export enum ReorderStatus {
  READY = 'ready',
  STARTING = 'starting',
  LOCALIZING = 'localizing',
  DONE_LOCALIZING = 'done localizing',
  LOCALIZED_AND_REORDERING = 'reordering'
}

export type ReorderableId = SingleOrder['orderId'] | Favorite['favoriteID'];

export interface ReorderState {
  state: ReorderStatus;
  id?: ReorderableId;
}

const initialReorderState = { state: ReorderStatus.READY };

const { actions, reducer } = createSlice({
  name: 'reorder',
  initialState: initialReorderState as ReorderState,
  reducers: {
    readyToReorder: () => ({ state: ReorderStatus.READY }),
    startingReorderFor: (state, { payload: { id } }) => ({ state: ReorderStatus.STARTING, id }),
    localizeForReorder: (state) => ({ ...state, state: ReorderStatus.LOCALIZING }),
    doneLocalizing: (state) => ({ ...state, state: ReorderStatus.DONE_LOCALIZING }),
    localizedAndReordering: (
      state
    ) => ({ ...state, state: ReorderStatus.LOCALIZED_AND_REORDERING }),
    doneReordering: () => initialReorderState
  }
});

const selectThisSlice = (state: StoreState): ReorderState => state.domain.reorder;
const selectors = {
  ready: createSelector(selectThisSlice, (reorder) => reorder.state === ReorderStatus.READY),
  starting: createSelector(
    selectThisSlice,
    (reorder) => reorder.state === ReorderStatus.STARTING
  ),
  reorderingFor: createSelector(selectThisSlice, (reorder) => reorder?.id),
  localizing: createSelector(
    selectThisSlice,
    (reorder) => reorder.state === ReorderStatus.LOCALIZING
  ),
  doneLocalizing: createSelector(
    selectThisSlice,
    (reorder) => reorder.state === ReorderStatus.DONE_LOCALIZING
  )
};

export {
  reducer, actions, selectors
};
