import {
  SET_PROMO_DATA,
  OVERRIDE_CMS_ENV,
  SET_TIMESTAMP,
  UPDATE_PROMO_DATA,
  OVERRIDE_GQL_VERSION
} from './actionTypes';
import { Occasion } from '../localization/constants';
import { PromoType } from '../dataAnalytics/dataAnalytics.d';
import { GqlVersion } from '../graphql/hooks/useGqlVersion/GqlVersion';

type PromoTypes = keyof typeof PromoType;

export const setPromoData = (
  initialApolloState: any,
  storeNumber: string,
  occasion: Occasion,
  isMobile: boolean,
  timestamp: string
) => ({
  type: SET_PROMO_DATA,
  initialApolloState,
  storeNumber,
  occasion,
  isMobile,
  timestamp
});

export const updatePromoData = (
  promoType: PromoTypes,
  promoData: any,
  isMobile?: boolean
) => ({
  type: UPDATE_PROMO_DATA,
  promoType,
  promoData,
  isMobile
});

export const overrideCmsEnv = (cmsEnvId: string) => ({
  type: OVERRIDE_CMS_ENV,
  cmsEnvId
});

export const overrideGqlVersion = (gqlVersion: GqlVersion) => ({
  type: OVERRIDE_GQL_VERSION,
  gqlVersion
});

export const setGqlTimestamp = (timestamp: string) => ({
  type: SET_TIMESTAMP,
  timestamp
});
