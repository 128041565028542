import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Price } from '../../../domain/Price';
import { RootState } from '@/rootStateTypes';

interface CartTotalPriceState extends Price {
  isLoading: boolean;
}

function domain(state: RootState): CartTotalPriceState {
  return state.presentational.cartTotalPrice;
}

function selectTotalInCents(state: RootState): number | null {
  return domain(state).totalPriceInCents;
}
function selectIsLoading(state: RootState): boolean {
  return domain(state).isLoading;
}

export const initialCartTotalPrice: CartTotalPriceState = {
  totalPriceInCents: null,
  isLoading: false
};

const CartTotalPriceSlice = createSlice({
  name: 'cartTotalPrice',
  initialState: initialCartTotalPrice,
  reducers: {
    updateCurrentBuilderPrice: (state: CartTotalPriceState, action: PayloadAction<Price>) => ({
      ...state,
      totalPriceInCents: action.payload.totalPriceInCents
    }),
    updatePriceIsLoading: (state: CartTotalPriceState, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload
    })
  }
});

export const {
  updateCurrentBuilderPrice,
  updatePriceIsLoading
} = CartTotalPriceSlice.actions;

export const cartTotalPriceSelectors = {
  selectTotalInCents,
  selectIsLoading
};

export default CartTotalPriceSlice.reducer;
