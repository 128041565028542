import {
  add, addDays, format, isBefore, isEqual, isSameDay
} from 'date-fns';
import { getStartAndEndTimes } from '@/localization/common/getOpeningHours/getStartAndEndTimes';

const INTERVAL = 15;

const getOpeningHours = (
  day: string,
  storeOpenTime: string,
  duration: string,
  promiseTime: number,
  isToday: boolean,
  storeTimezone: string
): Date[] => {
  const [
    firstTimeAvailable,
    lastTimeAvailable
  ] = getStartAndEndTimes(storeOpenTime, duration, promiseTime, isToday, storeTimezone);
  const openingHours = [];

  const firstTime = format(firstTimeAvailable, 'hh:mm a');

  let firstDateFormatted = new Date(`${day} ${firstTime}`);

  const lastTime = format(lastTimeAvailable, 'hh:mm a');
  let lastDateFormatted: Date;

  if (isSameDay(firstTimeAvailable, lastTimeAvailable)) {
    lastDateFormatted = new Date(`${day} ${lastTime}`);
  } else {
    const nextDay = addDays(new Date(day), 1);
    const nextDayFormatted = format(nextDay, 'MM/dd/yy');
    lastDateFormatted = new Date(`${nextDayFormatted} ${lastTime}`);
  }

  // generate dates in 15 minute intervals
  while (isBefore(firstDateFormatted, lastDateFormatted)
   || isEqual(firstDateFormatted, lastDateFormatted)) {
    openingHours.push(firstDateFormatted);
    firstDateFormatted = add(firstDateFormatted, { minutes: INTERVAL });
  }

  return openingHours;
};

export default getOpeningHours;
