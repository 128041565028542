import gql from 'graphql-tag';

const ImageFragmentV3 = gql`
  fragment ImageFragmentV3 on ImageV3 {
    desktop
    mobile
    altText
    title
  }
`;

export default ImageFragmentV3;
