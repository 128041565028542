import logger from './index';
import { NATIONAL, NATIONAL_STORE_ID_V2, NATIONAL_STORE_ID_V3 } from '../../localization/constants';
import telemetry from '../../telemetry';

interface Image {
  image?: {
    mobile: string;
    desktop: string;
  };
}

interface Link {
  link?: {
    link: string;
  };
}

export const SSR_CACHE_ERROR_CONTEXT = 'The GQL query result was not present in the server-side cache. '
    + 'This means that the component failed to server-side render. The GQL query will '
    + 'be tried again on the browser-side. This indicates either a bug (the server-side '
    + 'cache is not being populated with the right data), or a GQL call failure (either '
    + 'the call itself failed, or the response did not contain expected data).';

const getErrorMessage = (
  component: string, error: string, context: string, errorType = 'Data'
) => {
  const withContext = context ? ` | ${context}` : '';
  return `[GQL ${errorType} Error] | ${component} | ${error}${withContext}`;
};

const logGraphqlError = (
  component: string,
  error: string,
  context: string,
  errorType?: string
): void => logger.withoutTelemetry.error(getErrorMessage(component, error, context, errorType));

const sendGraphqlErrorToTelemetry = (
  component: string,
  error: string,
  context: string,
  errorType?: string
) => telemetry.addNoticeError(new Error(getErrorMessage(component, error, context, errorType)));

const storeOrNationalId = (id: string | null): string => {
  const nationalStoreIds = [NATIONAL_STORE_ID_V2, NATIONAL_STORE_ID_V3, null];
  return nationalStoreIds.includes(id) ? NATIONAL : id as string;
};

function logMissingImageError(array: Image[], component: string, storeId: string | null): void {
  const storeIdString = `store ID: ${storeOrNationalId(storeId)}`;

  array.forEach(({ image }, index) => {
    if (!image) {
      const message = `image ${index + 1} is missing`;
      sendGraphqlErrorToTelemetry(component, message, storeIdString);
      logGraphqlError(component, message, storeIdString);
      return;
    }

    if (!image.mobile) {
      const message = `Mobile image ${index + 1} is missing`;
      sendGraphqlErrorToTelemetry(component, message, storeIdString);
      logGraphqlError(component, message, storeIdString);
      return;
    }

    if (!image.desktop) {
      const message = `Desktop image ${index + 1} is missing`;
      sendGraphqlErrorToTelemetry(component, message, storeIdString);
      logGraphqlError(component, message, storeIdString);
    }
  });
}

function logMissingLinkError(array: Link[], component: string, storeId: string | null = null): void {
  const storeIdString = `store ID: ${storeOrNationalId(storeId)}`;

  array.forEach(({ link }, index) => {
    if (!link || !link.link) {
      const message = `link ${index + 1} is missing`;
      sendGraphqlErrorToTelemetry(component, message, storeIdString);
      logGraphqlError(component, message, storeIdString);
    }
  });
}

function logArrayEmptyError(component: string, storeId: string | null): void {
  const message = 'array is empty';
  const storeIdString = `store ID: ${storeOrNationalId(storeId)}`;

  sendGraphqlErrorToTelemetry(component, message, storeIdString);
  logGraphqlError(component, message, storeIdString);
}

function logCacheError(component: string): void {
  const message = 'Data not cached';
  const context = SSR_CACHE_ERROR_CONTEXT;

  sendGraphqlErrorToTelemetry(component, message, context, 'Query');
  logGraphqlError(component, message, context, 'Query');
}

export {
  logGraphqlError,
  logArrayEmptyError,
  logMissingImageError,
  logMissingLinkError,
  logCacheError,
  storeOrNationalId
};
