import React from 'react';
import {
  AppBar, Grid, Typography, Button
} from '@material-ui/core';
import CloseIcon from '@/localization/icons/CloseIcon';
import { ENTER_KEY_EVENT_CODE } from '@/common/constants';

import styles from './CartHeader.styles';
import { HEADER_TEXT, CLOSE_BTN_TESTID } from './constants';
import { Props } from './types';

export default function CartHeader(props: Props) {
  const { handleRailClose } = props;
  const classes = styles();

  function onCloseKeyDown(e: React.KeyboardEvent<HTMLButtonElement>) {
    if (e.key === ENTER_KEY_EVENT_CODE) {
      e.preventDefault();
      handleRailClose();
    }
  }

  return (
    <AppBar color="inherit" position="sticky" classes={{ root: classes.root }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.title}
      >
        <Grid item>
          <Typography
            variant="h1"
            data-testid="cart-rail-header-label"
            className={classes.label}
          >
            {HEADER_TEXT}
          </Typography>
        </Grid>
        <Grid item>
          <div className={classes.closeIconItem}>
            <Button
              data-testid={CLOSE_BTN_TESTID}
              aria-label="Close cart rail"
              onKeyDown={(e) => onCloseKeyDown(e)}
              onClick={handleRailClose}
              classes={{ root: classes.closeIcon }}
            >
              <CloseIcon />
            </Button>
          </div>
        </Grid>
      </Grid>
    </AppBar>
  );
}
