// eslint-disable-next-line max-classes-per-file
import { includeCredentials, phdapiPut } from '@/api/phdApiClient/base';
import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import AccountManagementEndpoints from '@/api/phdApiClient/accountManagement/endpoints';
import { createClientMethodImproved } from '@/api/createClientMethod';
import { Password } from '@/domain/Profile';

type Fetch = (token: string, password: Password) => Promise<Response>;

const fetch: Fetch = async (token, password) => phdapiPut(
  getUrl(AccountManagementEndpoints.UPDATE_PASSWORD),
  includeCredentials,
  JSON.stringify({
    token,
    password
  })
);

type Success = null;
export const success = (
  result: Success | PasswordComplexityError | UnexpectedUpdatePasswordError
): result is Success => result === null;

export class PasswordComplexityError extends Error { name = 'PasswordComplexityError'; }
export class RecentlyUsedPasswordError extends Error { name = 'RecentlyUsedPasswordError'; }
export class UnexpectedUpdatePasswordError extends Error {
  name = 'UnexpectedUpdatePasswordError';
}

type Failure = PasswordComplexityError | RecentlyUsedPasswordError | UnexpectedUpdatePasswordError;
type Result = Success | Failure;

const transformer = async (
  response: Response
): Promise<Result> => {
  if (response.status === 204) {
    return null; // success
  }

  let errorCode: `${number}`;
  let message: string;
  try {
    ({ error_code: errorCode, message } = await response.json());
  } catch (e) {
    return new UnexpectedUpdatePasswordError();
  }
  if (errorCode === '0309') {
    return new PasswordComplexityError(message);
  }
  if (errorCode === '0322') {
    return new RecentlyUsedPasswordError(message);
  }
  return new UnexpectedUpdatePasswordError();
};

export const updatePassword = createClientMethodImproved(fetch, transformer);
