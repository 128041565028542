import { commonUpsellAnalytics, getUpsellItems } from '@/dataAnalytics/dataAnalyticsHelper';
import {
  BaseAnalytics,
  BaseProductsAnalytics,
  BeaconIds,
  DealBaseAnalytics,
  ItemAnalytics,
  MenuProductAnalytics,
  PageViewWithItemsAnalytics
} from '@/dataAnalytics/analyticsTypes';
import { UpsellProducts } from '@/graphql/queries/upsell/upsell';
import { mockOnUpsellToastView } from '@/builders/deals/analytics/mock';

export enum ActionType {
  addMoreFood = 'Add More Food',
  viewOrder = 'View Order'
}

const productAnalytics = (item: ItemAnalytics, quantity: number) => ({
  product_index: item.item_index,
  product_id: item.item_id ?? '',
  product_name: item.item_name,
  product_category: item.item_category,
  product_quantity: quantity,
  product_price: item.item_price,
  product_position: item.item_position,
  product_action_list: 'Upsell',
  product_size: item.item_size ?? '',
  product_modifier: item.item_modifier ?? ''
});

export const onLoadUpsellPage = (
  items: UpsellProducts[],
  dealId?: string,
  dealName?: string
): PageViewWithItemsAnalytics => ({
  beacon_id: BeaconIds.UPSELL.INITIAL_LOAD,
  event: 'screen_view',
  ...commonUpsellAnalytics(dealId, dealName),
  items: getUpsellItems(items, 'Upsell Page')
});

export const upsellCTA = (
  action: ActionType,
  dealId?: string,
  dealName?: string
): DealBaseAnalytics => ({
  beacon_id: BeaconIds.UPSELL.CTA,
  ...commonUpsellAnalytics(dealId, dealName),
  event: 'button_click',
  event_category: 'Upsell',
  event_action: action,
  event_label: `Upsell > ${action}`
});

export const upsellProductAddedToCart = (
  item: ItemAnalytics,
  quantity: number
): BaseProductsAnalytics<MenuProductAnalytics> => ({
  ...commonUpsellAnalytics(),
  beacon_id: BeaconIds.UPSELL.ADD_TO_CART_CLICK,
  event_category: 'Upsell',
  event: 'product_click',
  event_action: 'Add to Order (Product List Click)',
  event_label: 'Upsell > Add to Order (Product List Click)',
  product_name_hit: item.item_name,
  product_action: 'click',
  products: [productAnalytics(item, quantity)]
});

export const upsellProductAddedToCartSuccess = (
  item: ItemAnalytics,
  quantity: number
): BaseProductsAnalytics<MenuProductAnalytics> => ({
  ...commonUpsellAnalytics(),
  beacon_id: BeaconIds.UPSELL.ADD_TO_CART_SUCCESS,
  event_category: 'Upsell',
  event: 'cart_add',
  event_action: 'Add to Order',
  event_label: 'Upsell > Add to Order',
  product_name_hit: item.item_name,
  product_action: 'Add',
  products: [
    {
      ...productAnalytics(item, quantity),
      product_added_location: 'Upsell'
    }
  ]
});

export const upsellProductAddedToCartFromRail = (
  item: ItemAnalytics,
  quantity: number
): BaseProductsAnalytics<MenuProductAnalytics> => ({
  ...commonUpsellAnalytics(),
  beacon_id: BeaconIds.UPSELL.ADD_TO_CART_CLICK_FROM_RAIL,
  event: 'product_click',
  event_category: 'Upsell',
  event_action: 'Product Info Slide > Add to Order',
  event_label: ' Upsell > Product Info Slide > Add to Order',
  product_name_hit: item.item_name,
  product_action: 'click',
  products: [{
    product_index: item.item_index,
    product_action_list: 'Upsell',
    product_quantity: quantity,
    product_id: item.item_id ?? '',
    product_name: item.item_name,
    product_category: 'Upsell',
    product_price: item.item_price,
    product_position: item.item_position,
    product_size: item.item_size ?? '',
    product_modifier: item.item_modifier ?? ''
  }]
});

export const upsellProductSuccessfullyAddedToCartFromRail = (
  item: ItemAnalytics,
  quantity: number
): BaseProductsAnalytics<MenuProductAnalytics> => ({
  ...commonUpsellAnalytics(),
  beacon_id: BeaconIds.UPSELL.ADD_TO_CART_SUCCESS_FROM_RAIL,
  event: 'cart_add',
  event_category: 'Upsell',
  event_action: 'Product Info Slide > Add to Order',
  event_label: ' Upsell > Product Info Slide > Add to Order',
  product_name_hit: item.item_name,
  product_action: 'Add',
  products: [{
    product_index: item.item_index,
    product_action_list: 'Upsell',
    product_quantity: quantity,
    product_id: item.item_id ?? '',
    product_name: item.item_name,
    product_category: 'Upsell',
    product_added_location: 'Upsell',
    product_price: item.item_price,
    product_position: item.item_position,
    product_size: item.item_size ?? '',
    product_modifier: item.item_modifier ?? ''
  }]
});

export const onUpsellToastCheckoutClick = (): BaseAnalytics => ({
  beacon_id: BeaconIds.UPSELL.TOAST.CHECKOUT_CLICK,
  event: 'button_click',
  screen_name: 'Upsell > Added to Order Toast',
  event_category: 'Upsell > Added to Order Toast',
  event_action: 'Checkout',
  event_label: ' Upsell > Added to Order Toast > Checkout',
  screen_level1: 'Upsell',
  screen_level2: 'Upsell > Added to Order Toast',
  screen_level3: 'Upsell > Added to Order Toast',
  screen_level4: 'Upsell > Added to Order Toast',
  level2_breadcrumb: 'Upsell',
  level3_aggregation: 'Upsell > Added to Order Toast'
});

export const onUpsellSelectSizeOrAdditionalOption = (
  product: string, modifier: string
): BaseAnalytics => ({
  beacon_id: BeaconIds.UPSELL.MODIFIER_CLICK,
  event: 'button_click',
  screen_name: 'Upsell',
  event_category: 'Upsell',
  event_action: modifier,
  event_label: `Upsell > ${modifier}`,
  screen_level1: 'Upsell',
  screen_level2: 'Upsell',
  screen_level3: 'Upsell',
  screen_level4: 'Upsell',
  level2_breadcrumb: 'Upsell',
  level3_aggregation: 'Upsell',
  product_name_hit: product
});

export const onUpsellToastView = (
  product: string
): Partial<BaseAnalytics> => ({
  beacon_id: BeaconIds.UPSELL.TOAST.INITIAL_LOAD,
  event: 'screen_view',
  screen_name: 'Upsell > Added to Order Toast',
  screen_level1: 'Upsell',
  screen_level2: 'Upsell',
  screen_level3: 'Upsell > Added to Order Toast',
  screen_level4: 'Upsell > Added to Order Toast',
  level2_breadcrumb: 'Upsell',
  level3_aggregation: 'Upsell > Added to Order Toast',
  product_name_hit: product
});
