import v3_query from './breakingNews.v3';
import { BreakingNewsQuery } from '../../types/BreakingNews';
import { GqlVersion } from '../../hooks/useGqlVersion/GqlVersion';

function useQuery(version?: GqlVersion): BreakingNewsQuery {
  switch (version) {
    default:
      return v3_query;
  }
}

export default useQuery;
