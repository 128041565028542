import { combineReducers } from 'redux';
import { presentationalRailReducer } from '@/clientCore/redux/rail/CartRailSlice';
import { presentationalCartStateReducer } from './cart/CartSlice';
import { confirmationStateReducer } from './confirmation/ConfirmationSlice';
import { checkoutStateReducer } from '@/clientCore/redux/checkout/CheckoutSlice';
import { debugModeStateReducer } from './debug/DebugSlice';

// const promiseAll = async (cookiesToGet) => {
//   const cookieValues = Promise.all([...cookiesToGet.map(authService.getCookieValue)]).then((values)=>{
//     return [...values]
//   })
//   return cookieValues
// }
// const getCCCartCookies = () => {
//   const cookiesToGet = [CC_CART_ID_COOKIE,CC_LOC_TOKEN_COOKIE]
//   const values = promiseAll(cookiesToGet).then(()=>values)
//   return values
// }

// const [ccCartIDCookie, ccLocTokenCookie] = getCCCartCookies();

const rootReducer = {
  clientCorePresentational: combineReducers({
    rail: presentationalRailReducer,
    cart: presentationalCartStateReducer
  }),
  confirmation: confirmationStateReducer,
  checkout: checkoutStateReducer,
  debugMode: debugModeStateReducer
};

export default rootReducer;
