import { SelectedRecipe } from './dealTypes';

export const dealWithRecipeAdded = (
  recipes: SelectedRecipe[],
  selectedRecipes: SelectedRecipe[]
): SelectedRecipe[] => {
  const indexFromSelectedRecipes = selectedRecipes.map((recipe) => recipe.index);
  const updatedRecipes = recipes
    ?.filter((recipe) => !indexFromSelectedRecipes.includes(recipe.index)) || [];

  return [
    ...updatedRecipes,
    ...selectedRecipes
  ].sort((a, b) => a && b && (a.index - b.index));
};
