import { RuleItem } from '@/builders/pizza/dataTransformers/builderTypes';

function findRuleBySizeAndCrust(sizeId?: string, crustId?: string) {
  if (!sizeId || !crustId) return () => false;

  return (ruleItem: RuleItem): boolean => {
    if (!ruleItem.sizeId || !ruleItem.crustId) return false;

    return sizeId.includes(ruleItem.sizeId) && crustId.includes(ruleItem.crustId);
  };
}

export default findRuleBySizeAndCrust;
