import { compose } from '@reduxjs/toolkit';
import { RootState } from '@/rootStateTypes';
import { CartAlert, OOSAlertType } from '@/clientCore/redux/rail/CartRailSlice';

export type SortedItemPointer = {
  cartItemId: string;
  itemId: string;
};

interface ClientCoreStoreType {
  rail: {
    isCartRailOpen: boolean;
    editCartItemId: string | null;
    cartChangedAlert: CartAlert | null;
    sortedItemPointers: SortedItemPointer[];
    oosAlertItemList: OOSAlertType[];
  };
}

// eslint-disable-next-line @typescript-eslint/no-shadow
const isCartRailOpen = (clientCoreStore: ClientCoreStoreType) => clientCoreStore.rail.isCartRailOpen;
const editCartItemId = (clientCoreStore: ClientCoreStoreType) => clientCoreStore.rail.editCartItemId;
const cartChangedAlert = (clientCoreStore: ClientCoreStoreType) => clientCoreStore.rail.cartChangedAlert;
const sortedItemPointers = (clientCoreStore: ClientCoreStoreType) => clientCoreStore.rail.sortedItemPointers;
const oosAlertItemList = (clientCoreStore: ClientCoreStoreType) => clientCoreStore.rail.oosAlertItemList;

const clientCoreStore = (state: RootState): ClientCoreStoreType => state.clientCorePresentational;

export const presentationalRailSelectors = {
  isCartRailOpen: compose(isCartRailOpen, clientCoreStore),
  editCartItemId: compose(editCartItemId, clientCoreStore),
  cartChangedAlert: compose(cartChangedAlert, clientCoreStore),
  sortedItemPointers: compose(sortedItemPointers, clientCoreStore),
  oosAlertItemList: compose(oosAlertItemList, clientCoreStore)
};
