import {
  Pizza,
  PizzaIngredient,
  PizzaIngredientOption
} from '../../dataTransformers/builderTypes';
import { isFinisher } from '../../identifiers';
import { restrictRulesBySizeAndCrust } from './rulesSelectors';

// #Finisher selectors
const selectPizzaFinisher = (pizza: Pizza): PizzaIngredient | null => pizza.finisher;

function selectFinisherOptions(pizza: Pizza) : PizzaIngredientOption[] {
  return pizza.pizzaOptions?.finishers?.filter(
    restrictRulesBySizeAndCrust(pizza, isFinisher)
  ) ?? [];
}

function selectPizzaFinisherOption(pizza: Pizza): PizzaIngredientOption | undefined {
  return pizza.pizzaOptions?.finishers?.find(({ id }) => id === pizza.finisher?.id);
}

function selectDefaultFlavor(pizza: Pizza): PizzaIngredientOption | undefined {
  return pizza.pizzaOptions?.finishers?.find(({ selected }) => selected);
}

export {
  selectDefaultFlavor,
  selectFinisherOptions,
  selectPizzaFinisher,
  selectPizzaFinisherOption
};
