import uuid from 'uuid-random';
import isClientSide from '../../common/isClientSide';
import ClsCrudHelper from './cls';

export class ConversationIDStoreImpl {
  get: () => string;

  set: (conversationID: string) => void;

  constructor() {
    if (isClientSide()) {
      this.get = uuid;
      this.set = () => undefined;
    } else {
      this.get = () => (ClsCrudHelper.get() || uuid());
      this.set = (conversationID: string) => {
        if ((conversationID && conversationID.trim())) {
          ClsCrudHelper.set(conversationID);
        } else {
          ClsCrudHelper.set(uuid());
        }
      };
    }
  }
}

const ConversationIDStore = new ConversationIDStoreImpl();
export default ConversationIDStore;
