import { AnyAction, combineReducers } from 'redux';
import {
  CART_LOADING,
  UPDATE_CART,
  UPDATE_CART_FAIL,
  CART_LOADED
} from './actionTypes';
import { TIP_INITIAL_VALUE } from '@/cart/constants';

const id = (state = 'temp-value-prevent-warning') => state;

const quantity = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART: {
      return action.cart.quantity;
    }
    default:
      return state;
  }
};

const subtotal = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART:
      return action.cart.subtotal;
    default:
      return state;
  }
};

const totalPriceInCents = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART: {
      return action.cart.totalPriceInCents;
    }
    default:
      return state;
  }
};

const tax = (state = '$0.00', action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART: {
      return action.cart.tax;
    }
    default:
      return state;
  }
};

const tip = (state = TIP_INITIAL_VALUE, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART: {
      return action.cart?.tip !== TIP_INITIAL_VALUE ? action.cart?.tip : TIP_INITIAL_VALUE;
    }
    default:
      return state;
  }
};

const percentage = (state = '', action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART: {
      return action.cart?.percentage !== '' ? action.cart?.percentage : '';
    }
    default:
      return state;
  }
};

const discountedAmount = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART: {
      return action.cart?.discountedAmount;
    }
    default:
      return state;
  }
};

const giftCards = (state = [], action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART: {
      return [];
    }
    default:
      return state;
  }
};

const items = (state = [], action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART: {
      return action.cart.items;
    }
    default:
      return state;
  }
};

const invalidItems = (state = [], action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART: {
      return action.cart.invalidItems;
    }
    default:
      return state;
  }
};

const fees = (state = [], action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART: {
      return action.cart.fees;
    }
    default:
      return state;
  }
};

const isLineupReady = (state = [], action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART: {
      return action.cart.isLineupReady;
    }
    default:
      return state;
  }
};

const lineupCount = (state = [], action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART: {
      return action.cart.lineupCount;
    }
    default:
      return state;
  }
};

const error = (state = false, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CART_FAIL: {
      return true;
    }
    default:
      return state;
  }
};

const loading = (state = false, action: AnyAction) => {
  switch (action.type) {
    case CART_LOADED:
    case UPDATE_CART:
    case UPDATE_CART_FAIL: {
      return false;
    }
    case CART_LOADING: {
      return true;
    }
    default:
      return state;
  }
};

const cartDomain = combineReducers({
  id,
  error,
  quantity,
  subtotal,
  items,
  invalidItems,
  totalPriceInCents,
  fees,
  tax,
  tip,
  percentage,
  giftCards,
  loading,
  discountedAmount,
  lineupCount,
  isLineupReady
});

export default cartDomain;
