import gql from 'graphql-tag';
import { ProductsByCategoryQuery } from '../../types/Category';
import ItemFragment from '../fragments/item';
import AvailabilityFragment from '../fragments/availability';
import transformer from './transformer';
import CategoryItemFragment from '@/graphql/queries/fragments/categoryItem';

const GET_PRODUCTS_BY_CATEGORY = gql`
  query getProducts($envID: String!, $pageID: String!, $storeID: String!, $occasions: [OccasionV3!]) {
    Homepage(envID: $envID, storeID: $storeID, occasions: $occasions) {
      category(categoryID: $pageID) {
        products {
          displayOrder
          item {
            exists
            ...CategoryItemFragment
            availability {
                ...AvailabilityFragment
              }
          }
        }
        categories {
          heading
          products {
            displayOrder
            item {
              exists
              ...ItemFragment
              availability {
                ...AvailabilityFragment
              }
              modifiers {
                name
                type
                id
                priority
                modifiers {
                  ...ItemFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${ItemFragment}
  ${CategoryItemFragment}
  ${AvailabilityFragment}
`;

const query = (): ProductsByCategoryQuery => (
  { query: GET_PRODUCTS_BY_CATEGORY, parser: transformer }
);

export default query;
