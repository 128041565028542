import { compose, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/rootStateTypes';
import { FavDetailsRailType, RailSliceType, RailType } from './Rail.slice.types';
import { ProductDetails } from '@/account/orders/favorites/Favorites.types';

const initialState: RailSliceType = {
  signIn: {
    options: {
      isOpen: false
    }
  },
  editFavorite: {
    options: {
      isOpen: false
    }
  },
  addFavorite: {
    options: {
      isOpen: false
    }
  },
  giftCardInfo: {
    options: {
      isOpen: false
    }
  },
  favDetails: {
    options: {
      isOpen: false,
      products: []
    }
  },
  editLoginInfo: {
    options: {
      isOpen: false
    }
  },
  manageAddress: {
    options: {
      isOpen: false
    }
  },
  confirmLocation: {
    options: {
      isOpen: false,
      isShown: false
    }
  }
};

const RailSlice = createSlice({
  name: 'Rail',
  initialState,
  reducers: {
    closeRail: (
      state: RailSliceType, action: PayloadAction<RailType>
    ) => {
      const railType = action.payload;
      Object.assign(state[railType].options, { isOpen: false });
    },
    openRail: (
      state: RailSliceType, action: PayloadAction<RailType>
    ) => {
      const railType = action.payload;

      if (state[railType]) {
        Object.assign(state[railType].options, { isOpen: true });
      }
    },
    toggleRail: (
      state: RailSliceType, action: PayloadAction<RailType>
    ) => {
      const railType = action.payload;

      if (state[railType]) {
        Object.assign(state[railType].options, { isOpen: !state[railType].options.isOpen });
      }
    },
    openFavoriteDetailsRail: (
      state: RailSliceType, action: PayloadAction<FavDetailsRailType>
    ) => {
      const { railType, products } = action.payload;

      if (state[railType]) {
        Object.assign(state[railType].options, { isOpen: true, products });
      }
    },
    openConfirmLocationRail: (
      state: RailSliceType, action: PayloadAction<RailType>
    ) => {
      const railType = action.payload;

      if (state[railType]) {
        Object.assign(state[railType].options, { ...state[railType].options, isOpen: true });
      }
    },
    closeConfirmLocationRail: (
      state: RailSliceType, action: PayloadAction<RailType>
    ) => {
      const railType = action.payload;
      Object.assign(state[railType].options, { isOpen: false, isShown: true });
    }
  }
});

const domain = (state: RootState): boolean => state.presentational.rail;

const isSignInRailVisible = (
  state: RailSliceType
): boolean => state.signIn.options.isOpen;

const isGiftCardInfoRailVisible = (
  state: RailSliceType
): boolean => state.giftCardInfo.options.isOpen;

const isEditFavoriteRailVisible = (
  state: RailSliceType
): boolean => state.editFavorite.options.isOpen;

const isAddFavoriteRailVisible = (
  state: RailSliceType
): boolean => state.addFavorite.options.isOpen;

const isFavDetailsVisible = (
  state: RailSliceType
): boolean => state.favDetails.options.isOpen;

const selectFavProducts = (
  state: RailSliceType
): ProductDetails[] => state.favDetails.options.products;

const isEditLoginInfoVisible = (
  state: RailSliceType
): boolean => state.editLoginInfo.options.isOpen;

const isManageAddressVisible = (
  state: RailSliceType
): boolean => state.manageAddress.options.isOpen;

const isConfirmLocationVisible = (
  state: RailSliceType
): boolean => state.confirmLocation.options.isOpen;

const isConfirmLocationShown = (
  state: RailSliceType
): boolean => state.confirmLocation.options.isShown;

export const selectors = {
  isSignInRailVisible: compose<boolean>(isSignInRailVisible, domain),
  isGiftCardInfoRailVisible: compose<boolean>(isGiftCardInfoRailVisible, domain),
  isEditFavoriteRailVisible: compose<boolean>(isEditFavoriteRailVisible, domain),
  isAddFavoriteRailVisible: compose<boolean>(isAddFavoriteRailVisible, domain),
  isFavDetailsVisible: compose<boolean>(isFavDetailsVisible, domain),
  selectFavProducts: compose<ProductDetails[]>(selectFavProducts, domain),
  isEditLoginInfoVisible: compose<boolean>(isEditLoginInfoVisible, domain),
  isAddAddressVisible: compose<boolean>(isManageAddressVisible, domain),
  isConfirmLocationVisible: compose<boolean>(isConfirmLocationVisible, domain),
  isConfirmLocationShown: compose<boolean>(isConfirmLocationShown, domain)
};

export const {
  closeRail,
  openRail,
  toggleRail,
  openFavoriteDetailsRail,
  closeConfirmLocationRail,
  openConfirmLocationRail
} = RailSlice.actions;

export default RailSlice.reducer;
