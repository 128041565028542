import clientSide from '../common/isClientSide';
import NewRelicAPMAdapter from './adapters/newRelicAPM';
import NewRelicBrowserAdapter from './adapters/newRelicBrowser';
import { InstrumentationAdapter } from './adapters/types';

class Telemetry {
  adapter: InstrumentationAdapter;

  constructor() {
    this.adapter = clientSide()
      ? new NewRelicBrowserAdapter()
      : new NewRelicAPMAdapter();
  }

  get agent() {
    return this.adapter.agent;
  }

  addCustomEvent(name: string, attributes?: Record<string, unknown>) {
    try {
      this.adapter.addCustomEvent(name, attributes);
    } catch (e) { } // eslint-disable-line
  }

  addCustomAttribute(name: string, value: string) {
    try {
      this.adapter.addCustomAttribute(name, value);
    } catch (e) { } // eslint-disable-line
  }

  addNoticeError(error: Error, customAttributes?: Record<string, unknown>) {
    try {
      this.adapter.addNoticeError(error, customAttributes);
    } catch (e) { } // eslint-disable-line
  }

  addCustomEventOnClick(actionName: string) {
    return () => this.addCustomEvent(actionName);
  }
}

const telemetry = new Telemetry();
export default telemetry;
