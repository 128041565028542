import { PHDApiStore } from '../types';

const phdApiStoreStub: PHDApiStore = {
  payment_types: [
    {
      name: 'CASH',
      occasions: [
        'DELIVERY',
        'CARRYOUT'
      ]
    },
    {
      name: 'CREDITCARD',
      occasions: [
        'DELIVERY'
      ]
    },
    {
      name: 'APPLEPAY',
      occasions: [
        'DELIVERY'
      ]
    }
  ]
};

export default phdApiStoreStub;
