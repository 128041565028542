import { Replacer } from '@/salesExperience/replacers/types';
import logger from '@/common/logger';
import telemetry from '@/telemetry';

const map: Record<string, string> = {
  'L_P_.B4GP4.M4.O4.P4PK4PS4_S': 'large-pan-supreme',
  'L_H_.B4GP4.M4.O4.P4PK4PS4_S': 'large-handtossed-supreme',
  'L_S_.B4GP4.M4.O4.P4PK4PS4_S': 'large-stuffed-supreme',
  'L_T_.B4GP4.M4.O4.P4PK4PS4_S': 'large-thin-supreme',
  'L_P_.B4BN4.H4IS4.P4PK4PS4_ML': 'large-pan-meatlovers',
  'L_H_.B4BN4.H4IS4.P4PK4PS4_ML': 'large-handtossed-meatlovers',
  'L_S_.B4BN4.H4IS4.P4PK4PS4_ML': 'large-stuffed-meatlovers',
  'L_T_.B4BN4.H4IS4.P4PK4PS4_ML': 'large-thin-meatlovers',
  L_P_PS4: 'large-pan-cheese',
  L_H_PS4: 'large-handtossed-cheese',
  L_S_PS4: 'large-stuffed-cheese',
  L_T_PS4: 'large-thin-cheese',
  'L_P_PS4.P4': 'large-pan-pepperoni',
  'L_H_PS4.P4': 'large-handtossed-pepperoni',
  'L_S_PS4.P4': 'large-stuffed-pepperoni',
  'L_T_PS4.P4': 'large-thin-pepperoni',
  'L_P_PS4.O4GP4.M4BO4DT4_VL': 'large-pan-veggielovers',
  'L_H_PS4.O4GP4.M4BO4DT4_VL': 'large-handtossed-veggielovers',
  'L_S_PS4.O4GP4.M4BO4DT4_VL': 'large-stuffed-veggielovers',
  'L_T_PS4.O4GP4.M4BO4DT4_VL': 'large-thin-veggielovers',
  'L_P_PS4.P4_PL ': 'large-pan-pepperonilovers',
  'L_H_PS4.P4_PL ': 'large-handtossed-pepperonilovers',
  'L_S_PS4.P4_PL ': 'large-stuffed-pepperonilovers',
  'L_T_PS4.P4_PL ': 'large-thin-pepperonilovers',
  'L_P_PS4.K4.H4PI4GP4_HK': 'large-pan-hawaiianchicken',
  'L_H_PS4.K4.H4PI4GP4_HK': 'large-handtossed-hawaiianchicken',
  'L_S_PS4.K4.H4PI4GP4_HK': 'large-stuffed-hawaiianchicken',
  'L_T_PS4.K4.H4PI4GP4_HK': 'large-thin-hawaiianchicken',
  'L_P_HQ4.K4BN4.O4_BY': 'large-pan-backyardbbqchicken',
  'L_H_HQ4.K4BN4.O4_BY': 'large-handtossed-backyardbbqchicken',
  'L_S_HQ4.K4BN4.O4_BY': 'large-stuffed-backyardbbqchicken',
  'L_T_HQ4.K4BN4.O4_BY': 'large-thin-backyardbbqchicken',
  'L_P_BS4.K4.O4BA4_BF': 'large-pan-buffalochicken',
  'L_H_BS4.K4.O4BA4_BF': 'large-handtossed-buffalochicken',
  'L_S_BS4.K4.O4BA4_BF': 'large-stuffed-buffalochicken',
  'L_T_BS4.K4.O4BA4_BF': 'large-thin-buffalochicken'
};

class PopularPizzaReplacerError extends Error {
  name = 'PopularPizzaReplacerError';
}

const popularPizzas: Replacer = {
  match(url) {
    return url.searchParams.has('fp');
  },

  replace(url) {
    const web1Id = url.searchParams.get('fp');
    if (web1Id === null) {
      const error = new PopularPizzaReplacerError('Did not find expected fp (query param)');
      logger.withoutTelemetry.error(String(error), url.toString());
      telemetry.addNoticeError(error, { url: url.toString() });
      return url.originalUrl;
    }

    const web2Id = map[web1Id];
    if (!web2Id) {
      const error = new PopularPizzaReplacerError('No mapping for web 1 popular pizza id.');
      logger.withoutTelemetry.error(String(error), url.toString());
      telemetry.addNoticeError(error, { url: url.toString() });
      return url.originalUrl;
    }

    url.searchParams.delete('fp');
    url.searchParams.set('productCode', web2Id);

    return `${url.prefix}menu/pizza/builder${url.search}`;
  }
};

export { popularPizzas };
