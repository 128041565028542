import React from 'react';
import { List, ListItem } from '@material-ui/core';

interface DialogListProps {
  list: string[];
  className: string;
}

const DialogList = ({ list, className }: DialogListProps): JSX.Element => (
  <List>
    {
        list.map(
          (listItem: string, index: number) => (
            <ListItem key={index} className={className}>
              {listItem}
            </ListItem>
          )
        )
      }
  </List>
);

export default DialogList;
