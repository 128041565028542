import deepmerge from 'deepmerge';

const merge = (existing, incoming) => {
  const overwriteMerge = (source, target) => target;

  return deepmerge(
    existing,
    incoming || {},
    { arrayMerge: overwriteMerge }
  );
};

export default merge;
