import React from 'react';

const SvgComponent = (props: any) => (
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#131313">
      <path d="M5.58 5.106c-1.056.845-1.52 1.443-1.735 2.517-.217 1.075-.102 1.81.077 2.567.18.758 1.28 3.098 2.901 4.769 1.62 1.67 5.14 3.59 6.208 3.779 1.07.19 1.95-.084 2.785-.57.836-.486 1.092-1.008 1.505-1.766.413-.759.493-1.05.184-1.442-.309-.393-3.165-2.243-3.639-2.22-.315.015-.891.559-1.727 1.632-1.07-.484-1.924-1.029-2.565-1.633a8.245 8.245 0 01-1.614-2.133c.655-.623 1.088-1.09 1.298-1.404.315-.47.506-.87.306-1.22-.199-.352-1.91-2.702-2.315-3.079-.406-.377-.614-.644-1.67.203zM5.558 5.215l3.361 4.3M12.647 13.665l4.54 2.834" />
      <path
        strokeWidth={0.5}
        d="M11.016 7.162c1.077.148 1.9.494 2.468 1.037.568.544.904 1.44 1.01 2.688M11.12 5.51c1.588.069 2.802.508 3.641 1.32.84.81 1.36 2.2 1.563 4.17"
      />
      <path
        strokeWidth={0.5}
        d="M11.12 3.565c2.038.24 3.626.916 4.766 2.026 1.14 1.111 1.933 2.914 2.382 5.41"
      />
    </g>
  </svg>
);

export default SvgComponent;
