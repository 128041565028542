import { Replacer } from '@/salesExperience/replacers/types';

// 'link.php?menu=deals': 'deals',
// 'link.php?menu=pizza': 'menu/pizza',
// 'link.php?menu=wings': 'menu/wings',
// 'link.php?menu=sides': 'menu/sides',
// 'link.php?menu=pasta': 'menu/pasta',
// 'link.php?menu=desserts': 'menu/desserts',
// 'link.php?menu=drinks': 'menu/drinks',
// 'link.php?menu=dips': 'menu/dips',
// 'link.php?menu=pzones': 'menu/pzone',
// 'link.php?menu=salads': 'menu/salads',
// 'link.php?menu=sandwiches': 'menu/sandwiches',
// 'link.php?menu=soups': 'menu/soups',

const mainMenu = (menuParam: string | null) => menuParam === '/' || menuParam === '';

const menu: Replacer = {
  match(url) {
    return url.pathname === '/link.php' && url.searchParams.has('menu');
  },

  replace(url) {
    const menuParam = url.searchParams.get('menu');
    url.searchParams.delete('menu');

    if (mainMenu(menuParam)) {
      return `${url.prefix}menu${url.search}`;
    }
    if (menuParam === 'pzones') {
      // plural => singular
      return `${url.prefix}menu/pzone${url.search}`;
    }
    return `${url.prefix}menu/${menuParam}${url.search}`;
  }
};

export { menu };
