import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export const CartCheckoutSkeleton = () => (
  <Grid
    container
    justifyContent="space-between"
    data-testid="total-price-skeleton"
    alignItems="center"
  >
    <Grid item>
      <Skeleton animation="wave" variant="text" width={80} height={30} />
    </Grid>
  </Grid>
);
