import { AuthState } from './../auth/userStates';
import { Action, combineReducers } from 'redux';
import { LOGOUT } from '../common/logout/actions';
import {
  AUTH_REFRESH_REQUEST_RECEIVED,
  AUTH_TOKEN_EXPIRED,
  AUTH_TOKEN_REFRESH_FAILURE, REFRESH_AUTH_AFTER_SESSION_EXPIRY,
  SET_LAST_ACTIVE_TIMESTAMP
} from '../auth/actions';
import {
  CUSTOMER_DETAILS_SUCCESS,
  CUSTOMER_DETAILS_FAILURE,
  LOGIN_STATUS_FALSE
} from './actionTypes';
import { reducer as profile } from '@/account/profile/profile.slice'

const defaultLoginStatus = AuthState.UNKNOWN;
const loginStatus = (state: any = defaultLoginStatus, action: Action) => {
  switch (action.type) {
    case CUSTOMER_DETAILS_SUCCESS: {
      return AuthState.LOGGED_IN;
    }
    case AUTH_TOKEN_EXPIRED:
      return AuthState.EXPIRED
    case CUSTOMER_DETAILS_FAILURE:
    case AUTH_TOKEN_REFRESH_FAILURE:
    case LOGIN_STATUS_FALSE:
    case LOGOUT: {
      return AuthState.GUEST;
    }
    default:
      return state;
  }
};

const lastActive = (state: number = new Date().getTime(), action: Action) => {
  switch (action.type) {
    case SET_LAST_ACTIVE_TIMESTAMP: {
      return (action as any).timestamp;
    }
    default:
      return state;
  }
};

const shouldRefreshAuth = (state = false, action: Action) => {
  switch (action.type) {
    case REFRESH_AUTH_AFTER_SESSION_EXPIRY: {
      return true;
    }
    case AUTH_REFRESH_REQUEST_RECEIVED: {
      return false;
    }
    default:
      return state;
  }
};

const authState = (state = AuthState.UNKNOWN, action: Action) => {
  switch (action.type) {
    case AuthState.UNKNOWN:
    case AuthState.EXPIRED:
    case AuthState.GUEST:
    case AuthState.LOGGED_IN: {
      return action.type;
    }
    default:
      return state;
  }
};

export default combineReducers({ loginStatus, lastActive, shouldRefreshAuth, authState, profile });
