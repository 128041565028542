import { DEFAULT_EXPERIENCE, WEB2 } from '@/salesExperience/constants';
import { isSalesExperience } from '@/salesExperience/types';

const isFeatureEnabled = (
  cookieOverride: string | undefined,
  runtimeConfigValue: string,
  salesExperience: string = DEFAULT_EXPERIENCE,
  ignoreExperience = false
): boolean => {
  if (cookieOverride) return cookieOverride === 'true';

  const parsedSalesExperience = isSalesExperience(salesExperience)
    ? salesExperience
    : DEFAULT_EXPERIENCE;

  return runtimeConfigValue === 'true'
    && (ignoreExperience || parsedSalesExperience === WEB2);
};

export default isFeatureEnabled;
