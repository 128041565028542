/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { HutRewardsSignedUp } from '@/checkout/types';
import {
  ConfirmationSliceData,
  ConfirmationStateTypes,
  CustomerBasicInfo
} from '@/clientCore/redux/confirmation/types';

const initialState: ConfirmationStateTypes = {
  data: {} as ConfirmationSliceData,
  hutRewardsSignUpStatus: HutRewardsSignedUp.NOT_ENROLLED,
  customerBasicInfo: {} as CustomerBasicInfo
};

export const ConfirmationStateSlice = createSlice({
  initialState,
  name: 'ConfirmationState',
  reducers: {
    setConfirmationData: (
      state: ConfirmationStateTypes,
      {
        payload: { data }
      }: {
        payload: {
          data: ConfirmationSliceData;
        };
      }
    ) => {
      state.data = data;
    },
    setOrderId: (
      state: ConfirmationStateTypes,
      {
        payload: { orderId }
      }: {
        payload: {
          orderId: string;
        };
      }
    ) => {
      state.data.orderId = orderId;
    },
    updateHutrewardsSignUpStatus: (
      state: ConfirmationStateTypes,
      {
        payload: { hutRewardsSignUpStatus }
      }: {
        payload: {
          hutRewardsSignUpStatus: HutRewardsSignedUp;
        };
      }
    ) => {
      state.hutRewardsSignUpStatus = hutRewardsSignUpStatus;
    },
    customerBasicInfo: (
      state: ConfirmationStateTypes,
      {
        payload: { customerBasicInfo }
      }: {
        payload: {
          customerBasicInfo: CustomerBasicInfo;
        };
      }
    ) => {
      state.customerBasicInfo = customerBasicInfo;
    }
  }
});

export const confirmationStateReducer = ConfirmationStateSlice.reducer;

export const {
  setConfirmationData,
  setOrderId,
  updateHutrewardsSignUpStatus,
  customerBasicInfo
} = ConfirmationStateSlice.actions;
