import setAttributes from './setAttributes';

export function setIsAuthenticated(isAuthenticated: boolean) {
  setAttributes({ isAuthenticated });
}

export function setStoreNumber(storeNumber: string) {
  setAttributes({ storeNumber });
}

export function setActiveDealId(activeDeal: string | undefined) {
  setAttributes({ activeDeal });
}

export function setPizzaId(id: string | undefined) {
  setAttributes({ id });
}

export function setMinDeliveryAmountMet(minimumDeliveryFee: boolean | undefined) {
  setAttributes({ minimumDeliveryFee });
}
