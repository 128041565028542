export const TIPS_PANEL = {
  carryout: {
    title: 'Tip your team members?',
    cashTip:
      "Since you've chosen to pay with cash, you can add a tip when you get to the restaurant.",
    giftCardTip:
      "Since you've chosen to pay with gift card, you can add a tip when you get to the restaurant.",
    noTip:
      'We’re sorry this store does not currently allow online tipping. You can add a tip when you get to the restaurant.'
  },
  delivery: {
    title: 'Add a tip for your driver?',
    cashTip:
      "Since you've chosen to pay with cash, you can add a tip when your driver arrives.",
    giftCardTip:
      "Since you've chosen to pay with gift card, you can add a tip at the door.",
    noTip:
      'We’re sorry this store does not currently allow online tipping. You can add a tip at the door.'
  },
  guestNoTip: {
    carryout:
      'Online tipping is currently unavailable, you can add a tip when you get to the restaurant.',
    deliveryCash:
      "Since you've chosen to pay with cash, you can add a tip at the door",
    deliveryCreditCard:
      'Online tipping is currently unavailable, you can add a tip at the door'
  },
  customTipZeroPercent: '0%'
};
