import gql from 'graphql-tag';
import decodeEntities from '@/graphql/helpers/decodeEntities';
import { Category as CategoryBase, CategoryByIdResult } from '../../types/Category';

const rawQuery = gql`
  query getCategoryById($storeID: String, $envEnvironmentID: String!, $categoryID: String!) {
    Homepage(envID: $envEnvironmentID, storeID: $storeID) {
      category(categoryID: $categoryID) {
        displayName
        mainImage {
          altText
          desktop
          mobile
        }
      }
    }
  }
`;

type NonNullableFields<T, K extends keyof T> = {
  [P in K]: NonNullable<T[P]>
} & T;

// Domain type, find a place where to store it
export type Category = NonNullableFields<
Pick<CategoryBase, 'displayName' | 'mainImage'>,
'displayName'
>;

const parser = (
  data: CategoryByIdResult
): Category => {
  const category = data.Homepage?.category;

  if (!category || !category.displayName) throw new Error('Could not find category');

  return {
    displayName: decodeEntities(category.displayName),
    mainImage: category.mainImage
  };
};

const query = { query: rawQuery, parser };
export default query;
