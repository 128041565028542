import {
  BaseProductsAnalytics,
  BeaconIds, MenuProductAnalytics,
  NationalItemAnalytics,
  PageViewWithNationalItemsAnalytics,
  PizzaProductAnalytics,
  ScreenLevelAnalytics
} from '@/dataAnalytics/analyticsTypes';
import {
  DealStepClickAnalyticsProps,
  DealBaseAnalytics, DealWithItems, DealWithProducts, DealWithPizzaProduct
} from './types';
import {
  ChildDealStep, CurrentStep, Deal, DealRecipe, DealSummary
} from '@/builders/deals/slice/dealTypes';
import ProductId from '@/common/ProductId';
import { BuiltPizza } from '@/builders/pizza/dataTransformers/builderTypes';
import { joinText } from '@/common/string-formatter';
import StepType from '../StepType';
import { Product } from '@/domain/product/types';
import {
  convertSelectedItemIntoProductAnalytics,
  getChildDealItems
} from '@/dataAnalytics/dataAnalyticsHelper';
import { MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';

export const commonDealBuilderScreenAnalytics = (
  page: string, deal_name?: string, deal_id?: string
) => ({
  screen_name: `Builder > Deal Builder > ${page}`,
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: `Builder > Deal Builder > ${page}`,
  screen_level4: `Builder > Deal Builder > ${page} > ${deal_name}`,
  level2_breadcrumb: `Deals > ${page}`,
  level3_aggregation: page,
  deal_name,
  deal_id: deal_id ? new ProductId(deal_id).globalId : ''
});

export const commonPizzaMenuOnDealAnalytics = (
  deal_name: string, deal_id: string
): ScreenLevelAnalytics => ({
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Pizza Builder',
  screen_level4: 'Builder > Deal Builder > Pizza Builder > Menu',
  level2_breadcrumb: 'Deals > Pizza Builder',
  level3_aggregation: 'Menu > Pizza Builder',
  screen_name: 'Builder > Deal Builder > Pizza Builder > Menu',
  deal_name,
  deal_id: deal_id ? new ProductId(deal_id).globalId : ''
});

export const createItemAnalyticsForNationalDeals = (
  deals: MenuRecipe[]
): NationalItemAnalytics[] => deals.map((deal, index) => ({
  item_list: 'Deals',
  item_index: index,
  item_price: deal.price ? deal.price / 100 : null,
  item_name: deal.name,
  item_category: 'Deals',
  item_position: `${index + 1}`,
  item_stock: deal.outOfStock ? 'Out of Stock' : 'In Stock',
  item_id: deal.id
}));

export const onMobileTapOnBackArrowOrContinue = ({
  deal_id,
  deal_name,
  product_name_hit
}: DealBaseAnalytics, action: 'Collapse' | 'Back Arrow' | 'Continue'): DealBaseAnalytics => ({
  ...commonDealBuilderScreenAnalytics('Product Builder', deal_name, deal_id),
  beacon_id: BeaconIds.DEAL.BACK_ARROW,
  event_action: action,
  event: 'button_click',
  event_category: 'Builder > Deal Builder',
  event_label: `Builder > Deal Builder > ${action}`,
  product_name_hit
});

export const onDealStepClick = ({
  step_name,
  deal_id,
  deal_name,
  product_name_hit,
  step_number
}: DealStepClickAnalyticsProps): DealBaseAnalytics => ({
  ...commonDealBuilderScreenAnalytics('Step Review', deal_name, deal_id),
  event: 'product_click',
  event_category: 'Builder > Deal Builder',
  event_action: `Edit > Step ${step_number} > ${step_name}`,
  event_label: `Builder > Deal Builder > Edit > Step ${step_number} > ${step_name}`,
  level2_breadcrumb: 'Deals > Deal Builder',
  product_action: 'click',
  beacon_id: BeaconIds.DEAL.CLICK_STEP,
  product_name_hit
});

export const onDealAnalyticsClick = (
  deal_id: string,
  deal: MenuRecipe,
  index: number | undefined
): DealWithProducts => ({
  event: 'button_click',
  screen_name: 'Menu > Deals',
  event_category: 'Menu > Deals',
  event_action: 'Deal List Click',
  event_label: 'Menu > Deals > Deal List Click',
  screen_level1: 'Menu',
  screen_level2: 'Menu > Deals',
  screen_level3: 'Menu > Deals',
  screen_level4: 'Menu > Deals',
  level2_breadcrumb: 'Deals',
  level3_aggregation: 'Deals',
  beacon_id: BeaconIds.DEAL.MENU_TILE_CLICK,
  deal_id,
  deal_name: deal.name,
  product_action: 'click',
  products: [{
    product_index: 0,
    product_id: deal_id ? new ProductId(deal_id).globalId : '',
    product_name: deal.name,
    product_category: 'Deals',
    product_price: (deal.price || 0) / 100,
    product_quantity: 1,
    product_deal_id: deal_id ? new ProductId(deal_id).globalId : '',
    product_deal_name: deal.name,
    product_action_list: 'Deals',
    product_position: index ? `${index + 1}` : '',
    product_size: null,
    product_modifier: null
  }]
});

export const onNationalDealMenuView = (
  items: NationalItemAnalytics[]
): PageViewWithNationalItemsAnalytics => ({
  beacon_id: BeaconIds.MENU.DEALS.NATIONAL_DEAL_MENU,
  event: 'screen_view',
  screen_name: 'Menu > Deals > National Deals',
  screen_level1: 'Menu',
  screen_level2: 'Menu > Deals',
  screen_level3: 'Menu > Deals > National Deals',
  screen_level4: 'Menu > Deals > National Deals',
  level2_breadcrumb: 'Deals',
  level3_aggregation: 'National Deals',
  items
});

const getDealData = (deal: DealSummary) => {
  const { globalId: dealGlobalId } = new ProductId(deal?.id);

  return {
    dealName: deal.name,
    dealId: dealGlobalId
  };
};

export const onMultiStepDealRenderAnalytics = (deal: DealSummary): DealBaseAnalytics => {
  const { dealId, dealName } = getDealData(deal);

  const baseAnalytics = {
    ...commonDealBuilderScreenAnalytics('Step Review', dealName, dealId),
    beacon_id: BeaconIds.DEAL.MULTI_STEP.RENDER,
    event: 'screen_view',
    level2_breadcrumb: 'Deals > Deal Builder',
    level3_aggregation: 'Step Review',
    product_action: 'Detail',
    product_name_hit: dealName
  };

  return baseAnalytics;
};

export const initialDealBuilderAnalytics = (
  deal: DealSummary,
  currentStep?: CurrentStep | null
): DealBaseAnalytics | DealWithProducts | DealWithItems => {
  const { dealId, dealName } = getDealData(deal);
  const items = currentStep?.recipeOptions;

  const baseAnalytics = {
    ...commonDealBuilderScreenAnalytics('Start Deal', dealName, dealId),
    beacon_id: BeaconIds.DEAL.INITIAL_LOAD,
    level2_breadcrumb: 'Deals > Start Deal',
    level3_aggregation: 'Start Deal',
    event: 'screen_view',
    form_name: 'Deal Builder'
  };

  switch (currentStep?.type) {
    case StepType.SINGLE_PIZZA_RECIPE:
      return {
        ...baseAnalytics,
        product_action: 'Detail',
        product_name_hit: items ? items[0].name : '',
        products: items?.map((item) => ({
          product_index: 0,
          product_id: item.fullId ?? '',
          product_name: item.name,
          product_category: 'Deals',
          product_price: (item.price || 0) / 100,
          product_quantity: 1,
          product_deal_id: dealId,
          product_deal_name: dealName
        }))
      };

    case StepType.MULTI_PIZZA_RECIPE:
      return {
        ...baseAnalytics,
        items: items?.map((item, index) => ({
          item_list: 'Deals',
          item_category: 'Deals',
          item_index: index,
          item_position: `${index + 1}`,
          item_stock: item.outOfStock ? 'Out of Stock' : 'In Stock',
          item_id: item.fullId ?? '',
          item_name: item.name,
          item_price: (item.price || 0) / 100
        }))
      };

    default:
      return baseAnalytics;
  }
};

export const addToDealClickAnalytics = ({
  deal_id,
  deal_name,
  product_name_hit
}: DealBaseAnalytics,
pizza: BuiltPizza): DealWithPizzaProduct => ({
  ...commonDealBuilderScreenAnalytics('Pizza Builder', deal_name, deal_id),
  beacon_id: BeaconIds.DEAL.ADD_TO_DEAL,
  event: 'button_click',
  event_category: 'Builder > Deal Builder > Pizza Builder',
  event_action: 'Add to Deal',
  event_label: 'Builder > Deal Builder > Pizza Builder > Add to Deal',
  level2_breadcrumb: 'Builder > Deal Builder',
  level3_aggregation: 'Builder > Deal Builder > Pizza Builder',
  product_name_hit,
  form_name: 'Pizza Builder',
  products: [{
    product_index: 0,
    product_id: pizza?.id ?? '',
    product_name: pizza?.name ?? '',
    product_category: 'Deals',
    product_quantity: 1,
    product_price: (pizza.price && pizza.price / 100) ?? 0,
    product_size: pizza.size?.name ?? '',
    product_sauce: pizza.sauce?.name ?? '',
    product_cheese: pizza.cheese?.name ?? '',
    product_crust: pizza.crust?.name ?? '',
    product_finisher: pizza.finisher?.name ?? '',
    product_veggies: joinText(pizza?.veggieToppings?.map(({ name }) => name)) ?? '',
    product_meats: joinText(pizza?.meatToppings?.map(({ name }) => name)) ?? '',
    product_deal_id: deal_id,
    product_deal_name: deal_name
  } as PizzaProductAnalytics]
});

export const nonPizzaProductAddToDealClickAnalytics = ({
  deal_id,
  deal_name,
  product_name_hit
}: DealBaseAnalytics,
product: Product): DealWithProducts => ({
  ...commonDealBuilderScreenAnalytics('Product Builder', deal_name, deal_id),
  beacon_id: BeaconIds.DEAL.ADD_NON_PIZZA_PRODUCT_TO_DEAL,
  event: 'button_click',
  event_action: 'Add to Deal',
  event_category: 'Builder > Deal Builder',
  event_label: 'Builder > Deal Builder > Add to Deal',
  form_name: 'Product Builder',
  product_name_hit,
  products: [
    {
      product_index: 0,
      product_id: product.id || '',
      product_name: product.name,
      product_category: 'Deals',
      product_quantity: product.quantity,
      product_price: 0,
      product_deal_id: deal_id || '',
      product_deal_name: deal_name || ''
    }
  ]
});

export const nonPizzaProductAddToDealClickAnalyticsFromRail = ({
  deal_id,
  deal_name,
  product_name_hit
}: DealBaseAnalytics,
product: Product): DealWithProducts => ({
  ...commonDealBuilderScreenAnalytics('Product Builder', deal_name, deal_id),
  beacon_id: BeaconIds.DEAL.ADD_NON_PIZZA_PRODUCT_TO_DEAL_FROM_RAIL,
  event: 'button_click',
  event_action: 'Product Info Slide > Add to Deal',
  event_category: 'Builder > Deal Builder',
  event_label: 'Builder > Deal Builder > Product Info Slide > Add to Deal',
  form_name: 'Product Builder',
  level2_breadcrumb: 'Deals > Product Builder',
  level3_aggregation: 'Product Builder',
  product_name_hit,
  products: [
    {
      product_index: 0,
      product_id: product.id || '',
      product_name: product.name,
      product_category: 'Deals',
      product_quantity: product.quantity,
      product_price: 0,
      product_deal_id: deal_id || '',
      product_deal_name: deal_name || ''
    }
  ]
});

export const onParentDealPageLoad = (
  deal_id: string,
  deal_name: string,
  items?: ChildDealStep[]
): DealWithItems => ({
  event: 'screen_view',
  screen_name: `Deals > ${deal_name}`,
  screen_level1: 'Deals',
  screen_level2: `Deals > ${deal_name}`,
  screen_level3: `Deals > ${deal_name}`,
  screen_level4: `Deals > ${deal_name}`,
  level2_breadcrumb: 'Deals',
  level3_aggregation: `Deals > ${deal_name}`,
  beacon_id: BeaconIds.DEAL.PARENT_DEAL_PAGE_LOAD,
  deal_id: new ProductId(deal_id).globalId,
  deal_name,
  items: items ? getChildDealItems(items) : []
});

export const onChildDealClick = (
  parent_deal_id: string,
  parent_deal_name: string,
  deal_id: string,
  product: ChildDealStep,
  index: number
): BaseProductsAnalytics<MenuProductAnalytics> => ({
  beacon_id: BeaconIds.DEAL.CHILD_DEAL_TILE_CLICK,
  event: 'button_click',
  event_category: `Deals > ${parent_deal_name}`,
  event_action: 'Deal List Click',
  event_label: `Deals > ${parent_deal_name} > Deal List Click`,
  screen_name: `Deals > ${parent_deal_name}`,
  screen_level1: 'Deals',
  screen_level2: `Deals > ${parent_deal_name}`,
  screen_level3: `Deals > ${parent_deal_name}`,
  screen_level4: `Deals > ${parent_deal_name}`,
  level2_breadcrumb: 'Deals',
  level3_aggregation: `Deals > ${parent_deal_name}`,
  deal_id,
  deal_name: product.name,
  product_action: 'click',
  products: [
    {
      product_index: index,
      product_action_list: 'Deals',
      product_id: product.id,
      product_name: product.name,
      product_category: 'Deals',
      product_price: (product.price ?? 0) / 100,
      product_position: `${index + 1}`,
      product_deal_id: new ProductId(parent_deal_id).globalId,
      product_deal_name: parent_deal_name,
      product_size: null,
      product_modifier: null,
      product_quantity: 1
    }
  ]
});

export const onAddMultiStepDealToOrderSuccess = (
  deal: Deal
): BaseProductsAnalytics<PizzaProductAnalytics | MenuProductAnalytics> => {
  const dealId = new ProductId(deal.data.id).globalId;
  const dealName = deal.data.name;
  const { recipes } = deal.userSelections.present;
  return {
    ...commonDealBuilderScreenAnalytics('Step Review', dealName, dealId),
    beacon_id: BeaconIds.DEAL.ON_MULTI_STEP_SUCCESS_ADD_TO_ORDER,
    event: 'cart_add',
    event_category: 'Builder > Deal Builder',
    event_action: 'Add to Order',
    event_label: 'Builder > Deal Builder > Add to Order',
    level2_breadcrumb: 'Deals > Deal Builder',
    form_name: 'Deal Builder',
    product_action: 'Add',
    products: recipes.map(
      (recipe) => convertSelectedItemIntoProductAnalytics(recipe, deal.data.steps, dealId, dealName)
    ) ?? []
  };
};

export const onAddMultiStepDealToOrderButtonClick = (
  deal: Deal
): BaseProductsAnalytics<PizzaProductAnalytics | MenuProductAnalytics> => {
  const dealId = new ProductId(deal.data.id).globalId;
  const dealName = deal.data.name;
  const { recipes } = deal.userSelections.present;
  return {
    ...commonDealBuilderScreenAnalytics('Step Review', dealName, dealId),
    beacon_id: BeaconIds.DEAL.ON_MULTI_STEP_CLICK_ADD_TO_ORDER,
    event: 'product_click',
    event_category: 'Builder > Deal Builder',
    event_action: 'Add to Order',
    event_label: 'Builder > Deal Builder > Add to Order',
    level2_breadcrumb: 'Deals > Deal Builder',
    product_action: 'click',
    products: recipes.map(
      (recipe) => convertSelectedItemIntoProductAnalytics(recipe, deal.data.steps, dealId, dealName)
    ) ?? []
  };
};

export const onAddToOrderButtonView = (
  pizzaName: string, deal_name: string, deal_id: string, isLineup: boolean
): DealBaseAnalytics => {
  const baseAnalytics = {
    beacon_id: BeaconIds.BUILDER.ADD_TO_ORDER_BUTTON_VIEW,
    event: 'add_impression',
    event_action: 'Add Impression',
    form_name: 'Pizza Builder',
    product_name_hit: pizzaName
  };

  if (deal_id) {
    return {
      ...baseAnalytics,
      ...commonDealBuilderScreenAnalytics(
        'Pizza Builder', deal_name, new ProductId(deal_id).globalId
      ),
      event_label: 'Builder > Deal Builder > Pizza Builder > Add Impression',
      event_category: 'Builder > Deal Builder > Pizza Builder'
    };
  }

  if (isLineup) {
    return {
      ...baseAnalytics,
      ...commonDealBuilderScreenAnalytics(
        'Pizza Builder', 'Lineup', 'Lineup'
      ),
      screen_name: 'Builder > Deal Builder > Pizza Builder > Lineup',
      event_label: 'Builder > Deal Builder > Add Impression',
      event_category: 'Builder > Deal Builder',
      level3_aggregation: 'Pizza Builder > Lineup'
    };
  }

  return {
    ...baseAnalytics,
    screen_name: 'Builder > Pizza Builder',
    event_category: 'Builder > Pizza Builder',
    event_label: 'Builder > Pizza Builder > Add Impression',
    screen_level1: 'Builder',
    screen_level2: 'Builder > Pizza Builder',
    screen_level3: 'Builder > Pizza Builder',
    screen_level4: `Builder > Pizza Builder > ${pizzaName}`,
    level2_breadcrumb: 'Menu > Pizza Builder',
    level3_aggregation: 'Menu > Pizza Builder'
  };
};

export const onPizzaMenuTileClickInsideDeal = (
  deal_name: string, deal_id: string, pizza: DealRecipe, index: number
): BaseProductsAnalytics<MenuProductAnalytics> => ({
  ...commonPizzaMenuOnDealAnalytics(deal_name, deal_id),
  beacon_id: BeaconIds.DEAL.PIZZA_MENU_TILE_CLICK,
  event: 'button_click',
  event_action: 'Deal List Click',
  event_category: 'Menu > Deals',
  event_label: 'Menu > Deals > Deal List Click',
  product_action: 'click',
  product_name_hit: pizza.name,
  products: [{
    product_index: index,
    product_id: pizza.fullId ?? '',
    product_name: pizza.name,
    product_category: 'Deals',
    product_quantity: 1,
    product_price: null,
    product_position: `${index + 1}`,
    product_action_list: 'Deals',
    product_size: null,
    product_modifier: null,
    product_deal_id: new ProductId(deal_id).globalId,
    product_deal_name: deal_name
  }]
});
