import { CartItem, Fee, GiftCardTypes } from '@/domain/cart/types';

export enum HutRewardsSignedUp {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  NOT_ENROLLED = 'NOT_ENROLLED'
}

export interface Order {
  orderId: string;
  susId: string;
  orderTime: string;
  subtotal: number;
  fees: Fee[];
  tax: string;
  tip: number | null;
  percentage: string | null;
  promiseTime: string;
  promiseTimeMax: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  promotionConsent: boolean;
  hutRewardsSignedUp?: HutRewardsSignedUp;
  totalPriceInCents: number;
  giftCards: ApplyableGiftCard[];
  paymentOption?: string;
  secondaryPaymentOption?: SecondaryPaymentType;
  savedCardPaymentId?: string;
  orderItems?: CartItem[];
  scheduleForLater?: boolean;
  discountedAmount?: number;
  isScheduleForLaterToday: boolean;
  quantity: number;
  contactless: string;
  shouldSaveNewCreditCard: boolean;
}

export type CreditInfo = {
  nameOnCard: string;
  cardNumber: string;
  cvvCode: string;
  expiration: string;
  billingZip: string;
};

export type SavedCardInfo = {
  paymentId: string;
  billingZip: string;
  name: string;
  cvvCode: string;
};

export type GiftCardInfo3rdParty = {
  cardNumber: string;
  cvvCode: string;
  expiration: string;
};

export type SmsOrderUpdate = {
  phone: string;
  smsConsent: boolean;
};

export type ScheduleForLaterInfo = {
  date: string;
  approximateDateTime: string | Date;
};

export type ContactlessCurbsideInfo = {
  foodPlacementLocation: string;
  vehicleColor: string;
  vehicleDescription: string;
};

interface HutRewardsPassword {
  password: string;
  confirmPassword: string;
}

export type CustomerCheckoutInfo = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  promotionConsent?: boolean;
  creditDebitInfo?: CreditInfo;
  scheduleForLater?: ScheduleForLaterInfo;
  contactlessCurbside?: ContactlessCurbsideInfo;
  contactlessDelivery?: boolean;
  windowPickup?: boolean;
  deliveryAddress?: DeliveryAddress;
  deliveryInstructions?: string;
  smsOrderUpdate?: string;
  hutRewardsPassword?: HutRewardsPassword;
  hutRewardsRecaptcha?: boolean;
};

export enum PaymentTypes {
  ApplePay = 'Pay',
  CreditDebit = 'Credit/Debit',
  SavedCard = 'SavedCreditcard',
  Cash = 'Cash',
  GiftCard = 'GiftCard',
  NotImplemented = 'NotImplemented'
}

export type SecondaryPaymentType = PaymentTypes | null;

export type OrderId = string;

export interface GiftCardBalance {
  balance: number;
}

export interface ApplyableTPGC extends ApplyableBaseGiftCard {
  cvv: string;
  expirationDate?: string;
}

export interface ApplyableLegacyGC extends ApplyableBaseGiftCard {
  pin?: string;
}

export interface ApplyableBaseGiftCard {
  token?: string;
  cardNumber: string;
  balance: number;
  appliedAmount: number;
  savedInfo?: {
    paymentId: string;
    type: GiftCardTypes.Legacy | GiftCardTypes.ThirdParty;
  };
}

export type ApplyableGiftCard = ApplyableTPGC | ApplyableLegacyGC;

export interface ApplePayResult {
  token: string;
  postalCode: string;
}

export type ReactFormRegister = {
  (name: string): void | undefined;
  (registerArgs?: Record<string, unknown>): unknown | undefined;
  (name: string, registerArgs?: Record<string, unknown>): unknown | undefined;
  (ref: HTMLInputElement | null, registerArgs?: Record<string, unknown>): unknown | undefined;
};

export type ReactFormSetValue = (name: string, value: unknown) => void;

export type YumPaymentSDK = {
  yumToken?: string;
};
