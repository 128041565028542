const editFavorite = typeof (jest) === 'undefined' ? new Promise(() => {}) : jest.fn();

const successfulNameChange = (): void => {
  const mockEditFavorite = editFavorite as jest.Mock;
  mockEditFavorite.mockResolvedValue({ error: false });
};

const failedNameChange = (): void => {
  const mockEditFavorite = editFavorite as jest.Mock;
  mockEditFavorite.mockResolvedValue({ error: true });
};

export default editFavorite;

export {
  successfulNameChange,
  failedNameChange
};
