import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/rootStateTypes';

type UseRefetchOnCartMismatchProps = {
  refetch: () => void;
  isUninitialized: boolean;
};

export const useRefetchOnYumEcommChanged = ({ refetch, isUninitialized }: UseRefetchOnCartMismatchProps) => {
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  useEffect(() => {
    if (isUninitialized) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isYumEcomm]);
};
