import { UrlTransformer } from '@/salesExperience/types';
import { menu, deals, account } from './replacers';
import { popularPizzas } from '@/salesExperience/replacers/popularPizzas';
import { SalesExperienceUrl } from '@/salesExperience/SalesExperienceUrl';

const urlSafe = (originalUrl: string) => {
  try {
    return new SalesExperienceUrl(originalUrl);
  } catch (e) {
    // URL doesn't contain base, use anything to avoid errors - we won't use it in the final url
    return new SalesExperienceUrl(originalUrl, 'https://www.pizzahut.com');
  }
};

// order matters; first-match does the replacement
const replacers = [deals, menu, account, popularPizzas];
const web2Replacer: UrlTransformer = (originalUrl) => {
  if (typeof originalUrl === 'undefined') {
    return originalUrl;
  }
  const url = urlSafe(originalUrl);
  const replacer = replacers.find((r) => r.match(url));
  if (!replacer) {
    return originalUrl;
  }
  return replacer.replace(url);
};

export { urlSafe, web2Replacer };
