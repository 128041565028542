import gql from 'graphql-tag';

const AvailabilityFragment = gql`
  fragment AvailabilityFragment on Availability {
    days
    startTime
    endTime
    occasion: orderMethod
    status
  }
`;
export default AvailabilityFragment;
