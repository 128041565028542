import React from 'react';

function SvgComponent(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="23"
      viewBox="0 0 18 23"
      {...props}
    >
      <path
        fill="#E52A33"
        fillRule="evenodd"
        d="M.466 15.023L17.638.853l-4.427 21.529-2.952-8.774z"
      />
    </svg>
  );
}

export default SvgComponent;
