import { useState, useEffect } from 'react';
import { Product, useGetProductQuery } from '@pizza-hut-us-development/client-core';
import { useSelector } from 'react-redux';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import checkAvailability, { AvailabilityItem } from '@/graphql/helpers/checkAvailability';
import { DisplayableProduct } from '@/domain/product/types';
import useCCGetDisplayableProduct from '@/clientCore/temporaryTransformationalHooks/useCCGetDisplayableProduct';
import { OccasionApi } from '@/localization/constants';
import useCCCartState from '@/clientCore/cart/hooks/useCCCartState';
import { RootState } from '@/rootStateTypes';

const useEditCart = () => {
  const { cart, selectedItem } = useCCCartState();
  const store = useSelector(orderSelectors.store);
  const { transformProduct } = useCCGetDisplayableProduct();
  const isYumEcomm = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  const { data, isLoading } = useGetProductQuery({
    itemId: selectedItem?.id || '',
    storeNumber: store?.storeNumber || '',
    variantCode: selectedItem?.variantCode
  });

  const [transformedProduct, setTransformedProduct] = useState<DisplayableProduct | null>(null);

  useEffect(() => {
    if (isLoading || !data || !cart || !store) return;

    const occasionId = (cart.occasionId === 'DELIVERY' ? 'D' : 'C') as OccasionApi;
    try {
      const availabilityProduct = checkAvailability(data, occasionId, store.timezone, isYumEcomm);
      const transformResult = transformProduct(availabilityProduct as AvailabilityItem<Product>);
      setTransformedProduct(transformResult);
    } catch (error) {
      console.error('Error transforming product');
    }
  }, [data, cart, store, isLoading, transformProduct, isYumEcomm]);

  return { cart, selectedItem, transformedProduct };
};

export default useEditCart;
