import AuthenticationEndpoints from '@/api/phdApiClient/Authentication/endpoints';
import { post } from '@/api/clientHelpers';
import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import { getPublicRuntimeConfig } from '@/api/phdApiClient/helpers/getPublicRuntimeConfig';
import { VALID_EMAIL_ADDRESS } from '@/checkout/CheckoutPage/ContactInfo/constants';

export const customerLookup = async (value: string): Promise<boolean> => {
  const url = getUrl(AuthenticationEndpoints.CUSTOMER_LOOKUP);
  const { phdApiServerEnv } = getPublicRuntimeConfig();

  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'x-server-env': phdApiServerEnv
  };

  const type = VALID_EMAIL_ADDRESS.test(value) ? 'email' : 'phone';

  const body = {
    [type]: value
  };

  return post(url, headers, body)
    .then((response) => {
      if (response.status > 299) {
        return true;
      }
      const responseJson = response.json();
      return responseJson.then((result) => Boolean(result?.customer_exists || result?.error_code));
    })
    .catch((error) => {
      throw error;
    });
};

export const customerLookupWithContents = async (value: string): Promise<{ customer_exists:boolean; email_opt_in:boolean }> => {
  const url = getUrl(AuthenticationEndpoints.CUSTOMER_LOOKUP);
  const { phdApiServerEnv } = getPublicRuntimeConfig();

  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'x-server-env': phdApiServerEnv
  };

  const type = VALID_EMAIL_ADDRESS.test(value) ? 'email' : 'phone';

  const body = {
    [type]: value
  };

  return post(url, headers, body)
    .then((response) => {
      const responseJson = response.json();
      return responseJson.then((result) => ({ ...result }));
    })
    .catch((error) => {
      throw error;
    });
};
