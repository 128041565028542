import React from 'react';
import {
  Card, CardContent, Grid, makeStyles, createStyles, Theme
} from '@material-ui/core';
import { mobileStartBreakpoint } from '../../materialUi/theme';
import { KEYBOARD_ENTER_KEYCODE, ENTER_KEY_EVENT_CODE } from '../../common/constants';

const useStyles = makeStyles((theme: Theme) => {
  const baseRootStyle = {
    border: '2px solid',
    height: '44px',
    '&:hover': {
      boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.3)',
      cursor: 'pointer'
    }
  };

  const arrowSize = 16;
  return createStyles({
    root: {
      ...baseRootStyle,
      borderColor: 'transparent',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.3)'
    },
    rootSelected: {
      ...baseRootStyle,
      borderColor: theme.palette.primary.main,
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)'
    },
    content: {
      padding: 8
    },
    label: {
      fontFamily: 'open_sans_semi',
      fontSize: 11,
      letterSpacing: 1,
      marginLeft: '13px',
      [theme.breakpoints.down(mobileStartBreakpoint)]: {
        marginLeft: '7px'
      }
    },
    arrowDown: {
      display: 'block',
      width: arrowSize,
      height: arrowSize,
      border: '2px solid transparent',
      borderBottomColor: theme.palette.primary.main,
      borderLeftColor: theme.palette.primary.main,
      margin: 'auto',
      marginTop: ((arrowSize / 2) + 2) * -1,
      transform: 'rotate(-45deg)',
      backgroundColor: 'white'
    }
  });
});

export default function OccasionTab({
  icon, label, selected, onClick
}: {
  icon: React.ReactNode,
  label: string,
  selected: boolean,
  onClick: (event?: any) => void
}) {
  const classes = useStyles({ selected });

  const onKeyDownHandler = (event: any) => {
    if (event.key === ENTER_KEY_EVENT_CODE || event.keycode === KEYBOARD_ENTER_KEYCODE) {
      onClick(event);
    }
  };

  return (
    <>
      <Card
        className={selected ? classes.rootSelected : classes.root}
        onKeyDown={onKeyDownHandler}
        onClick={onClick}
        tabIndex={0}
        role="tab"
        aria-selected={selected}
      >
        <CardContent className={classes.content}>
          <Grid container justifyContent="center">
            <Grid item>{icon}</Grid>
            <Grid item className={classes.label}>{label}</Grid>
          </Grid>
        </CardContent>
      </Card>
      {selected
        && <span className={classes.arrowDown} />}
    </>
  );
}
