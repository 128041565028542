import gql from 'graphql-tag';
import LinkFragmentV3 from './link.v3';

const CategoryFragmentV3 = gql`
  fragment CategoryFragmentV3 on Category {
      index
      isNational
      link {
          ...LinkFragmentV3
      }
      products {
          displayName
          item {
              id
              exists
          }
      }
  }
  ${LinkFragmentV3}
`;

const SubCategoryFragmentV3 = gql`
    fragment SubCategoryFragmentV3 on Category {
        ...CategoryFragmentV3
        categories {
            ...CategoryFragmentV3
        }
    }
   ${CategoryFragmentV3}
`;

const CategoryWithSubcategoryFragmentV3 = gql`
    fragment CategoryWithSubcategoryFragmentV3 on Category {
        ...CategoryFragmentV3
         categories {
             ...SubCategoryFragmentV3
         }
    }
    ${SubCategoryFragmentV3}
`;

export default CategoryWithSubcategoryFragmentV3;
