import { ApolloClient, InMemoryCache } from '@apollo/client';
import timeRequests from './links/timeRequests';
import batchHttpLink from './links/httpRequestLink';
import merge from './merge';
import { clientSideResolvers, clientSideTypeDefs } from './clientSchemaMock';

function createApolloClient(gqlUrlOverride?: string, disableClientSideResolvers?: boolean) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    typeDefs: clientSideTypeDefs,
    resolvers: clientSideResolvers(!disableClientSideResolvers),
    // @ts-ignore (because apollo is giving us mismatching types)
    link: timeRequests.concat(batchHttpLink(gqlUrlOverride)),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            Env: { // remove when v2 is no longer needed
              merge
            },
            Homepage: {
              keyArgs: ['envID', 'storeID', 'occasions'],
              merge
            },
            store: {
              merge
            }
          }
        },
        Item: {
          keyFields: (item) => {
            const steps = Array.isArray(item.steps) ? item.steps.length : '';
            return `${item.id}${item.price ?? ''}${item.maxAllowed ?? ''}${item.minAllowed ?? ''}${item.priority ?? ''}${steps}`;
          }
        }
      }
    })
  });
}

let persistedApolloClient: ApolloClient<any>;

export function getApollo(initialState?: Record<string, never>, gqlUrlOverride?: string, disableClientSideResolvers?: boolean) {
  const apolloClient = persistedApolloClient ?? createApolloClient(gqlUrlOverride, disableClientSideResolvers);

  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apolloClient.extract();

    // Restore the cache using the data from getServerSideProps + existing cached data
    apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSR, always create a new Apollo Client
  if (typeof window === 'undefined') return apolloClient;

  // Client side: only create apollo client once
  if (!persistedApolloClient) persistedApolloClient = apolloClient;
  return apolloClient;
}
