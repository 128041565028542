import { PortionTuple } from '@/builders/pizza/dataTransformers/builderTypes';

const Portion : PortionTuple = {
  NONE: 'none',
  LIGHT: 'light',
  REGULAR: 'regular',
  EXTRA: 'extra'
};

export default Portion;
