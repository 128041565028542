import getConfig from 'next/config';
import conversationIdStore from '../../telemetry/conversationIdStore';
import { LogContext, LoggerError } from './types';

export const contextWithoutConversationID = (): Omit<LogContext, 'ts' | 'conversationID'> => {
  const { publicRuntimeConfig } = getConfig();
  if (publicRuntimeConfig.ENVIRONMENT_NAME === undefined) {
    throw new LoggerError('No environment specified');
  }

  return {
    env: publicRuntimeConfig.ENVIRONMENT_NAME,
    serviceName: 'phc-web'
  };
};

export const context = (): Omit<LogContext, 'ts'> => ({
  ...contextWithoutConversationID(),
  conversationID: conversationIdStore.get()
});
