export enum IngredientInternalName {
  SIZES = 'sizes',
  MEATS = 'meats',
  VEGGIES = 'veggies',
  CHEESES = 'cheeses',
  SAUCES = 'sauces',
  FINISHERS = 'finishers'
}

export enum RuleIdentifier {
  SIZE = 'SIZE',
  MODIFIER = 'MODIFIER'
}
