import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CartItem, CartModifier } from '@pizza-hut-us-development/client-core';
import { presentationalCartSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/cart/presentationalCartSelectors';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { DISCOUNT } from '@/domain/constants';
import { getSortedCartItemIds } from '@/clientCore/redux/rail/helpers';
import { presentationalRailSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/rail/presentationalRailSelectors';
import AddedCoupons from '../components/AddedCoupons';
import CartItemComponent from '../components/CartItem';

export const useCartContent = () => {
  const { items } = useSelector(orderSelectors.cart) ?? { items: [] };

  // WEB-3458 - Steps from Yum are combined into a single step with multiple quantity if duplicated
  // This function parses those steps with quantity > 1 and rips them into individual steps for display purposes
  const extractDuplicateSteps = () => {
    const parsedItems = items.map((item: CartItem) => {
      const newModifiers: CartModifier[] = [];
      item.modifiers?.forEach((modifier) => {
        for (let i = 0; i < (modifier.quantity ?? 1); i++) {
          newModifiers.push({ ...modifier, quantity: 1 });
        }
      });
      return { ...item, modifiers: newModifiers };
    });
    return parsedItems;
  };

  const parsedItems = extractDuplicateSteps();

  const isCartLoading = useSelector(
    presentationalCartSelectors.isCartLoading
  );
  const sortedItemPointers = useSelector(presentationalRailSelectors.sortedItemPointers);
  const isCoupon = (cartItem: CartItem): boolean => cartItem.type === DISCOUNT && (cartItem.modifiers === undefined || cartItem.modifiers.length === 0);

  const sortedItems = useMemo(() => [...parsedItems].sort((currentItem, nextItem) => {
    const currentIndex = getSortedCartItemIds(sortedItemPointers).indexOf(currentItem.cartItemId);
    const nextIndex = getSortedCartItemIds(sortedItemPointers).indexOf(nextItem.cartItemId);

    if (currentIndex === nextIndex) return 0;

    return currentIndex < nextIndex ? -1 : 1;
  }), [sortedItemPointers, parsedItems]);

  // NON YUM
  const displayableCartItems = sortedItems.map((cartItem: CartItem, index: number) => {
    if (isCoupon(cartItem)) {
      return <AddedCoupons item={cartItem} />;
    }

    return (
      <CartItemComponent
        key={index}
        item={cartItem}
        index={index}
        isLastItem={index === sortedItems?.length - 1}
      />
    );
  });

  return {
    data: { isCartLoading, displayableCartItems }
  };
};
