import React from 'react';

import { InfoOutlined } from '@material-ui/icons';
import { Grid, Typography } from '@material-ui/core';

import formattedPrice from '@/common/formattedPrice';

import { TCaliforniaDeliveryFeeAdvisory } from './types';
import { useStyles } from './styles';

const CaliforniaDeliveryFeeAdvisory = ({ fee }: TCaliforniaDeliveryFeeAdvisory) => {
  const classes = useStyles();

  const formattedFee = formattedPrice(fee);
  const advisory = `By continuing with delivery, you understand that an additional delivery fee of ${formattedFee} will be added to your order. To avoid a delivery charge, you can switch to carryout at a restaurant near you.`;

  return (
    <Grid wrap="nowrap" item container className={classes.deliveryFeeAdvisory} direction="row" data-testid="californiaWarning">
      <InfoOutlined />
      <Typography component="span" variant="body1" className={classes.deliveryFeeAdvisoryText}>
        {advisory}
      </Typography>
    </Grid>
  );
};

export default CaliforniaDeliveryFeeAdvisory;
