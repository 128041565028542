import expressWinston from 'express-winston';
import { Handler } from 'express';
import getWinstonConfigLogger from './platforms/server/winstonConfig';
import { contextWithoutConversationID } from './context';
import conversationIdStore from '../../telemetry/conversationIdStore';

export default (): Handler => expressWinston.logger({
  winstonInstance: getWinstonConfigLogger(contextWithoutConversationID),
  requestWhitelist: ['url', 'method', 'originalUrl'],
  dynamicMeta: (req) => ({
    conversationID: req.headers.conversationid || conversationIdStore.get()
  }),
  metaField: null
});
