import NextRouter from 'next/router';
import { updateUrl } from '../common/url';
import Routes from './routes';

export enum Paths {
  DEALS = 'deals',
  HOME = 'home',
  MENU = 'menu',
  PIZZA = 'menu/pizza',
  DEALSW2 = 'dealsw2',
  JOIN = 'hutrewards'
}

export const redirectMapping = {
  [Routes.MENU.PIZZA_BUILDER]: Paths.MENU,
  [Routes.HOME]: Paths.HOME
};

type UpdateToURL = {
  deals: string;
  home: string;
  menu: string;
  dealsw2: string;
  hutrewards: string;
};

const nextJsRouter: Router = {
  goToRoute(route: string): Promise<boolean> {
    const updateToURL: UpdateToURL = {
      [Paths.DEALS]: Routes.MENU.DEALS,
      [Paths.HOME]: Routes.HOME,
      [Paths.MENU]: Routes.MENU.PIZZA,
      [Paths.DEALSW2]: Routes.MENU.DEALSW2,
      [Paths.JOIN]: Routes.ACCOUNT.JOIN
    };

    const newUrl = updateToURL[route as keyof UpdateToURL];
    if (newUrl) {
      if (newUrl === Routes.MENU.DEALS) {
        updateUrl(newUrl);
        return Promise.resolve(true);
      }
      return NextRouter.push(newUrl);
    }
    if (route?.includes(Routes.MENU.PIZZA_BUILDER) || route?.includes(Routes.MENU.DEALSW2)) {
      return NextRouter.push(route);
    }

    throw new Error(`Unknown route: ${route}`);
  }
};

export default nextJsRouter;
