import cookie from 'next-cookies';
import uuid from 'uuid-random';
import browser from './platforms/browser';
import embedded from './platforms/embedded';
import server from './platforms/server';
import { CreateLogger, CustomAttributes, Logger } from './types';
import middleware from './expressMiddleware';
import telemetry from '../../telemetry';
import clientSide from '../isClientSide';
// eslint-disable-next-line import/no-cycle

const createLogger: CreateLogger = () => {
  if (typeof window === 'undefined') {
    return server();
  }
  if (process.env.REACT_APP_EMBEDDED === 'true') {
    return embedded();
  }
  return browser();
};

type LogError = (error: Error, meta?: Record<string, any>) => void;

interface CombinedLogger {
  error: LogError;
  withoutTelemetry: Logger;
  telemetryOnly: typeof telemetry;
}

function generateUuid() {
  const savedUuid = cookie({})?.uuid;
  if (!savedUuid) {
    document.cookie = `uuid=${uuid()}; path=/;`;
  }
  return savedUuid;
}

const defaultAttributes = (): CustomAttributes => {
  const isClientSide = clientSide();
  let customAttributes: CustomAttributes = { isClientSide };
  if (isClientSide) {
    customAttributes = { ...customAttributes, uuid: generateUuid() };
  }
  return customAttributes;
};

const createCombinedLogger: () => CombinedLogger = () => {
  const logger = createLogger();
  const addDefaultAttributes = defaultAttributes();
  return ({
    error(error, meta = {}) {
      logger.error(error.message, { ...addDefaultAttributes, ...meta });
      telemetry.addNoticeError(error, { ...addDefaultAttributes, ...meta });
    },

    withoutTelemetry: logger,
    telemetryOnly: telemetry
  });
};

export default createCombinedLogger();
export { createLogger, createCombinedLogger, middleware };
