import Portion from '../../../../common/Portion';
import {
  PizzaIngredientOption,
  Pizza,
  IngredientOptionWithPortions,
  PizzaIngredient
} from '../../dataTransformers/builderTypes';
import { isSauce, isTheNoSauceOption } from '../../identifiers';
import { restrictRulesBySizeAndCrust } from './rulesSelectors';

// #Sauce selectors
const selectPizzaSauce = (pizza: Pizza): PizzaIngredient | null => pizza.sauce;

function selectSauceOptions(pizza: Pizza): IngredientOptionWithPortions[] {
  return pizza.pizzaOptions?.sauces?.filter(restrictRulesBySizeAndCrust(pizza, isSauce)) || [];
}

type MaybeUndefined<T> = T | undefined;

function selectRootSauceById(
  pizza: Pizza,
  sauceId: IngredientOptionWithPortions['id']
): MaybeUndefined<IngredientOptionWithPortions> {
  const sauceOptions = selectSauceOptions(pizza);

  const recipeDefaultSauce = sauceOptions?.find(({ selected }) => selected);
  return (
    sauceOptions?.find((sauce) => (sauce.id ? sauceId?.includes(sauce.id) : false))
    || recipeDefaultSauce
  );
}

function selectPizzaSauceOption(pizza: Pizza): MaybeUndefined<PizzaIngredientOption> {
  return selectRootSauceById(pizza, pizza.sauce?.id)?.portions
    .find(({ portion }) => portion === (pizza.sauce?.portion || Portion.REGULAR));
}

function selectNoSauceOption(pizza: Pizza): MaybeUndefined<PizzaIngredientOption> {
  return (
    selectSauceOptions(pizza)?.find(isTheNoSauceOption)?.portions.find(({ portion }) => portion === Portion.NONE)
  );
}

export {
  selectPizzaSauce,
  selectNoSauceOption,
  selectPizzaSauceOption,
  selectRootSauceById,
  selectSauceOptions
};
