import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import fontStyles from '../common/fontStyles';
import colors from '../common/colors';

interface ThemeOptions {
  htmlFontSize: number
}

export const extraLargeStartBreakpoint = 'xl';
export const largeStartBreakpoint = 'lg';
export const mobileStartBreakpoint = 'md';
export const smallMobileStartBreakpoint = 'sm';
export const extraSmallMobileStartBreakpoint = 'xs';

export const breakPointsValues = {
  xs: 0,
  sm: 361,
  md: 651,
  lg: 851,
  xl: 1100
};

const breakpoints = createBreakpoints({ values: breakPointsValues });

export default function createTheme({ htmlFontSize } : ThemeOptions) {
  return createMuiTheme({
    breakpoints,
    shape: {
      borderRadius: 8
    },
    typography: {
      // Tell Material-UI what the font-size on the html element is.
      htmlFontSize,
      h1: {
        [breakpoints.down(mobileStartBreakpoint)]: fontStyles.h1Mobile,
        [breakpoints.up(largeStartBreakpoint)]: fontStyles.h1
      },
      h2: {
        [breakpoints.down(mobileStartBreakpoint)]: fontStyles.h2Mobile,
        [breakpoints.up(largeStartBreakpoint)]: fontStyles.h2
      },
      h3: {
        [breakpoints.down(mobileStartBreakpoint)]: fontStyles.h3Mobile,
        [breakpoints.up(largeStartBreakpoint)]: fontStyles.h3
      },
      h4: fontStyles.h4,
      h5: fontStyles.h5,
      h6: fontStyles.h6,
      body1: fontStyles.body
    },
    palette: {
      primary: {
        main: colors.red
      },
      error: {
        main: colors.red
      }
    },
    overrides: {
      MuiLink: {
        root: fontStyles.textLink
      },
      MuiTypography: {
        body1: {
          whiteSpace: 'pre-line',
          fontFamily: 'open_sans'
        },
        caption: {
          fontFamily: 'open_sans'
        }
      },
      MuiSelect: {
        select: {
          ...fontStyles.body
        }
      },
      MuiButton: {
        contained: {
          fontFamily: 'open_sans_semi',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: 1.5,
          letterSpacing: '1px',
          textAlign: 'center',
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none'
          }
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#e21216',
            boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.3)'
          },
          '&:active': {
            backgroundColor: '#d10a14',
            boxShadow: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.3)'
          }
        }
      },
      MuiOutlinedInput: {
        root: {
          ...fontStyles.body
        }
      },
      MuiCssBaseline: {
        '@global': {
          html: {
            fontSize: '10px'
          },
          body: {
            fontFamily: '"open_sans", sans serif',
            lineHeight: '21px',
            backgroundColor: colors.white
          },
          '.badgeText': fontStyles.badgeText
        }
      },
      MuiButtonBase: {
        root: {
          padding: 'unset'
        }
      },
      MuiCircularProgress: {
        colorPrimary: {
          color: '#ffffff'
        }
      }
    }
  });
}
