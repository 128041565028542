import React from 'react';

export const AlertIcon = ({ color }: { color: string }): JSX.Element => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8.9 12.6H6.9V10.6H8.9V12.6ZM6.9 9H8.9V3H6.9V9Z" fill={color} />
    <mask id="mask0_31_67595" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8.9 12.6H6.9V10.6H8.9V12.6ZM6.9 9H8.9V3H6.9V9Z" fill="white" />
    </mask>
    <g mask="url(#mask0_31_67595)">
      <rect x="-4" y="-4" width="24" height="24" fill={color} />
    </g>
  </svg>
);
