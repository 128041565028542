import React from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  createStyles, makeStyles, Theme
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { smallMobileStartBreakpoint } from '../../../materialUi/theme';
import fontStyles from '../../../common/fontStyles';
import ResponsiveImageRender, { ResponsiveImageRenderProps } from '../../../common/ResponsiveImageRender';
import colors from '../../../common/colors';
import DialogActionButtons from '../DialogActionButtons';
import DialogList from '../DialogList';
import AnchorLink from '@/common/AnchorLink';
import { ExternalLinkIcon } from '../../../builders/pizza/PizzaBuilder/icons/ExternalLinkIcon';

const styles = makeStyles((theme: Theme) => createStyles({
  modal: {
    position: 'fixed',
    top: '110px',
    width: '656px',
    padding: '32px',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      padding: '24px 16px',
      width: '448px',
      position: 'relative',
      display: 'flex',
      top: 'auto',
      margin: '16px'
    }
  },
  title: {
    ...fontStyles.modalHeading,
    padding: 0,
    paddingBottom: '24px',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      padding: '0 32px 16px 0'
    }
  },
  dialogContent: {
    padding: 0,
    paddingBottom: '32px',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      padding: '0 0 24px 0'
    }
  },
  body: {
    ...fontStyles.body,
    marginBottom: 0,
    lineHeight: '21px'
  },
  bodyListItem: {
    padding: '0 10px 0',
    display: 'list-item',
    listStyle: 'disc inside none'
  },
  image: {
    paddingTop: 12,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  closeButtonRoot: {
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 48,
    height: 48,
    color: colors.gray900
  },
  linkDisplayText: {
    ...fontStyles.textLink,
    textDecoration: 'none'
  }
}));

export interface ModalProps {
  open: boolean;
  content: ModalContent;
  closeModal: () => void;
}

const responsiveImageProps: ResponsiveImageRenderProps = {
  testId: 'alert-dialog-image',
  altText: 'alert dialog image alt text',
  title: 'alert dialog image',
  className: '',
  desktop: '',
  mobile: ''
};

const WarningDialog = ({ open, content, closeModal }: ModalProps): JSX.Element => {
  const classes = styles();

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      classes={{ paper: classes.modal }}
      TransitionProps={{ role: 'dialog' } as TransitionProps}
      data-testid="alert-dialog"
    >
      <DialogTitle
        id="alert-dialog-title"
        data-testid="alert-dialog-title"
        className={classes.title}
        disableTypography
      >
        {content.title}
      </DialogTitle>
      <DialogContent
        id="alert-dialog-description"
        classes={{ root: classes.dialogContent }}
      >
        <div
          data-testid="alert-dialog-description"
          className={classes.body}
            // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content.body ?? '' }}
        />
        {content.bodyList?.length && (
          <DialogList
            list={content.bodyList}
            className={classes.bodyListItem}
          />
        )}
        {!!content.bodyElements?.length && (
          content.bodyElements.map((element) => (
            <>{element}</>
          ))
        )}
        {content.image && (
          <ResponsiveImageRender
            {...responsiveImageProps}
            className={classes.image}
            desktop={content.image}
            mobile={content.image}
          />
        )}
        {content.extraContent && (
          <Typography>{content.extraContent}</Typography>
        )}
        {content.link?.linkUrl && (
          <AnchorLink
            link={content.link.linkUrl}
            linkDisplayText={content.link.linkDisplayText ?? 'More Details'}
            testId={content.link.linkTestId ?? ''}
            external={false}
            className={classes.linkDisplayText}
          />
        )}
        {content.link?.linkIcon && (
          <ExternalLinkIcon />
        )}
      </DialogContent>
      <DialogActionButtons
        closeModal={closeModal}
        primary={content.cta}
        secondary={content.altCta}
      />
      {!content.hideCloseIcon && (
      <IconButton
        aria-label="close"
        data-testid="alert-dialog-close-icon"
        classes={{ root: classes.closeButtonRoot }}
        className={classes.closeButton}
        onClick={closeModal}
      >
        <CloseIcon />
      </IconButton>
      )}
    </Dialog>
  );
};

export default WarningDialog;
