import { Dispatch, AnyAction } from 'redux';
import { ApolloError } from '@apollo/client';
import { QueryNames } from './constants';
import {
  FOOTER_QUERY_ERROR,
  HEADER_QUERY_ERROR,
  HERO_QUERY_ERROR,
  SIDEKICK_QUERY_ERROR,
  PIZZA_MENU_QUERY_ERROR,
  PIZZA_MENU_QUERY_DATA_ERROR,
  PIZZA_BUILDER_QUERY_ERROR,
  CLEAR_PIZZA_ERROR,
  GET_PRODUCTS_QUERY_ERROR,
  RESET_GET_PRODUCTS_QUERY_ERROR,
  DEAL_QUERY_ERROR,
  DEAL_MENU_QUERY_ERROR,
  RESET_ALL_GQL_ERRORS,
  GET_CATEGORIES_QUERY_ERROR,
  CLEAR_DEAL_ERROR,
  CLEAR_GET_CATEGORIES_QUERY_ERROR,
  DEAL_PRODUCTS_BY_ID_QUERY_ERROR
} from './actionTypes';
import { logGraphqlError, storeOrNationalId } from '../../common/logger/logGraphqlError';
import telemetry from '../../telemetry';
import { RootState } from '@/rootStateTypes';

interface AsyncDispatch {
  (dispatch: Dispatch, getState?: () => RootState): Promise<void>;
}

export const headerGqlError = (gqlError: ApolloError): AnyAction => ({
  type: HEADER_QUERY_ERROR,
  gqlError
});

export const heroGqlError = (gqlError: ApolloError): AnyAction => ({
  type: HERO_QUERY_ERROR,
  gqlError
});

export const sidekickGqlError = (gqlError: ApolloError): AnyAction => ({
  type: SIDEKICK_QUERY_ERROR,
  gqlError
});

export const footerGqlError = (gqlError: ApolloError): AnyAction => ({
  type: FOOTER_QUERY_ERROR,
  gqlError
});

export const pizzaMenuGqlError = (gqlError: ApolloError): AnyAction => ({
  type: PIZZA_MENU_QUERY_ERROR,
  gqlError
});

export const dealGqlError = (gqlError: ApolloError): AnyAction => ({
  type: DEAL_QUERY_ERROR,
  gqlError
});

export const dealMenuGqlError = (gqlError: ApolloError): AnyAction => ({
  type: DEAL_MENU_QUERY_ERROR,
  gqlError
});

export const dealProductsGqlError = (gqlError: ApolloError): AnyAction => ({
  type: DEAL_PRODUCTS_BY_ID_QUERY_ERROR,
  gqlError
});

export const clearDealGqlError = (): AnyAction => ({
  type: CLEAR_DEAL_ERROR
});

export const pizzaMenuGqlDataError = (): AnyAction => ({
  type: PIZZA_MENU_QUERY_DATA_ERROR,
  gqlDataError: true
});

export const pizzaBuilderGqlError = (gqlError: ApolloError): AnyAction => ({
  type: PIZZA_BUILDER_QUERY_ERROR,
  gqlError
});

export const clearPizzaGqlError = (): AnyAction => ({
  type: CLEAR_PIZZA_ERROR
});

export const getProductsGqlError = (gqlError: ApolloError): AnyAction => ({
  type: GET_PRODUCTS_QUERY_ERROR,
  gqlError
});

export const getCategoriesGqlError = (gqlError: ApolloError): AnyAction => ({
  type: GET_CATEGORIES_QUERY_ERROR,
  gqlError
});

export const clearGetCategoriesGqlError = (): AnyAction => ({
  type: CLEAR_GET_CATEGORIES_QUERY_ERROR
});

export const resetGetProductsGqlError = (): AnyAction => ({
  type: RESET_GET_PRODUCTS_QUERY_ERROR
});

export const resetAllGqlErrors = (): AnyAction => ({
  type: RESET_ALL_GQL_ERRORS
});

export const onGqlError = (
  queryName: QueryNames,
  error: ApolloError,
  storeId: string | null
): AsyncDispatch => async (dispatch: Dispatch) => {
  const storeID = storeOrNationalId(storeId);

  logGraphqlError(queryName, 'Query returned error', `store ID: ${storeID}`);
  telemetry.addNoticeError(new Error(`[GQL Query Error] ${queryName} query returned error for store ID ${storeID}`), serialize(error));

  if (queryName === QueryNames.DEAL) {
    dispatch(dealGqlError(error));
  }

  if (queryName === QueryNames.DEAL_MENU) {
    dispatch(dealMenuGqlError(error));
  }

  if (queryName === QueryNames.PIZZA_MENU) {
    dispatch(pizzaMenuGqlError(error));
  }

  if (queryName === QueryNames.PIZZA_BUILDER) {
    dispatch(pizzaBuilderGqlError(error));
  }

  if (queryName === QueryNames.HEADER) {
    dispatch(headerGqlError(error));
  }

  if (queryName === QueryNames.HERO) {
    dispatch(heroGqlError(error));
  }

  if (queryName === QueryNames.SIDEKICK) {
    dispatch(sidekickGqlError(error));
  }

  if (queryName === QueryNames.GET_PRODUCTS_BY_CATEGORY) {
    dispatch(getProductsGqlError(error));
  }

  if (queryName === QueryNames.GET_CATEGORIES_LOCALIZED
    || queryName === QueryNames.GET_CATEGORIES_NATIONAL) {
    dispatch(getCategoriesGqlError(error));
  }

  if (queryName === QueryNames.GET_PRODUCTS_BY_ID) {
    dispatch(dealProductsGqlError(error));
  }
};

type Serialized = { serializedGqlError: string } | { serializationError: string };

export function serialize(error: ApolloError): Serialized {
  try {
    return {
      serializedGqlError: JSON.stringify({
        message: error.message,
        name: error.name,
        graphQLErrors: error.graphQLErrors,
        networkError: error.networkError,
        extraInfo: error.extraInfo
      }).slice(0, 300)
    };
  } catch (e) {
    return { serializationError: 'Could not serialize and record GQL error.' };
  }
}
