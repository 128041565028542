import React from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { CtaButton } from '@/coreComponents/buttons/CtaButton/CtaButton';
import { EMPTY_CART_SUBTITLE, EMPTY_CART_TITLE, START_YOUR_ORDER } from './constants';
import { closeCartRail, openCartRail } from '@/clientCore/redux/rail/CartRailSlice';
import useOpenLocalizationWithWarningModal from '@/header/useOpenLocalizationWithWarningModal';
import styles from './EmptyCart.styles';
import { onRailStartOrderClick } from '@/dataAnalytics/dataAnalyticsHelper';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { EmptyCartIcon } from '../../common/EmptyCartIcon';
import Coupon from '../CartContent/components/Coupon/Coupon';

interface EmptyCartProps {
  isLocalized: boolean;
}

export default function EmptyCart({ isLocalized }: EmptyCartProps): JSX.Element {
  const classes = styles();
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const onCancel = () => dispatch(openCartRail());
  const openLocalizationWithWarningModal = useOpenLocalizationWithWarningModal({ onCancel });

  const handleClick = () => {
    dispatch(closeCartRail());
    openLocalizationWithWarningModal();
    analytics.push(() => onRailStartOrderClick());
  };

  return (
    <>
      <div className={classes.root} data-testid="empty-cart">
        <EmptyCartIcon />
        <Typography variant="h2" className={classes.title}>{EMPTY_CART_TITLE}</Typography>
        <Typography className={classes.subtitle}>{EMPTY_CART_SUBTITLE}</Typography>
        {!isLocalized && (
          <CtaButton
            color="primary"
            clickHandler={handleClick}
            labelText={START_YOUR_ORDER}
            fullWidth
            size="large"
          />
        )}
      </div>
      {isLocalized && (
        <Coupon />
      )}
    </>
  );
}
