import React from 'react';
import { useSelector } from 'react-redux';
import {
  IconButton, Grid, Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Product } from '@pizza-hut-us-development/client-core';
import ResponsiveImageRender from '@/common/ResponsiveImageRender';
import useAddToCart from '@/clientCore/menu/hooks/useAddToCart';
import Spinner from '@/coreComponents/Spinner';
import formattedPrice from '@/common/formattedPrice';
import { RootState } from '@/rootStateTypes';
import useOneClickUpsellCartRailRowStyles from './styles';

const FALLBACK_IMAGE = '/images/nationalMenu/fallback-product-image-mobile.jpg';

interface OneClickUpsellCartRailRowProps {
  index: number;
  product: Product;
}

const OneClickUpsellCartRailRow = ({ index, product }: OneClickUpsellCartRailRowProps): JSX.Element => {
  const classes = useOneClickUpsellCartRailRowStyles();
  const { addToCart, isLoading } = useAddToCart();
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  const {
    imageURL, description, name, price
  } = product;

  const handleAddToCart = async () => {
    await addToCart(product, 1, isYumEcomm);
  };

  return (
    <Grid container alignItems="center">
      {
        isLoading
          ? (
            <Grid className={classes.spinnerRow} container alignItems="center" justifyContent="center" spacing={2}>
              <Grid item><Spinner size={20} /></Grid>
              <Grid item><Typography className={classes.addToCartText}>Adding to cart...</Typography></Grid>
            </Grid>
          )
          : (
            <Grid container wrap="nowrap" alignItems="center" data-testid="one-click-upsell-cart-rail-row">
              <IconButton onClick={handleAddToCart} aria-label={`Add ${name} to cart`}>
                <AddIcon />
              </IconButton>
              <ResponsiveImageRender
                desktop={imageURL || FALLBACK_IMAGE}
                mobile={imageURL || FALLBACK_IMAGE}
                pictureClassName={classes.hutPictureContainer}
                className={classes.hutFavoriteImage}
                testId={`cart-oneclick-upsell-image-${index}`}
                altText={description || name}
                title={name}
              />
              <div className={classes.hutFavoriteTextContent}>
                <div className={classes.hutFavoriteTitle}>{name}</div>
                <div className={classes.hutFavoriteSubTitle} data-testid="one-click-upsell-cart-rail-row-price">
                  {formattedPrice(price)}
                </div>
              </div>
            </Grid>
          )
      }
    </Grid>
  );
};

export default OneClickUpsellCartRailRow;
