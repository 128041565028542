import { combineReducers } from 'redux';
import cmsEnv from './common/pageSetup/cmsEnv/cmsEnvReducers';
import localizationUi from './localization/uiReducers';
import localizationDomain from './localization/domainReducers';
import cartDomainLegacy from './cart/legacyCart/legacyDomainReducers';
import cartDomain from './cart/domainReducers';
import userDomain from './header/userDomainReducers';
import headerUi from './header/uiReducers';
import gqlErrorsUi from './graphql/errors/reducers';
import user from './dataAnalytics/reducers/userReducers';
import store from './dataAnalytics/reducers/storeReducers';
import pizzaDomain from './builders/pizza/slice/pizza.slice';
import dealReducer from './builders/deals/slice/deal.slice';
import userSelectionsReducer from './builders/deals/slice/userSelections.slice';
import pizza from './dataAnalytics/reducers/pizzaReducers';
import deal from './dataAnalytics/reducers/dealReducers';
import page from './dataAnalytics/reducers/pageReducers';
import modalDomain from './builders/pizza/slice/modal/modal.slice';
import gqlVersion from './common/pageSetup/gqlVersion/gqlVersionReducers';
import ToastSlice from './common/CartToast/slices/Toast.slice';
import CartTotalPriceSlice from './builders/pizza/CartTotalPrice/CartTotalPrice.slice';
import cartUi from './cart/uiReducers';
import RailSlice from './rail/slices/Rail.slice';
import { reducer as salesExperience } from '@/salesExperience/salesExperience.slice';
import { reducer as reorderDomain } from '@/account/orders/reorder.slice';

const rootReducer = {
  config: combineReducers({
    salesExperience
  }),
  domain: combineReducers({
    gqlVersion,
    cmsEnv,
    localization: localizationDomain,
    cartLegacy: cartDomainLegacy,
    cart: cartDomain,
    user: userDomain,
    pizza: pizzaDomain,
    deal: combineReducers({
      data: dealReducer,
      userSelections: userSelectionsReducer
    }),
    reorder: reorderDomain
  }),
  presentational: combineReducers({
    localization: localizationUi,
    header: headerUi,
    gqlErrors: gqlErrorsUi,
    modal: modalDomain,
    toast: ToastSlice,
    cart: cartUi,
    rail: RailSlice,
    cartTotalPrice: CartTotalPriceSlice
  }),
  analyticsDataModel: combineReducers({
    user,
    store,
    pizza,
    deal,
    page
  })
};

export default rootReducer;
