import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './MaxOrderLimitAlert.styles';
import colors from '@/common/colors';
import { MAX_ORDER_LIMIT } from '../../constants';
import { AlertIcon } from '../../common/AlertIcon';

interface MaxOrderLimitAlertProps {
  children: React.ReactNode;
}

export const MaxOrderLimitAlert = (
  props: MaxOrderLimitAlertProps
): JSX.Element => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Grid container direction="row" className={classes.container} wrap="nowrap">
      <Grid item className={classes.icon}>
        <AlertIcon color={colors.red} />
      </Grid>
      <Grid item className={classes.textContainer}>
        <Grid container>
          <Typography
            component="span"
            variant="caption"
            data-testid={MAX_ORDER_LIMIT.testId}
          >
            {children}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
