export const FIRST_NAME_LABEL = 'First name';
export const LAST_NAME_LABEL = 'Last name';
export const FIRST_NAME_ID = 'first-name';
export const LAST_NAME_ID = 'last-name';
export const EMAIL_LABEL = 'Email';
export const EMAIL_ID = 'email';
export const PHONE_LABEL = 'Phone number';
export const PHONE_INPUT_TIP = '10 digits';
export const PHONE_ID = 'phone-number';
export const TEXT_INPUT_PHONE_LENGTH = 14;
export const INVALID_EMAIL = 'Invalid email address';
export const INVALID_NAME = 'Special characters and numbers not allowed';
export const EMPTY_FIRST_NAME = 'Please add your first name';
export const EMPTY_LAST_NAME = 'Please add your last name';
export const VALID_EMAIL_ADDRESS = /^[a-z0-9]+([.\-_]*[a-z0-9]+)*@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;

export const LAST_NAME_ERRORS = {
  required: EMPTY_LAST_NAME,
  pattern: INVALID_NAME
};

export const FIRST_NAME_ERRORS = {
  required: EMPTY_FIRST_NAME,
  pattern: INVALID_NAME
};

export const EMAIL_ERRORS = {
  required: INVALID_EMAIL,
  pattern: INVALID_EMAIL,
  validate: INVALID_EMAIL
};

export const SIGN_IN_DESCRIPTION = 'Want a faster checkout?';
export const SIGN_IN_LABEL = 'Sign in';
