import React from 'react';
import { useDecision } from '@optimizely/react-sdk';
import CartItemSkeleton from './components/CartItem/components/CartItemSkeleton';
import Coupon from './components/Coupon/Coupon';
import { useCartContent } from './hooks/useCartContent';
import OneClickUpsellCartRailList from '../OneClickUpsellCartRail/components/OneClickUpsellCartRailList/OneClickUpsellCartRailList';

const CartContent = () => {
  const [{ enabled: isOneClickCartUpsellItemsEnabled, variationKey }] = useDecision('fr-dtg-38-cart-one-click-upsell');

  const showOneClickCartUpsell = isOneClickCartUpsellItemsEnabled && variationKey === 'control_cart_one_click_on';

  const { data } = useCartContent();
  const {
    isCartLoading,
    displayableCartItems
  } = data;

  return (
    <div data-testid="cart-content" style={{ flexGrow: 1 }}>
      {isCartLoading ? (
        <CartItemSkeleton />
      ) : (
        <>
          {displayableCartItems}
        </>
      )}
      <Coupon />
      {showOneClickCartUpsell && <OneClickUpsellCartRailList />}
    </div>
  );
};

export default CartContent;
