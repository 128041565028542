export function updateUrl(link) {
  window.location.href = link;
}

const isValidDeeplink = (link: string) => link?.includes('link.php');
const hasDealInLink = (link: string) => link?.includes('c=');

// Shouldn't show confirm, because localization happened recently
export const shouldShowConfirmLocationModal = (link: string | undefined): boolean => {
  return false; // see W20M-417

//   if (link === undefined) {
//     return false;
//   }
//   // note: passing 'https://wwww.pizzahut.com' (the second parameter of URL) below avoids errors -
//   // it's not used for anything
//   const { searchParams, pathname } = new URL(link, 'https://www.pizzahut.com');
//   const web1Menu = searchParams.has('menu');
//   const web1Deal = searchParams.has('c');
//   const web1Builder = searchParams.has('fp');
//   const web2Menu = pathname.startsWith('/menu');
//   const web2DealsPage = pathname.startsWith('/deals');
//   const web2Deal = pathname.startsWith('/deals') && searchParams.has('id');
//   return web1Menu || web1Deal || web2Menu || web1Builder || web2DealsPage || web2Deal;
};

export function getDealNameFromUrl(link: string): string | undefined {
  if (isValidDeeplink(link) && hasDealInLink(link)) {
    const isFullLink = link.startsWith('http');
    const isRelativeLink = link.startsWith('link.php') || link.startsWith('/link.php');

    if (!isFullLink && !isRelativeLink) {
      return undefined;
    }

    const dealName = new URL(isFullLink ? link : `http://example.com/${link}`)
      .searchParams
      .get('c');

    return dealName || undefined;
  }

  return undefined;
}

/* The Deal URL requires an argument to let web1 know that we should route to the deals page,
   even when when an item in the cart is eligible for the deal */
export const createDealLink = (link: string, isRelativeLink: boolean) => {
  let enrichedLink = link;
  if (isValidDeeplink(link) && hasDealInLink(link)) {
    enrichedLink += '&bypass_deals_page=false';
  }

  return isRelativeLink ? `/${enrichedLink}` : enrichedLink;
};
