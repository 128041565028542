export interface DataAnalytics {
  push(eventAnalytics: {}, dataModel?: {} | any): void
  pushEventAnalytics(eventAnalytics: {}): void
}

export enum PromoType {
  HEROES_PROMO = 'HEROES_PROMO',
  SIDEKICKS_PROMO = 'SIDEKICKS_PROMO',
  BREAKING_NEWS_PROMO = 'BREAKING_NEWS_PROMO',
  MARKETING_BANNERS_PROMO = 'MARKETING_BANNERS_PROMO',
  HUT_FAVORITES_PROMO = 'HUT_FAVORITES_PROMO'
}
