import { StoreStatus, StoreTileButtonType, StoreTileHeaderType } from './constants';
import { Occasion, OccasionString } from '../../constants';

interface DetermineStoreTileStatusProps {
  occasion: keyof typeof OccasionString;
  store: StoreDetail;
}

interface StoreTileStatus {
  headerType: StoreTileHeaderType;
  storeStatusMessage: string | null;
  buttonType: StoreTileButtonType;
}

export default function determineStoreTileStatus({
  occasion,
  store: {
    storeStatus, storeClosureReason, phoneNumber, acceptFutureOrders, includeBeerWarning
  }
}: DetermineStoreTileStatusProps): StoreTileStatus {
  const occasionAvailable = storeStatus?.includes(occasion);

  // Searched Occasion is available
  if (occasionAvailable) {
    const storeStatusMessage = includeBeerWarning
      ? 'We\'re unable to deliver beer with your order (due to regulations). You can order beer if you switch to carryout.'
      : null;

    const buttonType = includeBeerWarning
      ? StoreTileButtonType.DELIVER_WITHOUT_BEER
      : StoreTileButtonType.CONTINUE;

    return {
      headerType: StoreTileHeaderType.PROMISE_TIME,
      storeStatusMessage,
      buttonType
    };
  }

  // Searched Occasion is not available
  switch (storeStatus) {
    case StoreStatus.CARRYOUT_ONLY:
    case StoreStatus.DELIVERY_ONLY: {
      const occasionStr = (occasion === Occasion.CARRYOUT)
        ? OccasionString.D.toLowerCase()
        : OccasionString.C.toLowerCase();
      const notAvailableMsg = `${OccasionString[occasion]} is unavailable right now.`;
      const switchOccasionMsg = acceptFutureOrders
        ? ` Order ${occasionStr} now or pre-order ${OccasionString[occasion].toLowerCase()} for later.`
        : '';

      return {
        headerType: StoreTileHeaderType.OCCASION_NOT_AVAILABLE,
        storeStatusMessage: notAvailableMsg + switchOccasionMsg,
        buttonType: acceptFutureOrders
          ? StoreTileButtonType.PREORDER
          : StoreTileButtonType.SWITCH_OCCASION
      };
    }

    case StoreStatus.ONLINE_BUT_OUTSIDE_STORE_HOURS: {
      const message = occasion === Occasion.DELIVERY && !acceptFutureOrders
        ? 'This store is closed right now. You can choose a different store if you order carryout.'
        : null;
      return {
        headerType: StoreTileHeaderType.STORE_CLOSED,
        storeStatusMessage: message,
        buttonType: acceptFutureOrders
          ? StoreTileButtonType.PREORDER
          : StoreTileButtonType.NO_BUTTON
      };
    }
    case StoreStatus.TEMP_CLOSED: {
      const storeStatusMessage = occasion === Occasion.DELIVERY
        ? `${storeClosureReason} You can choose a different store if you order carryout.`
        : storeClosureReason;
      return {
        headerType: StoreTileHeaderType.STORE_CLOSED,
        storeStatusMessage,
        buttonType: acceptFutureOrders
          ? StoreTileButtonType.PREORDER
          : StoreTileButtonType.NO_BUTTON
      };
    }

    case StoreStatus.TEMP_CLOSED_WITH_REASON: {
      const storeStatusMessage = occasion === Occasion.DELIVERY && !acceptFutureOrders
        ? 'This store is closed today. You can choose a different store if you order carryout.'
        : 'This store is closed today';
      return {
        headerType: StoreTileHeaderType.STORE_CLOSED,
        storeStatusMessage,
        buttonType: acceptFutureOrders
          ? StoreTileButtonType.PREORDER
          : StoreTileButtonType.NO_BUTTON
      };
    }

    case StoreStatus.INACTIVE:
      return {
        headerType: StoreTileHeaderType.ONLINE_NOT_AVAILABLE,
        storeStatusMessage: `This store is not accepting online orders right now. Please call ${phoneNumber}.`,
        buttonType: StoreTileButtonType.NO_BUTTON
      };

    default:
      return {
        headerType: StoreTileHeaderType.STORE_CLOSED,
        storeStatusMessage: null,
        buttonType: StoreTileButtonType.NO_BUTTON
      };
  }
}
