import winston, { format as createFormat, Logger } from 'winston';
import getConfig from 'next/config';
import { Format } from 'logform';
import { LogContext, LoggerError } from '../../types';
import { context } from '../../context';

export const validLogFormat = (format: string): format is LogFormat => (format === 'json' || format === 'simple');
type LogFormat = 'json' | 'simple';
type DefaultFormat = (logFormat: LogFormat, getContext: () => Partial<LogContext>)=> Format;
type GetWinstonConfigLogger = (getContext: () => Partial<LogContext>) => Logger;

const timestamp = createFormat((info) => {
  // eslint-disable-next-line no-param-reassign
  info.ts = new Date().toISOString();
  return info;
});

export const defaultFormat: DefaultFormat = (logFormat, getContext) => winston.format.combine(
  winston.format((logLine) => ({
    ...logLine,
    ...getContext()
  }))(),
  timestamp(),
  logFormat === 'json'
    ? winston.format.json()
    : winston.format.simple()
);

const getWinstonConfigLogger: GetWinstonConfigLogger = (getContext = context) => {
  // Note: getConfig() returns undefined when booting dev server w/ New Relic enabled
  const { publicRuntimeConfig } = getConfig() || { publicRuntimeConfig: {} };

  const logLevel = publicRuntimeConfig.LOG_LEVEL;
  const logFormatString = publicRuntimeConfig.LOG_FORMAT || 'json';

  if (!validLogFormat(logFormatString)) {
    throw new LoggerError('Invalid log format specified for server logger');
  }

  return winston.createLogger({
    transports: [new winston.transports.Console({
      level: logLevel
    })],
    format: defaultFormat(logFormatString, getContext)
  });
};

export default getWinstonConfigLogger;
