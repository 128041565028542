import {
  CustomerAddressResponse, CustomerDetailsError, CustomerDetailsResponse, PromotionResponse
} from '@/api/phdApiClient/transformPHDData';

export default {
  status: 200,
  response: {
    customer_addresses: [
      {
        address1: '3333 PRESTON RD',
        address2: '',
        city: 'FRISCO',
        postal_code: '75034',
        state: 'TX',
        name: ''
      }
    ],
    customer_id: '12050609089824045607478584',
    first_name: 'JANE',
    programs: [
      {
        program_id: '1'
      }
    ],
    promotions: [
      {
        promotion_id: '1',
        postal_code: '99975',
        birth_date: new Date(''),
        contact_methods: [{ type: 'TEXT' }]
      }
    ]
  }
} as { response: CustomerDetailsResponse | CustomerDetailsError; status: number };
