import createClientMethod from '@/api/createClientMethod';
import { includeCredentials, phdapiDelete } from '@/api/phdApiClient/base';
import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import AccountManagementEndpoints from '@/api/phdApiClient/accountManagement/endpoints';

const fetch = async (code: number) => phdapiDelete(
  getUrl(`${AccountManagementEndpoints.CUSTOMER_COUPON}/${code}`),
  includeCredentials
);

const transformer = async (response: Response): Promise<ClientResult<void>> => ({
  error: !response.ok
});

export const deleteCoupon = createClientMethod(fetch, transformer);
