import React, { useState, useEffect } from 'react';
import {
  Grid, InputLabel, makeStyles, Button, CircularProgress
} from '@material-ui/core';
import { FieldValues, useForm } from 'react-hook-form';
import OutlineTextInput from '../../common/OutlineTextInput';
import { getInputsFromGooglePlace } from '../common/utils';
import { initGoogleAutoSuggest, removeGoogleAutocomplete } from '../common/google/autoComplete';
import { onSubmitHandler } from './OccasionForm';
import LinkButton from '../../common/LinkButton';
import fontStyles from '../../common/fontStyles';

const useStyles = makeStyles({
  content: {
    width: '100%'
  },
  occasion: {
    paddingBottom: '10px'
  },
  inputLabel: {
    ...fontStyles.formFieldLabel,
    lineHeight: 1.43
  },
  validationError: {
    ...fontStyles.errorText,
    marginLeft: '5px'
  },
  search: {
    paddingTop: '10px'
  },
  aptSuite: {
    marginTop: '20px'
  }
});

export const onPlaceChanged = (autocomplete: any, onFormChange: (props: DeliveryFormAddress, validate: boolean) => void) => {
  const placeDetails = autocomplete.getPlace();
  const {
    streetNumber,
    streetAddress,
    city,
    state,
    zipcode
  } = getInputsFromGooglePlace(placeDetails);

  // update all delivery fields
  onFormChange({
    deliveryStreetNumber: streetNumber,
    deliveryAddress: `${streetNumber || ''} ${streetAddress}`.trim(),
    deliveryCity: city,
    deliveryState: state,
    deliveryZipcode: zipcode
  }, true);
};

interface DeliveryFormFieldsProps {
  initialDeliveryState: any;
  searchDelivery: (options: DeliveryAddress) => void;
  isSearchingIndicator: boolean;
  showUseSavedAddressButton: boolean;
  switchToSavedAddresses: ()=> void;
}

interface DeliveryFormAddress {
  deliveryStreetNumber?: number;
  deliveryAddress?: string;
  deliveryAddress2?: string;
  deliveryCity?: string;
  deliveryState?: string;
  deliveryZipcode?: string;
}

export default function DeliveryFormFields(props: DeliveryFormFieldsProps) {
  const {
    initialDeliveryState,
    searchDelivery,
    isSearchingIndicator,
    showUseSavedAddressButton,
    switchToSavedAddresses
  } = props;
  const [autoCompleteActive, setAutoCompleteActive] = useState(false);
  const [autocompleteListener, setAutocompleteListener] = useState<EventListener | null>(null);
  const classes = useStyles();

  const {
    handleSubmit, register, errors, watch: getFormInputData, setValue, getValues
  } = useForm({
    defaultValues: {
      deliveryAddress: initialDeliveryState.address,
      deliveryAddress2: initialDeliveryState.address2,
      deliveryCity: initialDeliveryState.city,
      deliveryState: initialDeliveryState.state,
      deliveryZipcode: initialDeliveryState.zipcode
    }
  });

  const createId = (id: string) => `w2-${id}`;

  const addressFieldId = createId('address');
  const address2FieldId = createId('address2');
  const cityFieldId = createId('city');
  const stateFieldId = createId('state');
  const zipFieldId = createId('zip');

  const {
    deliveryAddress, deliveryAddress2, deliveryCity, deliveryState, deliveryZipcode
  } = getFormInputData();

  useEffect(() => {
    register({ name: 'deliveryAddress' }, { required: true });
    register({ name: 'deliveryAddress2' });
    register({ name: 'deliveryCity' }, { required: true });
    register({ name: 'deliveryState' }, { required: true });
    register({ name: 'deliveryZipcode' },
      { required: true, validate: validateZip });
  }, [register]);

  useEffect(() => function cleanup() {
    if (autocompleteListener) {
      removeGoogleAutocomplete(autocompleteListener)
    }
  }, [autocompleteListener]);

  const onFormChange = (deliveryInput: DeliveryFormAddress, addressFromGoogle = false) => {
    let keys = Object.keys(deliveryInput) as [keyof DeliveryFormAddress];
    keys.forEach((key) => {
      if (addressFromGoogle && key === 'deliveryAddress' && !deliveryInput.deliveryStreetNumber) {
        const streetNumber = getValues().deliveryAddress.split(' ')[0];
        setValue(key, `${streetNumber} ${deliveryInput[key]}`.trim(), { shouldValidate: true });
      } else {
        setValue(key, deliveryInput[key] || '', { shouldValidate: true });
      }
    });
  };

  async function onDeliveryAddressChanged(deliveryInput: DeliveryFormAddress) {
    onFormChange(deliveryInput);
    if (deliveryInput.deliveryAddress && deliveryInput.deliveryAddress.length > 2) {
      // initialize autocomplete only once to avoid duplicate dropdowns
      if (!autoCompleteActive) {
        const options = {
          inputId: addressFieldId,
          types: 'address',
          onPlaceChanged,
          onFormChange
        };
        const listener = initGoogleAutoSuggest(options);
        setAutocompleteListener(listener);
        setAutoCompleteActive(true);
      }
    }
  }

  function buildErrorMessage(): string {
    const errorFields: Array<string> = [];
    Object.keys(errors).forEach((error) => {
      if (error === 'deliveryCity') errorFields.push('city');
      if (error === 'deliveryState') errorFields.push('state');
      if (error === 'deliveryZipcode') errorFields.push('zip code');
    });

    if (errorFields.length === 3) return 'Enter a valid city, state and zip code';
    if (errorFields.length === 2) return `Enter a valid ${errorFields[0]} and ${errorFields[1]}`;
    if (errorFields.length === 1) return `Enter a valid ${errorFields[0]}`;
    return '';
  }

  const errorMessage = buildErrorMessage();

  function validateZip(input: string): (string | true) {
    const isZip = /^\d{5}$/.test(input);
    const isNumberOnly = /^\d+$/.test(input);
    if (!isZip || !isNumberOnly) {
      return 'Enter a valid zip code';
    }
    return true;
  }

  const submitButton = (
    <Button data-testid="search" type="submit" variant="contained" color="primary" disableRipple fullWidth>
      {isSearchingIndicator ? <CircularProgress size={24} /> : 'Search'}
    </Button>
  );

  const onSubmit = (data: FieldValues, event: any) => {
    onSubmitHandler(event, () => {
      searchDelivery({
        address: data.deliveryAddress,
        address2: data.deliveryAddress2,
        city: data.deliveryCity,
        state: data.deliveryState,
        zipcode: data.deliveryZipcode
      });
    });
  };

  return (
    <form className={classes.content} onSubmit={handleSubmit(onSubmit)}>
      <Grid item className={classes.occasion} container xs={12} spacing={1}>
        <Grid item xs={8}>
          <InputLabel className={classes.inputLabel} htmlFor={addressFieldId}>
            Street address
          </InputLabel>
          <OutlineTextInput
              onChange={(input) => onDeliveryAddressChanged({ deliveryAddress: input })}
              id={addressFieldId}
              value={deliveryAddress}
              testId="delivery-address"
              error={!!errors?.deliveryAddress?.type}
          />
        </Grid>
        <Grid item xs={4} data-testid="aptGrid">
          <InputLabel className={classes.inputLabel} htmlFor={address2FieldId}>Apt/Suite</InputLabel>
          <OutlineTextInput
              onChange={(input) => onFormChange({ deliveryAddress2: input })}
              id={address2FieldId}
              value={deliveryAddress2}
              testId="delivery-address2"
              name="deliveryAddress2"
          />
        </Grid>
        {errors?.deliveryAddress?.type === 'required' && (
            <div className={classes.validationError} data-testid="delivery-address-required">
              Invalid street address
            </div>
        )}
      </Grid>
      <Grid item className={classes.occasion} container xs={12} spacing={1}>
        <Grid item xs={6}>
          <InputLabel className={classes.inputLabel} htmlFor={cityFieldId}>
            City
          </InputLabel>
          <OutlineTextInput
            onChange={(input) => onFormChange({ deliveryCity: input })}
            id={cityFieldId}
            value={deliveryCity}
            testId="delivery-city"
            error={!!errors?.deliveryCity?.type}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel className={classes.inputLabel} htmlFor={stateFieldId}>
            State
          </InputLabel>
          <OutlineTextInput
            onChange={(input) => onFormChange({ deliveryState: input.toUpperCase() })}
            id={stateFieldId}
            value={deliveryState}
            testId="delivery-state"
            error={!!errors?.deliveryState?.type}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel className={classes.inputLabel} htmlFor={zipFieldId}>
            ZIP
          </InputLabel>
          <OutlineTextInput
            onChange={(input) => onFormChange({ deliveryZipcode: input })}
            id={zipFieldId}
            value={deliveryZipcode}
            testId="delivery-zip"
            error={!!errors?.deliveryZipcode?.type}
          />
        </Grid>
        {errorMessage && (
          <div className={classes.validationError} data-testid="delivery-city-state-zip-required">
            {errorMessage}
          </div>
        )}
      </Grid>
      {showUseSavedAddressButton
        && (
          <Grid>
            <LinkButton
              testId="switchto-saved-address"
              onClick={switchToSavedAddresses}
            >
              Use a saved address
            </LinkButton>
          </Grid>
        )}
      <Grid className={classes.search} container item xs={12} alignItems="flex-end">
        {submitButton}
      </Grid>
    </form>
  );
}
