import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import {
  OOSAlertType,
  removeOOSAlertItem
} from '@/clientCore/redux/rail/CartRailSlice';
import {
  OOS_ITEM_REMOVED_MESSAGE
} from '@/clientCore/cart/constants';
import { AlertIcon } from '@/clientCore/cart/components/CartRail/common/AlertIcon';
import { CloseIcon } from '@/clientCore/cart/components/CartRail/common/CloseIcon';

import colors from '@/common/colors';
import {
  ENTER_KEY_EVENT_CODE,
  KEYBOARD_ENTER_KEYCODE
} from '@/common/constants';
import styles from './styles';

type CartOOsAlertProps = {
  ossAlertItem: OOSAlertType;
};

const CartOOSAlert = ({ ossAlertItem }: CartOOsAlertProps): JSX.Element => {
  const classes = styles();
  const dispatch = useDispatch();

  const onClose = () => dispatch(removeOOSAlertItem(ossAlertItem));

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === ENTER_KEY_EVENT_CODE || event.keyCode === KEYBOARD_ENTER_KEYCODE) {
      event.preventDefault();
      onClose();
    }
  };

  return (
    <Grid container direction="row" className={clsx(classes.container)} wrap="nowrap">
      <Grid item className={classes.icon}>
        <AlertIcon color={colors.black} />
      </Grid>
      <Grid item className={classes.textContainer}>
        <Grid container>
          <Typography
            component="span"
          >
            {OOS_ITEM_REMOVED_MESSAGE.replace('[Item name]', ossAlertItem.name)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          data-testid="close-alert"
          aria-label="Discard message"
          onClick={onClose}
          onKeyDown={onKeyDown}
          className={clsx(classes.closeIcon)}
        >
          <CloseIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default CartOOSAlert;
