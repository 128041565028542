import getConfig from 'next/config';
import { GqlVersion } from '../../../graphql/hooks/useGqlVersion/GqlVersion';
import { OVERRIDE_GQL_VERSION } from '../../../ReduxApplication/actionTypes';

interface Action {
  type: string;
  gqlVersion: string;
}

const defaultGqlVersion: GqlVersion = getConfig()?.publicRuntimeConfig?.GQL_VERSION || 'v2';

const gqlVersion = (state = defaultGqlVersion, action: Action): string => {
  switch (action.type) {
    case OVERRIDE_GQL_VERSION: {
      return action.gqlVersion;
    }
    default:
      return state;
  }
};

export default gqlVersion;
