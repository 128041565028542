import gql from 'graphql-tag';

const NutritionFragment = gql`
  fragment NutritionFragment on Nutrition {
    calories
    servings
    unit
    portion
    qualifiers
    name
    additionalNutrition {
      calories
      servings
      unit
      name
    }
}
`;
export default NutritionFragment;
