import MobileDetect from 'mobile-detect';
import React, { createContext, useContext } from 'react';

const ResponsiveContext = createContext(null);

interface UAProps {
  userAgent: string;
  initialWidth?: string | null;
}

const ResponsiveProvider = ({
  value,
  children
}: {
  value: UAProps;
  children: JSX.Element;
}): JSX.Element => {
  const { userAgent, initialWidth = null } = value;
  const md = new MobileDetect(userAgent);
  const isMobile = !!md.mobile();
  const responsiveWidth = isMobile ? 'xs' : 'lg';

  return (
    <ResponsiveContext.Provider
      value={{
        ua: userAgent,
        isMobile,
        initialWidth: initialWidth || responsiveWidth
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  );
};

const useResponsiveContext = (): {
  ua: string;
  isMobile: boolean;
  initialWidth: string;
} => useContext(ResponsiveContext);

export { ResponsiveProvider, useResponsiveContext };
