import { compose } from '@reduxjs/toolkit';
import { RootState } from '@/rootStateTypes';

const domain = (state: RootState): LocalizationDetails => state.domain.localization;

const occasion = (
  locDetail: LocalizationDetails
): OccasionChoice => locDetail.localizedOccasion;

const storeTimeZone = (
  locDetail: LocalizationDetails
): string => locDetail.localizedStoreDetails?.storeTimezone;

const localizedStore = (
  locDetail: LocalizationDetails
): StoreDetail => locDetail.localizedStoreDetails;

const localizationInitialized = (
  locDetail: LocalizationDetails
): boolean => locDetail.localizationInitialized;

const selectOccasion = compose(occasion, domain);
const selectStoreTimeZone = compose(storeTimeZone, domain);
const selectLocalizedStore = compose(localizedStore, domain);
const selectLocalizationInitialized = compose(localizationInitialized, domain);

export {
  selectOccasion,
  selectStoreTimeZone,
  selectLocalizedStore,
  selectLocalizationInitialized
};
