import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import {
  defaultStoreConfig as clientCoreConfig,
  setStore
} from '@pizza-hut-us-development/client-core';
import { useSelector } from 'react-redux';
import rootReducer from './rootReducer';
import ccRootReducer from '@/clientCore/redux/rootReducer';
import rtkReducer from './api/reduxToolkit';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const defaultStoreConfig = {
  reducer: combineReducers({
    ...rootReducer,
    ...clientCoreConfig.reducer,
    ...ccRootReducer,
    ...rtkReducer.reducers
  }),
  middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) => {
    const defaultMiddlewareWithOptions = getDefaultMiddleware({
      serializableCheck: {
        // disabling checks to quiet CI output
        ignoreState: true,
        ignoredActionPaths: [
          'meta.arg', // comes by default
          'data.cta.callback',
          'store.daysAvailableForSchedule',
          'data.altCta.callback',
          'gqlError',
          'handleAfterLocalizing'
        ],
        ignoredPaths: ['presentational.localization.modal.data.cta.callback'],
        warnAfter: 200
      },
      immutableCheck: {
        warnAfter: 200
      }
    });

    const clientCoreMiddleware = clientCoreConfig.middleware(getDefaultMiddleware);

    return clientCoreMiddleware.concat(defaultMiddlewareWithOptions).concat(rtkReducer.middlewares);
  }
};
const createStore = () => configureStore(defaultStoreConfig);
/* eslint-enable @typescript-eslint/explicit-module-boundary-types */

export const store = createStore();
setStore(store);
export type AppStore = ReturnType<typeof createStore>;
export type StoreState = ReturnType<typeof store.getState>;

export const useCustomSelector = () => {
  const selector = useSelector((state: StoreState) => state);

  return selector;
};

export default createStore;
