// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import {
  createStyles, Grid, makeStyles, Theme
} from '@material-ui/core';
import getMarkers from './googleMap';
import SelectedPin from './icons/SelectedPin/selectedPin.png';
import Pin from './icons/Pin/pin.png';
import GreyScaleMapStyle from './greyScaleMapStyle';
import { extraSmallMobileStartBreakpoint } from '../../../materialUi/theme';

interface MapProps {
  mapId: string;
  stores: Array<StoreDetail>;
  onMarkerClick?: Function;
  highlightedMarker?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  mapContainer: {
    width: 'auto',
    height: '280px',
    [theme.breakpoints.down(extraSmallMobileStartBreakpoint)]: {
      height: '200px'
    }
  }
}));

function Map({
  mapId, stores, onMarkerClick, highlightedMarker
}: MapProps) {
  const [currentMap, setMap] = useState(null);
  const [markers, setMarkers] = useState(null);

  useEffect(() => {
    initMap();
  }, [currentMap, stores, highlightedMarker]);

  const classes = useStyles();
  const mapRef = useRef();
  const { google } = window as any;
  const { Map, StyledMapType } = google.maps;

  function initMap() {
    const map = getMap();

    if (!markers) {
      const { markers: mapMarkers, bounds } = getMarkers(stores, onMarkerClick, highlightedMarker);

      if (mapMarkers.length) {
        pinMarkersToMap(map, mapMarkers, bounds);
        setMarkers(mapMarkers);
      }
    } else {
      const store = stores.find((store) => store.storeNumber === highlightedMarker);
      markers.forEach((marker) => setMarkerIcon(marker, store));
    }

    setMap(map);
  }

  function setMarkerIcon(marker: any, store: StoreDetail) {
    const { position } = marker;
    const isStoreSelectedMarker = store
      && position.lat() === store.lat
      && position.lng() === store.long;

    if (isStoreSelectedMarker) {
      recenterMapIfMarkerIsOutOfView(position);
      marker.setIcon(SelectedPin);
      marker.setZIndex(3);
    } else {
      marker.setIcon(Pin);
      marker.setZIndex(2);
    }
  }

  function recenterMapIfMarkerIsOutOfView(position: any) {
    if (currentMap.getBounds() && !currentMap.getBounds().contains(position)) {
      currentMap.setCenter(position);
    }
  }

  function pinMarkersToMap(map: any, markers: any, bounds: any) {
    markers.map((marker) => marker.setMap(map));
    map.fitBounds(bounds);
  }

  function getMap() {
    let map = currentMap;
    if (!map) {
      const mapOptions = {
        center: { lat: 37.09024, lng: -95.712891 },
        controlSize: 25,
        disableDefaultUI: true,
        zoomControl: true
      };
      map = new Map(mapRef.current, mapOptions);

      const mapStyle = new StyledMapType(GreyScaleMapStyle);
      map.mapTypes.set('gray-scale-map', mapStyle);
      map.setMapTypeId('gray-scale-map');
    }
    return map;
  }

  return (
    <Grid ref={mapRef} id={mapId} data-testid={mapId} className={classes.mapContainer} />
  );
}

export default Map;
