// eslint-disable-next-line max-classes-per-file
import getConfig from 'next/config';
import { NextPageContext } from 'next';
import logger from '../../common/logger';
import AngularEndpoints from './endpoints';
import transformCartInfo from './transformCartInfo';
import { parseJson } from '../clientHelpers';
import loggingFetch from '../../common/loggingFetch';
import telemetry from '../../telemetry';
import {
  getLocalizationToken,
  getQOSESSIDCookie,
  getUserStateCookie
} from '../../localization/localizationToken';
import isClientSide from '../../common/isClientSide';
import { CartInformation } from '../../cart/legacyCart/legacyCartType';

class AngularAPIException extends Error {
  errorCode: string;

  constructor(message: string, errorCode: string) {
    super(message);
    this.message = message;
    this.errorCode = errorCode;
  }
}

class CartInfoException extends AngularAPIException {}
class ClearCartException extends AngularAPIException {}
class RemoveAlcoholFromCartException extends AngularAPIException {}
class GetWeb2TokenException extends AngularAPIException {}
class MerchantValidationException extends AngularAPIException {}

const generateErrorMessage = (message: string, status: number, errorCode: string) => {
  let errorMessage = `Unexpected response: ${message} (status: ${status})`;

  if (errorCode) {
    errorMessage += ` (error_code: ${errorCode})`;
  }

  return errorMessage;
};

const getUrl = (endpoint: AngularEndpoints) => {
  const { publicRuntimeConfig } = getConfig();
  const angularApiUrl = publicRuntimeConfig.ANGULAR_API_URL;

  if (isClientSide()) return endpoint;

  return angularApiUrl ? `${angularApiUrl}${endpoint}` as AngularEndpoints : endpoint;
};

interface angularApi {
  get(endpoint: AngularEndpoints, retries?: number): Promise<any>;
  post(endpoint: AngularEndpoints, retries?: number, body?: string): Promise<any>;
  getCartInfo(): Promise<ClientResult<CartInformation>>;
  clearCart(): Promise<ClientResult<void>>;
  removeAlcoholFromCart(): Promise<ClientResult<void>>;
  tokenFromLegacyUserState(userStateToken: string, qosessId: string): Promise<ClientResult<string>>;
  getToken(ctx: any): Promise<IncomingLocalizationToken>;
  merchantValidation(applePayUrl: string): Promise<any>;
}

const get = (endpoint: AngularEndpoints, retries = 2): Promise<any> => {
  return loggingFetch(getUrl(endpoint),
    {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(parseJson)
    .then(({ response, status }) => {
      if (response?.session_reset) {
        if (retries === 0) throw new AngularAPIException(`${endpoint} tried twice when session expired`, response?.error_code);
        return angularApiClient.get(endpoint, retries - 1);
      }
      return { response, status };
    })
    .catch((error) => {
      telemetry.addNoticeError(error, { endpoint });
      logger.withoutTelemetry.error(error.message);
      return error;
    });
};

const headers = {
  accept: 'application/json, text/plain, */*',
  'accept-language': 'en-US,en;q=0.9',
  'content-type': 'application/json;charset=UTF-8'
};

/* POSTWITHHEADERS and POST are now deprecated and should not be called externally */
const postWithHeaders = (url: string, headers: any, body = ''): Promise<Response> => loggingFetch(url, {
  method: 'POST',
  headers,
  credentials: 'include',
  body
});

const post = (endpoint: AngularEndpoints, retries = 2, body = ''): Promise<any> => {
  return postWithHeaders(getUrl(endpoint), headers, body)
    .then(parseJson)
    .then(({ response, status }) => {
      if (response?.session_reset) {
        if (retries === 0) throw new AngularAPIException(`${endpoint} tried twice when session expired`, response?.error_code);
        return angularApiClient.post(endpoint, retries - 1, body);
      }
      return { response, status };
    })
    .catch((error: AngularAPIException) => {
      telemetry.addNoticeError(error, { endpoint });
      logger.withoutTelemetry.error(error.message);
      return error;
    });
};

const getCartInfo = async (): Promise<ClientResult<CartInformation>> => {
  const endpoint = AngularEndpoints.GET_SUMMARY_DATA;
  return post(endpoint, 2, JSON.stringify({ channel_id: 'WEB2' }))
    .then(({ response, status }) => {
      if (status !== 200) {
        const errorCode = response?.error_code;
        const message = generateErrorMessage(response?.message, status, errorCode);
        throw new CartInfoException(message, errorCode);
      }

      const transformedCartInfo = transformCartInfo(response);
      return { result: transformedCartInfo, error: false };
    })
    .catch((error: AngularAPIException) => {
      telemetry.addNoticeError(error, { endpoint });
      logger.withoutTelemetry.error(error.message);
      return { error: true, errorCode: error.errorCode };
    });
};

const clearCart = (): Promise<ClientResult<void>> => {
  const endpoint = AngularEndpoints.CLEAR_CART;
  return get(endpoint)
    .then(({ response, status }) => {
      if (status !== 200) {
        const errorCode = response?.error_code;
        const message = generateErrorMessage(response?.message, status, errorCode);
        throw new ClearCartException(message, errorCode);
      }
      return { error: false };
    })
    .catch((error: AngularAPIException) => {
      telemetry.addNoticeError(error, { endpoint });
      logger.withoutTelemetry.error(error.message);
      return { error: true, errorCode: error.errorCode };
    });
};

const removeAlcoholFromCart = (): Promise<ClientResult<void>> => {
  const endpoint = AngularEndpoints.REMOVE_ALCOHOL;
  return get(endpoint)
    .then(({ response, status }) => {
      if (status !== 200) {
        const errorCode = response?.error_code;
        const message = generateErrorMessage(response?.message, status, errorCode);
        throw new RemoveAlcoholFromCartException(message, errorCode);
      }
      return { error: false };
    })
    .catch((error: AngularAPIException) => {
      telemetry.addNoticeError(error, { endpoint });
      logger.withoutTelemetry.error(error.message);
      return { error: true, errorCode: error.errorCode };
    });
};

const tokenFromLegacyUserState = (userStateToken: string, qosessId: string): Promise<ClientResult<string>> => {
  const endpoint = AngularEndpoints.GET_WEB2_TOKEN;
  const customCookieHeader = { Cookie: `user_state=${userStateToken}; QOSESSID=${qosessId};` };

  return loggingFetch(getUrl(endpoint),
    {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        ...customCookieHeader
      }
    })
    .then(parseJson)
    .then(({ response, status }) => {
      if (status !== 200 || !response.token) {
        const errorCode = response?.error_code;
        const message = generateErrorMessage(response?.message, status, errorCode);
        throw new GetWeb2TokenException(message, errorCode);
      }

      return { result: response.token, error: false };
    })
    .catch((error: AngularAPIException) => {
      telemetry.addNoticeError(error, { endpoint });
      logger.withoutTelemetry.error(error.message);
      return { error: true, errorCode: error.errorCode };
    });
};

const getToken = async (ctx: NextPageContext): Promise<IncomingLocalizationToken> => {
  const localizationToken = getLocalizationToken(ctx);
  if (localizationToken) {
    return { token: localizationToken, source: 'cookie' };
  }

  const userStateToken = getUserStateCookie(ctx);
  const qosessId = getQOSESSIDCookie(ctx);
  if (!userStateToken || !qosessId) {
    return { token: undefined };
  }

  const networkToken = await angularApiClient.tokenFromLegacyUserState(userStateToken, qosessId);
  return { token: networkToken.result, source: 'network' };
};

const merchantValidation = (applePayUrl: string): Promise<any> => {
  const endpoint = AngularEndpoints.MERCHANT_VALIDATION;

  return loggingFetch(`${getUrl(endpoint)}?u=${applePayUrl}`,
    {
      method: 'GET'
    })
    .then(parseJson)
    .then(({ response, status }) => {
      if (status !== 200) {
        const errorCode = response?.error_code;
        const message = generateErrorMessage(response?.message, status, errorCode);
        throw new MerchantValidationException(message, errorCode);
      }

      return { result: response, error: false };
    })
    .catch((error: AngularAPIException) => {
      telemetry.addNoticeError(error, { endpoint });
      logger.withoutTelemetry.error(error.message);
      return { error: true, errorCode: error.errorCode };
    });
}

const angularApiClient: angularApi = {
  get,
  post,
  getCartInfo,
  clearCart,
  removeAlcoholFromCart,
  tokenFromLegacyUserState,
  getToken,
  merchantValidation
};

export default angularApiClient;
