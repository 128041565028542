import { compose } from '@reduxjs/toolkit';
import { RootState } from '@/rootStateTypes';
import { CartStateTypes } from '@/clientCore/redux/cart/types';

interface ClientCoreStoreType {
  cart: CartStateTypes;
}
// Domain
const clientCoreStore = (state: RootState): ClientCoreStoreType => state.clientCorePresentational;

// Selectors
const isCartLoading = (ccStore: ClientCoreStoreType) => ccStore.cart.isCartLoading;
const cartInfoAndWariningView = (ccStore: ClientCoreStoreType) => ccStore.cart.showCartInfoAndWariningView;
const cartInfoAndWariningViewType = (ccStore: ClientCoreStoreType) => ccStore.cart.cartInfoAndWarningViewType;

export const presentationalCartSelectors = {
  isCartLoading: compose(isCartLoading, clientCoreStore),
  showCartInfoAndWariningView: compose(
    cartInfoAndWariningView,
    clientCoreStore
  ),
  cartInfoAndWariningViewType: compose(
    cartInfoAndWariningViewType,
    clientCoreStore
  )
};
