import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { closeModal, selectors } from '../../localization/actions';
import WarningDialog from '../../coreComponents/Modal/WarningDialog';
import { modalErrorPopupAnalytics } from '../../dataAnalytics/dataAnalyticsHelper';
import useMappedRoute from '../../dataAnalytics/hooks/useMappedRoute';
import { RootState } from '@/rootStateTypes';
import useAnalytics, { Analytics } from '@/dataAnalytics/hooks/useAnalytics';
import telemetry from '@/telemetry';

const pushAnalytics = (
  { analyticsModalData, ...modalData }: ModalContent,
  screenName: string,
  analytics: Analytics
) => {
  analytics.push(() => modalErrorPopupAnalytics(
    analyticsModalData?.title || modalData?.title || '',
    analyticsModalData?.body || modalData?.body || '',
    analyticsModalData?.screenName || screenName,
    analyticsModalData?.errorCode,
    analyticsModalData?.occasion,
    analyticsModalData?.isSavedAddress,
    analyticsModalData?.storeSearchStage
  ));
};

const Modal = (): JSX.Element => {
  const modal = useSelector(selectors.selectModalDetails);
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { showLoadingBar, hasLoadingQuery } = useSelector(
    (state: RootState) => state.presentational.localization
  );
  const screenName = useMappedRoute(useRouter());
  const isLoading = useMemo(() => (showLoadingBar || hasLoadingQuery),
    [showLoadingBar, hasLoadingQuery]);
  const isOpen = useMemo(() => (
    (modal.open && !isLoading) || (modal.open && !modal.redirect)
  ), [modal.open, modal.redirect, isLoading]);

  const handleCloseModal = () => {
    if (modal.data?.onClose) modal.data.onClose();
    dispatch(closeModal());
  };

  useEffect(() => {
    if (modal.open) {
      pushAnalytics(modal.data, screenName, analytics);
      telemetry.addCustomEvent('open-modal', { modalTitle: modal?.data?.title || '' });
    }
  }, [modal.open, modal.data, screenName, analytics]);

  return (
    <WarningDialog
      open={isOpen}
      content={modal.data}
      closeModal={handleCloseModal}
    />
  );
};

export default Modal;
