import gql from 'graphql-tag';

const LinkFragmentV3 = gql`
    fragment LinkFragmentV3 on LinkV3 {
        link: path
        linkType: type
        linkDisplayText: displayText
        linkTitle: title 
        external
    }
`;
export default LinkFragmentV3;
