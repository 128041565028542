// eslint-disable-next-line max-classes-per-file
import { includeCredentials, phdapiPost } from '@/api/phdApiClient/base';
import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import AccountManagementEndpoints from '@/api/phdApiClient/accountManagement/endpoints';
import { createClientMethodImproved } from '@/api/createClientMethod';
import { AccountInfo, Password } from '@/domain/Profile';
import { getPublicRuntimeConfig } from '@/api/phdApiClient/helpers/getPublicRuntimeConfig';

type Fetch = (password: Password, email: AccountInfo['email']) => Promise<Response>;
type APISuccess = {
  token: string;
};
type APIError = {
  error_code: `${number}`;
};
type Token = {
  token: string;
};

export const token = (
  result: Token | IncorrectPasswordError | Error
): result is Token => 'token' in result;

const apiPasswordIncorrect = (
  result: APISuccess | APIError
): result is APIError => 'error_code' in result && result.error_code === '0303';

const apiError = (
  result: APISuccess | APIError
): result is APIError => 'error_code' in result;

const fetch: Fetch = async (password, email) => {
  // load channel ID
  const { phdApiAuthChannel } = getPublicRuntimeConfig();

  return phdapiPost(
    getUrl(AccountManagementEndpoints.ESCALATE_PRIVILEGES),
    includeCredentials,
    JSON.stringify({
      channel_id: phdApiAuthChannel,
      username: email,
      password
    })
  );
};

export class IncorrectPasswordError extends Error { name = 'IncorrectPasswordError'; }
export class UnexpectedEscalatePrivilegesError extends Error {
  name = 'UnexpectedEscalatePrivilegesError';
}

const transformer = async (
  response: Response
): Promise<Token | IncorrectPasswordError | UnexpectedEscalatePrivilegesError> => {
  const result: APISuccess | APIError = await response.json();

  if (apiPasswordIncorrect(result)) {
    return new IncorrectPasswordError();
  }

  if (apiError(result)) {
    return new UnexpectedEscalatePrivilegesError();
  }

  return result;
};

export const escalatePrivileges = createClientMethodImproved(fetch, transformer);
