import {
  makeStyles, createStyles, TextField, InputProps, Theme
} from '@material-ui/core';
import React from 'react';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import colors from '../colors';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import fontStyles from '../fontStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '&$focused $notchedOutline, &:hover $notchedOutline': {
      borderColor: colors.gray900,
      borderWidth: '1px',
      boxShadow: '0 2px 4px 0 rgba(19, 19, 19, 0.5)'
    }
  },
  helperText: {
    ...fontStyles.bodySmall,
    margin: 0
  },
  input: {
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '16px'
    },
    padding: '10px',
    '&::placeholder': {
      fontStyle: 'italic',
      color: colors.gray601,
      opacity: '1',
      fontSize: '14px'
    },
    '&:disabled': {
      backgroundColor: colors.gray100,
    }
  },
  focused: {},
  notchedOutline: {
    borderWidth: '1px',
    borderColor: colors.gray601
  },
  error: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    '& .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline': {
      borderColor: '#e52a33'
    },
    '&$focused $notchedOutline, &:hover $notchedOutline': {
      borderColor: '#e52a33'
    }
  }
}));

interface OutlineTextInputProps {
  id: string;
  testId?: string;
  value?: string;
  placeholder?: string;
  onChange?: (input: string) => void;
  icon?: InputProps;
  defaultValue?: string;
  name?: string;
  inputRef?: any;
  error?: boolean;
  onKeyPress?: (event: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  autoComplete?: string;
  helperText?: string;
  autoFocus?: boolean;
  maxLength?: { maxLength: number };
  type?: string;
  inputMode?:
  | 'none'
  | 'text'
  | 'search'
  | 'numeric'
  | 'email'
  | 'tel'
  | 'url'
  | 'decimal';
  helperTextClassName?: string;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  ariaLabel?: string;
  ariaRequired?: boolean;
  multiline?: boolean;
  minRows?: number;
}

const OutlineTextInput = ({
  onChange,
  testId,
  icon,
  defaultValue,
  inputRef,
  error,
  onKeyPress,
  onFocus,
  onBlur,
  autoComplete,
  autoFocus,
  maxLength,
  inputMode = 'text',
  helperTextClassName = '',
  disabled,
  ariaLabel,
  readOnly,
  ariaRequired = false,
  multiline,
  minRows,
  ...rest
}: OutlineTextInputProps) => {
  const { helperText, ...classes } = useStyles();

  return (
    <TextField
      {...rest}
      error={error}
      FormHelperTextProps={{ className: clsx(helperTextClassName, helperText) }}
      InputProps={{ classes, ...icon }}
      inputProps={{ 'aria-label': ariaLabel, 'data-testid': testId, 'aria-required': ariaRequired, defaultValue, inputMode, ...maxLength, readOnly }}
      variant="outlined"
      inputRef={inputRef || null}
      onChange={({ target }) => onChange && onChange(target.value)}
      onFocus={onFocus}
      onBlur={onBlur}
      fullWidth
      onKeyPress={onKeyPress}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={disabled}
      multiline={multiline}
      minRows={minRows}
    />
  );
};

export default OutlineTextInput;
