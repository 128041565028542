import { QueryHookOptions } from '@apollo/client';
import useLocalizationQueryVariables from './useLocalizationQueryVariables';

export type ProductDomain = 'items' | 'discounts';

export default function useProduct(
  productDomain: ProductDomain, productId?: string
) : QueryHookOptions {
  const { variables } = useLocalizationQueryVariables();

  const itemID = (productId && !productId.includes('stores/'))
    ? `stores/${variables?.storeID.replace(/\D/g, '')}/${productDomain}/${productId}`
    : productId;

  return {
    variables: {
      itemID
    }
  };
}
