import React, { CSSProperties } from 'react';

const style : CSSProperties = {
  color: 'black',
  fontFamily: 'courier new',
  fontSize: '14px',
  marginRight: '4px'
};

export default function CaratIcon() {
  return (<span style={style}>&gt;</span>);
}
