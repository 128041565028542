import { _delete } from '@/api/clientHelpers';
import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import { getPublicRuntimeConfig } from '@/api/phdApiClient/helpers/getPublicRuntimeConfig';
import ClearSessionEndpoints from './endpoints';

const clearSession = async (): Promise<Response> => {
  const url = getUrl(ClearSessionEndpoints.CLEAR_SESSION);
  const { phdApiServerEnv } = getPublicRuntimeConfig();

  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/json',
    'x-server-env': phdApiServerEnv
  };

  return _delete(url, headers)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};

export default clearSession;
