import phdApiClient from '@/api/phdApiClient';
import telemetry from '@/telemetry';
import { CLEAR_SESSION_FAILED } from './constants';
import standaloneApiClient from '@/api/standaloneApiClient';
import StandaloneEndpoints from '@/api/standaloneApiClient/endpoints';

const clearSession = async (): Promise<boolean> => {
  try {
    await phdApiClient.clearSession();

    return true;
  } catch {
    const clearSessionError = new Error(CLEAR_SESSION_FAILED);

    telemetry.addNoticeError(clearSessionError);

    try {
      await phdApiClient.clearSession();

      return true;
    } catch {
      telemetry.addNoticeError(clearSessionError);

      return false;
    }
  }
};

const saveServerEnv = async (serverEnv: string): Promise<ClientResult<void>> => {
  try {
    await standaloneApiClient.post({ serverEnv }, StandaloneEndpoints.SAVE_SERVER_ENV);

    return { error: false };
  } catch {
    return { error: true };
  }
};

const deleteServerEnv = async (): Promise<ClientResult<void>> => {
  try {
    await standaloneApiClient.remove(StandaloneEndpoints.DELETE_SERVER_ENV);

    return { error: false };
  } catch {
    return { error: true };
  }
};

export default { clearSession, saveServerEnv, deleteServerEnv };
