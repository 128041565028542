import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  PromotionNotAppliedWarning,
  useApplyPromoCodeMutation,
  useGetDiscountFromMarketingCodeQuery,
  useRemovePromoCodeMutation,
  YumCart
} from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import { CouponReturn } from '../types';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import {
  GUEST_COUPON_ERROR_MESSAGE,
  POS_SERIALIZED_CODE_LENGTH,
  QO_SERIALIZED_CODE_LENGTH, YUM_COUPON_ERROR_MESSAGE
} from '@/clientCore/cart/constants';
import { onRailCouponEntry } from '@/checkout/checkout.analytics';
import { closeCartRail } from '@/clientCore/redux/rail/CartRailSlice';
import Routes from '@/router/routes';
import { getAlphanumericValue } from '@/clientCore/cart/helpers';
import { RootState } from '@/rootStateTypes';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import YumAddedCoupons from '../../YumAddedCoupons/YumAddedCoupons';
import triggerMutationWrapper from '@/clientCore/helper/triggerMutationWrapper';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { findRedemptionWarningFromPromoOrCode, isRedemptionWarning, isYumCart } from '@/clientCore/cart/components/CartRail/components/CartContent/components/YumAddedCoupons/helpers';
import useCodeRedemption from '../../YumAddedCoupons/hooks/useCodeRedemption';

const useCoupon = (): CouponReturn => {
  const cart = useSelector(orderSelectors.cart);
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const [{ enabled: useYumCouponCodeAllowHyphenDecision }] = useDecision('fr-3720-coupon-code-allow-hyphen');

  const [handleRedemptionWarnings] = useCodeRedemption();

  const [couponId, setCouponId] = useState('');
  const [couponLoading, setCouponLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(isYumEcomm ? ((cart as YumCart)?.appliedPromotions?.length ?? 0) > 0 : false);
  const [errorMessage, setErrorMessage] = useState('');
  const [marketingCode, setMarketingCode] = useState<string>('');

  const dispatch = useDispatch();
  const router = useRouter();
  const analytics = useAnalytics();

  const [applyYumPromoCode] = useApplyPromoCodeMutation();
  const [removePromoCode] = useRemovePromoCodeMutation();
  const {
    data: discountCodeData, isFetching, error: discountCodeError
  } = useGetDiscountFromMarketingCodeQuery(
    { storeNumber: storeDetails?.storeNumber, marketingCode },
    { skip: !storeDetails?.storeNumber || !isYumEcomm || !marketingCode }
  );

  const isGuestSingleUseSerializedCode = (coupon: string): boolean => {
    if (
      isYumEcomm
      && (coupon.length === QO_SERIALIZED_CODE_LENGTH
        || (coupon.length === POS_SERIALIZED_CODE_LENGTH && coupon.startsWith('PH' || 'ph')))
    ) {
      setErrorMessage(GUEST_COUPON_ERROR_MESSAGE);
      return true;
    }
    return false;
  };

  const sanitizeCouponId = (coupon: string) => getAlphanumericValue(coupon, isYumEcomm, useYumCouponCodeAllowHyphenDecision);

  const sanitizeAndSetCouponId = (coupon: string) => {
    setErrorMessage('');
    setCouponId(sanitizeCouponId(coupon));
  };
  // used for phd
  const routeToDeals = () => {
    if (couponId.trim().length && !isGuestSingleUseSerializedCode(couponId)) {
      analytics.push(() => onRailCouponEntry(couponId));
      router.push({ pathname: Routes.DEALS, query: { id: couponId } });
      dispatch(closeCartRail());
    }
  };
  // used for yum
  const submitYumPromoCode = async () => {
    if (!couponId) {
      return;
    }
    const trimmedCouponId = couponId.toUpperCase().trim();
    setCouponLoading(true);
    await triggerMutationWrapper(
      applyYumPromoCode(trimmedCouponId), {
        onSuccess: (responseCart) => {
          if (isYumCart(responseCart)) {
            const isCouponApplied = responseCart?.appliedPromotions?.some((promotion) => promotion?.code === trimmedCouponId);
            const associatedWarning = findRedemptionWarningFromPromoOrCode(responseCart, undefined, trimmedCouponId);

            if (!isCouponApplied && !associatedWarning) setErrorMessage(YUM_COUPON_ERROR_MESSAGE);
            if (associatedWarning) handleRedemptionWarnings(associatedWarning.promotionId, responseCart);
          }
          setCouponLoading(false);
        },
        onError: (error) => {
          setErrorMessage(YUM_COUPON_ERROR_MESSAGE);
          console.error({ error });
          setCouponLoading(false);
        }
      }
    );
  };

  const checkYumCouponRouting = () => {
    if (!couponId) {
      setErrorMessage(YUM_COUPON_ERROR_MESSAGE);
      return;
    }
    setMarketingCode(couponId.toUpperCase());
  };

  const handleRemoveYumPromoCode = async (code: string) => {
    try {
      await removePromoCode(code);
    } catch (error) {
      console.error({ error });
    }
  };

  const appliedPromotions = useMemo(() => (cart as YumCart)?.appliedPromotions || [], [cart]);

  const redemptionWarnings = useMemo(() => (cart as YumCart)?.warnings?.reduce((acc: PromotionNotAppliedWarning[], warning) => {
    if (!isRedemptionWarning(warning)) return acc;
    if (!acc.some((parsedWarning) => parsedWarning.promotionId === warning.promotionId)) {
      return [...acc, warning];
    }
    return acc;
  }, []) || [], [cart]);

  useEffect(() => {
    if ((redemptionWarnings.length || appliedPromotions.length) && isYumEcomm && !isExpanded) {
      setIsExpanded(true);
    }
  }, [isExpanded, isYumEcomm, setIsExpanded, redemptionWarnings, appliedPromotions]);

  const yumDisplayableCoupons = [...appliedPromotions, ...redemptionWarnings].map((promotionOrWarning) => <YumAddedCoupons promotionOrWarning={promotionOrWarning} />);

  useEffect(() => {
    if (discountCodeError && !isFetching) {
      submitYumPromoCode();
      setMarketingCode('');
    }

    if (!isFetching && discountCodeData) {
      router.push({ pathname: Routes.DEALS, query: { id: marketingCode } });
      dispatch(closeCartRail());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountCodeData, isFetching, discountCodeError, marketingCode]);

  return [
    {
      couponId,
      errorMessage,
      isExpanded,
      yumDisplayableCoupons,
      couponLoading
    },
    {
      applyButtonSubmit: isYumEcomm ? checkYumCouponRouting : routeToDeals,
      handleRemoveYumPromoCode,
      sanitizeAndSetCouponId,
      setIsExpanded
    }
  ];
};

export default useCoupon;
