import { createStyles, makeStyles } from '@material-ui/core';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import colors from '@/common/colors';

const styles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
    padding: '20px 17px 0px 15px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      paddingTop: '20px',
      paddingLeft: '24px',
      paddingRight: '28px'
    }
  },
  goBack: {
    margin: '20px 0 0 16px'
  },
  goBackText: {
    marginLeft: '6px'
  },
  goBackIcon: {
    fontSize: '13px'
  },
  title: {
    margin: '0 0 15px 0',
    fontSize: '18px',
    fontFamily: 'open_sans_bold',
    lineHeight: 1.2,
    color: colors.gray900,

    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      fontSize: '20px'
    }
  }
}));

export default styles;
