import { createSelector, createSlice } from '@reduxjs/toolkit';
import { DEFAULT_EXPERIENCE } from './constants';
import { RootState } from '@/rootStateTypes';
import { Experience, isSalesExperience } from './types';
import logger from '@/common/logger';
import telemetry from '@/telemetry';

class SalesExperienceError extends Error { name = 'SalesExperienceError'; }

const { actions, reducer } = createSlice({
  name: 'salesExperience',
  initialState: DEFAULT_EXPERIENCE as Experience,
  reducers: {
    experience: (state, action) => {
      if (!isSalesExperience(action.payload)) {
        const error = new SalesExperienceError('Tried setting sales experience to unknown value');
        logger.withoutTelemetry.error(error.message, { value: action.payload });
        telemetry.addNoticeError(error, { value: action.payload });
        return state;
      }
      return action.payload;
    }
  }
});

const selectors = {
  experience: createSelector((state: RootState) => state.config, (state) => state.salesExperience)
};

export {
  reducer,
  actions,
  selectors
};
