import { OccasionString } from '../constants';

enum Occasion {
  CARRYOUT = 'C',
  DELIVERY = 'D',
  UNKNOWN = 'U'
}

export const getMaxOrderLimitForOccasion = ({
  maxOrderLimitsList,
  occasion
}: {
  maxOrderLimitsList: OrderMaxOccasion[];
  occasion: Occasion;
}): number => {
  const maxOrderForOccasion = maxOrderLimitsList?.find(
    (maxOrderLimit) => maxOrderLimit.name === OccasionString[occasion]
  );

  return maxOrderForOccasion?.maxOrder || Infinity;
};
