import {
  Favorite, PaymentDetails, ProductDetails, ProductModifier
} from '@/account/orders/favorites/Favorites.types';
import {
  FavoritesResponse,
  OccasionAddressResponse,
  PaymentDetailsResponse, ProductDetailsResponse
} from '@/api/phdApiClient/accountManagement/favorites/getFavorites.types';
import { ProductModifierResponse } from '../orders/getOrders.types';
import { Occasion, OccasionApi } from '@/localization/constants';
import { CommonAddress } from '@/account/orders/orderHistory/order/Order.types';

const transformModifiers = (
  modifiers: ProductModifierResponse[]
): ProductModifier[] => modifiers.map(
  ({ modifier, modifier_id, quantities }) => ({ name: modifier, id: modifier_id, quantities })
);

const transformProducts = (
  products: ProductDetailsResponse[]
): ProductDetails[] => products.map((product) => ({
  productId: product.product_id,
  product: product.product,
  modifiers: transformModifiers(product.modifiers),
  nutrition: product.nutrition,
  quantity: product.quantity
}));

const transformAddress = (address: OccasionAddressResponse): CommonAddress => ({
  address1: address?.address1 || '',
  zipcode: address?.postal_code || '',
  state: address?.state || '',
  city: address?.city || ''
});

const transformPayment = (payment: PaymentDetailsResponse): PaymentDetails => ({
  name: payment.name,
  paymentId: payment.payment_id,
  type: payment.type
});

const getFormattedAddress = ({
  address1,
  city,
  state,
  zipcode
}: CommonAddress): string => (address1.length ? `${address1}, ${city}, ${state}, ${zipcode}` : '');

const transformOccasion = (occasionId: PHDApiStoreOccasion): Occasion => {
  if (occasionId.toLowerCase() === OccasionApi.C.toLowerCase()) {
    return Occasion.CARRYOUT;
  }
  return Occasion.DELIVERY;
};

export const transformFavoritesData = (favoritesData: FavoritesResponse): Favorite[] => {
  const { favorites, stores } = favoritesData;

  if (!favorites) {
    return [];
  }

  const carryoutAddress = transformAddress(stores[0]);

  return favorites.map(({ name, products, ...favorite }) => {
    const deliveryAddress = transformAddress(favorite.delivery_address);
    const paymentDetails = transformPayment(favorite.form_of_payment);
    const transformedProducts = transformProducts(products);
    const occasion = transformOccasion(favorite.occasion_id);
    const occasionAddress = occasion === Occasion.DELIVERY
      ? getFormattedAddress(deliveryAddress)
      : getFormattedAddress(carryoutAddress);
    const storeNumber = favorite.store_number;

    return {
      id: favorite.favorite_id,
      occasionAddress,
      paymentDetails,
      occasion,
      name,
      localizationAddress: occasion === Occasion.DELIVERY ? deliveryAddress : carryoutAddress,
      products: transformedProducts,
      isPrimary: favorite.is_default,
      storeNumber
    };
  });
};
