import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { DisplayableModifier, Product, ProductModifier } from '@/domain/product/types';
import formattedPrice from '@/common/formattedPrice';
import telemetry from '@/telemetry';
import {
  createSingleItemAnalytics,
  onLocalizedProductTileAddToCartClickFromMenuTile,
  onLocalizedProductTileAddToCartClickFromProductInfoRail,
  onLocalizedProductTileAddToCartClickFromProductInfoRailInsideDeal,
  onLocalizedProductTileAddToCartSuccessFromMenuTile,
  onLocalizedProductTileAddToCartSuccessFromProductInfoRail,
  onLocalizedProductTileInfoButtonClick,
  onSelectSizeOrAdditionalOption,
  onSelectSizeOrAdditionalOptionFromProductInfoRail,
  onViewProductInfoSlideOut,
  ProductTileCommonEventsParam
} from '@/menu/categories/categories.analytics';
import { ItemAnalytics } from '@/dataAnalytics/analyticsTypes';
import { RootState } from '@/rootStateTypes';
import logger from '@/common/logger';
import { AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';
import useAddToCart from '@/cart/hooks/useAddToCart';
import { FormChangeEvent } from '@/common/eventTypes';
import {
  DisplayableProductHookItem,
  UseDisplayableProduct,
  UseDisplayableProductProps
} from '@/menu/categories/useDisplayableProduct/types';
import { MELT, MODIFIER, SIZE } from '@/domain/constants';
import useButtonState from '@/hooks/useButtonState';
import { UPSELL } from '@/deals/constants';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { oneClickProductOOSBody } from '@/builders/pizza/hooks/useOutOfStockModal/constants';
import { closeModal, openModal } from '@/localization/actions';

const findFirstAvailableModifier = (
  arr?: DisplayableModifier[],
  preSelectedOption?: DisplayableModifier
): DisplayableModifier | undefined => {
  if (!arr) return undefined;
  /**
   *  for a menu product sizes dropdown, we are returning the first available option that is not out of stock.
   *  as per WEB-1445 Order needs to be hardcoded as a default option, Hence sorting the array to keep Order at the top
   */

  const SINGLE_ORDER_ID = '3tSWkDGufhHogBYWlIlQqg';
  const sortedArr = [...arr]
    // eslint-disable-next-line no-nested-ternary
    .sort((a, b) => (a.id.includes(SINGLE_ORDER_ID) ? -1 : b.id.includes(SINGLE_ORDER_ID) ? 1 : 0));

  let option;
  if (preSelectedOption) {
    option = sortedArr?.find(
      (modifier: DisplayableModifier) => modifier.id === preSelectedOption.id && !modifier.isOutOfStock
    );
  }

  if (option) return option;
  return sortedArr?.find((modifier: DisplayableModifier) => !modifier.isOutOfStock);
};

const useDisplayableProduct = (props: UseDisplayableProductProps): UseDisplayableProduct => {
  const {
    sizes,
    additionalOptions,
    categoryName,
    showErrorModal,
    productIndex,
    id,
    name,
    description,
    isOutOfStock,
    subcategory,
    sodiumWarning,
    handleAddButtonClick,
    preSelectedAdditionalOption,
    preSelectedQuantity,
    preSelectedSize,
    telemetryEventName,
    handleAnalyticsForAddToCartClick,
    handleAnalyticsForAddToCartClickFromRail,
    handleAnalyticsForAddToCartSuccessFromRail,
    handleAnalyticsForModifierSelected,
    isSingleStepDeal,
    userAction = '',
    oneClickDefaults = undefined,
    type = ''
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [showInfoRail, setShowInfoRail] = useState(false);
  const [quantity, setQuantity] = useState(preSelectedQuantity || 1);
  const [selectedSize, selectSize] = useState<DisplayableModifier | undefined>(
    findFirstAvailableModifier(sizes, preSelectedSize)
  );
  const dispatch = useDispatch();
  const [{ enabled: oosMeltsToppingsEnabled }] = useDecision('fr-web-4008-oos-melts-toppings');

  const selectOptions = (size?: DisplayableModifier) => (
    selectedSize && Object.values(size ?? selectedSize).length && additionalOptions && Object.values(additionalOptions).length
      ? additionalOptions[(size ?? selectedSize).name] ?? []
      : []
  );
  const [optionsBySize, setOptionsBySize] = useState<DisplayableModifier[]>(
    selectedSize && Object.values(selectedSize).length && additionalOptions && Object.values(additionalOptions).length
      ? additionalOptions[selectedSize.name] ?? []
      : []
  );
  const [selectedAdditionalOption, selectAdditionalOption] = useState<DisplayableModifier | undefined>(
    findFirstAvailableModifier(optionsBySize, preSelectedAdditionalOption)
  );

  const { addToCart } = useAddToCart();
  const analytics = useAnalytics();
  const { dealId, dealName } = useSelector((state: RootState) => ({
    dealId: state.domain.deal.data.id,
    dealName: state.domain.deal.data.name
  }));
  const isDeal = useSelector((state: RootState) => !!state.domain.deal.data.id);

  const hasMultipleSizes = useMemo(() => (sizes ? sizes.length > 1 : false), [sizes]);
  const hasAdditionalOptions = useMemo(() => optionsBySize.length > 0, [optionsBySize]);
  const hasNutrition = useMemo(
    () => !!(selectedSize?.nutrition?.length || selectedAdditionalOption?.nutrition?.length),
    [selectedSize, selectedAdditionalOption]
  );
  const buttonState = useButtonState(isLoading);

  const isProductOutOfStock = isOutOfStock
    || Boolean(sizes?.length && !selectedSize)
    || Boolean(optionsBySize?.length && !selectedAdditionalOption);

  const basePrice = (selectedSize?.price || sizes?.[0]?.price || 0);
  const unitPrice = basePrice + (selectedAdditionalOption?.price ? selectedAdditionalOption?.price : 0);
  const unitPriceNumber = unitPrice / 100;

  const formattedUnitPrice = formattedPrice(unitPrice);
  const lowerCaseCategory = categoryName.toLowerCase();
  const telemetryEvent = telemetryEventName ?? `localized-menu-${lowerCaseCategory}`;

  const itemAnalytics = useMemo(() => {
    const categoryForItem = isDeal ? 'Deals' : categoryName;
    const displayableProductHookItem: DisplayableProductHookItem = {
      id,
      name,
      price: unitPriceNumber,
      isOutOfStock,
      selectedSize,
      selectedAdditionalOption,
      ...(subcategory ? { subcategory } : {})
    };

    return createSingleItemAnalytics(categoryForItem, displayableProductHookItem, productIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSize, selectedAdditionalOption]);

  const updateQuantity = (event: FormChangeEvent) => {
    setQuantity(parseInt(event.target.value as string, 10));
    telemetry.addCustomEvent(`${telemetryEvent}-quantity-update`, {
      productId: id
    });
  };

  const resetProductOption = () => {
    setQuantity(1);
    const size = findFirstAvailableModifier(sizes, preSelectedSize);
    selectSize(size);
    const defaultOptionsBySize = selectOptions(size);
    setOptionsBySize(defaultOptionsBySize);
    selectAdditionalOption(findFirstAvailableModifier(defaultOptionsBySize, preSelectedAdditionalOption));
  };

  const handleInfoButtonClick = () => {
    setShowInfoRail(true);
    telemetry.addCustomEvent(`${telemetryEvent}-info-icon-click`, {
      productId: id
    });
    if (categoryName === UPSELL) {
      const itemAnalyticsWithSizesAndModifiers = {
        ...itemAnalytics,
        item_price: unitPrice / 100,
        item_modifier: selectedAdditionalOption?.name ?? undefined,
        item_size: selectedSize?.name ?? undefined
      };

      analytics.push(() => onLocalizedProductTileInfoButtonClick(
        categoryName,
        itemAnalytics.item_name,
        dealId,
        dealName,
        itemAnalyticsWithSizesAndModifiers,
        quantity
      ));
    }
    if (categoryName !== UPSELL) {
      analytics.push(() => onLocalizedProductTileInfoButtonClick(categoryName, itemAnalytics.item_name, dealId, dealName));
    }
  };

  const handleAnalyticsForInfoRailSlideOut = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { item_size, item_modifier, ...rest } = itemAnalytics;
    if (!dealId && !dealName) {
      analytics.push(() => onViewProductInfoSlideOut(categoryName, rest, dealId, dealName));
    }
  };

  const handleAnalyticsForAddToCartClickFromMenuTile = (item: ItemAnalytics) => {
    if (handleAnalyticsForAddToCartClick) {
      handleAnalyticsForAddToCartClick(item, quantity);
    } else if (!dealId && !dealName) {
      analytics.push(() => onLocalizedProductTileAddToCartClickFromMenuTile(categoryName, item, quantity, userAction));
    }
  };

  const handleAnalyticsForAddToCartClickFromProductInfoRail = (item: ItemAnalytics) => {
    if (handleAnalyticsForAddToCartClickFromRail) {
      handleAnalyticsForAddToCartClickFromRail(item, quantity);
    } else {
      if (dealId && dealName) {
        analytics.push(() => onLocalizedProductTileAddToCartClickFromProductInfoRailInsideDeal(
          item,
          quantity,
          userAction,
          dealId,
          dealName
        ));
      }
      analytics.push(() => onLocalizedProductTileAddToCartClickFromProductInfoRail(categoryName, item, quantity, userAction));
    }
  };

  const handleAnalyticsForAddToCartSuccessFromMenuTile = (item: ItemAnalytics) => {
    analytics.push(() => onLocalizedProductTileAddToCartSuccessFromMenuTile(categoryName, item, quantity, userAction, dealId, dealName));
  };

  const handleAnalyticsForAddToCartSuccessFromProductInfoRail = (item: ItemAnalytics) => {
    if (handleAnalyticsForAddToCartSuccessFromRail) {
      handleAnalyticsForAddToCartSuccessFromRail(item, quantity);
    } else {
      analytics.push(() => onLocalizedProductTileAddToCartSuccessFromProductInfoRail(
        categoryName,
        item,
        quantity,
        userAction,
        dealId,
        dealName
      ));
    }
  };

  const handleAnalyticsForSizeOrAdditionalOptionSelected = (
    { price, size, modifier }: Partial<ProductTileCommonEventsParam>,
    selectedModifierType: typeof SIZE | typeof MODIFIER
  ) => {
    const selectedModifier = selectedModifierType === SIZE ? size : modifier;
    if (handleAnalyticsForModifierSelected) {
      handleAnalyticsForModifierSelected(itemAnalytics, selectedModifier ?? '');
    } else {
      analytics.push(() => onSelectSizeOrAdditionalOption({
        category: categoryName,
        item: itemAnalytics,
        price: (price ?? 0) / 100,
        quantity,
        size: size ?? selectedSize?.name ?? '',
        modifier: modifier || '',
        dealId,
        dealName
      }));
    }
  };

  const handleAnalyticsForSizeOrAdditionalOptionSelectedFromProductInfoRail = ({
    price,
    size,
    modifier
  }: Partial<ProductTileCommonEventsParam>) => {
    analytics.push(() => onSelectSizeOrAdditionalOptionFromProductInfoRail({
      category: categoryName,
      item: itemAnalytics,
      price: (price ?? 0) / 100,
      quantity,
      size: size ?? selectedSize?.name ?? '',
      modifier: modifier || '',
      dealId,
      dealName
    }));
  };

  const updateSize = (event: FormChangeEvent, fromInfoRail: boolean) => {
    const currentSize = sizes?.find((size) => size.id === event.target.value) ?? ({} as DisplayableModifier);
    selectSize(currentSize);

    const newOptions = additionalOptions?.[currentSize?.name] || [];
    const currentAdditionalIndex = selectedAdditionalOption ? optionsBySize.indexOf(selectedAdditionalOption) : -1;
    setOptionsBySize(newOptions);
    selectAdditionalOption(newOptions[currentAdditionalIndex]);

    telemetry.addCustomEvent(`${telemetryEvent}-size-update`, {
      productId: id
    });
    const analyticsData = {
      price: (currentSize.price ?? 0) + (selectedAdditionalOption?.price || 0),
      size: currentSize.name,
      modifier: selectedAdditionalOption?.name
    };
    if (fromInfoRail && categoryName !== UPSELL) {
      handleAnalyticsForSizeOrAdditionalOptionSelectedFromProductInfoRail(analyticsData);
    } else {
      handleAnalyticsForSizeOrAdditionalOptionSelected(analyticsData, SIZE);
    }
  };

  const updateAdditionalOption = (event: FormChangeEvent, fromInfoRail: boolean) => {
    const currentAdditionalOption = optionsBySize?.find((additionalOption) => additionalOption.id === event.target.value)
      ?? ({} as DisplayableModifier);
    selectAdditionalOption(currentAdditionalOption);

    telemetry.addCustomEvent(`${telemetryEvent}-additional-option-update`, {
      productId: id
    });
    const analyticsData = {
      price: (currentAdditionalOption.price ?? 0) + basePrice,
      modifier: currentAdditionalOption.name
    };
    if (fromInfoRail && categoryName !== UPSELL) {
      handleAnalyticsForSizeOrAdditionalOptionSelectedFromProductInfoRail(analyticsData);
    } else {
      handleAnalyticsForSizeOrAdditionalOptionSelected(analyticsData, MODIFIER);
    }
  };

  const getSelectedProduct = (): Product => {
    const hasSelectedOption = selectedAdditionalOption && selectedAdditionalOption.type !== 'DEFAULT';
    const selectedModifiersAddition = oneClickDefaults ?? [
      {
        id: selectedSize?.id ?? '',
        name: selectedSize?.name ?? '',
        type: selectedSize?.type ?? '',
        sodiumWarning: selectedSize?.sodiumWarning,
        variantCode: selectedSize?.variantCode
      }
    ];

    const cartProduct: Product = {
      kind: AddableCartItemKinds.PRODUCT,
      type,
      id,
      name,
      description,
      quantity,
      sodiumWarning,
      selectedModifiers: selectedModifiersAddition
    };

    if (hasSelectedOption) {
      cartProduct.selectedModifiers.push({
        id: selectedAdditionalOption?.id ?? '',
        name: selectedAdditionalOption?.name ?? '',
        type: selectedAdditionalOption?.type ?? '',
        sodiumWarning: selectedAdditionalOption?.sodiumWarning,
        placement: selectedAdditionalOption?.placement,
        portion: selectedAdditionalOption?.portion,
        variantCode: selectedAdditionalOption?.variantCode,
        slotCode: selectedAdditionalOption?.slotCode,
        weightCode: selectedAdditionalOption?.weightCode
      });
    }

    return cartProduct;
  };

  const continueToAddToCart = async (product: Product, fromProductInfoRail: boolean) => {
    // makesure matching shape
    const itemAnalyticsWithSizesAndModifiers = {
      ...itemAnalytics,
      item_price: unitPrice / 100,
      item_modifier: selectedAdditionalOption?.name ?? undefined,
      item_size: selectedSize?.name ?? undefined
    };

    const sendAddToCartAnalytics = () => {
      if (!handleAddButtonClick || isSingleStepDeal) {
        if (fromProductInfoRail) {
          handleAnalyticsForAddToCartClickFromProductInfoRail(itemAnalyticsWithSizesAndModifiers);
        } else {
          handleAnalyticsForAddToCartClickFromMenuTile(itemAnalyticsWithSizesAndModifiers);
        }
      }
    };

    if (fromProductInfoRail) {
      telemetry.addCustomEvent(`${telemetryEvent}-info-rail-add-button-click`, {
        productId: id
      });
      sendAddToCartAnalytics();
    } else {
      telemetry.addCustomEvent(`${telemetryEvent}-tile-add-button-click`, {
        productId: id
      });
      sendAddToCartAnalytics();
    }

    const onAddedToCart = () => {
      if (fromProductInfoRail) {
        handleAnalyticsForAddToCartSuccessFromProductInfoRail(itemAnalyticsWithSizesAndModifiers);
      } else {
        handleAnalyticsForAddToCartSuccessFromMenuTile(itemAnalyticsWithSizesAndModifiers);
      }
      setIsLoading(false);
      resetProductOption();
    };

    const onFailedRequest = () => {
      showErrorModal();
      setIsLoading(false);
      resetProductOption();
    };

    try {
      await (handleAddButtonClick
        ? handleAddButtonClick(product, onAddedToCart, onFailedRequest, fromProductInfoRail)
        : addToCart(product, onAddedToCart, onFailedRequest, itemAnalytics, undefined));
    } catch (error) {
      logger.withoutTelemetry.error((error as Error).message);
    }

    if (showInfoRail) setShowInfoRail(false);
  };

  const checkOOSForSelectedOptions = (selectedProduct: Product) => selectedProduct?.selectedModifiers?.filter((option) => option.outOfStock);

  const getOOSModalDetails = (oosSelectedOptions:ProductModifier[], selectedProduct: Product, fromProductInfoRail: boolean): ModalContent => ({
    title: 'Topping so good, they\'re gone',
    body: oneClickProductOOSBody.replaceAll('[ingredients]', oosSelectedOptions.map((option) => option.name).join(', ')),
    altCta: {
      text: 'Cancel',
      reverseButtonsOrder: true,
      callback: () => {
        setIsLoading(false);
        dispatch(closeModal());
      }
    },
    cta: {
      text: 'Add to cart',
      reverseButtonsOrder: true,
      callback: () => {
        continueToAddToCart(
          {
            ...selectedProduct,
            selectedModifiers: selectedProduct.selectedModifiers.filter((option) => !option.outOfStock)
          },
          fromProductInfoRail
        );
        dispatch(closeModal());
      }
    },
    hideCloseIcon: false
  });

  const handleAddToCart = async (eventProps: React.MouseEvent<Element>, fromProductInfoRail: boolean) => {
    setIsLoading(true);
    const product = getSelectedProduct();
    const oosSelectedModifiers = checkOOSForSelectedOptions(product);

    if (oosMeltsToppingsEnabled && oosSelectedModifiers.length > 0) {
      const modalDetails = getOOSModalDetails(oosSelectedModifiers, product, fromProductInfoRail);
      dispatch(openModal(modalDetails));
      return;
    }
    if (oosMeltsToppingsEnabled) {
      continueToAddToCart(product, fromProductInfoRail);
      return;
    }

    // Rest of the code with in this function can be cleaned up,
    // while retiring flag 'fr-web-4008-oos-melts-toppings'.
    // All of this code is in function continueToAddToCart.

    // makesure matching shape
    const itemAnalyticsWithSizesAndModifiers = {
      ...itemAnalytics,
      item_price: unitPrice / 100,
      item_modifier: selectedAdditionalOption?.name ?? undefined,
      item_size: selectedSize?.name ?? undefined
    };

    const sendAddToCartAnalytics = () => {
      if (!handleAddButtonClick || isSingleStepDeal) {
        if (fromProductInfoRail) {
          handleAnalyticsForAddToCartClickFromProductInfoRail(itemAnalyticsWithSizesAndModifiers);
        } else {
          handleAnalyticsForAddToCartClickFromMenuTile(itemAnalyticsWithSizesAndModifiers);
        }
      }
    };

    if (fromProductInfoRail) {
      telemetry.addCustomEvent(`${telemetryEvent}-info-rail-add-button-click`, {
        productId: id
      });
      sendAddToCartAnalytics();
    } else {
      telemetry.addCustomEvent(`${telemetryEvent}-tile-add-button-click`, {
        productId: id
      });
      sendAddToCartAnalytics();
    }

    const onAddedToCart = () => {
      if (fromProductInfoRail) {
        handleAnalyticsForAddToCartSuccessFromProductInfoRail(itemAnalyticsWithSizesAndModifiers);
      } else {
        handleAnalyticsForAddToCartSuccessFromMenuTile(itemAnalyticsWithSizesAndModifiers);
      }
      setIsLoading(false);
      resetProductOption();
    };

    const onFailedRequest = () => {
      showErrorModal();
      setIsLoading(false);
      resetProductOption();
    };

    try {
      await (handleAddButtonClick
        ? handleAddButtonClick(product, onAddedToCart, onFailedRequest, fromProductInfoRail)
        : addToCart(product, onAddedToCart, onFailedRequest, itemAnalytics, undefined));
    } catch (error) {
      logger.withoutTelemetry.error((error as Error).message);
    }

    if (showInfoRail) setShowInfoRail(false);
  };

  return {
    values: {
      quantity,
      selectedSize,
      optionsBySize,
      isProductOutOfStock,
      selectedAdditionalOption,
      formattedUnitPrice,
      unitPriceNumber,
      hasMultipleSizes,
      hasAdditionalOptions,
      hasNutrition,
      showInfoRail,
      buttonState,
      lowerCaseCategory
    },
    setters: {
      updateQuantity,
      updateAdditionalOption,
      updateSize,
      setShowInfoRail
    },
    handlers: {
      handleAddToCart,
      handleInfoButtonClick,
      handleAnalyticsForInfoRailSlideOut
    }
  };
};

export default useDisplayableProduct;
