import { Product, useGetUpsellQuery } from '@pizza-hut-us-development/client-core';
import { useSelector } from 'react-redux';

import { localizationSelectors } from '@/localization/localizationSelectors';
import { RootState } from '@/rootStateTypes';
import { transformGetUpsellData } from './transformGetUpsellData';
import { UpsellProducts } from '@/graphql/queries/upsell/upsell';
import { OccasionApi } from '@/localization/constants';
import { selectOccasion } from '@/localization/selectors';
import { useRefetchOnYumEcommChanged } from '@/clientCore/helper/useRefetchOnYumEcommChanged';
import { debugModeSelectors } from '@/clientCore/redux/selectors/debugSelectors';
import { transformationDebugLogging } from '@/clientCore/debuggerLogging';
import { DebugModeOptions } from '@/clientCore/redux/debug/types';

declare type GetUpsellQueryResponse = {
  data: UpsellProducts[] | Product[] | null | undefined;
  isLoading: boolean | null | undefined;
  isError: boolean | null | undefined;
};

declare type Props = { skip?: boolean; skipTransform?: boolean };

declare type UseCCGetUpsellQuery = (arg0: Props) => GetUpsellQueryResponse;

export const useCCGetUpsellQuery: UseCCGetUpsellQuery = ({ skip, skipTransform }) => {
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const storeOccasion = useSelector(selectOccasion) as keyof typeof OccasionApi;
  const environmentId = useSelector(
    (state: RootState) => state.domain.cmsEnv.id
  );
  const occasion = OccasionApi[storeOccasion] as OccasionApi;
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );
  const debugEnabled = useSelector(debugModeSelectors.enabled);
  const debugMode = useSelector(debugModeSelectors.mode);

  const {
    data, isLoading, isFetching, isError, refetch, isUninitialized
  } = useGetUpsellQuery(
    {
      storeNumber: storeDetails?.storeNumber ?? '',
      environmentId
    },
    {
      skip: skip || !storeDetails?.storeNumber,
      refetchOnMountOrArgChange: true // TODO: Remove when authenticated users are supported when isYumEcomm = true
    }
  );
  // TODO: Remove when authenticated users are supported when isYumEcomm = true
  useRefetchOnYumEcommChanged({ refetch, isUninitialized });

  const isQueryLoading = isLoading || isFetching;

  if (!storeDetails) {
    return {
      data: undefined,
      isLoading: true,
      isError: null
    };
  }

  if (skip || !storeDetails.storeNumber) {
    return {
      data: undefined,
      isLoading: false,
      isError: null
    };
  }

  let upsellData: UpsellProducts[] | Product[] | null = null;

  if (data) {
    upsellData = skipTransform ? data : transformGetUpsellData(data, occasion, storeDetails.storeTimezone, isYumEcomm);
  }

  if (debugEnabled) {
    if (upsellData) {
      transformationDebugLogging('useCCGetUpsellQuery', 'useGetUpsellQuery', data, upsellData, debugMode, [
        DebugModeOptions.MENU,
        DebugModeOptions.TRANSFORMATIONS
      ]);
    }
  }

  return {
    data: upsellData,
    isLoading: isQueryLoading,
    isError
  };
};
