import { OccasionString } from '../../localization/constants';

// store open or accepts future orders
const confirmLocation = (
  occasion: OccasionChoice,
  storeDetails: StoreDetail,
  altCtaCallback: () => void,
  ctaCallback: () => void
): ModalContent => {
  const {
    address, city, state, zipcode, storeStatus, acceptFutureOrders
  } = storeDetails;

  const toFromOccasionChange = OccasionString[occasion] === 'Carryout' ? 'from' : 'to';
  const currentStoreClosedAcceptsFutureOrders = storeStatus !== 'DC' && acceptFutureOrders ? 'Pre-Order' : 'Continue';

  return {
    title: 'Please confirm',
    body: `This order is for ${OccasionString[occasion]} ${toFromOccasionChange}:
     ${address}
     ${city}, ${state} ${zipcode}`,
    altCta: {
      text: 'Change',
      callback: altCtaCallback,
      reverseButtonsOrder: true
    },
    cta: {
      text: currentStoreClosedAcceptsFutureOrders,
      callback: ctaCallback,
      reverseButtonsOrder: true
    },
    hideCloseIcon: true
  };
};

const confirmLocationStoreCloseNoFutureOrders = (
  ctaCallback: () => void
): ModalContent => ({
  title: 'This restaurant is currently closed',
  body: 'But you can order from another location',
  cta: {
    text: 'Change Restaurant',
    callback: ctaCallback
  },
  hideCloseIcon: true
});

export { confirmLocation, confirmLocationStoreCloseNoFutureOrders };
