export const SET_LAST_ACTIVE_TIMESTAMP = 'SET_LAST_ACTIVE_TIMESTAMP';
export const AUTH_TOKEN_CHANGED = 'AUTH_TOKEN_CHANGED';
export const AUTH_TOKEN_REFRESH_FAILURE = 'AUTH_TOKEN_REFRESH_FAILURE';
export const AUTH_TOKEN_EXPIRED = 'AUTH_TOKEN_EXPIRED';
export const REFRESH_AUTH_AFTER_SESSION_EXPIRY = 'REFRESH_AUTH_AFTER_SESSION_EXPIRY';
export const AUTH_REFRESH_REQUEST_RECEIVED = 'AUTH_REFRESH_REQUEST_RECEIVED';

export const refreshAuthTokenAfterSessionExpiry = () => ({
  type: REFRESH_AUTH_AFTER_SESSION_EXPIRY
});

export const authRefreshRequestReceived = () => ({
  type: AUTH_REFRESH_REQUEST_RECEIVED
});

export const authTokenChanged = (authToken: string) => ({
  type: AUTH_TOKEN_CHANGED,
  token: authToken
});

export const authTokenRefreshFailure = () => ({
  type: AUTH_TOKEN_REFRESH_FAILURE
});

export const authTokenExpired = () => ({
  type: AUTH_TOKEN_EXPIRED
});

export const setLastActiveTimestamp = (timestamp: number) => ({
  type: SET_LAST_ACTIVE_TIMESTAMP,
  timestamp
});
