import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AUTH_TOKEN_UPDATED_EVENT } from '@/auth/authTokenHelpers';
import { RootState } from '@/rootStateTypes';

import { useCreateCart } from '@/clientCore/cart/hooks/useCreateCart';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';

export const useCartRefresh = () => {
  const [createCart] = useCreateCart();

  const currentCart = useSelector(orderSelectors.cart);
  const isYumEcomm = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  // TODO: Remove when authenticated users are supported when isYumEcomm = true
  // Watches for changes to the cart and isYumEcomm flag and recreates the cart if there
  // is ever a mismatch. This only occurs currently when toggling between a guest
  // and authenticated user session when a store has isYumEcomm = true for
  // only guest users
  useEffect(() => {
    if (!currentCart?.type) {
      return;
    }

    if ((isYumEcomm && currentCart.type !== 'YUM') || (!isYumEcomm && currentCart.type !== 'PHD')) {
      createCart();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCart?.type, isYumEcomm]);

  // handles recreating a cart when auth tokens change after
  // logging in/out or after being refreshed
  useEffect(() => {
    const authTokenChangedEventHandler = () => {
      if (!currentCart) {
        return;
      }
      createCart();
    };

    window.addEventListener(AUTH_TOKEN_UPDATED_EVENT, authTokenChangedEventHandler);
    return () => {
      window.removeEventListener(AUTH_TOKEN_UPDATED_EVENT, authTokenChangedEventHandler);
    };
  }, [createCart, currentCart]);
};
