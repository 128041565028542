import { ProfileAddress } from '@/domain/Profile';
import { AddCustomerAddressPayload } from '@/services/accountManagementService/phdApiAccountManagementService/types';

const editCustomerAddress = typeof (jest) === 'undefined' ? new Promise(() => {}) : jest.fn();

const successfulEditCustomerAddress = (
  address: AddCustomerAddressPayload
): ClientResult<AddCustomerAddressPayload> => {
  const mockEditCustomerAddress = editCustomerAddress as jest.Mock;
  const value = {
    error: false,
    result: address
  };
  mockEditCustomerAddress.mockResolvedValue(value);
  return value;
};

const failedEditCustomerAddress = (): ClientResult<ProfileAddress> => {
  const mockEditCustomerAddress = editCustomerAddress as jest.Mock;
  const value = {
    error: true
  };
  mockEditCustomerAddress.mockResolvedValue(value);
  return value;
};

export default editCustomerAddress;

export {
  successfulEditCustomerAddress,
  failedEditCustomerAddress
};
