import React from 'react';

export const ExternalLinkIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 12 12">
    <g fill="none" fillRule="evenodd">
      <g fill="#131313">
        <g>
          <path d="M10.291 5.47c-.28 0-.509.228-.509.508v4.665c0 .184-.155.34-.34.34H1.358c-.184 0-.34-.156-.34-.34V2.557c0-.184.156-.34.34-.34H6.08c.28 0 .508-.228.508-.508S6.358 1.2 6.08 1.2H1.357C.61 1.2 0 1.809 0 2.557v8.086C0 11.39.609 12 1.357 12h8.086c.748 0 1.357-.609 1.357-1.357V5.978c0-.28-.229-.509-.509-.509" transform="translate(-176 -329) translate(176 329)" />
          <path d="M11.404 0h-3.62c-.286 0-.52.234-.52.52 0 .286.234.52.52.52h2.441L6.152 5.113c-.203.202-.203.533 0 .735.202.203.533.203.735 0l4.073-4.073V4.39c0 .286.234.52.52.52.286 0 .52-.234.52-.52V.596C12 .267 11.733 0 11.404 0" transform="translate(-176 -329) translate(176 329)" />
        </g>
      </g>
    </g>
  </svg>
);
