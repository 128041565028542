import telemetry from '../../../../telemetry';
import { OrderHistoryType } from '@/account/orders/orderHistory/OrderHistory.types';
import { getUrl } from '../../helpers/getUrl';
import { getWithHeaders } from '../../helpers/getWithHeaders';
import AccountManagementEndpoints from '../endpoints';
import { transformOrdersData } from './transformOrdersData';
import logger from '../../../../common/logger';
import phdApiClientMock from '@/api/phdApiClient/__mocks__';

export const getOrders = (): Promise<ClientResult<OrderHistoryType>> => {
  const endpoint = AccountManagementEndpoints.GET_ORDERS_HISTORY;

  return getWithHeaders(
    getUrl(endpoint),
    {} as Headers,
    { credentials: 'include' },
    phdApiClientMock.getOrders
  )
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.code) throw new Error(responseJson.message);
      return responseJson;
    })
    .then((responseJson) => ({
      error: false,
      result: transformOrdersData(responseJson)
    }))
    .catch((error) => {
      telemetry.addNoticeError(error, { endpoint });
      logger.withoutTelemetry.error('Could not fetch orders', error);
      return { error: true };
    });
};
