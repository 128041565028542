import { AddableCartItem } from '@/api/phdApiV2Client/request.types';
import {
  BuiltPizza,
  IngredientOptionWithPortions,
  PortionChoice
} from '@/builders/pizza/dataTransformers/builderTypes';
import { PizzaBuilderOptions } from '@/graphql/dynamicQueries/pizza';

// Used For populating the sauce picker with no sauce for a yum Pizza
export const sauceHardcodedConstant = (priority: number | undefined): IngredientOptionWithPortions => ({
  id: 'no-sauce',
  name: 'No sauce',
  required: false,
  type: 'MODIFIER',
  selected: false,
  priority: priority ?? 0,
  glutenFree: false,
  outOfStock: false,
  maxAllowed: 1,
  splittable: false,
  sodiumWarning: false,
  isPanCrust: false,
  image: 'https://www.pizzahut.com/assets/w/evo_pb/sidebar/icon_sauce_none.png',
  price: undefined,
  portions: [
    {
      price: undefined,
      id: 'no-sauce/portion',
      name: 'No sauce',
      required: false,
      type: 'MODIFIER',
      selected: false,
      priority,
      glutenFree: false,
      outOfStock: false,
      maxAllowed: 1,
      splittable: false,
      sodiumWarning: false,
      isPanCrust: false,
      nutrition: [],
      portion: 'none' as PortionChoice,
      image: 'https://www.pizzahut.com/assets/w/evo_pb/sidebar/icon_sauce_none.png'
    }
  ],
  nutrition: null
});

const nullifySauce = (pizzaItem: BuiltPizza): BuiltPizza => ({ ...pizzaItem, sauce: null });
//   Used for transforming the no sauce option into null for an AddableCartItem the output will be used to add to cart.
const translateNoSauceOptionToNullForPizza = (item: AddableCartItem): AddableCartItem => {
  const itemHasSauce = 'sauce' in item && Boolean(item?.sauce);
  if (!itemHasSauce) return item;
  const hardCodedNoSauce = item?.sauce?.id === 'no-sauce/portion';
  if (!hardCodedNoSauce) return item;

  const pizzaNullSauce = nullifySauce(item);

  return pizzaNullSauce;
};

const translateNoSauceOptionForDealPizzas = (deal: AddableCartItem): AddableCartItem => {
  if (!('userSelections' in deal)) return deal;
  // destructure to circumvent read only
  const { userSelections } = deal;
  const { present } = userSelections;
  const { recipes } = present;
  const dealRecipes = recipes.map((recipe) => {
    if (recipe.item.type === 'PIZZA' && 'sauce' in recipe.item) {
      const hardCodedNoSauce = recipe?.item?.sauce?.id === 'no-sauce/portion';
      if (!hardCodedNoSauce) return recipe;

      return { ...recipe, item: nullifySauce({ ...recipe.item }) };
    }
    return recipe;
  });

  return { ...deal, userSelections: { ...userSelections, present: { ...present, recipes: dealRecipes } } };
};

export const translateNoSauceFromDealOrPizza = (item: AddableCartItem): AddableCartItem => {
  const transformDecision = 'userSelections' in item ? 'DEAL' : item.type;
  switch (transformDecision) {
    case 'PIZZA':
      return translateNoSauceOptionToNullForPizza(item);
    case 'DEAL':
      return translateNoSauceOptionForDealPizzas(item);
    default:
      return item;
  }
};

const hasNoSauce = (pizzaSauces?: IngredientOptionWithPortions[]) => Boolean(pizzaSauces?.find((sauce) => sauce?.name?.toLowerCase() === 'no sauce'));

// add No sauce to yum pizzabuilder
export const addNoSauce = (transformedPizza?: PizzaBuilderOptions): PizzaBuilderOptions | undefined => {
  if (!transformedPizza) return undefined;

  if (!hasNoSauce(transformedPizza.sauces)) {
    const newTransformedPizza = { ...transformedPizza };
    newTransformedPizza?.sauces?.push(sauceHardcodedConstant(transformedPizza?.sauces?.length));
  }
  return transformedPizza;
};

export const dealAddNoSauce = (sauces: IngredientOptionWithPortions[]): IngredientOptionWithPortions[] => {
  if (!hasNoSauce(sauces)) {
    const newSauces = [...sauces, sauceHardcodedConstant(sauces.length)];
    return newSauces;
  }
  return sauces;
};
