import { CustomerLoyalty } from '@/domain/loyalty/CustomerLoyalty';
import { CustomerLoyaltyResponse } from '@/api/phdApiClient/accountManagement/myAccount/customerLoyalty';

const customerLoyaltyRes: CustomerLoyaltyResponse = {
  coupons: [
    {
      code: 'DEAL_CODE',
      coupon_association_key: 888
    }
  ]
};

const customerLoyaltyTransformed: CustomerLoyalty = {
  coupons: [
    {
      code: 'DEAL_CODE',
      rewardsRedemptionCode: 888
    }
  ]
};

export {
  customerLoyaltyTransformed,
  customerLoyaltyRes
};
