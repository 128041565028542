import { Action, combineReducers } from 'redux';
import {
  HEADER_QUERY_ERROR,
  HERO_QUERY_ERROR,
  SIDEKICK_QUERY_ERROR,
  FOOTER_QUERY_ERROR,
  PIZZA_MENU_QUERY_ERROR,
  PIZZA_MENU_QUERY_DATA_ERROR,
  PIZZA_BUILDER_QUERY_ERROR,
  CLEAR_PIZZA_ERROR,
  GET_PRODUCTS_QUERY_ERROR,
  RESET_GET_PRODUCTS_QUERY_ERROR,
  DEAL_QUERY_ERROR,
  RESET_ALL_GQL_ERRORS,
  GET_CATEGORIES_QUERY_ERROR,
  CLEAR_DEAL_ERROR,
  CLEAR_GET_CATEGORIES_QUERY_ERROR,
  DEAL_PRODUCTS_BY_ID_QUERY_ERROR
} from './actionTypes';

const headerError = (state = false, action: Action) => {
  switch (action.type) {
    case HEADER_QUERY_ERROR: {
      return true;
    }
    case RESET_ALL_GQL_ERRORS: {
      return false;
    }
    default:
      return state;
  }
};

const heroError = (state = false, action: Action) => {
  switch (action.type) {
    case HERO_QUERY_ERROR: {
      return true;
    }
    case RESET_ALL_GQL_ERRORS: {
      return false;
    }
    default:
      return state;
  }
};

const sidekickError = (state = false, action: Action) => {
  switch (action.type) {
    case SIDEKICK_QUERY_ERROR: {
      return true;
    }
    case RESET_ALL_GQL_ERRORS: {
      return false;
    }
    default:
      return state;
  }
};

const footerError = (state = false, action: Action) => {
  switch (action.type) {
    case FOOTER_QUERY_ERROR: {
      return true;
    }
    case RESET_ALL_GQL_ERRORS: {
      return false;
    }

    default:
      return state;
  }
};

const pizzaError = (state = false, action: Action) => {
  switch (action.type) {
    case PIZZA_MENU_QUERY_ERROR:
    case PIZZA_MENU_QUERY_DATA_ERROR:
    case PIZZA_BUILDER_QUERY_ERROR: {
      return true;
    }
    case CLEAR_PIZZA_ERROR:
    case RESET_ALL_GQL_ERRORS: {
      return false;
    }
    default:
      return state;
  }
};

const getProductsError = (state = false, action: Action) => {
  switch (action.type) {
    case RESET_GET_PRODUCTS_QUERY_ERROR:
    case RESET_ALL_GQL_ERRORS: {
      return false;
    }
    case GET_PRODUCTS_QUERY_ERROR: {
      return true;
    }
    default:
      return state;
  }
};

const categoriesError = (state = false, action: Action) => {
  switch (action.type) {
    case GET_CATEGORIES_QUERY_ERROR: {
      return true;
    }
    case CLEAR_GET_CATEGORIES_QUERY_ERROR:
    case RESET_ALL_GQL_ERRORS: {
      return false;
    }
    default:
      return state;
  }
};

const dealError = (state = false, action: Action) => {
  switch (action.type) {
    case DEAL_PRODUCTS_BY_ID_QUERY_ERROR:
    case DEAL_QUERY_ERROR: {
      return true;
    }
    case CLEAR_DEAL_ERROR:
    case RESET_ALL_GQL_ERRORS: {
      return false;
    }
    default:
      return state;
  }
};

const gqlErrors = combineReducers({
  headerError,
  heroError,
  sidekickError,
  footerError,
  pizzaError,
  dealError,
  getProductsError,
  categoriesError
});

export default gqlErrors;
