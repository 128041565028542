import {
  Categories, isDip, ItemType, Modifier, Nutrition, Portion, Product
} from '@pizza-hut-us-development/client-core';
import {
  DisplayableAdditionalOptions,
  DisplayableModifier,
  DisplayableNutritionInfo,
  ProductModifier
} from '@/domain/product/types';
import { AvailabilityItem } from '@/graphql/helpers/checkAvailability';
import { negate } from '@/utils';
import { MELT, SIZE } from '@/domain/constants';
import {
  APPETIZERTOPP, BAKED_WINGS_DIP_CUP_SLOT, FRIES, FRIES_SEASONING, WINGSTREET_PROD,
  WINGSTREET_SIZE
} from './constants';
import { extractVariantCodeFromModifiers } from '@/clientCore/helper/extractVariantCodeFromModifiers';
import isOptimizelyFeatureEnabled from '../../../../../optimizely/utils/isOptimizelyFeatureEnabled';
import { getGlobalId } from '@/cart/helpers/getGlobalId';
import { checkSodiumWarning } from '@/clientCore/helper/checkSodiumWarning';
import { getOrInitializeOptimizely } from '../../../../../optimizely/optimizely';

export const sizePredicate = (productOption: Modifier): boolean => {
  const productId = productOption.id?.toLowerCase() ?? '';
  const productName = productOption.name?.toLowerCase() ?? '';

  return (
    productId.includes(SIZE.toLowerCase())
    || productName.includes(SIZE.toLowerCase())
    || productOption.modifiers?.find((option) => option.type === 'SIZE') !== undefined
  );
};

// generic
export const getDisplayableNutritionInfo = (nutritionInfo: Nutrition[]) => {
  const nutritionShaper = (nutritionData: Nutrition): DisplayableNutritionInfo => ({
    unit: nutritionData.unit ?? '',
    calories: nutritionData.calories ?? 0,
    servings: nutritionData.servings ?? '',
    name: nutritionData.name ?? ''
  });
  return nutritionInfo.reduce((nutritionAcc: DisplayableNutritionInfo[], currentNutrition) => {
    const extraNutrition = currentNutrition?.additionalNutrition?.length
      ? currentNutrition?.additionalNutrition?.map((addlNutrition) => nutritionShaper(addlNutrition))
      : [];

    return [...nutritionAcc, nutritionShaper(currentNutrition), ...extraNutrition];
  }, []);
};

const findAndGetNutritionInfo = (modifiers: Modifier[]) => {
  const modifierWithNutrition = modifiers?.find((modifier: Modifier) => modifier?.nutrition?.length);
  return modifierWithNutrition?.nutrition ? getDisplayableNutritionInfo(modifierWithNutrition.nutrition) : [];
};

const getMeltsNutritionInfo = (melt: Product, size: Modifier) => {
  const sizeNutritionInfo = findAndGetNutritionInfo(size.modifiers ?? []);
  if (melt?.selectedOptions?.length === 0) {
    const nonYumMeltSelectedOptions = melt?.options?.reduce((acc: Modifier[], current: Modifier) => {
      const { modifiers } = current;
      const selections = modifiers ? modifiers.filter((mod) => mod.selected) : [];
      return [...acc, ...selections];
    }, []);
    const sizeGlobalId = getGlobalId(size?.id) ?? size.id;
    nonYumMeltSelectedOptions?.forEach((defaultOption) => {
      const selectedNutrition = defaultOption?.nutrition?.filter(
        (nutrition) => nutrition?.qualifiers?.includes(sizeGlobalId) && nutrition.portion === Portion.REGULAR
      );
      if (selectedNutrition && sizeNutritionInfo.length) {
        sizeNutritionInfo[0].calories += selectedNutrition?.[0].calories ?? 0;
      }
    });
  } else {
    melt?.selectedOptions?.forEach((option) => {
      if (sizeNutritionInfo?.[0]?.calories) {
        sizeNutritionInfo[0].calories += option?.nutrition?.find((nutri) => nutri.portion === option.portion)?.calories ?? 0;
      }
    });
  }
  return sizeNutritionInfo;
};

const getNutritionForSize = (product: Product, size: Modifier, categoryId?: Categories) => {
  // Wings and fries display nutrition info dynamically based on additional options.
  // So nutrition here can be empty
  if (categoryId === Categories.WINGS || product.name === FRIES || product.name === 'Regular Double Order of Fries') {
    return [];
  }
  if ((categoryId === Categories.MELTS || product.type === MELT)) {
    return getMeltsNutritionInfo(product, size);
  }
  return size.nutrition ? getDisplayableNutritionInfo(size.nutrition) : findAndGetNutritionInfo(size.modifiers ?? []);
};

export const getMatchedSizes = (product: Product, categoryId?: Categories): DisplayableModifier[] => {
  const isShowSodiumWarningEnabled = isOptimizelyFeatureEnabled('fr-web-3855-yum-sodium-warning-ui');
  const transformOutOfStockFixEnabled = isOptimizelyFeatureEnabled('fr-web-4006-fix_transform_out_of_stock');

  const sizesOption = product.options.find(sizePredicate);
  if (!sizesOption?.modifiers) return [];
  return sizesOption.modifiers.map((size) => {
    const variantCode = size.variantCode ?? extractVariantCodeFromModifiers(size.modifiers ?? []);
    return {
      id: size.id,
      name: size.name,
      priority: 0,
      isOutOfStock: transformOutOfStockFixEnabled ? size.outOfStock : product.outOfStock,
      sodiumWarning: isShowSodiumWarningEnabled ? checkSodiumWarning([size]) : product.sodiumWarning,
      type: size.type || '',
      price: size.price || (size.modifiers ?? [])[0]?.price || product.price,
      nutrition: getNutritionForSize(product, size, categoryId),
      variantCode,
      modifiers: []
    };
  });
};

export const getDefaultOption = (key: string | undefined): DisplayableModifier | undefined => (key
  ? {
    id: `default-${key}`,
    name: key,
    type: 'DEFAULT',
    price: 0,
    priority: undefined,
    modifiers: [],
    nutrition: []
  }
  : undefined);

type CustomSizeIds = {
  keyName: string;
  calories?: number;
  nutritionName?: string;
  id?: string;
  variantCode?: string;
};

// Formats new additional opts
const formatAdditionalOpt = (currentOpt: Modifier): DisplayableModifier => ({
  id: currentOpt.id,
  name: currentOpt.name,
  priority: currentOpt.displayOrder,
  isOutOfStock: currentOpt.outOfStock,
  sodiumWarning: currentOpt?.sodiumWarning ?? false,
  type: currentOpt.type ?? '',
  modifiers: [],
  nutrition: [
    currentOpt?.nutrition?.length
    && currentOpt?.nutrition[0]?.additionalNutrition?.length && {
      unit: currentOpt?.nutrition[0]?.additionalNutrition[0]?.unit || '',
      calories: currentOpt?.nutrition[0]?.additionalNutrition[0]?.calories || 0,
      servings: currentOpt?.nutrition[0]?.additionalNutrition[0]?.servings || 0,
      name: currentOpt?.nutrition[0]?.additionalNutrition[0]?.name || ''
    }
  ] as DisplayableNutritionInfo[],
  placement: currentOpt.placement,
  portion: currentOpt.portion,
  variantCode: currentOpt.variantCode,
  slotCode: currentOpt.slotCode,
  weightCode: currentOpt.weightCode ?? (currentOpt.weights ?? [])[0]
});

const computeOptionPriceFromUpcharge = (option: Modifier, product: AvailabilityItem<Product>): number | undefined => {
  const { selectedOptions } = product;
  const selectedOption = selectedOptions.length
    ? selectedOptions.find((selectedOptionMod) => selectedOptionMod?.optionTypeCode === 'pasta_size')
    : undefined;
  const upcharge = selectedOption?.upcharges?.length
    ? selectedOption.upcharges?.find((upchargeVariant) => upchargeVariant.slotCode === option?.slotCode)
    : undefined;
  const upchargeModifier = upcharge?.modifiers.length
    ? upcharge.modifiers.find((modifier) => modifier.modifierCode === option.id)
    : undefined;

  return upchargeModifier?.weights[0]?.price?.amount;
};

export const extractYumPastaAdditionalOption = (
  product: AvailabilityItem<Product>
): DisplayableAdditionalOptions | undefined => {
  // TODO: fix hardcode WEB-2725 pasta for breadsticks modifier.
  const optimizelyPastaUpchargeFix = isOptimizelyFeatureEnabled('fix-web-3589-pasta-cheesestick-upcharge-fix');
  const transformOutOfStockFixEnabled = isOptimizelyFeatureEnabled('fr-web-4006-fix_transform_out_of_stock');

  const sideItem = product.options.find((option) => option.id === 'slot_pasta_side_item');
  const defaultOption = product.defaultSelectedOption;
  const sideModIncludeBreadSticks = !(sideItem?.modifiers ?? []).find((mod) => mod.name === defaultOption);
  if (sideItem && sideModIncludeBreadSticks) {
    const sideItemModifiers: DisplayableModifier[] = [...(sideItem?.modifiers ?? [])]
      .map((sideItemMod) => ({
        ...sideItemMod,
        displayOrder: sideItemMod.displayOrder + 1
      }))
      .map((option) => {
        const modifier = {
          id: option.id,
          name: option.name,
          priority: option.displayOrder,
          placement: option.placement,
          portion: option.portion,
          type: option.type,
          // TODO: WEB-3589 janky transform logic to make this work.
          price: optimizelyPastaUpchargeFix ? computeOptionPriceFromUpcharge(option, product) : undefined,
          modifiers: option.modifiers,
          nutrition: option.nutrition,
          slotCode: option.slotCode,
          weightCode: option.weightCode,
          variantCode: option.variantCode
        } as DisplayableModifier;

        if (transformOutOfStockFixEnabled) {
          modifier.isOutOfStock = option.outOfStock;
        }

        return modifier;
      });
    // TODO: remove when non hardcoded solution is found.
    sideItemModifiers.unshift({
      id: 'default-breadsticks',
      price: 0,
      type: 'DEFAULT' as ItemType,
      name: defaultOption ?? 'Breadsticks',
      nutrition: [],
      modifiers: []
    });
    // will need to fix this 'single' with an actual value
    // TODO: fix hardcode WEB-2725 pasta for breadsticks modifier.
    return { Single: sideItemModifiers };
  }
  return undefined;
};

const shapeAdditionalOptions = (
  origOpts: Modifier[],
  otherOptKeys: string[],
  currentIdentifier: CustomSizeIds,
  productId: string
): DisplayableModifier[] => {
  const optimizely = getOrInitializeOptimizely();
  const additionalOptionsTransformFixEnabled = optimizely?.isFeatureEnabled('fr-web-3941-additional_options_transform_fix');

  // Appends Modifier[] to the ADDITIONAL_OPTION_OBJECT.
  const origOptsObject: { [key: string]: Modifier[] } = origOpts.reduce((acc, current) => {
    const newAcc = {
      [current.name]: current?.modifiers ?? [],
      ...acc
    };

    return newAcc;
  }, {});

  // Takes ADDITIONAL_OPTION_OBJECT and formats Modifier[] into DisplayableModifier[]
  return otherOptKeys.flatMap((optKey) => origOptsObject[optKey].reduce((optAcc: DisplayableModifier[], currentOpt) => {
    const currentNutrition = currentOpt.nutrition?.length ? currentOpt?.nutrition[0] : {};

    if (additionalOptionsTransformFixEnabled && !isDip(productId) && currentNutrition.qualifiers) {
      const qualifiers = new Set(currentNutrition.qualifiers);
      if (
        currentIdentifier.id && qualifiers.has(currentIdentifier.id)
        && currentIdentifier.variantCode && qualifiers.has(currentIdentifier.variantCode)
      ) {
        return [...optAcc, formatAdditionalOpt(currentOpt)];
      }
    }

    if (
      currentNutrition?.calories === currentIdentifier?.calories
      && currentNutrition?.name === currentIdentifier?.nutritionName
    ) {
      return [...optAcc, formatAdditionalOpt(currentOpt)];
    }

    return optAcc;
  }, []));
};

export const extractFromOptions = (product: Product): DisplayableAdditionalOptions => {
  const optimizely = getOrInitializeOptimizely();
  const doubleOrderFriesTransformEnabled = optimizely?.isFeatureEnabled('fr-web-3991-double_order_fries_deal_transform');
  const transformOutOfStockFixEnabled = optimizely?.isFeatureEnabled('fr-web-4006-fix_transform_out_of_stock');

  if (product.type === MELT || (isDip(product.id))) {
    return {};
  }

  const fryOptionResult: DisplayableAdditionalOptions = {
    'Order Wingstreet': [],
    Order: [],
    'Double Order': []
  };

  const generateNutrition = (modifier: Modifier) => (modifier.nutrition?.length
    ? [
      ...(modifier.nutrition.map((entry) => ({
        calories: entry.calories,
        servings: entry.servings,
        unit: entry.unit,
        name: entry.name
      })) as DisplayableNutritionInfo[])
    ]
    : []);

  const getDisplayableModifier = (mod: Modifier): DisplayableModifier => {
    const modifier: DisplayableModifier = {
      id: mod.id,
      name: mod.name,
      type: mod.type ?? '',
      nutrition: generateNutrition(mod),
      modifiers: [],
      placement: mod.placement,
      portion: mod.portion,
      variantCode: mod.variantCode,
      slotCode: mod.slotCode,
      weightCode: mod.weightCode
    };

    if (transformOutOfStockFixEnabled) {
      modifier.isOutOfStock = mod.outOfStock;
    }

    return modifier;
  };

  // temporary change for a different fry product name
  if (product.name === FRIES || product.name === 'Regular Double Order of Fries') {
    const wingstreetOption = product.options.find((option) => option.name === WINGSTREET_PROD);
    wingstreetOption?.modifiers?.forEach((mod) => {
      const gqlFryOptions = getDisplayableModifier(mod);
      fryOptionResult['Order Wingstreet'].push(gqlFryOptions);
    });

    const frySeasoningOptions = product.options.find((option) => option.name === FRIES_SEASONING);
    frySeasoningOptions?.modifiers?.forEach((mod) => {
      const ccFryOptions = getDisplayableModifier(mod);
      fryOptionResult.Order.push(ccFryOptions);
    });

    if (doubleOrderFriesTransformEnabled) {
      const doubleOrder = product.options.find((option) => option.name === WINGSTREET_SIZE);
      doubleOrder?.modifiers?.forEach((mod) => {
        mod.modifiers?.forEach((subMod) => {
          const doubleOrderOptions = getDisplayableModifier(subMod);
          fryOptionResult['Double Order'].push(doubleOrderOptions);
        });
      });
    }

    // For YUM Store fries need to be handled differently.
    let currentGroup: Modifier | undefined;
    product.options
      .flatMap((option) => option.modifiers)
      .flatMap((group) => {
        currentGroup = group;
        return group?.modifiers;
      })
      .forEach((mod) => mod && currentGroup && fryOptionResult[currentGroup.name]?.push(getDisplayableModifier(mod)));

    return fryOptionResult;
  }

  const origOpts = product.options;
  // Create unique pointers
  const sizeData = origOpts.find(sizePredicate);
  if (!sizeData?.modifiers) return {};

  const customSizeIds: CustomSizeIds[] = sizeData.modifiers.map((size) => {
    if (!size?.nutrition?.length) return { keyName: size.name };
    return {
      keyName: size.name,
      calories: size.nutrition[0].calories,
      nutritionName: size.nutrition[0].name,
      id: size.id,
      variantCode: size.variantCode
    };
  });

  const additionalOptionKeys = origOpts.filter(negate(sizePredicate)).map((opt) => opt.name);

  const additionalOpts: DisplayableAdditionalOptions = customSizeIds?.reduce(
    (acc: DisplayableAdditionalOptions, current) => {
      // Create additional options
      const inProgressAdditionalOpts = acc;
      inProgressAdditionalOpts[current.keyName] = [...shapeAdditionalOptions(origOpts, additionalOptionKeys, current, product.id)];

      // If has default Create default
      if (inProgressAdditionalOpts[current.keyName].length && product.defaultSelectedOption) {
        inProgressAdditionalOpts[current.keyName].unshift({
          id: `default-${product.defaultSelectedOption}`,
          name: product.defaultSelectedOption,
          type: 'DEFAULT',
          price: 0,
          nutrition: [],
          modifiers: []
        });
      }

      return inProgressAdditionalOpts;
    },
    {}
  );

  return additionalOpts;
};

// DTG-1051 Oneclick upsell will start using this for adding melts from the cartrail
export const transformOneClickMods = (selections: Modifier[]): ProductModifier[] => selections.map((selection) => {
  const {
    id, name, type, modifiers
  } = selection;

  const selectedModifiers = modifiers?.filter((mod) => mod.selected) || [];

  return {
    ...selection,
    id: selectedModifiers?.length > 0 ? selectedModifiers[0]?.id : id,
    name,
    type: type || '',
    modifiers: type === SIZE ? modifiers : [],
    quantities: [1]
  };
});

export const shapeOneClickDefaults = (product: AvailabilityItem<Product>, isYumEcomm = false): ProductModifier[] => {
  const yumSelectedOptions = (yumProduct: AvailabilityItem<Product>) => yumProduct.selectedOptions.filter(
    (selectedOption) => (selectedOption.slotCode && selectedOption.weightCode) || selectedOption.variantCode
  );

  const nonYumSelectedOptions = (nonYumProduct: AvailabilityItem<Product>) => nonYumProduct.options.reduce((acc: Modifier[], current: Modifier) => {
    const { modifiers } = current;
    const selections = modifiers ? modifiers.filter((mod) => mod.selected) : [];
    return [...acc, ...selections];
  }, []);

  const oneClickSelections = isYumEcomm ? yumSelectedOptions(product) : nonYumSelectedOptions(product);
  const transformedOneClickDefaults = transformOneClickMods(oneClickSelections);

  return transformedOneClickDefaults;
};

// wings:
const wingsOptionsToDisplayableModifier = (
  isAppetizerTopping: boolean,
  options?: Modifier[]
): DisplayableModifier[] => {
  if (!options) return [];
  return options
    .map(
      (option) => ({
        id: option.id,
        name: option.name,
        priority: option.displayOrder,
        isOutOfStock: option.outOfStock,
        sodiumWarning: option?.sodiumWarning ?? false,
        type: option.type,
        nutrition:
          option?.nutrition?.length && !isAppetizerTopping
            ? option.nutrition.map((nutritionItem) => ({
              unit: nutritionItem.unit,
              calories: nutritionItem.calories,
              servings: nutritionItem.servings,
              name: nutritionItem.name
            }))
            : [],
        modifiers: [],
        placement: option.placement,
        portion: option.portion,
        variantCode: option.variantCode,
        slotCode: option.slotCode,
        weightCode: option.weightCode
      }) as DisplayableModifier
    )
    .sort((a, b) => (a?.priority && b?.priority && a.priority - b.priority) || 0);
};

export const wingsAdditionalOptionsFromCCData = (
  product: AvailabilityItem<Product>,
  isLineup = false
): DisplayableAdditionalOptions => {
  /* This function will take CC options from CC Available product
  and extrapolate out whether the additional options of the wings product will be a flavor
  for the wing or a sauce. The differentiator found for determining wheter we have a sauce or
  not is based on whether "Appetizer Topp" is present within  our options. */

  const optimizely = getOrInitializeOptimizely();
  const bakedWingsDipCupsEnabled = optimizely?.isFeatureEnabled('fr-web-3959-baked_wings_dip_cups');

  // Target Sizes Under Options
  const sizesOption = product.options.find(sizePredicate);
  // Check whether these options are sauces.
  const isAppetizerTopping = Boolean(product.options.find((option) => option.name === APPETIZERTOPP));

  const sauces = product.options.find((option) => option.name === WINGSTREET_PROD);

  const bakedWingsDipCups = product.options.find((option) => option.slotCode === BAKED_WINGS_DIP_CUP_SLOT);
  const additionalDipCupOptions = wingsOptionsToDisplayableModifier(isAppetizerTopping || isLineup, bakedWingsDipCups?.modifiers);

  // Declaring the additional option return here.
  const additionalOptions: DisplayableAdditionalOptions = {};

  sizesOption?.modifiers?.forEach((size) => {
    const sizeModifiers = sizesOption?.modifiers?.length === 1 && sauces?.modifiers ? sauces.modifiers : size.modifiers;
    const additionalWingOptions = wingsOptionsToDisplayableModifier(isAppetizerTopping || isLineup, sizeModifiers);

    if (bakedWingsDipCupsEnabled && additionalDipCupOptions?.length) {
      additionalOptions[size.name] = [...additionalDipCupOptions];
      if (product.defaultSelectedOption) {
        additionalOptions[size.name].push({
          id: `default-${product.defaultSelectedOption}`,
          name: product.defaultSelectedOption,
          type: 'DEFAULT',
          price: 0,
          nutrition: [],
          modifiers: []
        });
      }
    } else {
      // If "Appetizer Topp" its a sauce and not a flavor. Thusly it has a default no option.
      additionalOptions[size.name] = isAppetizerTopping
        ? ([
          ...additionalWingOptions,
          {
            id: `default-${product.defaultSelectedOption}`,
            name: product.defaultSelectedOption,
            type: 'DEFAULT',
            price: 0,
            nutrition: [],
            modifiers: []
          }
        ] as DisplayableModifier[])
        : [...additionalWingOptions];
    }
  });
  return additionalOptions;
};
