import { useDecision } from '@optimizely/react-sdk';
import { MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import { getGlobalId } from '@/cart/helpers/getGlobalId';

type HideBDBVariables = {
  subDeals?: {
    commonString?: string;
  };
};

type HideMHBVariables = {
  subDeals?: {
    ids: string[];
  };
};

// This filtering is needed until we're fully on Yum menu, to prevent some menu changes that
// are needed for Yum from negatively impacting the non-Yum menu experience
const useSubOfferFiltering = () => {
  const [{ enabled: hideBDBSubDealsEnabled, variables: hideBDBSubDealsVariables }] = useDecision('exp-web3461-hide_bdb_sub_deals');
  const bdbSubDealString = (hideBDBSubDealsVariables as HideBDBVariables)?.subDeals?.commonString;
  const [{ enabled: hideMHBSubDealsEnabled, variables: hideMHBSubDealsVariables }] = useDecision('fr-web-3655-filter_mhb_suboffers');
  const mhbSubDealIds = (hideMHBSubDealsVariables as HideMHBVariables)?.subDeals?.ids;

  // Converts string to lowercase and removes empty spaces
  const stripString = (string: string) => string.toLowerCase().replace(/\s/g, '');

  const removeSubDeals = (deals: MenuRecipe[]) => {
    let filteredDeals = [...deals];

    // WEB-3461 - Hide BDB sub-deals for non-Yum stores
    if (hideBDBSubDealsEnabled && bdbSubDealString) {
      filteredDeals = filteredDeals.filter((deal) => !stripString(deal.name).includes(stripString(bdbSubDealString)));
    }

    // WEB-3655 - Hide MHB sub-deals for non-Yum stores
    if (hideMHBSubDealsEnabled && mhbSubDealIds) {
      const strippedSubDealIds = mhbSubDealIds.map((id) => stripString(id));

      filteredDeals = filteredDeals.filter((deal) => {
        const dealId = getGlobalId(deal.id);
        return dealId && !strippedSubDealIds.includes(stripString(dealId));
      });
    }

    return { deals: filteredDeals };
  };

  return removeSubDeals;
};

export default useSubOfferFiltering;
