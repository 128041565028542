import { SerializedError } from '@reduxjs/toolkit';

interface MutationCallbacks<ResponseType> {
  onMutate?: () => void;
  onSuccess?: (response: ResponseType) => void | undefined | Promise<void>;
  onError?: (error: SerializedError) => void | undefined;
  onSettled?: () => void;
}

type CustomMutationActionCreatorResult<ResponseType, PayloadType> = Promise<
| {
  data: ResponseType;
}
| {
  // Update Later with actual core error types
  error: unknown;
}
> & {
  arg: {
    endpointName: string;
    originalArgs: PayloadType;
    track?: boolean;
    fixedCacheKey?: string;
  };
  requestId: string;
  abort(): void;
  unwrap(): Promise<ResponseType>;
  reset(): void;
  unsubscribe(): void;
};

/**
 * Triggers the mutation and utilizes callbacks for each mutation state
 * @remarks
 * Wrap Client Core or any RTK Mutation Trigger Function Call
 * in order to better deal with side effects
 *
 * Optional Typescript generics: Response (Response type from core), Payload type (Payload Type from Core)
 * Parameters:
 * RTK Mutation Trigger Result
 *
 * Mutation Callbacks:
 *   onMutate: when the mutation begins
 *   onSuccess: when the mutation is successful, it recieves the response in the callback param
 *   onError: when the mutation is unsuccessful, it revieves the error in the callback param
 *   onSetteled: when the mutation is successful or unsuccessful
 *
 * @example
 * ```ts
 * await triggerMutationWrapper(trigger(payloadForMutation), {
 *   onMutate: () => console.log('muatation started!'),
 *   onSuccess: (response) => {
 *        Toast.show({
 *         type: 'successToast',
 *           text2: `You completed ${response.name}`,
 *         })
 *     },
 *     onError: (error) => {
 *         Toast.show({
 *             type: 'errorToast',
 *             text1: 'Trigger Failed',
 *             text2: error.message,
 *         });
 *     },
 *     onSettled: () => console.log("mutation success or error"),
 * });
 * ```
 */

const triggerMutationWrapper = async <ResponseType, PayloadType>(
  triggerResult: CustomMutationActionCreatorResult<ResponseType, PayloadType>,
  {
    onMutate, onSuccess, onError, onSettled
  }: MutationCallbacks<ResponseType>
) => {
  if (onMutate) onMutate();
  await triggerResult
    .unwrap()
    .then((response) => onSuccess && onSuccess(response))
    .catch((err) => onError && onError(err))
    .finally(() => onSettled && onSettled());
};

export default triggerMutationWrapper;
