import { CartItem } from '@pizza-hut-us-development/client-core';
import { SortedItemPointer } from '../selectors/clientCorePresentational/rail/presentationalRailSelectors';

export const getSortedCartItemIds = (sortedItemPointers:SortedItemPointer[]):string[] => sortedItemPointers.map((pointer) => pointer.cartItemId);

export const findNewCartItemId = (sortedItemPointers: SortedItemPointer[], cartItems:CartItem[]) => {
  const cartItemIdArrayFromPointers = getSortedCartItemIds(sortedItemPointers);
  const cartItemIdsFromCart = cartItems.map((cartItem) => cartItem.cartItemId);
  const cartItemId = cartItemIdsFromCart.find((cartItemIdInCart) => !cartItemIdArrayFromPointers.includes(cartItemIdInCart));

  return cartItemId;
};
