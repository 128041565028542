import { useDispatch, useSelector } from 'react-redux';
import {
  BACK_TO_CART_BUTTON,
  BACK_TO_CART_LABEL,
  DELIVERY_MINIMUM,
  GOT_IT_BUTTON,
  SERVICE_FEE,
  SERVICE_FEE_INFO_CONTENT,
  SERVICE_FEE_TITLE,
  WARNING_CONTENT,
  WARNING_CONTENT_TITLE_DELIVERY_MINIMUM,
  WARNING_CONTENT_TITLE_PRICE_TC
} from '@/clientCore/cart/constants';
import { CartStateSlice } from '@/clientCore/redux/cart/CartSlice';
import { presentationalCartSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/cart/presentationalCartSelectors';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import formattedPrice from '@/common/formattedPrice';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { onBackToCartDeliveryMinimum } from '@/checkout/checkout.analytics';
import { cartItemsToLegacy } from '@/clientCore/cart/helpers';

export const useCartInfoAndWarningView = () => {
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const cart = useSelector(orderSelectors.cart);
  const { toggleCartInfoAndWarningView } = CartStateSlice.actions;
  const infoAndWarningType = useSelector(
    presentationalCartSelectors.cartInfoAndWariningViewType
  );
  const storeOccasions = useSelector(orderSelectors.store)!.occasions;
  const currentOccasion = useSelector(orderSelectors.currentOccasion);

  const currentOccasionInfo = storeOccasions.find(
    (storeOccasion) => storeOccasion.occasionId === currentOccasion
  );

  const deliveryMinimum = currentOccasionInfo?.minOrder ?? 0;

  const handleCloseClick = (isDeliveryMin = false) => {
    if (isDeliveryMin && cart?.items) {
      analytics.push(() => onBackToCartDeliveryMinimum(BACK_TO_CART_BUTTON, cartItemsToLegacy(cart.items)));
    }
    dispatch(toggleCartInfoAndWarningView({ displayState: false }));
  };

  const handleWarningContent = () => {
    switch (infoAndWarningType) {
      case DELIVERY_MINIMUM:
        return {
          title: WARNING_CONTENT_TITLE_DELIVERY_MINIMUM,
          content: WARNING_CONTENT(formattedPrice(deliveryMinimum)),
          label: BACK_TO_CART_LABEL,
          onClick: () => handleCloseClick(true),
          shouldDispatchAnalyticsEvent: true
        };
      case SERVICE_FEE:
        return {
          title: SERVICE_FEE_TITLE,
          content: SERVICE_FEE_INFO_CONTENT,
          label: GOT_IT_BUTTON,
          onClick: handleCloseClick,
          showBackToCartLink: true
        };
      default:
        return {
          title: WARNING_CONTENT_TITLE_PRICE_TC,
          content: infoAndWarningType.replace(/\./g, '.\n'),
          label: GOT_IT_BUTTON,
          onClick: handleCloseClick,
          showBackToCartLink: true
        };
    }
  };

  return [
    {
      warningContent: handleWarningContent()
    }
  ];
};
