import Typography from '@material-ui/core/Typography';
import React from 'react';
import nutritionForProduct from '../../dataTransformers/nutritionForProduct';
import getCaloriesPerUnitText from '../../dataTransformers/caloriesPerUnit';

interface Props {
  label: string;
  nutrition: Partial<Nutrition> | undefined | null;
  isProduct?: boolean;
  includeProductName: boolean;
  isDip?: boolean;
}

const CalorieDisplay = ({
  label,
  nutrition,
  isProduct = false,
  includeProductName,
  isDip = false
}: Props): JSX.Element => (
  <Typography data-testid={`modifier-${label}-cal`}>
    {isProduct
      ? nutritionForProduct(nutrition, includeProductName, isDip)
      : getCaloriesPerUnitText(nutrition)}
  </Typography>
);

export default CalorieDisplay;
