import { createSlice } from '@reduxjs/toolkit';
import { DebugStateTypes, DebugTypes } from './types';

const initialState: DebugStateTypes = {
  enabled: false,
  mode: 'all',
  forceNonYum: false
};

export const DebugModeStateSlice = createSlice({
  initialState,
  name: 'DebugModeState',
  reducers: {
    setEnabled: (
      state: DebugStateTypes,
      {
        payload: { isEnabled }
      }: {
        payload: {
          isEnabled: boolean;
        };
      }
    ) => ({
      ...state,
      enabled: isEnabled
    }),
    setMode: (state: DebugStateTypes, { payload: { debugMode } }: { payload: { debugMode: DebugTypes } }) => ({
      ...state,
      mode: debugMode
    }),
    setForceNonYumMode: (state: DebugStateTypes, { payload: { forceNonYum } }: { payload: { forceNonYum: boolean } }) => ({
      ...state,
      forceNonYum
    })
  }
});

export const debugModeStateReducer = DebugModeStateSlice.reducer;

export const { setEnabled: setCCDebugEnabled, setMode: setCCDebugMode, setForceNonYumMode } = DebugModeStateSlice.actions;
