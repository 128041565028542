import GET_CATEGORIES_NATIONAL from './getCategoriesNational';
import GET_CATEGORIES_LOCALIZED from './getCategoriesLocalized';
import CategoryFragment, { ApiCategoryFragment } from '../../types/fragment/Category';
import filterExists from '@/graphql/helpers/filterExists';

export {
  GET_CATEGORIES_NATIONAL,
  GET_CATEGORIES_LOCALIZED
};

const categoryHasProducts = (
  { products, categories }: ApiCategoryFragment,
  maxDepth = 3
): boolean => {
  if (maxDepth === 0) {
    return false;
  }

  if (products && products.length > 0) {
    return products.some(({ item }) => filterExists(item));
  }

  if (!categories || categories.length === 0) {
    return false;
  }

  return categories.some((category) => categoryHasProducts(category, maxDepth - 1));
};

export const categoriesWithProducts = (
  categories: ApiCategoryFragment[] = []
): CategoryFragment[] => categories.filter((category) => categoryHasProducts(category));

export const matchingCustomerLocalization = (
  customerIsNational: boolean
) => ({ isNational }: CategoryFragment): boolean => Boolean(customerIsNational ? isNational : true);

export const sortByIndex = (
  a: Pick<CategoryFragment, 'index'>,
  b: Pick<CategoryFragment, 'index'>
): number => Number(a.index) - Number(b.index);
