import { NextRouter, useRouter } from 'next/router';

const defaultBaseUrl = 'https://www.pizzahut.com';
const spanishSiteBaseUrl = 'https://es.order.pizzahut.com';

interface UseSeoMetadataResponse {
  canonicalUrl: string;
  spanishSiteUrl: string;
  pageId: string;
}

const removeQueryParamsFromPath = (path: string): string => path.split('?')[0];

const useSeoLinksMetadata = (): UseSeoMetadataResponse => {
  const router: NextRouter = useRouter();
  let asPathFormatted = removeQueryParamsFromPath(router.asPath);
  if (asPathFormatted === '/menu/pizza/builder') {
    asPathFormatted = asPathFormatted.slice(0, asPathFormatted.lastIndexOf('/'));
  }
  let pageId = 'homepage';

  if (router.pathname.startsWith('/menu/pizza')) {
    pageId = 'pizza';
  }

  if (router.pathname.startsWith('/menu/wings')) {
    pageId = 'wings';
  }

  if (router.pathname.startsWith('/menu/[category]')) {
    pageId = router.query.category as string;
  }

  if (router.pathname.startsWith('/deals')) {
    pageId = 'deals';
  }

  if (router.pathname === '/menu') {
    pageId = 'menu';
  }

  return {
    canonicalUrl: `${defaultBaseUrl}${asPathFormatted}`,
    spanishSiteUrl: `${spanishSiteBaseUrl}${asPathFormatted}`,
    pageId
  };
};

export default useSeoLinksMetadata;
