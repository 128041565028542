import createClientMethod from '@/api/createClientMethod';
import { includeCredentials, phdapiEdit } from '@/api/phdApiClient/base';
import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import AccountManagementEndpoints from '@/api/phdApiClient/accountManagement/endpoints';
import {
  CustomerDetailsError,
  CustomerDetailsResponse,
  isCustomerDetailsError
} from '@/api/phdApiClient/transformPHDData';
import { EditLoginDataPayload } from '@/services/accountManagementService/phdApiAccountManagementService/types';
import { Profile } from '@/domain/Profile';

const fetch = async (editLoginDataPayload : EditLoginDataPayload) => phdapiEdit(
  getUrl(AccountManagementEndpoints.UPDATE_CUSTOMER),
  includeCredentials,
  JSON.stringify(editLoginDataPayload)
);

const transformCustomerProfile = (data: CustomerDetailsResponse): Profile => ({
  loyaltyToken: data?.loyalty_token,
  firstName: data?.first_name,
  lastName: data?.last_name,
  email: data?.email,
  phone: data?.phone,
  customerAddresses: [],
  payments: [],
  promotions: []
});

const transformer = async (
  response: Response
): Promise<ClientResult<Profile>> => (
  response.json()
)
  .then((res: CustomerDetailsResponse | CustomerDetailsError) => {
    if (isCustomerDetailsError(res)) {
      return { error: true };
    }

    return {
      error: false,
      result: transformCustomerProfile(res)
    };
  });

const updateCustomer = createClientMethod(fetch, transformer);

export default updateCustomer;
