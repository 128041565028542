import {
  Pizza,
  PizzaIngredientOption,
  PizzaIngredient,
  PizzaOptions,
  CrustOption
} from '../../dataTransformers/builderTypes';
import { selectCrustOptionsByName } from './crustSelectors';

const emptyArray = Object.freeze([]);
const getGlobalId = (id) => id?.split('/').pop();

// #Size Options selector
const selectSizesOptions = (
  pizza: Pizza
): PizzaIngredientOption[] => pizza.pizzaOptions?.sizes ?? emptyArray as PizzaIngredientOption[];

// #Size Option by SizeID selector
function selectPizzaSizeOptionByID(
  sizes: PizzaIngredientOption[], sizeId: PizzaIngredientOption['id']
): PizzaIngredientOption {
  return sizes?.find((size) => size.id.includes(sizeId));
}

// #Size Option selector
function selectPizzaSizeOption(pizza: Pizza): PizzaIngredientOption {
  return selectPizzaSizeOptionByID(selectSizesOptions(pizza), pizza.size?.id);
}

function selectPizzaSizeOptionBySizeId(pizza: Pizza, sizeId: PizzaIngredientOption['id']): PizzaIngredientOption {
  return selectPizzaSizeOptionByID(selectSizesOptions(pizza), sizeId);
}

// #Is Pizza Size Splittable selector
const isSplittable = (pizza: Pizza): boolean => selectPizzaSizeOption(pizza)?.splittable;

// #Pizza Recipe Size selector
const selectPizzaSize = (pizza: Pizza): PizzaIngredient => pizza.size;

// #Pizza Recipe Size Global ID selector
const selectPizzaSizeGlobalId = (pizza: Pizza): string => getGlobalId(pizza?.size?.id);

function selectSizeOptionForCrust(
  pizzaOption: PizzaOptions | undefined,
  selectedCrust: CrustOption | PizzaIngredient | null
): PizzaIngredientOption {
  const crusts = selectCrustOptionsByName(pizzaOption?.crusts, selectedCrust);
  return crusts.length === 1
    ? selectPizzaSizeOptionByID(pizzaOption?.sizes, crusts[0].sizeId)
    : null;
}

function selectSizeOptionsForCrust(
  pizzaOption: PizzaOptions,
  selectedCrust: CrustOption | PizzaIngredient
): PizzaIngredientOption[] {
  const crustOptions = selectCrustOptionsByName(pizzaOption?.crusts, selectedCrust)
    .map((crust) => crust.sizeId);

  return pizzaOption?.sizes.filter((size) => crustOptions.includes(size.id)) || [];
}

export {
  isSplittable,
  selectPizzaSize,
  selectPizzaSizeOption,
  selectPizzaSizeGlobalId,
  selectPizzaSizeOptionByID,
  selectPizzaSizeOptionBySizeId,
  selectSizesOptions,
  selectSizeOptionsForCrust,
  selectSizeOptionForCrust
};
