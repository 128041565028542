import createClientMethod from '@/api/createClientMethod';
import { AddCustomerAddressPayload } from '@/services/accountManagementService/phdApiAccountManagementService/types';
import { includeCredentials, phdapiPost } from '../../base';
import { getUrl } from '../../helpers/getUrl';
import AccountManagementEndpoints from '../endpoints';
import { ProfileAddress } from '@/domain/Profile';
import { CustomerAddressResponse } from '../../transformPHDData';

export const transformAddress = (
  address: CustomerAddressResponse
): ProfileAddress => ({
  addressId: address.customer_address_id,
  address1: address.address1,
  address2: address.address2,
  city: address.city,
  isDefault: address.is_default,
  name: address.name,
  postalCode: address.postal_code,
  state: address.state,
  locationType: address.type,
  deliveryInstructions: address.delivery_instructions,
  phone: address.phone
});

const fetch = async (addCustomerAddressPayload: AddCustomerAddressPayload) => phdapiPost(
  getUrl(AccountManagementEndpoints.CUSTOMER_ADDRESS),
  includeCredentials,
  JSON.stringify(addCustomerAddressPayload)
);

const transformer = async (response: Response): Promise<ClientResult<ProfileAddress>> => (
  response.json()
).then((res) => {
  if (res.error_code) {
    return {
      error: true
    };
  }

  return {
    error: false,
    result: transformAddress(res)
  };
});

const addCustomerAddress = createClientMethod(fetch, transformer);
export default addCustomerAddress;
