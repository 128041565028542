const deleteCard = typeof (jest) === 'undefined' ? new Promise(() => {}) : jest.fn();

const successfulDeleteCard = (): ClientResult<void> => {
  const mockDeleteCard = deleteCard as jest.Mock;
  const value = { error: false };
  mockDeleteCard.mockResolvedValue(value);
  return value;
};

const failedDeleteCard = (): ClientResult<void> => {
  const mockDeleteCard = deleteCard as jest.Mock;
  const value = { error: true };
  mockDeleteCard.mockResolvedValue(value);
  return value;
};

export default deleteCard;

export {
  successfulDeleteCard,
  failedDeleteCard
};
