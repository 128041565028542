import { decodeHTML } from 'entities';

const decodeEntities = (htmlText: string | null | undefined): string => {
  if (htmlText) {
    const ignoredTags = htmlText
      .replace(/<\/?sup>/gi, '')
      .replace(/<br\s*\/?>/gi, '');

    return decodeHTML(ignoredTags);
  }

  return '';
};

export default decodeEntities;
