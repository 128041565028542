export enum QueryNames {
  HEADER = 'HEADER',
  BREAKING_NEWS = 'BREAKING_NEWS',
  HERO = 'HERO',
  SIDEKICK = 'SIDEKICK',
  MARKETING = 'MARKETING',
  FOOTER = 'FOOTER_*',
  PIZZA_MENU = 'PIZZA_MENU',
  PIZZA_BUILDER = 'PIZZA_BUILDER',
  GET_PRODUCTS_BY_CATEGORY = 'GET_PRODUCTS_BY_CATEGORY',
  GET_PRODUCTS_BY_ID = 'GET_PRODUCTS_BY_ID',
  DEAL = 'DEAL',
  DEAL_MENU = 'DEAL_MENU',
  GET_CATEGORIES_NATIONAL = 'GET_CATEGORIES_NATIONAL',
  GET_CATEGORIES_LOCALIZED = 'GET_CATEGORIES_LOCALIZED'
}

export default QueryNames;
