import { Button, makeStyles, createStyles } from '@material-ui/core';
import React from 'react';
import fontStyles from '../fontStyles';
import colors from '../colors';

const useStyles = makeStyles(() => createStyles({
  root: {
    '&.MuiButton-root': {
      ...fontStyles.h4,
      textTransform: 'none',
      color: colors.blue,
      letterSpacing: 'normal'
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    },
    '&.MuiButton-text': {
      padding: '0px'
    }
  }
}));

interface LinkButtonProps {
  testId: string;
  onClick: (value?: any) => void;
  children?: React.ReactNode;
  startIcon?: React.ReactNode;
}

const LinkButton = (props: LinkButtonProps) => {
  const { testId, children, ...rest } = props;
  const classes = useStyles();

  return (
    <Button
      data-testid={testId}
      classes={classes}
      disableRipple
      variant="text"
      {...rest}
    >
      {children}
    </Button>
  );
};

export default LinkButton;
