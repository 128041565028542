/* eslint-disable no-console */
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import getConfig from 'next/config';
import loggingFetch from '../../common/loggingFetch';
import promiseWithTimeout, { PromiseTimeout } from '../promiseWithTimeout';
import logger from '@/common/logger';
import telemetry from '@/telemetry';
import isClientSide from '@/common/isClientSide';

if (process.env.NODE_ENV === 'development') {
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
}

class UnsupportedHeaderType extends Error {
  headersObj: Headers | string[][];

  constructor(message: string, headersObj: Headers | string[][]) {
    super(message);
    this.headersObj = headersObj;
  }
}
export const addCustomHeaders = (originalHeaders: HeadersInit = {}): Record<string, string> => {
  let newHeaders: Record<string, string>;
  if (originalHeaders instanceof Headers || originalHeaders instanceof Array) {
    logger.withoutTelemetry.error('Unsupported header type', originalHeaders);
    telemetry.addNoticeError(new UnsupportedHeaderType('Unsupported header type', originalHeaders));
    newHeaders = {};
  } else {
    newHeaders = { ...originalHeaders };
  }

  const { serverRuntimeConfig } = getConfig();

  if (!isClientSide()) {
    newHeaders['user-agent'] = 'Pizza Hut Webserver';
  }

  // workaround for W20M-225
  if (serverRuntimeConfig.AKAMAI_BOT_BYPASS_KEY) {
    newHeaders.akaatornr = serverRuntimeConfig.AKAMAI_BOT_BYPASS_KEY;
  }

  return newHeaders;
};

let numRequests = 1;
const timedLoggingFetch = (request: Request, options: RequestInit) => {
  const newOptions = { ...options };
  const label = `making a fetch request #${numRequests}`;
  const startTime = new Date().getTime();
  logger.withoutTelemetry.debug(`making a fetch request #${numRequests}`);
  numRequests += 1;

  if (newOptions) {
    newOptions.headers = addCustomHeaders(options.headers);
  }

  return promiseWithTimeout(loggingFetch(request, newOptions))
    .catch((err) => {
      telemetry.addNoticeError(err);
      if (err instanceof PromiseTimeout) {
        logger.withoutTelemetry.error('Timeout fetching GQL data.', err);
      }
      throw err;
    })
    .finally(() => logger.withoutTelemetry.debug(`${label} ${new Date().getTime() - startTime}`));
};

const getStitcherApiUrl = (gqlUrlOverride?: string) => {
  const { publicRuntimeConfig } = getConfig();
  return gqlUrlOverride || publicRuntimeConfig.GQL_URL;
};

const batchHttpLink = (gqlUrlOverride?: string): BatchHttpLink => new BatchHttpLink({
  fetch: timedLoggingFetch,
  uri: getStitcherApiUrl(gqlUrlOverride)
});

export default batchHttpLink;
