import localized_v3_query from './localizedHeader.v3';
import national_v3_query from './nationalHeader.v3';
import cc_localized_v3_query from './ccGQLPartialLocalizedHeader.v3';
import { HeaderQuery } from '../../types/Header';
import { GqlVersion } from '../../hooks/useGqlVersion/GqlVersion';

function headerNationalQuery(version?: GqlVersion): HeaderQuery {
  switch (version) {
    default:
      return national_v3_query;
  }
}
function headerLocalizedQuery(version?: GqlVersion): HeaderQuery {
  switch (version) {
    default:
      return localized_v3_query;
  }
}

function ccGQLPartialHeaderLocalizedQuery(version?: GqlVersion): HeaderQuery {
  return cc_localized_v3_query;
}

export {
  headerNationalQuery as HEADER_NATIONAL,
  headerLocalizedQuery as HEADER_LOCALIZED,
  ccGQLPartialHeaderLocalizedQuery as CC_GQL_PARTIAL_HEADER_LOCALIZED
};
