import { customerStub } from '@/api/phdApiClient/__mocks__/myAccount/getCustomer.stub';
import { Profile } from '@/domain/Profile';

const updateCustomer = typeof (jest) === 'undefined' ? new Promise(() => {}) : jest.fn();

const successfulUpdateCustomerProfileLoginInfo = (): ClientResult<Profile> => {
  const mockUpdateCustomer = updateCustomer as jest.Mock;
  const value = {
    error: false,
    result: customerStub
  };
  mockUpdateCustomer.mockResolvedValue(value);
  return value;
};

const failedUpdateCustomerProfileLoginInfo = (): ClientResult<Profile> => {
  const mockUpdateCustomer = updateCustomer as jest.Mock;
  const value = {
    error: true
  };
  mockUpdateCustomer.mockResolvedValue(value);
  return value;
};

export default updateCustomer;

export {
  successfulUpdateCustomerProfileLoginInfo,
  failedUpdateCustomerProfileLoginInfo
};
