import toIngredient from '../../dataTransformers/toIngredient';
import Portion from '../../../../common/Portion';
import SelectedBy from '../../../../common/SelectedBy';
import { getUniqCrusts } from '../selectors/crustSelectors';
import {
  CrustOption,
  DisplayableItem,
  IngredientOptionWithPortions,
  Pizza,
  PizzaIngredient,
  PizzaIngredientOption,
  PizzaOptions,
  PizzaSummary
} from '../../dataTransformers/builderTypes';
import { PIZZA } from '@/domain/constants';
import { IsCyo } from '@/menu/pizza/pizzaMenuTypes';

const isNotOOS = (option: PizzaIngredientOption) => 'outOfStock' in option && !option.outOfStock;
const portionIsRegular = (portion: PizzaIngredientOption) => portion.portion === Portion.REGULAR;
const portionIsSelected = (portion: PizzaIngredientOption) => portion.selected;

const getSelectedIngredient = (
  option: PizzaIngredientOption
) => option.selected && isNotOOS(option);

const getSelectedIngredients = (
  ingredients: PizzaIngredientOption[] | undefined
): PizzaIngredientOption[] | undefined => ingredients?.filter(getSelectedIngredient);

const getSelectedNotOOS = <T extends PizzaIngredientOption>(
  options: T[]
) => options.find(getSelectedIngredient);

const getFirstNotOOS = <T extends PizzaIngredientOption>(
  options: T[]
) => options.find(isNotOOS);

/**
 * When are no selected size, crust, cheese, finisher default to first one in that list
 */
const getDefaultCrust = (
  crusts: CrustOption[] | undefined,
  size: PizzaIngredient | null
): PizzaIngredient | null => {
  if (crusts?.length === 1) return toIngredient(crusts[0], SelectedBy.SYSTEM);

  const crustsForSize = size
    ? crusts?.filter((crust) => crust.sizeId === size.id)
    : getUniqCrusts(crusts);

  if (!crustsForSize) return null;

  const firstOption = getSelectedNotOOS(crustsForSize);
  return firstOption ? toIngredient(firstOption, SelectedBy.SYSTEM) : null;
};

const defaultSize = (options: PizzaIngredientOption[] | undefined) => {
  if (options?.length === 1) return toIngredient(options[0], SelectedBy.SYSTEM);

  const firstOption = options?.find(portionIsSelected);
  return firstOption ? toIngredient(firstOption, SelectedBy.SYSTEM) : null;
};

const getRegularPortion = <T extends IngredientOptionWithPortions>(
  option: T
) => option?.portions?.find(portionIsRegular);

const getFirstPortion = (option: IngredientOptionWithPortions) => option.portions[0];

const getDefaultSelectedModifier = <T extends PizzaIngredientOption>(
  options: T[]
) => getSelectedNotOOS(options) ?? getFirstNotOOS(options) ?? options[0];

const getDefaultSelectedPortion = <T extends IngredientOptionWithPortions>(
  option: T
) => getRegularPortion(option) ?? getFirstPortion(option);

const getDefaultSaucePortion = (
  options: IngredientOptionWithPortions[] | undefined
) => {
  if (!options) return null;
  const selectedOption = getDefaultSelectedModifier(options);
  const portion = getDefaultSelectedPortion(selectedOption);
  return toIngredient(portion, SelectedBy.SYSTEM);
};

const getDefaultCheesePortion = (
  options: IngredientOptionWithPortions[] | undefined
) => {
  if (!options) return null;

  const availabePortions = options
    .filter(isNotOOS)
    .flatMap((ingredient) => ingredient.portions)
    .filter(isNotOOS);

  const portion = availabePortions.find(portionIsSelected)
    ?? availabePortions.find(portionIsRegular)
    ?? availabePortions[0];

  return toIngredient(portion, SelectedBy.SYSTEM);
};

const getDefaultFinisher = (
  options: PizzaIngredientOption[] | undefined
) => {
  if (!options || options?.length <= 0) return null;
  const selectedOption = getDefaultSelectedModifier(options);
  return toIngredient(selectedOption, SelectedBy.SYSTEM);
};

const recipeToppingCount = (
  ingredients: PizzaIngredientOption[]
) => ingredients.filter(portionIsSelected).length;

const getSummary = (
  summary: DisplayableItem
): PizzaSummary => ({
  id: summary.id,
  name: summary.name,
  price: summary.price,
  displayName: summary.displayName,
  quantity: 1,
  recipeDefaultToppingsCount: 0,
  splittable: summary.splittable
});

export default function selectDefaults({
  summary,
  sizes,
  crusts,
  sauces,
  cheeses,
  finishers,
  meatToppings: meats,
  veggieToppings: veggies
}: PizzaOptions): Pizza {
  const size = defaultSize(sizes);
  const pizzaSummary = getSummary(summary);
  const crust = getDefaultCrust(crusts, size);
  const sauce = getDefaultSaucePortion(sauces);
  const cheese = getDefaultCheesePortion(cheeses);
  const finisher = getDefaultFinisher(finishers);

  const meatToppings = getSelectedIngredients(meats)?.map(
    (item) => toIngredient(item, SelectedBy.SYSTEM) as PizzaIngredient
  );
  const veggieToppings = getSelectedIngredients(veggies)?.map(
    (item) => toIngredient(item, SelectedBy.SYSTEM) as PizzaIngredient
  );
  const recipeDefaultToppingsCount = summary?.recipeDefaultToppingsCount
    || recipeToppingCount([...meats || [], ...veggies || []]);
  const isCYO = IsCyo.UNKNOWN;

  return {
    ...pizzaSummary,
    type: PIZZA,
    isCYO,
    recipeDefaultToppingsCount,
    size,
    crust,
    finisher,
    cheese,
    sauce,
    veggieToppings,
    meatToppings,
    specialInstructions: ''
  };
}
