import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { LinkButtonProps } from './types';

export const LinkButton = ({
  className,
  testId,
  ariaLabel,
  onClick,
  children,
  isExpandable = false,
  isExpanded = false
}: LinkButtonProps) => (
  <Grid container justifyContent="space-between">
    <Button
      data-testid={testId}
      disableRipple
      className={className}
      onClick={onClick}
      aria-labelledby={ariaLabel}
      aria-expanded={isExpandable ? isExpanded : undefined}
    >
      {children}
    </Button>
  </Grid>
);
