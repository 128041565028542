import React from 'react';

export const WarningIcon = () => (
  <>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.85953 2.57323L1.21286 11.9999C1.09644 12.2015 1.03484 12.4301 1.03418 12.6629C1.03353 12.8957 1.09385 13.1246 1.20914 13.3269C1.32443 13.5292 1.49068 13.6977 1.69133 13.8158C1.89199 13.9339 2.12006 13.9973 2.35286 13.9999H13.6462C13.879 13.9973 14.1071 13.9339 14.3077 13.8158C14.5084 13.6977 14.6746 13.5292 14.7899 13.3269C14.9052 13.1246 14.9655 12.8957 14.9649 12.6629C14.9642 12.4301 14.9026 12.2015 14.7862 11.9999L9.13953 2.57323C9.02068 2.3773 8.85334 2.21531 8.65366 2.10288C8.45397 1.99046 8.22868 1.9314 7.99953 1.9314C7.77037 1.9314 7.54508 1.99046 7.3454 2.10288C7.14571 2.21531 6.97837 2.3773 6.85953 2.57323V2.57323Z"
        stroke="#E6740A"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 6V8.66667" stroke="#E6740A" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8 11.3333H8.00667"
        stroke="#E6740A"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);
