/* eslint-disable no-param-reassign */
import { compose, createSlice, PayloadAction } from '@reduxjs/toolkit';
import selectDefaults from './selectDefaults';
import toIngredient from '../dataTransformers/toIngredient';
import SelectedBy from '../../../common/SelectedBy';
import {
  isSplittable,
  selectPizzaSize,
  selectPizzaSizeGlobalId,
  selectPizzaSizeOption,
  selectPizzaSizeOptionByID,
  selectSizeOptionForCrust,
  selectSizesOptions
} from './selectors/sizeSelectors';
import {
  hasSameCrustOptions,
  selectCrustOptionForSize,
  selectCrustOptions,
  selectPizzaCrust,
  selectPizzaCrustGlobalId,
  selectPizzaCrustOption
} from './selectors/crustSelectors';
import {
  hasPizzaOptions,
  isCrustAndSizeSelected,
  selectPizzaSummaryTitle,
  selectPizzaOptions,
  selectPizzaSummaryOption,
  selectServings,
  hideCrustStepCarat,
  showTotalPrice,
  selectCartAddablePizza,
  selectPizzaId,
  selectPizzaDisplayName
} from './selectors/pizzaSelectors';
import {
  isReachedBNYCrustToppingsLimit,
  isReachedMaxAllowedToppings,
  isReachedPanCrustToppingsLimit,
  isReachedToppingsLimit,
  numberOfSelectedToppings,
  selectMeatToppingOptions,
  selectPizzaMeatToppings,
  selectPizzaVeggieToppings,
  selectRecipeDefaultToppingsCount,
  selectVeggieToppingsOptions
} from './selectors/toppingSelectors';
import {
  selectDefaultFlavor,
  selectFinisherOptions,
  selectPizzaFinisher,
  selectPizzaFinisherOption
} from './selectors/finisherSelectors';
import {
  selectPizzaSauce,
  selectPizzaSauceOption,
  selectRootSauceById,
  selectSauceOptions
} from './selectors/sauceSelectors';
import {
  selectCheeseOptions,
  selectPizzaCheese,
  selectPizzaCheeseOption
} from './selectors/cheeseSelectors';
import {
  selectCrustPricingRules,
  selectRestrictRulesBySizeAndCrust
} from './selectors/rulesSelectors';
import withCrustRules from './withCrustRules';
import { PizzaCrustAndSizeOption } from './withCrustRules/withCrustRules';
import {
  Pizza,
  CrustOption,
  IngredientOptionWithPortions,
  PizzaSummary,
  PizzaIngredient,
  PizzaIngredientOption,
  PizzaUserSelections,
  PizzaOptions
} from '../dataTransformers/builderTypes';
import { RootState } from '@/rootStateTypes';
import { PIZZA } from '@/domain/constants';
import { IsCyo } from '@/menu/pizza/pizzaMenuTypes';

const domain = (state: RootState): Pizza => state.domain.pizza;

export const selectors = {
  // #Pizza selectors
  selectCartAddablePizza: compose(selectCartAddablePizza, domain),
  selectPizzaSummaryTitle: compose(selectPizzaSummaryTitle, domain),
  isCrustAndSizeSelected: compose(isCrustAndSizeSelected, domain),
  selectPizzaSummaryOption: compose(selectPizzaSummaryOption, domain),
  selectServings: compose(selectServings, domain),
  hasPizzaOptions: compose(hasPizzaOptions, domain),
  selectPizzaOptions: compose(selectPizzaOptions, domain),
  showTotalPrice: compose(showTotalPrice, domain),
  hideCrustStepCarat: compose(hideCrustStepCarat, domain),
  selectPizzaId: compose(selectPizzaId, domain),
  selectPizzaDisplayName: compose(selectPizzaDisplayName, domain),

  // #Size selectors
  isSplittable: compose(isSplittable, domain),
  selectPizzaSize: compose(selectPizzaSize, domain),
  selectPizzaSizeOption: compose(selectPizzaSizeOption, domain),
  selectPizzaSizeGlobalId: compose(selectPizzaSizeGlobalId, domain),
  selectSizeOptions: compose(selectSizesOptions, domain),
  selectPizzaSizeOptionByID,

  // #Crust selectors
  hasSameCrustOptions: compose(hasSameCrustOptions, domain),
  selectCrustOptions: compose(selectCrustOptions, domain),
  selectPizzaCrust: compose(selectPizzaCrust, domain),
  selectPizzaCrustOption: compose(selectPizzaCrustOption, domain),
  selectPizzaCrustGlobalId: compose(selectPizzaCrustGlobalId, domain),
  selectPizzaCrustOptionsBySizeId(
    state: RootState,
    sizeId: string | undefined
  ): CrustOption[] | undefined {
    return selectCrustOptions(domain(state), sizeId);
  },

  // #Sauce selectors
  selectPizzaSauce: compose(selectPizzaSauce, domain),
  selectSauceOptions: compose(selectSauceOptions, domain),
  selectPizzaSauceOption: compose(selectPizzaSauceOption, domain),
  selectRootSauceById(state: RootState, sauceId: string): IngredientOptionWithPortions | undefined {
    return selectRootSauceById(domain(state), sauceId);
  },

  // #Cheese selectors
  selectCheeseOptions: compose(selectCheeseOptions, domain),
  selectPizzaCheese: compose(selectPizzaCheese, domain),
  selectPizzaCheeseOption: compose(selectPizzaCheeseOption, domain),

  // #Finisher selectors
  selectDefaultFlavor: compose(selectDefaultFlavor, domain),
  selectFinisherOptions: compose(selectFinisherOptions, domain),
  selectPizzaFinisherOption: compose(selectPizzaFinisherOption, domain),
  selectPizzaFinisher: compose(selectPizzaFinisher, domain),

  // #Topping selectors
  isReachedMaxAllowedToppings: compose(isReachedMaxAllowedToppings, domain),
  isReachedToppingsLimit: compose(isReachedToppingsLimit, domain),
  isReachedPanCrustToppingsLimit: compose(isReachedPanCrustToppingsLimit, domain),
  isReachedBNYCrustToppingsLimit: compose(isReachedBNYCrustToppingsLimit, domain),
  numberOfSelectedToppings: compose(numberOfSelectedToppings, domain),
  selectRecipeDefaultToppingsCount: compose(selectRecipeDefaultToppingsCount, domain),
  selectPizzaMeatToppings: compose(selectPizzaMeatToppings, domain),
  selectPizzaVeggieToppings: compose(selectPizzaVeggieToppings, domain),
  selectMeatToppingOptions: compose(selectMeatToppingOptions, domain),
  selectVeggieToppingsOptions: compose(selectVeggieToppingsOptions, domain),

  // #Rule selectors
  selectPricingRules: compose(selectCrustPricingRules, domain),
  selectRestrictRules: compose(selectRestrictRulesBySizeAndCrust, domain)
};

const initialPizza: Pizza = {
  type: PIZZA,
  id: '',
  name: '',
  quantity: 1,
  price: null,
  splittable: null,
  recipeDefaultToppingsCount: 0,
  veggieToppings: [],
  meatToppings: [],
  finisher: null,
  cheese: null,
  sauce: null,
  crust: null,
  size: null,
  specialInstructions: '',
  isCYO: IsCyo.FALSE
};

const pizzaSlice = createSlice({
  name: 'pizza',
  initialState: initialPizza,
  reducers: {
    updatePizzaSpecialInstructions: (
      pizza: Pizza,
      action: PayloadAction<string>
    ) => {
      pizza.specialInstructions = action.payload;
    },
    updatePizzaSummary: (state, action: PayloadAction<PizzaSummary>) => {
      Object.assign(state, action.payload);
    },
    updatePizzaFinisher: (
      pizza: Pizza,
      action: PayloadAction<PizzaIngredient>
    ) => {
      pizza.finisher = action.payload;
    },
    updatePizzaCheese: (
      pizza: Pizza,
      action: PayloadAction<PizzaIngredient>
    ) => {
      pizza.cheese = action.payload;
    },
    updatePizzaSauce: (
      pizza: Pizza,
      action: PayloadAction<PizzaIngredient>
    ) => {
      pizza.sauce = action.payload;
    },
    updateSizeAndCrust: withCrustRules((
      pizza: Pizza,
      action: PayloadAction<PizzaCrustAndSizeOption>
    ) => {
      pizza.size = action.payload?.size;
      pizza.crust = action.payload?.crust;
    }),
    updatePizzaCrust: withCrustRules((
      pizza: Pizza,
      action: PayloadAction<CrustOption | null>
    ) => {
      pizza.crust = action.payload ? toIngredient(action.payload) : null;

      const sizeOption = selectSizeOptionForCrust(pizza.pizzaOptions, pizza.crust);
      pizza.size = sizeOption ? toIngredient(sizeOption, SelectedBy.SYSTEM) : pizza.size;
    }),
    updatePizzaSize: withCrustRules((
      pizza: Pizza,
      action: PayloadAction<PizzaIngredientOption | null>
    ) => {
      const hasSizeOption = action.payload && !action.payload.outOfStock;
      pizza.size = hasSizeOption ? toIngredient(action.payload || undefined) : null;

      const crustOption = selectCrustOptionForSize(
        pizza.pizzaOptions,
        pizza.size,
        pizza.crust || undefined
      );
      pizza.crust = (crustOption && !crustOption.outOfStock)
        ? toIngredient(crustOption, SelectedBy.SYSTEM)
        : null;
    }),
    updatePizzaMeatToppings: (
      pizza: Pizza,
      action: PayloadAction<PizzaIngredient[]>
    ) => {
      pizza.meatToppings = action.payload;
    },
    updatePizzaVeggieToppings: (
      pizza: Pizza,
      action: PayloadAction<PizzaIngredient[]>
    ) => {
      pizza.veggieToppings = action.payload;
    },
    clearPizza: () => initialPizza,
    updatePizzaWithUserSelections: (
      pizza: Pizza,
      action: PayloadAction<Partial<PizzaUserSelections>>
    ) => ({ ...pizza, ...action.payload }),
    updatePizzaOptions: withCrustRules((
      pizza: Pizza,
      action: PayloadAction<PizzaOptions>
    ) => {
      Object.assign(pizza, selectDefaults(action.payload));
      pizza.pizzaOptions = action.payload;
    })
  }
});

export const {
  updatePizzaCheese,
  updatePizzaCrust,
  updatePizzaFinisher,
  updatePizzaSauce,
  updatePizzaSize,
  updateSizeAndCrust,
  updatePizzaSummary,
  clearPizza,
  updatePizzaSpecialInstructions,
  updatePizzaMeatToppings,
  updatePizzaVeggieToppings,
  updatePizzaOptions,
  updatePizzaWithUserSelections
} = pizzaSlice.actions;

export default pizzaSlice.reducer;
