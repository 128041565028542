import { omit } from 'remeda';
import { selectPizzaSize, selectPizzaSizeOption } from './sizeSelectors';
import { selectPizzaCrust, selectPizzaCrustOption } from './crustSelectors';
import { extractGlobalId } from '../../identifiers';
import { AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';
import {
  Pizza,
  BuiltPizza,
  Item,
  PizzaOptions,
  PizzaSummaryOption
} from '../../dataTransformers/builderTypes';

const getCheeseName = (pizza: Pizza): string => (pizza?.cheese?.name?.includes('Cheese') ? pizza.cheese.name : `${pizza?.cheese?.name} Cheese`);

const selectCartAddablePizza = (pizza: Pizza): BuiltPizza => ({
  ...omit({
    ...pizza,
    ...(pizza?.cheese && {
      cheese: {
        ...pizza.cheese,
        name: pizza?.cheese?.name ? getCheeseName(pizza) : ''
      }
    })
  }, ['pizzaOptions', 'recipeDefaultToppingsCount', 'splittable', 'displayName']),
  name: pizza.displayName ?? pizza.name,
  kind: AddableCartItemKinds.BUILT_PIZZA
});

const isCrustAndSizeSelected = (
  pizza: Pizza
): boolean => !!(selectPizzaCrust(pizza) && selectPizzaSize(pizza));

// #Summary selectors
const selectPizzaSummaryOption = (
  pizza: Pizza
): PizzaSummaryOption | undefined => pizza.pizzaOptions?.summary;

// #Summary selectors
const selectPizzaSummaryTitle = (pizza: Pizza): Item['name'] => pizza.name;
const selectPizzaDisplayName = (pizza: Pizza): Item['displayName'] => pizza.displayName;
const showTotalPrice = (pizza: Pizza): boolean => !!pizza.pizzaOptions?.summary?.showTotalPrice;
const hideCrustStepCarat = (pizza: Pizza): boolean => {
  const crustStepCarat = pizza.pizzaOptions?.summary?.hideCrustStepCarat;
  return Boolean(crustStepCarat);
};
const selectPizzaId = (pizza: Pizza): Item['id'] => pizza.id;

// #Servings selectors
const selectServings = (pizza: Pizza): string | undefined => {
  const selectedCrust = selectPizzaCrustOption(pizza);
  const selectedSize = selectPizzaSizeOption(pizza);

  const selectedCrustNutrition = selectedCrust?.nutrition
    ?.find(({ qualifiers = [] }) => {
      const globalId = extractGlobalId(selectedSize);
      return globalId ? qualifiers.includes(globalId) : null;
    });

  return (
    selectedCrustNutrition
    && `${selectedCrustNutrition?.servings} ${selectedCrustNutrition?.unit}s`.toLowerCase()
  );
};

// #Pizza Options selectors
const selectPizzaOptions = (pizza: Pizza): PizzaOptions | undefined => pizza.pizzaOptions;
const hasPizzaOptions = (pizza: Pizza): boolean => Boolean(selectPizzaOptions(pizza));

export {
  selectCartAddablePizza,
  isCrustAndSizeSelected,
  selectPizzaSummaryOption,
  selectPizzaSummaryTitle,
  selectServings,
  showTotalPrice,
  hideCrustStepCarat,
  selectPizzaOptions,
  hasPizzaOptions,
  selectPizzaId,
  selectPizzaDisplayName
};
