import { ReactSDKClient, createInstance, enums } from '@optimizely/react-sdk';
import getConfig from 'next/config';

const config = getConfig()?.publicRuntimeConfig;
let optimizely: ReactSDKClient;

type Props = {
  datafile?: Record<string, unknown>;
  isBrowser?: boolean;
  allowInitialize?: boolean;
  userId?: string;
};

export const getOrInitializeOptimizely = ({
  datafile = {},
  isBrowser = true,
  allowInitialize = false,
  userId
}: Props = { datafile: {}, isBrowser: true, allowInitialize: false }): ReactSDKClient | null => {
  const { OPTIMIZELY_SDK_KEY } = config;
  if (!allowInitialize && !optimizely) return null;
  if (!datafile) return null;
  if (!isBrowser) {
    return createInstance({ datafile, datafileOptions: { autoUpdate: false } });
  }

  if (!optimizely) {
    optimizely = createInstance({
      datafile,
      sdkKey: OPTIMIZELY_SDK_KEY,
      datafileOptions: {
        autoUpdate: true,
        updateInterval: 300 * 1000,
        urlTemplate: 'https://cdn.optimizely.com/datafiles/%s.json'
      },
      logLevel: enums.LOG_LEVEL.ERROR
    });
    if (userId) optimizely.setUser({ id: userId });
  }

  return optimizely;
};
