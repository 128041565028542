import gql from 'graphql-tag';
import { DealMenu } from '../../../menu/deals/types';
import { DealMenuQuery, DealMenuQueryResult } from '../../types/DealMenu';
import AvailabilityFragment from '../fragments/availability';
import checkAvailability from '@/graphql/helpers/checkAvailability';
import { CheckAvailabilityVariables } from '@/graphql/hooks/variables/useCheckAvailabilityVariables';
import decodeEntities from '@/graphql/helpers/decodeEntities';
import { DealItem } from '@/graphql/types/Deal';
import { RecipeItem, MenuRecipe, DealSteps } from '@/menu/pizza/pizzaMenuTypes';
import isOptimizelyFeatureEnabled from '../../../../optimizely/utils/isOptimizelyFeatureEnabled';

// Checks if a recipe is out of stock
const isRecipeOutOfStock = (recipe: Pick<RecipeItem, 'outOfStock'>) => recipe.outOfStock;

// Checks if all recipes in a step are out of stock using the isRecipeOutOfStock function
const areAllRecipesOutOfStock = (step: DealSteps) => step.recipes.every(isRecipeOutOfStock);

// Filters out deals with all items out of stock
export const filterDealsWithAllItemsOutOfStock = (deal: MenuRecipe | DealItem): boolean => {
  // If there are no steps in the deal, consider it as not filtered out (return true)
  if (!deal.steps) {
    return true;
  }

  // Check if there's a step where all items are out of stock
  const hasStepWithAllItemsOutOfStock = deal.steps.some((step) => areAllRecipesOutOfStock(step));

  // Return the inverse of the result: true if no steps have all items out of stock, false otherwise
  return !hasStepWithAllItemsOutOfStock;
};

export const masterSubDealQuery = gql`
  query getDeal($storeID: String!, $itemID: ID!) {
    Homepage: store(storeID: $storeID) {
      menu {
        item(itemID: $itemID) {
          id
          name
          steps: modifiers {
            id
            name
            recipes: modifiers {
              id
              name
              outOfStock
            }
          }
        }
      }
    }
  }
`;

const rawQuery = gql`
  query getDealsMenu($storeID: String!, $occasion: OrderMethod!) {
    Homepage: store(storeID: $storeID) {
      menu {
        deals: items(itemType: "DISCOUNT", occasion: $occasion) {
          id
          name
          imageURL
          type
          price
          displayPrice
          outOfStock
          sodiumWarning
          legalText
          priority
          hidden
          allergenDisclaimer
          productDescription: description
          availability {
            ...AvailabilityFragment
          }
          steps: modifiers {
            recipes: modifiers {
              outOfStock
            }
          }
        }
        masterDeals: items(itemType: "MASTER_DISCOUNT") {
          id
          name
          imageURL
          type
          price
          outOfStock
          sodiumWarning
          priority
          hidden
          productDescription: description
          availability {
            ...AvailabilityFragment
          }
        }
      }
    }
  }
  ${AvailabilityFragment}
`;

const parser = (
  data: DealMenuQueryResult,
  { occasion, storeTimeZone }: CheckAvailabilityVariables
): DealMenu => {
  if (!data) return { deals: [] };
  const filterOosDealsEnabled = isOptimizelyFeatureEnabled('fr-web896-filter_oos_deals_enabled');

  const regularDeals = data.Homepage.menu.deals.map((item) => ({
    ...checkAvailability(item, occasion, storeTimeZone),
    name: decodeEntities(item.name),
    productDescription: decodeEntities(item.productDescription)
  }))
    .filter(({ available }) => available)
    .filter(({ name }) => name)
    .filter((deal) => {
      if (!filterOosDealsEnabled) return true; // If flag is disabled skip filter
      return filterDealsWithAllItemsOutOfStock(deal);
    });

  const masterDeals = data.Homepage.menu.masterDeals.map((masterDeal) => ({
    ...masterDeal,
    name: decodeEntities(masterDeal.name),
    productDescription: decodeEntities(masterDeal.productDescription)
  }));

  const deals = [
    ...regularDeals,
    ...masterDeals
  ].filter(({ hidden }) => !hidden);

  return {
    deals
  };
};

const query: DealMenuQuery = { query: rawQuery, parser };
export default query;
