import { CartItem, DiningOccasion } from '@pizza-hut-us-development/client-core';
import { CartItem as LegacyCartItem } from '@/domain/cart/types';
import { AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';
import formattedPrice from '@/common/formattedPrice';
import { Occasion } from '@/localization/constants';
import { CityStateAndZipCodeArgs } from '@/clientCore/cart/types';

export const cartItemToLegacy = (item: CartItem): LegacyCartItem => ({
  kind: AddableCartItemKinds.CART_ITEM,
  id: item.id,
  name: item.name,
  type: item.type,
  displayablePrice: formattedPrice(item.price),
  quantity: item.quantity ?? 1,
  modifiers: item.modifiers ?? [],
  description: item.description ?? '',
  specialInstructions: item.specialInstructions,
  cartItemId: item.cartItemId
});

export const cartItemsToLegacy = (items: CartItem[]): LegacyCartItem[] => items.map((item) => cartItemToLegacy(item)) ?? [];

export function getAlphanumericValue(value: string, isYumEcomm?: boolean, useYumCouponCodeAllowHyphenDecision?: boolean): string {
  if (isYumEcomm && useYumCouponCodeAllowHyphenDecision) {
    return value.replace(/[^a-zA-Z0-9-]/ig, '');
  }
  return value.replace(/[^a-zA-Z0-9]/ig, '');
}

export const occasionLegacyToCC = (occasion: Occasion): DiningOccasion => {
  if (occasion === Occasion.DELIVERY) {
    return DiningOccasion.DELIVERY;
  }
  return DiningOccasion.CARRYOUT;
};

export const getCityStateAndZipcode = (
  { city, state, postalCode }: CityStateAndZipCodeArgs
): string => `${city}, ${state} ${postalCode}`;

export const calculateCartQuantity = (items: CartItem[]) => items.reduce(
  (total: number, currentItem: CartItem): number => {
    if (currentItem.quantity) {
      return total + currentItem.quantity;
    }
    return total;
  },
  0
) ?? 0;
