import { CartModifier } from '@pizza-hut-us-development/client-core';
import Portion from '@/common/Portion';
import SelectedBy from '../../../common/SelectedBy';
import {
  PizzaIngredientOption,
  PortionChoice,
  PlacementChoice,
  SelectedByEntity,
  PizzaIngredient
} from './builderTypes';

export default function toIngredient(
  option?: PizzaIngredientOption | CartModifier,
  selectedBy: SelectedByEntity = SelectedBy.USER,
  usePortionAsId = false
): PizzaIngredient | null {
  if (option === undefined || option === null || selectedBy === null) {
    return null;
  }

  let id;
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    id = usePortionAsId ? option.portions.find(({ portion }) => portion === Portion.REGULAR).id : option.id;
  } catch (error) {
    id = option.id;
  }

  const ingredient: PizzaIngredient = ('isPanCrust' in option) ? {
    id,
    name: option.name,
    type: option.type,
    portion: option?.portion ?? Portion.REGULAR,
    placement: option.placement,
    isPanCrust: option?.isPanCrust,
    slotCode: option.slotCode,
    weightCode: option.weightCode,
    variantCode: option.variantCode,
    selectedBy
  } : {
    id,
    name: option.name,
    type: option.type,
    portion: option?.portion as PortionChoice ?? Portion.REGULAR,
    placement: option.placement,
    slotCode: option.slotCode,
    weightCode: option.weightCode,
    variantCode: option.variantCode,
    selectedBy
  };

  // type checking, to make sure that no extra fields exist on "ingredient"
  const checkType = <T>(thing: Exact<T, PizzaIngredient>): PizzaIngredient => thing;

  return checkType(ingredient);
}

const getPizzaIngredientWeightCode = (portion: PortionChoice, placement: PlacementChoice, option :PizzaIngredientOption): string => {
  if (!option?.weights) return '';

  return option.weights.find((weight) => {
    const lcWeight = weight.toLowerCase();
    return lcWeight.includes(portion) && lcWeight.includes(placement);
  }) || '';
};

export function toIngredientWithPortionAndPlacement(
  option: PizzaIngredientOption,
  portion: PortionChoice,
  placement: PlacementChoice,
  selectedBy: SelectedByEntity = SelectedBy.USER
): PizzaIngredient {
  const ingredient: PizzaIngredient = {
    id: option.id,
    name: option.name,
    type: option.type,
    isPanCrust: option.isPanCrust,
    slotCode: option.slotCode,
    weightCode: getPizzaIngredientWeightCode(portion, placement, option),
    variantCode: option.variantCode,
    portion,
    placement,
    selectedBy
  };

  // type checking, to make sure that no extra fields exist on "ingredient"
  const checkType = <T>(thing: Exact<T, PizzaIngredient>): PizzaIngredient => thing;

  return checkType(ingredient);
}
