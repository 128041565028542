import { createStyles, makeStyles, Theme } from '@material-ui/core';
import colors from '@/common/colors';
import fontStyles from '@/common/fontStyles';
import { smallMobileStartBreakpoint } from '@/materialUi/theme';
import { ColorOption } from '@/clientCore/components/CtaButton/types';

const getFontStyleKey = (
  color: ColorOption,
  label: string
): keyof typeof fontStyles => `${color}${label}` as keyof typeof fontStyles;

export const useStyles = (fullWidth: boolean, color: ColorOption) => makeStyles((theme: Theme) => createStyles({
  label: {
    fontFamily: 'open_sans_semi'
  },
  containedBtn: {
    borderRadius: '8px'
  },
  lowercaseBtn: {
    textTransform: 'inherit'
  },
  containedBtnPrimary: {
    backgroundColor: colors.red
  },
  additionalStyles: {
    '& .MuiCircularProgress-colorPrimary': {
      color: colors.red
    },
    border: '1px solid red !important'
  },
  containedBtnSecondary: {
    borderRadius: 8,
    backgroundColor: colors.white,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  containedBtnTertiary: {
    backgroundColor: colors.black,
    '&:hover, &:active': {
      backgroundColor: colors.black
    }
  },
  small: {
    ...fontStyles[getFontStyleKey(color, 'SmallCtaLabel')],
    width: 128,
    height: 32
  },
  medium: {
    ...fontStyles[getFontStyleKey(color, 'CtaLabel')],
    width: 192,
    height: 40
  },
  large: {
    ...fontStyles[getFontStyleKey(color, 'CtaLabel')],
    width: fullWidth ? '100%' : 280,
    height: 40,
    paddingRight: '1.4rem',
    paddingLeft: '1.4rem',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      width: '100%'
    }
  },
  loadingPrimary: {
    backgroundColor: `${colors.red} !important`
  },
  loadingSecondary: {
    backgroundColor: `${colors.white} !important`
  },
  loadingTertiary: {
    backgroundColor: `${colors.black} !important`
  }
}));
