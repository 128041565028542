import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import { includeCredentials, phdapiEdit } from '@/api/phdApiClient/base';
import createClientMethod from '@/api/createClientMethod';
import { Favorite } from '@/account/orders/favorites/Favorites.types';
import AccountManagementEndpoints from '@/api/phdApiClient/accountManagement/endpoints';

const rankIds = (favToMakePrimary: Favorite, favorites: Favorite[]): string[] => {
  const favoriteIds = favorites.map(({ id }) => id);
  const rankedFavorites = new Set(favToMakePrimary.id);

  favoriteIds.forEach((id) => (rankedFavorites.add(id)));

  return Array.from(rankedFavorites);
};

type RankId = { favorite_id: string };

interface PhdAPIRankFavoritesBody {
  rank: RankId[];
}

const apiRequestBody = (favoriteIds: string[]): PhdAPIRankFavoritesBody => ({
  rank: favoriteIds.map((id) => ({ favorite_id: id }))
});

const fetch = async (
  favorite: Favorite, favorites: Favorite[]
) => phdapiEdit(
  getUrl(`${AccountManagementEndpoints.RANK_FAVORITES}`),
  includeCredentials,
  JSON.stringify(apiRequestBody(rankIds(favorite, favorites)))
);

const transformer = async (response: Response): Promise<ClientResult<void>> => ({
  error: !response.ok
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const rankFavorites = createClientMethod(fetch, transformer);
