const colors = {
  blueLinkHover: '#113E7B',
  gray900: '#131313',
  gray601: '#717170',
  gray600: '#717171',
  gray400: '#CFCECC',
  gray200: '#F4F4F4',
  gray100: '#F7F7F7',
  blue: '#266DCC',
  red: '#E21216',
  redPressed: '#AF0003',
  redErrorLabel: '#e52a33',
  white: '#FFFFFF',
  black: '#000',
  green: '#238618',
  darkGray: '#2E2E2D',
  orange: '#E8801E',
  gold: '#E8D088;'
};

export default colors;
