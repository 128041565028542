import DealType from '../../DealTypes';
import {
  ChildDealStep, Deal, DealData, DealStep, DealSummary
} from '../dealTypes';
import { selectCurrentStep, selectRecipesForCurrentStep } from './userSelectionsSelectors';

const selectDeal = (deal: Deal): Deal | null => deal;

const selectDealType = (deal: DealData): DealType | null => deal.type;

const selectDealSteps = (deal: DealData): DealStep[] | ChildDealStep[] => deal.steps;

const selectDealSummary = (deal: DealData): DealSummary => ({
  id: deal.id,
  name: deal.name,
  image: deal.image
});

const selectDealStepNameById = (
  deal: DealData,
  id: DealStep['id']
): DealStep['name'] | null => deal.steps?.find((step) => step.id === id)?.name || null;

const selectCustomizationsIncluded = (deal: Deal): number | undefined => {
  const dealRecipes = selectRecipesForCurrentStep(deal);
  const currentStep = selectCurrentStep(deal);
  const selectedRecipeId = currentStep?.recipeId;
  const selectedRecipe = dealRecipes?.find(
    (recipe) => recipe.id === selectedRecipeId
  );

  return selectedRecipe?.customizationsIncluded;
};

export {
  selectDeal,
  selectDealType,
  selectDealSummary,
  selectDealSteps,
  selectCustomizationsIncluded,
  selectDealStepNameById
};
