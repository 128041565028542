import {
  isPizzaProduct,
  DisplayableMenuItem
} from '@/domain/product/types';
import { ProductByIdResult } from '@/graphql/types/Product';
import {
  priceExistsFor,
  transformItemToDisplayableProduct
} from '@/graphql/helpers/categoryProductsToDisplayableProductsTransformer';

const transformer = (data: ProductByIdResult): DisplayableMenuItem | null => {
  const itemData = data?.store?.menu?.item;

  if (itemData?.name) {
    const priceExists = isPizzaProduct(itemData)
      ? !!itemData.price : priceExistsFor(itemData);
    if (priceExists) return transformItemToDisplayableProduct(itemData);
  }

  return null;
};

export default transformer;
