import { Replacer } from '@/salesExperience/replacers/types';
import logger from '@/common/logger';
import telemetry from '@/telemetry';

const dealsPage = (url: URL) => url.searchParams.get('menu') === 'deals';

class DealsReplacerError extends Error {
  name = 'DealsReplacerError';

  message = 'Unable to replace deal URL, because it has no ID';
}

const deals: Replacer = {
  match(url) {
    if (url.pathname !== '/link.php') {
      return false;
    }
    return dealsPage(url) || url.searchParams.has('c');
  },

  replace(url) {
    if (dealsPage(url)) {
      url.searchParams.delete('menu');
      return `${url.prefix}deals${url.search}`;
    }
    const id = url.searchParams.get('c');
    if (!id) {
      const error = new DealsReplacerError();
      logger.withoutTelemetry.error(String(error), url.toString());
      telemetry.addNoticeError(error, { url: url.toString() });
      return url.originalUrl;
    }
    url.searchParams.delete('c');
    url.searchParams.set('id', id);
    return `${url.prefix}deals${url.search}`;
  }
};

export { deals };
