import React, { useEffect, useState } from 'react';
import {
  initializeLocalization,
  localizationInitialized,
  rememberOccasion,
  selectStore,
  setCurrentlyLocalizedAddress
} from '@/localization/actions';
import {
  CoreConfigActions,
  SalesChannel,
} from '@pizza-hut-us-development/client-core';
import { NATIONAL, OccasionString } from '@/localization/constants';
import { rememberCart, setAuthState, setLogInStatusToFalse } from '@/header/actions';
import isClientSide from '@/common/isClientSide';
import { overrideCmsEnv, overrideGqlVersion, setGqlTimestamp } from './actions';
import telemetry from '@/telemetry';
import { init as initAuth } from '../auth';
import { AuthState } from '@/auth/userStates';
import { ReduxApplicationProps } from './types';
import { actions as salesExperienceActions } from '@/salesExperience/salesExperience.slice';
import * as getConfig from 'next/config';
import { setLastActiveTimestamp } from '@/auth/actions';
import debounce from 'just-debounce';
import { Provider } from 'react-redux';
import createStore from '@/store';

interface PopulateStoreProps extends Partial<ReduxApplicationProps> {
  store?: any;
}

const { setStagingEnv, setProductionEnv, setAuthChannel, setSalesChannel, setXServerEnv } = CoreConfigActions;

const envs = {
    production: setProductionEnv(),
    staging: setStagingEnv(),
};

export function populateStore(props: PopulateStoreProps) {
  const {
    cmsEnvId,
    gqlVersion,
    timestamp,
    localizationToken,
    sessionRememberCart,
    occasion,
    userAuthentication,
    storeNumber = NATIONAL,
    localizedTokenAddress,
    expirationDate,
    onStoreCreation = () => { },
    issuedDate,
    localizationTokenSource,
    salesExperience,
    store = createStore(),
    yumAuthRefreshData,
  } = props;
  const clientSide = isClientSide();
  const isLocalized = Boolean(localizationToken);
  const { publicRuntimeConfig } = getConfig.default();
  const coreProviderEnv =
    publicRuntimeConfig.ENVIRONMENT_NAME === 'prod' ? 'production' : 'staging';

  store.dispatch(envs[coreProviderEnv]);
  store.dispatch(setAuthChannel('WEB2'));
  store.dispatch(setSalesChannel(SalesChannel.WEB));
  store.dispatch(CoreConfigActions.setIsYumEcomm(true));

  if (salesExperience)
    store.dispatch(salesExperienceActions.experience(salesExperience));
  telemetry.addCustomAttribute('storeNumber', storeNumber);
  telemetry.addCustomAttribute('salesExperience', String(salesExperience));

  // Note: initAuth() can trigger an asynchronous refresh for expired tokens, so
  // proceeding code needs to handle expired tokens
  initAuth(
    store,
    isLocalized,
    localizationToken,
    userAuthentication,
    yumAuthRefreshData
  );

  if (cmsEnvId) store.dispatch(overrideCmsEnv(cmsEnvId));
  if (gqlVersion) store.dispatch(overrideGqlVersion(gqlVersion));
  if (timestamp) store.dispatch(setGqlTimestamp(timestamp));

  if (localizationToken)
    store.dispatch(rememberCart(Boolean(sessionRememberCart)));
  if (occasion) {
    store.dispatch(rememberOccasion(occasion));
    telemetry.addCustomAttribute('occasion', OccasionString[occasion]);
  }

  if (
    clientSide &&
    (userAuthentication?.authState === AuthState.GUEST ||
      userAuthentication?.authState === AuthState.EXPIRED)
  ) {
    store.dispatch(setLogInStatusToFalse());
  }
  if (clientSide && userAuthentication) {
    store.dispatch(setAuthState(userAuthentication.authState));
  }

  store.dispatch(
    selectStore(
      storeNumber,
      occasion,
      store.getState().domain.localization.localizedStoreDetails
    )
  );

  if (localizedTokenAddress) {
    store.dispatch(setCurrentlyLocalizedAddress(localizedTokenAddress));
  }
  if (clientSide && localizationToken) {
    const baseLocalizationIAT = publicRuntimeConfig.BASE_LOCALIZATION_IAT;
    store.dispatch(
      initializeLocalization(
        occasion,
        storeNumber,
        expirationDate,
        issuedDate,
        baseLocalizationIAT,
        localizationToken,
        localizationTokenSource
      )
    );
  } else if (clientSide) {
    store.dispatch(localizationInitialized());
  }

  if (clientSide) {
    store.dispatch(setXServerEnv(localStorage.getItem('cc-x-server-env') || publicRuntimeConfig.PHD_API_SERVER_ENV))
  }

  onStoreCreation(store);

  return store;
}

export default function ReduxApplication(props: ReduxApplicationProps) {
  const { children, ...populateData } = props;
  const [store] = useState(() => populateStore({ ...populateData }));

  useEffect(() => {
    const debouncedActivityEventListener = debounce(() => store.dispatch(setLastActiveTimestamp(new Date().getTime())), 500);
    const events = ['scroll', 'keydown', 'keypress', 'keyup', 'click', 'dblclick'];

    function createUserActivityEventListeners() {
      events.forEach((event) => document.addEventListener(event, debouncedActivityEventListener));
    }

    function removeUserActivityEventListeners() {
      events.forEach((event) => document.removeEventListener(event, debouncedActivityEventListener));
    }

    if (isClientSide()) {
      createUserActivityEventListeners();
      return () => {
        removeUserActivityEventListeners()
      };
    }
    return undefined;
  }, []);

  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
}