export default class ProductId {
  public storeSpecificId: string;

  public globalId: string;

  private parse = (id: string): string => id?.substring(id?.lastIndexOf('/') + 1);

  constructor(storeSpecificId: string) {
    this.storeSpecificId = storeSpecificId;
    this.globalId = this.parse(storeSpecificId);
  }
}
