import { CardType } from '@/account/profile/paymentMethods/PaymentMethods.types';

const patterns: Record<string, RegExp> = {
  electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
  maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
  dankort: /^(5019)\d+$/,
  interpayment: /^(636)\d+$/,
  unionpay: /^(62|88)\d+$/,
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  mc: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]*$/,
  amex: /^3[47][0-9]{13}$/,
  diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  disc: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
  jcb: /^(?:2131|1800|35\d{3})\d{11}$/
};
export const getCardType = (cardNumber: string): string => {
  let type = '';
  let wasTypeFound = false;

  Object.keys(patterns).forEach((pattern) => {
    if (patterns[pattern].test(cardNumber) && !wasTypeFound) {
      wasTypeFound = true;
      type = pattern;
    }
  });
  return type.toUpperCase();
};

const cardTypeShortLookup: [CardType, RegExp][] = [
  [CardType.VI, patterns.visa],
  [CardType.MC, patterns.mc],
  [CardType.DI, patterns.disc],
  [CardType.AM, patterns.amex]
];
export const getCardTypeShort = (cardNumber: `${number}`): string => {
  const match = cardTypeShortLookup.find(([_type, pattern]) => pattern.test(cardNumber));
  if (!match) {
    return '';
  }
  const [type] = match;
  return type;
};
