import {
  CreditCardSavedPayment,
  CustomerAddress, CustomerResponse,
  Promotion,
  SavedPayment
} from '@pizza-hut-us-development/client-core';
import { GiftCardSavedPayment } from '@pizza-hut-us-development/client-core/lib/esm/types/customer/customerTypes';
import { Profile, ProfileAddress, Promotions } from '@/domain/Profile';
import { CreditCardInfo, GiftCardInfo, PaymentType } from '@/domain/payments/types';

const transformCustomerAddresses = (customerAddresses: CustomerAddress[]): ProfileAddress[] => customerAddresses.map((address): ProfileAddress => ({
  addressId: address?.customerAddressId ?? address?.addressId ?? '',
  address1: address.address1,
  address2: address.address2,
  city: address.city,
  isDefault: address.isDefault ?? false,
  name: address.name,
  postalCode: address.postalCode,
  state: address.state,
  locationType: address.type,
  deliveryInstructions: address.deliveryInstructions ?? '',
  phone: address.phone ?? '',
  company: address.company
}));

const transformCreditCard = (
  payment: SavedPayment
):(CreditCardInfo | GiftCardInfo
  ) => ({
  isDefault: payment.isDefault ?? false,
  paymentId: payment.paymentId,
  name: '',
  paymentType: payment.type as PaymentType.creditcard,
  cardInfo: {
    cardType: (payment as CreditCardSavedPayment).metadata.type,
    expiration: (payment as CreditCardSavedPayment).metadata.expiration,
    lastFour: (payment as CreditCardSavedPayment).metadata.lastFour || '',
    postalCode: (payment as CreditCardSavedPayment).metadata.postalCode
  }
});

const transformGiftCard = (
  payment: SavedPayment
): GiftCardInfo => ({
  isDefault: payment.isDefault ?? false,
  paymentId: payment.paymentId,
  paymentType: payment.type as PaymentType.giftcard | PaymentType.tpgc,
  cardInfo: {
    number: (payment as GiftCardSavedPayment).metadata.number || '',
    balance: (payment as GiftCardSavedPayment).metadata.balance || 0
  }
});

const transformPromotions = (
  res: Promotion[]
): Promotions[] => res?.map((promotion) => ({
  promotionId: promotion.promotionId,
  birthDate: new Date(promotion.birthDate).toJSON(),
  contactMethods: promotion.contactMethods.map((contact) => ({
    type: contact.type,
    value: contact.value
  }))
}));

const transformPayments = (
  res: SavedPayment[]
): (CreditCardInfo | GiftCardInfo
  )[] => res?.map((payment) => (
  payment?.type === 'creditcard' ? transformCreditCard(payment) : transformGiftCard(payment)
));

const getPhoneNumber = (phone: string): string => {
  if (phone?.startsWith('+1')) return phone.substring(2).trim();

  return phone;
};

export const transformCustomerInfo = (customerDetails: CustomerResponse): Profile => {
  const {
    firstName = '',
    lastName,
    email,
    phone,
    customerAddresses,
    payments,
    loyaltyToken = '',
    promotions
  } = customerDetails;
  return {
    firstName,
    lastName,
    email,
    phone: getPhoneNumber(phone),
    loyaltyToken,
    customerAddresses: transformCustomerAddresses(customerAddresses),
    payments: payments ? transformPayments(payments) : [],
    promotions: promotions ? transformPromotions(promotions) : []
  };
};

export const transformPromos = (data: CustomerResponse) => ({
  hutRewardsMember: !!data?.programs?.find(
    (program) => program.programId === '1'
  ),
  birthdaySent: !!data?.promotions?.find(
    (promotion) => promotion.birthDate
  ),
  hutLoversMarketingOptIn: !!data?.promotions?.find(
    (promotion) => promotion?.contactMethods.find((contact) => contact.type === 'EMAIL')
  ),
  textOptIn: !!data?.promotions?.find(
    (promotion) => promotion?.contactMethods.find((contact) => contact.type === 'TEXT')
  )
});
