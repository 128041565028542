// eslint-disable-next-line no-restricted-imports
import 'isomorphic-unfetch';
import telemetry from '../../telemetry';
import ConversationIDStore from '../../telemetry/conversationIdStore';
import logger from '../logger';

export enum ApiResponses {
  FAILED = 'API call failed',
  SUCCESS = 'API call returned successfully',
  FAILED_WITH_CODE = 'API call returned with non-2xx status'
}

const loggingFetch = (
  requestUrl: RequestInfo,
  options: RequestInit = {}
): Promise<Response> => {
  const requestMethod = options?.method || 'GET';
  const requestHeaders = options?.headers || {};
  const conversationID = ConversationIDStore.get();

  const requestOptions = {
    ...options,
    headers: {
      ...requestHeaders,
      conversationID
    }
  };

  const telemetryAttributes = {
    conversationID,
    requestHeaders: JSON.stringify(requestHeaders),
    requestMethod,
    requestUrl
  };

  return fetch(requestUrl, requestOptions)
    .then(async (response) => {
      let body;
      try {
        body = await response.text();
      } catch (e) {
        // avoid TypeError: Failed to construct 'Response': Response with null body
      }
      const {
        status, headers, url, statusText
      } = response;

      if (status > 299) {
        logger.withoutTelemetry.error(ApiResponses.FAILED_WITH_CODE, {
          url,
          status,
          body,
          method: requestMethod
        });
        telemetry.addNoticeError(new Error(ApiResponses.FAILED), {
          ...telemetryAttributes,
          responseStatus: status,
          responseHeaders: headers
        });
      } else {
        logger.withoutTelemetry.debug(ApiResponses.SUCCESS, {
          url,
          status,
          method: requestMethod
        });

        telemetry.addCustomEvent(ApiResponses.SUCCESS, telemetryAttributes);
      }

      return new Response(body || null, {
        headers,
        status,
        statusText
      });
    })
    .catch((reason) => {
      telemetry.addNoticeError(
        new Error(ApiResponses.FAILED),
        telemetryAttributes
      );

      logger.withoutTelemetry.error(ApiResponses.FAILED, {
        reason,
        request: requestUrl,
        options
      });

      throw reason;
    });
};

export default loggingFetch;
