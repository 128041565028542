const mockAccountService: AccountService = {
  getUserSavedAddresses: mockResolvedValue([{
    address: '42 Wallaby Way',
    address_two: '',
    index: 1,
    state: 'NY',
    zip: '00001',
    city: 'Sydney',
    locationType: 'House'
  }])
};

function mockResolvedValue<T>(value: T): () => Promise<T> {
  if (typeof (jest) === 'undefined') {
    // When using this mock in our dev server, jest doesn't exist.
    // Handroll a mock instead.
    return () => new Promise((resolve) => resolve(value));
  }

  return jest.fn().mockResolvedValue(value);
}
export default mockAccountService;
