import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styles from './CartLocalization.styles';
import LinkButton from '@/common/LinkButton';
import { useCartLocalization } from './hooks/useCartLocalization';
import { CTA_CHANGE } from './constants';
import { CartLocalizationProps } from './types';

const CartLocalization = ({
  shouldDisplayCityStateZipcode,
  shouldDisplayChangeLocalizationButton = true
}: CartLocalizationProps): JSX.Element => {
  const classes = styles();
  const [data, handlers] = useCartLocalization();

  const {
    displayableAddress, displayableOccasion, displayablePromiseTime, addressLine2, cityStateAndZipcode, promiseTimeClasses
  } = data;

  const {
    handleChangeLocalizedStore
  } = handlers;

  return (
    <Grid data-testid="localization-promise-time" container justifyContent="space-between" alignItems="center">
      <Grid container justifyContent="space-between" alignItems="center" className={classes.container}>
        <Grid container justifyContent="space-between" direction="column">
          <Grid item>
            <Typography variant="h4" className={classes.title}>
              {displayableOccasion}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{displayableAddress}</Typography>
            {addressLine2 && <Typography>{addressLine2}</Typography>}
            {shouldDisplayCityStateZipcode && <Typography>{cityStateAndZipcode}</Typography>}
          </Grid>
          <Grid item>
            <Typography component="span" className={classes[promiseTimeClasses]} variant="h4">
              {displayablePromiseTime}
            </Typography>
          </Grid>
        </Grid>
        {shouldDisplayChangeLocalizationButton && (
          <div className={classes.changeButton}>
            <LinkButton testId="cart-rail-change-button" onClick={handleChangeLocalizedStore}>
              {CTA_CHANGE}
            </LinkButton>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default CartLocalization;
