
const setCookie = (name: string, value: string, days: number) => {
    let expires = '';
    let date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));
    expires = '; expires=' + date.toUTCString();
    document.cookie = name + '=' + (value || '')  + expires + '; path=/';
};

interface LocalizedCookieAttributes {
    occasion: string,
    storeNumber: string
  };
  
export const setlocalizedCustomerCookieAttribute = (attribute: LocalizedCookieAttributes) => {
    Object.entries(attribute).forEach(([key, value]) => {
        setCookie(key, value, 30);
    });
};
