import { favoriteStub } from '@/api/phdApiClient/__mocks__/favorites/getFavorites.stub';
import { Favorite } from '@/account/orders/favorites/Favorites.types';

const getFavorites = typeof (jest) === 'undefined' ? new Promise(() => {}) : jest.fn();

const returnNFavorites = (n: number):
Omit<Required<ClientResult<Favorite[]>>, 'errorCode' | 'errorMessage'> => {
  const mockGetFavorites = getFavorites as jest.Mock;
  const value = {
    error: false,
    result: Array(n).fill({}).map((_, index) => {
      if (index !== 0) {
        return { ...favoriteStub, isPrimary: false, id: `${index}` };
      }
      return { ...favoriteStub, id: `${index}` };
    })
  };
  mockGetFavorites.mockResolvedValue(value);
  return value;
};

const returnError = (): ClientResult<undefined> => {
  const mockGetFavorites = getFavorites as jest.Mock;

  const value = {
    error: true,
    result: undefined
  };

  mockGetFavorites.mockResolvedValue(value);

  return value;
};

export default getFavorites;
export {
  returnNFavorites,
  returnError
};
