import { WEB1, WEB2 } from '@/salesExperience/constants';

export type Experience = typeof WEB1 | typeof WEB2;

export type UrlTransformer = {
  (uri?: string): string | undefined;
};

export type UseSalesExperience = {
  (): {
    linkForSalesExperience: UrlTransformer;
  };
};

export const isSalesExperience = (
  experience: string
): experience is Experience => [WEB1, WEB2].includes(experience);
