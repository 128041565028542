import React, { Dispatch, SetStateAction } from 'react';
import { Cart } from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import Checkout7DLWarning from '@/clientCore/cart/components/CartRail/components/Checkout7DLWarning';
import EditCart from '@/clientCore/cart/components/CartRail/components/EditCart';
import CartInfoAndWarningView from '@/clientCore/cart/components/CartRail/components/CartInfoAndWarningView';
import CartLocalization from '@/clientCore/cart/components/CartLocalization/CartLocalization';
import CartChangedAlert from '@/clientCore/cart/components/CartRail/components/CartChangedAlert';
import {
  MaxOrderLimitAlert
} from '@/clientCore/cart/components/CartRail/components/MaxOrderLimitAlert/MaxOrderLimitAlert';
import Inline7DLWarning from '@/clientCore/cart/components/CartRail/components/Inline7DLWarning';
import EmptyCart from '@/clientCore/cart/components/CartRail/components/EmptyCart';
import CartContent from '@/clientCore/cart/components/CartRail/components/CartContent';
import CartCheckout from '@/clientCore/cart/components/CartRail/components/CartCheckout';
import { CartAlert } from '@/domain/cart/types';
import { CartRailStyles } from '@/clientCore/cart/components/CartRail/CartRail.styles';
import CartOOSAlert from '@/clientCore/cart/components/CartRail/components/CartOOSAlert/CartOOSAlert';
import { OOSAlertType } from '@/clientCore/redux/rail/CartRailSlice';

type CartRailBodyProps = {
  cart: Cart | undefined;
  isEmptyCart: boolean;
  isLocalized: boolean;
  showInfoAndWarningView: boolean;
  alertMessageText: string;
  showEditCart: boolean;
  show7DLInlineWarning: boolean;
  show7DLCheckoutWarning: boolean;
  cartChangedAlert: CartAlert | null;
  setShow7DLCheckoutWarning: Dispatch<SetStateAction<boolean>>;
  oosAlertItemList: OOSAlertType[];
};

const CartRailBody = ({
  cart,
  isEmptyCart,
  isLocalized,
  showInfoAndWarningView,
  alertMessageText,
  showEditCart,
  show7DLInlineWarning,
  show7DLCheckoutWarning,
  cartChangedAlert,
  setShow7DLCheckoutWarning,
  oosAlertItemList
}: CartRailBodyProps) => {
  const railClasses = CartRailStyles();
  const [{ enabled: displayOOSAlertEnabled }] = useDecision('fr-web-4041-display-error-for-oos-product-cart-view');

  if (show7DLCheckoutWarning) {
    return (
      <Checkout7DLWarning
        items={cart?.items ?? []}
        setShow7DLCheckoutWarning={setShow7DLCheckoutWarning}
      />
    );
  }

  // if (shouldShowCartItemEditBuilderError) return <CartItemEditBuilderError />;

  if (showEditCart) {
    return <EditCart />;
  }

  // I want to use this for all message views making it redux driven.
  if (showInfoAndWarningView) return <CartInfoAndWarningView />;

  return (
    <>
      {isLocalized && (
        <div className={railClasses.localizationPromiseTime}>
          <CartLocalization />
        </div>
      )}
      {cartChangedAlert?.displayAlert && (
        <CartChangedAlert cartChangedAlert={cartChangedAlert} />
      )}
      {displayOOSAlertEnabled && oosAlertItemList?.map((item) => (
        <CartOOSAlert ossAlertItem={item} />
      ))}

      {alertMessageText && (
        <MaxOrderLimitAlert>{alertMessageText}</MaxOrderLimitAlert>
      )}
      {show7DLInlineWarning && <Inline7DLWarning />}
      {isEmptyCart && <EmptyCart isLocalized={Boolean(isLocalized)} />}
      {isLocalized && cart?.items?.length ? (
        <>
          <CartContent />
          {!isEmptyCart && (
            <CartCheckout
              setShow7DLCheckoutWarning={setShow7DLCheckoutWarning}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default CartRailBody;
