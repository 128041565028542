import { isPizzaProduct } from '@/domain/product/types';
import { ProductsByCategoryResult } from '../../types/Category';
import { LocalizedMenuPageSections } from '@/common/components/LocalizedProductTile/LocalizedProductTile.types';
import {
  priceExistsFor,
  transformCategoryProductToDisplayableProduct
} from '@/graphql/helpers/categoryProductsToDisplayableProductsTransformer';
import checkAvailability from '../../helpers/checkAvailability';
import { CheckAvailabilityVariables } from '@/graphql/hooks/variables/useCheckAvailabilityVariables';
import filterExists from '@/graphql/helpers/filterExists';
import decodeEntities from '@/graphql/helpers/decodeEntities';

const transformer = (
  data: ProductsByCategoryResult,
  { occasion, storeTimeZone }: CheckAvailabilityVariables
): LocalizedMenuPageSections => {
  const productsData = data?.Homepage?.category?.products ?? [];
  const productsForMainCategory = productsData
    .filter(({ item }) => filterExists(item))
    .map((product) => ({
      ...product,
      item: {
        ...checkAvailability(product.item, occasion, storeTimeZone),
        name: decodeEntities(product.item.name),
        description: decodeEntities(product.item.description)
      }
    }))
    .filter((product) => {
      if (product.item?.name && product.item?.available) {
        return isPizzaProduct(product.item) ? !!product.item.price : priceExistsFor(product.item);
      }
      return false;
    })
    .map((categoryProduct) => transformCategoryProductToDisplayableProduct(
      categoryProduct,
      occasion,
      storeTimeZone
    ));

  const subCategoriesData = (data?.Homepage?.category?.categories ?? []).map((category) => ({
    ...category,
    products: category.products
      .filter(({ item }) => filterExists(item))
      .map((product) => ({
        ...transformCategoryProductToDisplayableProduct(product, occasion, storeTimeZone),
        name: decodeEntities(product.item.name),
        description: decodeEntities(product.item.description)
      }))
      .filter(({ available }) => available)
  }));

  return {
    productsForMainCategory,
    subCategories: subCategoriesData
  };
};

export default transformer;
