export function padNumber(value) {
  const valueAsString = value.toString();
  return valueAsString.length === 1 ? `0${valueAsString}` : valueAsString;
}

export default function determineCloseTime(storeHours: any) {
  if (!storeHours.days) return undefined;

  const startTime = storeHours.interval_start_time
    .split(':')
    .map((value) => parseInt(value, 10));

  const duration = storeHours.duration
    .split(':')
    .map((value) => parseInt(value, 10));

  const closeTime = new Date(0, 0, 0,
    startTime[0] + duration[0],
    startTime[1] + duration[1],
    startTime[2] + duration[2]);

  return `${padNumber(closeTime.getHours())}:${padNumber(closeTime.getMinutes())}:${padNumber(closeTime.getSeconds())}`;
}
