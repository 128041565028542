const updatePromotions = typeof (jest) === 'undefined' ? new Promise(() => {}) : jest.fn();

const successfulUpdatePromotion = (): ClientResult<void> => {
  const mockUpdatePromotion = updatePromotions as jest.Mock;
  const value = { error: false };
  mockUpdatePromotion.mockResolvedValue(value);
  return value;
};

const failedUpdatePromotion = (): ClientResult<void> => {
  const mockUpdatePromotion = updatePromotions as jest.Mock;
  const value = { error: true };
  mockUpdatePromotion.mockResolvedValue(value);
  return value;
};

export default updatePromotions;

export {
  successfulUpdatePromotion,
  failedUpdatePromotion
};
