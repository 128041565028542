import { useSelector } from 'react-redux';
import { GqlVersion } from './GqlVersion';

interface StateProps {
  domain: {
    gqlVersion: GqlVersion;
  };
}
const useGqlVersion = (): GqlVersion => useSelector((state: StateProps) => state.domain.gqlVersion);

export default useGqlVersion;
