import { getOrInitializeOptimizely } from "../optimizely";

export default function setAttributes(newAttributes: Record<string, any>) {
    const optimizely = getOrInitializeOptimizely();

    optimizely?.setUser({
        ...optimizely?.user,
        attributes: {
            ...optimizely?.user.attributes,
            ...newAttributes
        }
    });
}
