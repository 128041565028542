import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AccordionStyles from './styles';

interface ExpandableDetailsProps {
  expanded: boolean,
  summary: any,
  children: any,
  ariaControls: string,
  id: string
}

export default function ExpandableDetails(props : ExpandableDetailsProps) {
  const {
    expanded, summary, children, ariaControls, id
  } = props;
  const expandPanelClasses = AccordionStyles();

  return (
    <Accordion
      expanded={expanded}
      classes={{
        root: expandPanelClasses.expansionPanelRoot,
        rounded: expandPanelClasses.expansionPanelRounded
      }}
    >
      <AccordionSummary
        aria-controls={ariaControls}
        id={id}
        classes={{
          root: expandPanelClasses.root,
          content: expandPanelClasses.content,
          expandIcon: expandPanelClasses.expandIconStyle
        }}
        expandIcon={
            !expanded && (
            <ExpandMoreIcon
              classes={{ root: expandPanelClasses.expandIconSvgStyle }}
              data-testid="expand-more-icon"
            />
            )
        }
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails classes={{
        root: expandPanelClasses.detailRoot
      }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
