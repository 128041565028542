import RuleAction from '../../../../common/RuleAction';
import { RuleIdentifier } from '../../../pizza/identifiers/constants';
import { Deal, DealRuleItem } from '../dealTypes';
import { CrustIdWithUpcharge } from '@/builders/pizza/dataTransformers/builderTypes';

const selectDealIncludeRules = (deal: Deal): undefined | DealRuleItem[] => deal?.data.rules
  && deal?.data.rules[RuleAction.INCLUDE];

const findIdsByIdentifier = (deal: Deal, identifier: RuleIdentifier) => {
  const rules: DealRuleItem[] | undefined = selectDealIncludeRules(deal);

  const isRuleIdentifier = (rule: DealRuleItem) => rule.type === identifier
    && rule.stepId === deal.userSelections.present.currentStep?.id;

  return rules?.filter(isRuleIdentifier).map(({ id }) => id);
};

const findIdsandPriceByIdentifier = (deal: Deal, identifier: RuleIdentifier) => {
  const rules: DealRuleItem[] | undefined = selectDealIncludeRules(deal);

  const isRuleIdentifier = (rule: DealRuleItem) => rule.type === identifier
      && rule.stepId === deal.userSelections.present.currentStep?.id;

  return rules?.filter(isRuleIdentifier).map(({ id, price }) => ({ id, price }));
};

// #Size Selector
const selectSizeIdForDeal = (deal: Deal): DealRuleItem['id'][] | undefined => findIdsByIdentifier(
  deal,
  RuleIdentifier.SIZE
);

// #Crust Selector
const selectCrustIdForDeal = (deal: Deal): DealRuleItem['id'][] | undefined => findIdsByIdentifier(
  deal,
  RuleIdentifier.MODIFIER
);

// #Crust Selector with id and price
const selectCrustIdandUpchargeForDeal = (deal: Deal): CrustIdWithUpcharge[] | undefined => findIdsandPriceByIdentifier(
  deal,
  RuleIdentifier.MODIFIER
);

export {
  selectSizeIdForDeal,
  selectCrustIdForDeal,
  selectCrustIdandUpchargeForDeal,
  selectDealIncludeRules
};
