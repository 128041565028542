import {
  NATIONAL, NATIONAL_STORE_ID_V2, NATIONAL_STORE_ID_V3, Occasion
} from '../localization/constants';
import { GqlVersion } from './hooks/useGqlVersion/GqlVersion';

export const getLinkContent = (link: Links): LinkContent => {
  if ((link as SubLink).subLink) return (link as SubLink).subLink;
  if (typeof (link as Link).link === 'object') return (link as Link).link;
  return (link as LinkContent);
};

const nationalStoreValues = [undefined, NATIONAL, 'loading', NATIONAL_STORE_ID_V3, NATIONAL_STORE_ID_V2];

export const isNational = (store: string): boolean => nationalStoreValues.includes(store);

export function convertStoreToGqlStoreFormat(
  store : string, hasNationalOverrides = false, queryVersion: GqlVersion
) : string {
  const nationalStoreID = hasNationalOverrides ? NATIONAL_STORE_ID_V3 : NATIONAL_STORE_ID_V2;
  const localStoreID = (queryVersion === 'v3') && hasNationalOverrides ? store : `stores/${store}`;
  return isNational(store) ? nationalStoreID : localStoreID;
}

export enum GqlOccasion {
  CARRYOUT = 'CARRYOUT',
  DELIVERY = 'DELIVERY',
  UNKNOWN = 'UNKNOWN',
  UNKNOWN_OCCASION = 'UNKNOWN_OCCASION'
}

export function convertOccasionToGqlOccasion(
  occasion?: Occasion | null, hasNationalOverrides?: boolean
): GqlOccasion {
  switch (occasion) {
    case Occasion.CARRYOUT:
      return GqlOccasion.CARRYOUT;
    case Occasion.DELIVERY:
      return GqlOccasion.DELIVERY;
    case Occasion.UNKNOWN:
    case null:
    case undefined:
      return hasNationalOverrides ? GqlOccasion.UNKNOWN_OCCASION : GqlOccasion.UNKNOWN;
    default:
      throw new Error('this should never happen - and it if does, it`s a bug');
  }
}
