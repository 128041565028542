export default {
  found: true,
  results: [
    {
      address1: '8605 Ohio Dr',
      address2: '',
      address3: '',
      address4: '',
      allows_future_orders: true,
      city: 'Plano',
      country: 'US',
      hours: [
        {
          days: [
            1, 2, 3, 4, 5, 6, 7
          ],
          duration: '19:00:00',
          interval_start_time: '06:00:00',
          name: 'Hours',
          occasion_id: 'CARRYOUT'
        }
      ],
      landmark: 'Behind Target',
      metadata: {},
      online_status: 'online',
      phone_number: '(972)731-8000',
      postal_code: '75024',
      show_unavailable_stock: true,
      start_of_day: '2020-07-29T06:00:00.000-05:00',
      state: 'TX',
      status: 'DC',
      store_id: '311016',
      store_number: '311016',
      timezone: 'America/Chicago',
      distance: {
        unit: 'mi',
        value: '1.306'
      },
      latitude: '33.095263',
      longitude: '-96.797399',
      occasions: [
        {
          apply_discount_to_net: true,
          display_order: 1,
          is_min_with_discount: true,
          max_order: -1,
          min_order: -1,
          name: 'Delivery',
          occasion_id: 'DELIVERY',
          service_time: 25,
          service_time_pos_code: ''
        },
        {
          apply_discount_to_net: true,
          max_order: -1,
          min_order: -1,
          name: 'Carryout',
          occasion_id: 'CARRYOUT',
          service_time: 15,
          service_time_pos_code: ''
        }
      ],
      token: 'eyJhbGciOiJQUzUxMiIsImtpZCI6Ijg5ZjAwZGViLTFlNmItNDA0Ny1iMTBmLTAxMmZkNGU5MzQwMCIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiUEhEQVBJIiwiUEhQQVkiXSwiYnIiOnsiZnJpIjpbeyJlbmQiOiIyMzo1OSIsInN0YXJ0IjoiMDA6MDAifV0sIm1vbiI6W3siZW5kIjoiMjM6NTkiLCJzdGFydCI6IjAwOjAwIn1dLCJzYXQiOlt7ImVuZCI6IjIzOjU5Iiwic3RhcnQiOiIwMDowMCJ9XSwic3VuIjpbeyJlbmQiOiIyMzo1OSIsInN0YXJ0IjoiMDA6MDAifV0sInRodSI6W3siZW5kIjoiMjM6NTkiLCJzdGFydCI6IjAwOjAwIn1dLCJ0dWUiOlt7ImVuZCI6IjIzOjU5Iiwic3RhcnQiOiIwMDowMCJ9XSwid2VkIjpbeyJlbmQiOiIyMzo1OSIsInN0YXJ0IjoiMDA6MDAifV19LCJiemlkIjoiMzExMDE2LmJ6LkUtMDEiLCJleHAiOjE1OTYzMDc4MjcsImlhdCI6MTU5NjA0ODYyNywiaXNzIjoiUEggQ29ubmVjdCIsIm5iZiI6MTU5NjA0ODYyNywib2NjIjoiQ0FSUllPVVQiLCJzbiI6IjMxMTAxNiIsInN1YiI6IkNsaWVudCJ9.nSiiVjo-MYUTzUUXExiI6h0JBJXj4glONHuss7RkRXEMPPodlP8mK6xQV4GPfWeMginyUDHVKKxECO_fTQGtGsTpGjXmz4WMNOdldF5hso0KHRJTrBq-DX-hK6NQhi_76Lh5g2BAitnVEKyDY7oGmLfZvznDowUacsXtXPmSoUbJyzeXKyP2-vjeJSH2BSWBarvhhJBYMySTbl06u7hl5yS3N5yAY_j4JtMH86UE1nh72ftcL5X5TQjL7tpNBPx08PnQ_SyGXHBM5gDWQ4h8YorpXzNGFcPuI8QbmsfyQiGh6nrzIZhqxk3ns5sUnuQT7ZtdxpxUU8mznvY_VvHsZA',
      payment_types: []
    }
  ]
};
