import { Action, AnyAction, combineReducers } from 'redux';
import {
  CART_CHANGED_ALERT,
  CLOSE_CART_RAIL,
  OPEN_CART_RAIL,
  RESET_OPEN_CART_RAIL_AFTER_REDIRECT
} from './actionTypes';
import { CartAlert, CartRailState } from '@/domain/cart/types';

const cartRail = (state: CartRailState = CartRailState.CLOSED, action: Action) => {
  switch (action.type) {
    case OPEN_CART_RAIL: {
      return CartRailState.OPEN;
    }
    case CLOSE_CART_RAIL: {
      return CartRailState.CLOSED;
    }
    default: {
      return state;
    }
  }
};

const openCartRailAfterRedirect = (state = false, action: Action) => {
  switch (action.type) {
    case RESET_OPEN_CART_RAIL_AFTER_REDIRECT: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const cartChangedAlert = (
  state: CartAlert = {
    displayAlert: false,
    quantityChanged: false,
    previousPrice: 0,
    currentPrice: 0,
    itemsRemoved: []
  },
  action: AnyAction
) => {
  switch (action.type) {
    case CART_CHANGED_ALERT: {
      return action.cartAlertContent;
    }
    default:
      return state;
  }
};

export const rail = combineReducers({
  state: cartRail,
  cartChangedAlert,
  openCartRailAfterRedirect
});

const cartUi = combineReducers({
  rail
});

export default cartUi;
