/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useState
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Drawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  largeStartBreakpoint, mobileStartBreakpoint, extraLargeStartBreakpoint
} from '../../materialUi/theme';

export interface ToastProps {
  onClose: () => void;
  isOpen: boolean;
  displayTimeout: number;
  children: JSX.Element;
  testId?: string;
  id: string;
  ariaLabel?: string;
  isKeyboardClick?: boolean;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    zIndex: 999,
    top: '79px',
    padding: '24px',
    margin: 'auto',
    backgroundColor: '#fff',
    width: '328px',
    borderRadius: '8px',
    boxShadow: '1px 4px 9px 0 rgba(19, 19, 19, 0.2), 0 0 4px 0 rgba(19, 19, 19, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2)',

    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      right: '24px',
      left: 'auto'
    },

    [theme.breakpoints.up(largeStartBreakpoint)]: {
      width: '352px',
      top: '66px',
      right: '88px'
    },

    [theme.breakpoints.up(extraLargeStartBreakpoint)]: {
      right: 'max(24px, calc((100vw - 1152px)/2))'
    }
  },
  closeIconItem: {
    position: 'absolute',
    top: '16px',
    right: '10px'
  },
  closeIcon: {
    minWidth: '32px',
    '& svg': {
      fontSize: '2.7rem',
      fill: '#717170'
    }
  },
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    zIndex: 990,
    background: 'rgba(19, 19, 19, 0.5)'
  }
}));

const Toast = ({
  onClose,
  isOpen,
  displayTimeout,
  children,
  testId,
  id,
  ariaLabel = 'Added to order!',
  isKeyboardClick
}: ToastProps) : JSX.Element => {
  const classes = useStyles();
  const [toastTimeout, setToastTimeout] = useState(setTimeout(() => { }, 4000));
  const [hasMouseFocus, setHasMouseFocus] = useState(false);
  const [hasKeyboardFocus, setHasKeyboardFocus] = useState(false);

  const beforeClose = () => {
    setHasMouseFocus(false);
    setHasKeyboardFocus(false);
    onClose();
  };

  useEffect(() => {
    clearTimeout(toastTimeout);
    const shouldStartTimer = isOpen && !hasMouseFocus && !hasKeyboardFocus && !isKeyboardClick;
    if (shouldStartTimer) {
      setToastTimeout(setTimeout(beforeClose, displayTimeout));
    }
  }, [displayTimeout, onClose, isOpen, hasMouseFocus, hasKeyboardFocus, isKeyboardClick]);

  return (
    <Drawer
      classes={{ paper: classes.paper }}
      style={{ transition: 'top .3s ease-in-out', zIndex: 999 }}
      anchor="top"
      PaperProps={{
        'aria-label': ariaLabel
      }}
      onClose={beforeClose}
      open={isOpen}
      data-testid={testId}
      id={id}
      disableScrollLock
    >
      <div
        onFocus={() => setHasKeyboardFocus(true)}
        onBlur={() => setHasKeyboardFocus(false)}
        onMouseEnter={() => setHasMouseFocus(true)}
        onMouseLeave={() => setHasMouseFocus(false)}
      >
        {children}
        <div className={classes.closeIconItem}>
          <Button
            data-testid="toast-close"
            aria-label="Close"
            onClick={beforeClose}
            classes={{ root: classes.closeIcon }}
            tabIndex={0}
          >
            <CloseIcon />
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default Toast;
