import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import { includeCredentials, phdapiPost } from '@/api/phdApiClient/base';
import AccountManagementEndpoints from '@/api/phdApiClient/accountManagement/endpoints';
import { PaymentTypes as APIPaymentTypes } from '@/api/phPayApiClient/types';
import { PaymentDetailsPayload } from '@/api/phdApiClient/accountManagement/favorites/getFavorites.types';

const parseBody = (
  name: string,
  isDefault: boolean,
  paymentId?: string
): PaymentDetailsPayload => {
  const payment = paymentId ? { payment_id: paymentId } : {};

  return {
    name,
    is_default: isDefault,
    form_of_payment: {
      ...payment,
      type: paymentId ? APIPaymentTypes.Card : APIPaymentTypes.Cash
    }
  };
};

const transformer = async (response: Response): Promise<ClientResult<void>> => ({
  error: !response.ok
});

export const addFavorite = async (
  name: string, isDefault: boolean, paymentId?: string
): Promise<ClientResult<void>> => {
  const body = parseBody(name, isDefault, paymentId);

  const result = await phdapiPost(
    getUrl(AccountManagementEndpoints.ADD_FAVORITES),
    includeCredentials,
    JSON.stringify(body)
  );

  return transformer(result);
};
