import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CartItem } from '@pizza-hut-us-development/client-core';
import { setEditCartItemId } from '@/clientCore/redux/rail/CartRailSlice';
import EditCartItem from './components/EditCartItem';
import { findPreSelectedValues } from '@/cart/cartRail/EditCart/transformers';
import { CartItem as LegacyCartItem } from '@/domain/cart/types';
import LinkButton from '@/common/LinkButton';
import styles from './styles';
import { BACK_TO_CART_BUTTON } from '@/cart/constants';
import useEditCart from './hooks/useEditCart';
import EditCartError from './components/EditCartError';
import { onCartRailProductGoBack } from '@/checkout/checkout.analytics';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

const EditCart = () => {
  const [hasError, setHasError] = useState(false);
  const classes = styles();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { cart, selectedItem, transformedProduct } = useEditCart();
  const isLoading = transformedProduct == null || !cart || !selectedItem;

  if (isLoading) return <></>;

  if (hasError) return <EditCartError />;

  const handleBackClick = () => {
    analytics.push(() => onCartRailProductGoBack(BACK_TO_CART_BUTTON));
    dispatch(setEditCartItemId(null));
  };

  return (
    <div data-testid="edit-cart-item">
      <div className={classes.goBack}>
        <LinkButton
          testId="edit-product-go-back"
          onClick={handleBackClick}
        >
          <ArrowBackIcon className={classes.goBackIcon} />
          <div className={classes.goBackText}>{BACK_TO_CART_BUTTON}</div>
        </LinkButton>
      </div>
      <EditCartItem
        cartId={cart.cartId}
        cartItem={selectedItem as CartItem}
        product={transformedProduct}
        onError={() => setHasError(true)}
        {...findPreSelectedValues(
          transformedProduct,
          selectedItem as unknown as LegacyCartItem
        )}
      />
    </div>
  );
};

export default EditCart;
