import { DealRecipe } from '@/builders/deals/slice/dealTypes';
import Portion from '@/common/Portion';
import ProductId from '@/common/ProductId';
import { IsCyo, MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import {
  BuiltPizza,
  IngredientOptionWithPortions,
  Item, PizzaIngredient,
  PizzaIngredientOption, PizzaSummaryOption
} from '../dataTransformers/builderTypes';
import {
  IngredientInternalName
} from './constants';

function isCreateYourOwnPizza(
  thing: BuiltPizza | Partial<DealRecipe> | Partial<MenuRecipe> | PizzaSummaryOption
): boolean {
  return thing?.isCYO === IsCyo.TRUE;
}

function isTheNoSauceOption(thing: IngredientOptionWithPortions): boolean {
  return thing.portions.length === 1 && thing.portions[0].portion === Portion.NONE;
}

function isOriginalPanCrust(thing: PizzaIngredientOption | PizzaIngredient | null): boolean {
  return !!thing?.isPanCrust;
}

function isSauce(thing: Item): boolean {
  return thing.internalName === IngredientInternalName.SAUCES;
}

function isCheese(thing: Item): boolean {
  return thing.internalName === IngredientInternalName.CHEESES;
}

function isFinisher(thing: Item): boolean {
  return thing.internalName === IngredientInternalName.FINISHERS;
}

function isSize(thing: Item): boolean {
  return thing.internalName === IngredientInternalName.SIZES;
}

function isMeat(thing: Item): boolean {
  return thing.internalName === IngredientInternalName.MEATS;
}

function isVeggie(thing: Item): boolean {
  return thing.internalName === IngredientInternalName.VEGGIES;
}

function extractGlobalId(thing: Item | null): string | undefined {
  return thing?.id ? new ProductId(thing.id).globalId : undefined;
}

export {
  extractGlobalId,
  isCreateYourOwnPizza,
  isTheNoSauceOption,
  isOriginalPanCrust,
  isSauce,
  isCheese,
  isFinisher,
  isSize,
  isMeat,
  isVeggie
};
