import { Dispatch, AnyAction } from 'redux';
import {
  CLOSE_CART_RAIL,
  OPEN_CART_RAIL,
  RESET_OPEN_CART_RAIL_AFTER_REDIRECT,
  UPDATE_CART,
  UPDATE_CART_FAIL,
  CART_LOADING,
  CART_CHANGED_ALERT,
  CART_LOADED
} from './actionTypes';
import { AsyncDispatch } from '@/localization/localizeActions';
import { Cart, CartAlert } from '@/domain/cart/types';
import telemetry from '@/telemetry';

export const closeCartRail = (): AnyAction => {
  telemetry.addCustomEvent('close-cart-rail');
  return {
    type: CLOSE_CART_RAIL
  };
};

export const openCartRail = (): AnyAction => {
  telemetry.addCustomEvent('open-cart-rail');
  return {
    type: OPEN_CART_RAIL
  };
};

export const toggleRail = (): AsyncDispatch => async (dispatch: Dispatch, getState?: () => any) => {
  const isOpen = getState && getState().presentational.cart.rail.isOpen;
  if (isOpen) {
    dispatch(closeCartRail());
  } else {
    dispatch(openCartRail());
  }
};

export const resetOpenCartRailAfterRedirect = (): AnyAction => ({
  type: RESET_OPEN_CART_RAIL_AFTER_REDIRECT
});

export const updateCart = (cart: Cart): AnyAction => ({
  type: UPDATE_CART,
  cart
});

export const updateCartFail = (): AnyAction => ({
  type: UPDATE_CART_FAIL
});

export const cartLoading = (): AnyAction => ({
  type: CART_LOADING
});
export const cartLoaded = (): AnyAction => ({
  type: CART_LOADED
});

export const cartChangedAlert = (cartAlertContent: CartAlert): AnyAction => ({
  type: CART_CHANGED_ALERT,
  cartAlertContent
});
