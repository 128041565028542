import { PromotionAction, PromotionDefinition, useGetDealBuilderQuery } from '@pizza-hut-us-development/client-core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useDecision } from '@optimizely/react-sdk';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { OccasionApi } from '@/localization/constants';
import { selectOccasion } from '@/localization/selectors';
import { DealBuilderWithAvailability, transformGetDealBuilderData } from './transformGetDealBuilderData';
import { RootState } from '@/rootStateTypes';
import ProductId from '@/common/ProductId';
import useProduct from '@/graphql/hooks/variables/useProductItem';
import { transformationDebugLogging } from '@/clientCore/debuggerLogging';
import { debugModeSelectors } from '@/clientCore/redux/selectors/debugSelectors';
import { DebugModeOptions } from '@/clientCore/redux/debug/types';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { getPromotionDefinition } from '@/clientCore/cart/components/CartRail/components/CartContent/components/YumAddedCoupons/helpers';
import { openModal } from '@/localization/actions';
import Routes from '@/router/routes';
import { RedemptionQueryParams } from '@/clientCore/redemptionMenu/constants';

export const useCCGetDealBuilderQuery = ({ itemId = '', skip = false }) => {
  const [promotionDefinition, setPromotionDefinition] = useState<PromotionDefinition>();
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const storeOccasion = useSelector(selectOccasion) as keyof typeof OccasionApi;
  const occasion = OccasionApi[storeOccasion] as OccasionApi;
  const [storeNumber, setStoreNumber] = useState<string>('');
  const [storeTimezone, setStoreTimeZone] = useState<string | undefined>(undefined);
  const debugEnabled = useSelector(debugModeSelectors.enabled);
  const debugMode = useSelector(debugModeSelectors.mode);

  const router = useRouter();
  const dispatch = useDispatch();
  const cart = useSelector(orderSelectors.cart);
  const [{ enabled: redemptionBuilderEnabled }] = useDecision('fr-web-3856-builder_promotion_support');

  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  useEffect(() => {
    if (storeDetails?.storeNumber) {
      setStoreNumber(storeDetails.storeNumber);
    }
    if (storeDetails?.storeTimezone) setStoreTimeZone(storeDetails.storeTimezone);
  }, [storeDetails]);

  const { globalId } = new ProductId(itemId);
  const storeSpecificId = useProduct('discounts', itemId).variables?.itemID;
  const idToUse = isYumEcomm ? globalId : storeSpecificId;

  const flowId = router.query.fl?.toString();
  const redemptionCode = router.query.red?.toString();

  // Grab our associated promotion definition
  useEffect(() => {
    if (!cart || !redemptionCode || !redemptionBuilderEnabled) return;
    getPromotionDefinition(redemptionCode, cart, dispatch).then((res) => {
      if (!res) {
        // No promotion definition found or fetched
        dispatch(openModal({
          title: 'We’re sorry',
          body: 'Something went wrong. Please try again.',
          cta: {
            text: 'Ok',
            callback: () => router.push(Routes.DEALS)
          }
        }));
      }
      setPromotionDefinition(res);
    });
  }, [cart, redemptionCode, dispatch, router, redemptionBuilderEnabled]);

  const {
    data, isLoading, isFetching, error
  } = useGetDealBuilderQuery(
    {
      storeNumber,
      itemId: idToUse,
      promotionDefinition: promotionDefinition ? {
        definition: promotionDefinition,
        action: flowId === RedemptionQueryParams.REQUIREMENTS ? PromotionAction.REQUIREMENTS : PromotionAction.EFFECTS
      } : undefined
    },
    { skip: !storeNumber || skip }
  );

  if (skip || error) {
    return {
      data: null,
      loading: false,
      error: null,
      storeID: null
    };
  }

  if (isLoading || isFetching || !storeNumber) {
    return {
      data: undefined,
      loading: true,
      error: null,
      storeID: null
    };
  }

  const transformedData = data && storeTimezone
    ? transformGetDealBuilderData(data as DealBuilderWithAvailability, occasion, storeTimezone || '', isYumEcomm)
    : undefined;

  if (debugEnabled) {
    transformationDebugLogging('useCCGetDealBuilderQuery', useGetDealBuilderQuery.name, data, transformedData, debugMode, [
      DebugModeOptions.TRANSFORMATIONS,
      DebugModeOptions.MENU,
      DebugModeOptions.DEALS
    ]);
  }

  return {
    data: transformedData,
    loading: isLoading,
    storeID: storeNumber,
    rawData: data
  };
};
