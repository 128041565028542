const deleteFavorite = typeof (jest) === 'undefined' ? new Promise(() => {}) : jest.fn();

const successfulDelete = (): void => {
  const mockDeleteFavorite = deleteFavorite as jest.Mock;
  mockDeleteFavorite.mockResolvedValue({ error: false });
};

export default deleteFavorite;

export {
  successfulDelete
};
