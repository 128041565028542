import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CartItem, UpdateCartItemInput, useUpdateCartItemMutation } from '@pizza-hut-us-development/client-core';
import ProductInfoContent from '@/common/components/LocalizedProductTile/ProductInfoContent/ProductInfoContent';
import useDisplayableProduct from '@/menu/categories/useDisplayableProduct';
import { DisplayableModifier, DisplayableProduct, Product } from '@/domain/product/types';
import { replaceSpacesWithDash } from '@/common/replaceSpacesWithDash';
import { useCCTransformLegacyCartItem } from '@/clientCore/temporaryTransformationalHooks/useCCTransformLegacyCartItem/useCCTransformLegacyCartItem';
import { setCartItemIdOfSortedPointer, setEditCartItemId } from '@/clientCore/redux/rail/CartRailSlice';
import { CART_PRODUCT_SAVE_LABEL_ANALYTICS } from '@/clientCore/cart/constants';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { onCartProductSaveClick } from '@/checkout/checkout.analytics';
import { findNewCartItemId } from '@/clientCore/redux/rail/helpers';
import { presentationalRailSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/rail/presentationalRailSelectors';
import { debugModeSelectors } from '@/clientCore/redux/selectors/debugSelectors';
import { transformationDebugLogging } from '@/clientCore/debuggerLogging';
import { DebugModeOptions } from '@/clientCore/redux/debug/types';

interface EditCartItemProps {
  product: DisplayableProduct;
  cartItem: CartItem;
  cartId: string;
  quantity: number;
  onError: () => void;
  selectedSize?: DisplayableModifier;
  selectedAdditionalOption?: DisplayableModifier;
}

const EditCartItem = ({
  product,
  cartId,
  cartItem,
  quantity,
  onError,
  selectedSize,
  selectedAdditionalOption
}: EditCartItemProps) => {
  const dispatch = useDispatch();
  const [updateCartItem] = useUpdateCartItemMutation();
  const { handleLegacyCartItemTransformer } = useCCTransformLegacyCartItem();
  const analytics = useAnalytics();
  // Debug
  const debugEnabled = useSelector(debugModeSelectors.enabled);
  const debugMode = useSelector(debugModeSelectors.mode);

  const sortedItemPointers = useSelector(presentationalRailSelectors.sortedItemPointers);

  const handleUpdateItem = async (updateItem: Product) => {
    analytics.push(() => onCartProductSaveClick(CART_PRODUCT_SAVE_LABEL_ANALYTICS));
    try {
      const update: UpdateCartItemInput = {
        cartId,
        item: {
          ...handleLegacyCartItemTransformer(updateItem),
          cartItemId: cartItem.cartItemId
        }
      };

      const response = await updateCartItem(update);

      if ('data' in response) {
        if (debugEnabled) {
          transformationDebugLogging(
            'useCCTransformLegacyCartItem | handleUpdateItem',
            `handleLegacyCartItemTransformer type:${updateItem.type}`,
            updateItem,
            response.data,
            debugMode,
            [DebugModeOptions.CART, DebugModeOptions.EDIT, DebugModeOptions.TRANSFORMATIONS]
          );
        }
        const foundNewCartItemId = findNewCartItemId(sortedItemPointers, response.data.items);
        if (foundNewCartItemId) {
          dispatch(
            setCartItemIdOfSortedPointer({
              currentPointer: {
                cartItemId: cartItem.cartItemId,
                itemId: cartItem.id
              },
              newCartId: foundNewCartItemId
            })
          );
        }
      } else {
        throw new Error('updateCartItem mutation failed');
      }
      dispatch(setEditCartItemId(null));
      return true;
    } catch (error) {
      onError();
      return false;
    }
  };

  const displayableProduct = useDisplayableProduct({
    categoryName: '', // Not needed (yet, might for analytics) - how to query for this?
    showErrorModal: () => {}, // refactor to remove the prop?
    productIndex: 0,
    handleAddButtonClick: handleUpdateItem,
    ...product,
    preSelectedQuantity: quantity,
    preSelectedAdditionalOption: selectedAdditionalOption,
    preSelectedSize: selectedSize
  });

  const { values, setters, handlers } = displayableProduct;

  return (
    <ProductInfoContent
      product={product}
      productNameWithoutSpaces={replaceSpacesWithDash(product.name)}
      quantity={values.quantity}
      handleAddToCart={(e) => handlers.handleAddToCart(e as unknown as React.MouseEvent<Element>, true)}
      handleAnalyticsForInfoRailSlideOut={handlers.handleAnalyticsForInfoRailSlideOut}
      updateQuantity={setters.updateQuantity}
      formattedUnitPrice={values.formattedUnitPrice}
      additionalOptions={values.optionsBySize}
      hasMultipleSizes={values.hasMultipleSizes}
      selectedSize={values.selectedSize}
      updateSize={(e) => setters.updateSize(e, true)}
      hasAdditionalOptions={values.hasAdditionalOptions}
      selectedAdditionalOption={values.selectedAdditionalOption}
      productId="productId"
      hasNutrition={values.hasNutrition}
      updateAdditionalOption={(e) => setters.updateAdditionalOption(e, true)}
      buttonState={values.buttonState}
      buttonDetails={{
        action: 'ADD_TO_CART',
        text: 'Save',
        ariaLabel: 'Save Changes',
        ariaLabelLoading: 'Loading save changes',
        size: 'primary'
      }}
      quantitySelectorInputLabel="edit-product-quantity"
      showDescription={false}
    />
  );
};

export default EditCartItem;
