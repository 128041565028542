import { KeyboardEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import Routes from '@/router/routes';
import useLocationConfirmationModal from '@/common/useLocationConfirmationModal';
import { useSalesExperience } from '@/salesExperience/hooks';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { openLocalizationRail } from '@/localization/actions';
import {
  onHeaderDealsClick,
  onSignInSignOutClick
} from '@/dataAnalytics/dataAnalyticsHelper';
import {
  ENTER_KEY_EVENT_CODE,
  KEYBOARD_ENTER_KEYCODE
} from '@/common/constants';
import {
  LinkTypes,
  MouseOrKeyboardEventHandler,
  UseAnchorLink
} from '@/common/AnchorLink/types';
import { WEB2 } from '@/salesExperience/constants';
import { selectors } from '@/salesExperience/salesExperience.slice';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { getOrInitializeOptimizely } from '../../../../optimizely/optimizely';
import isClientSide from '@/common/isClientSide';

function isPizzaBuilder(link: string): boolean {
  return link.includes(Routes.MENU.PIZZA_BUILDER);
}

function isDealBuilder(link: string): boolean {
  return link.includes(Routes.DEALS) && link.includes('?id=');
}

function isWeb1Deal(link: string, isWeb1: boolean): boolean {
  return isWeb1 && link.includes('?c=') && link.includes('link.php');
}

function needsLocalization(link: string, isWeb1: boolean): boolean {
  return isPizzaBuilder(link) || isDealBuilder(link) || isWeb1Deal(link, isWeb1);
}

export const useAnchorLink: UseAnchorLink = ({
  children,
  linkDisplayText,
  linkType,
  link,
  dataAnalyticsAction,
  onClick,
  handleAfterLocalizing,
  preventHref
}) => {
  const dispatch = useDispatch();
  const { showLocationConfirmationModal } = useLocationConfirmationModal();
  const linkContent = children || linkDisplayText;
  const { linkForSalesExperience } = useSalesExperience();
  const experience = useSelector(selectors.experience);
  const isWeb2 = experience === WEB2;
  const isWeb1 = !isWeb2;
  const relativeLink = linkForSalesExperience(
    link && link[0] === '/' ? link : `/${link}`
  );
  const deepLink = link?.match(/^\//) || link === undefined ? link : relativeLink;
  const isTranslated = (typeof relativeLink !== 'undefined'
    && isWeb2
    && link !== undefined
    && linkType === LinkTypes.DEEP_LINK
    && relativeLink !== deepLink
    && !preventHref);
  const isRelativeLink = linkType === LinkTypes.RELATIVE_LINK || isTranslated;
  const isDeepLink = linkType === LinkTypes.DEEP_LINK;
  const href = linkForSalesExperience(isDeepLink ? deepLink : link);
  const isLocalized = useSelector(localizationSelectors.isLocalized);
  const analytics = useAnalytics();
  const optimizelyInstance = getOrInitializeOptimizely({ datafile: {}, isBrowser: isClientSide(), allowInitialize: false });

  const handleOnAction: MouseOrKeyboardEventHandler = (event): void => {
    if (
      !isLocalized
      && typeof relativeLink !== 'undefined'
      && needsLocalization(relativeLink, isWeb1)
    ) {
      event.preventDefault();
      if (handleAfterLocalizing instanceof Function) {
        dispatch(
          openLocalizationRail({
            handleAfterLocalizing: async (tokenLocalization = '') => {
              handleAfterLocalizing(tokenLocalization);
            }
          })
        );
      } else {
        dispatch(openLocalizationRail({ routeTo: relativeLink }));
      }
      return;
    }

    if (dataAnalyticsAction === 'Deals') {
      analytics.push(() => onHeaderDealsClick());
      optimizelyInstance?.track('DealsPageView');
    }
    if (dataAnalyticsAction === 'Sign In') {
      analytics.push(() => onSignInSignOutClick(dataAnalyticsAction));
    }
    showLocationConfirmationModal(link, event, onClick);
  };

  const handleOnKeyDown: KeyboardEventHandler<HTMLAnchorElement> = (
    event
  ): void => {
    if (
      onClick
      && (event.key === ENTER_KEY_EVENT_CODE
        || event.keyCode === KEYBOARD_ENTER_KEYCODE)
    ) {
      handleOnAction(event);
    }
  };
  return {
    linkContent,
    isRelativeLink,
    relativeLink,
    href,
    handleOnAction,
    handleOnKeyDown
  };
};
