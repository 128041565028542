import cookie from 'next-cookies';

export const SHOULD_MOCK_CLIENTS_COOKIE_NAME = 'SHOULD_MOCK_CLIENTS';

export function shouldMockAPIResponse(): boolean {
  const shouldMockClientsCookie = cookie({})[SHOULD_MOCK_CLIENTS_COOKIE_NAME];

  if (!shouldMockClientsCookie) {
    return false;
  }

  return shouldMockClientsCookie === 'true';
}
