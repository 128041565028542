// Endpoint: /orders/{order_id}/status

import { CarryoutStatus } from '@/api/phdApiClient/types';
import combine from '@/common/combine';

const date = new Date(Date.now());
export const setPromisedTime = (newDate: Date, addedTime: number): Date => {
  newDate.setMinutes(date.getMinutes() + addedTime);
  return newDate;
};

export const stubCarryoutStatus = (
  override: Partial<CarryoutStatus> = {}
): CarryoutStatus => combine<CarryoutStatus>({
  order_id: '3210917000840001488995',
  promised_time: setPromisedTime(date, 30).toISOString(),
  status: 'successful'
}, override);

export const carryoutOrderStatusResult = {
  order_id: '3210917000840001488995',
  message: 'this is a message', // not in Api response
  estimated_transmission_time: date.toISOString(), // not in api response
  promised_time: setPromisedTime(date, 30).toISOString(),
  status: 'successful'
};
