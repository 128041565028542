import React, { CSSProperties } from 'react';
import SvgComponent from './svg';

// this adjusts the icon so it can be perfectly inline with text
const style : CSSProperties = {
  position: 'relative',
  top: '5px',
  marginRight: '10px'
};

export default function AdjustedIcon() {
  return <SvgComponent aria-hidden style={style} />;
}
