/**
 * todo: Remove this index.tsx and change paths where these queries are used
 */
import PIZZAS from './pizzaMenu';
import SINGLE_PIZZA from '../dynamicQueries/pizza';
import SEO from './seo';
import HERO from './hero';
import SIDEKICKS from './sidekicks';
import BREAKING_NEWS from './breakingNews';
import MARKETING_BANNERS from './marketingBanners';
import HUT_FAVORITES from './hutFavorites';
import {
  FOOTER_NATIONAL,
  FOOTER_LOCALIZED,
  CC_GQL_PARTIAL_FOOTER_LOCALIZED
} from './footer';
import {
  HEADER_NATIONAL,
  HEADER_LOCALIZED,
  CC_GQL_PARTIAL_HEADER_LOCALIZED
} from './header';
import DEAL from './deal';
import DEAL_MENU from './dealMenu';
import GET_PRODUCTS_BY_CATEGORY from './productsByCategory';
import GET_CATEGORY_BY_ID from './categoryById';
import GET_PRODUCT_BY_ID from './productById';
import GET_CATEGORY_DISPLAY_NAME from './categoryDisplayName';
import {
  GET_CATEGORIES_LOCALIZED,
  GET_CATEGORIES_NATIONAL
} from './categories';

export {
  SEO,
  MARKETING_BANNERS,
  HUT_FAVORITES,
  PIZZAS,
  SINGLE_PIZZA,
  SIDEKICKS,
  HERO,
  BREAKING_NEWS,
  FOOTER_LOCALIZED,
  FOOTER_NATIONAL,
  CC_GQL_PARTIAL_FOOTER_LOCALIZED,
  HEADER_NATIONAL,
  HEADER_LOCALIZED,
  CC_GQL_PARTIAL_HEADER_LOCALIZED,
  DEAL,
  DEAL_MENU,
  GET_PRODUCT_BY_ID,
  GET_PRODUCTS_BY_CATEGORY,
  GET_CATEGORY_DISPLAY_NAME,
  GET_CATEGORIES_NATIONAL,
  GET_CATEGORIES_LOCALIZED,
  GET_CATEGORY_BY_ID
};
