import { useSelector } from 'react-redux';
import { cartSelectors } from '@/cart/cartSelectors';

export enum SelectorButtonState {
  DEFAULT, SHOW_LOADING_SPINNER, PREVENT_CLICK
}

const useButtonState = (isLoading: boolean): SelectorButtonState => {
  const isCartLoading = useSelector(cartSelectors.loading);

  if (isLoading) {
    return SelectorButtonState.SHOW_LOADING_SPINNER;
  }

  if (isCartLoading) {
    return SelectorButtonState.PREVENT_CLICK;
  }

  return SelectorButtonState.DEFAULT;
};

export default useButtonState;
