import { CartItem } from '@/domain/cart/types';
import { isCoupon } from '@/cart/cartRail/cartRailHelpers';

const getItemQuantityByType = (items: CartItem[] | undefined, type: string): number | undefined => items?.filter(
  (item) => item.type === type
)
  .reduce((pvq, item) => pvq + item.quantity, 0);

const getCouponQuantity = (items: CartItem[] | undefined) => items?.filter((item) => isCoupon(item))
  .reduce((pvq, item) => pvq + item.quantity, 0);

export {
  getItemQuantityByType,
  getCouponQuantity
};
