export default {
  found: true,
  results: [
    {
      address1: '7100 Corporate Dr',
      address2: '',
      address3: '',
      address4: '',
      city: 'PLANO',
      country: 'US',
      hours: [
        {
          days: [
            1, 2, 3, 4, 5, 6, 7
          ],
          duration: '19:30:00',
          interval_start_time: '06:30:00',
          name: 'Hours',
          occasion_id: 'DELIVERY'
        }
      ],
      landmark: '',
      metadata: {},
      online_status: 'inactive',
      dragontail: true,
      phone_number: '(972) 612-1133',
      postal_code: '75023',
      show_unavailable_stock: true,
      state: 'TX',
      status: 'T',
      store_id: '995',
      store_number: '995',
      timezone: 'America/Chicago',
      distance: {
        unit: 'mi',
        value: '0'
      },
      latitude: '-22.1',
      longitude: '79.30184',
      occasions: [
        {
          apply_discount_to_net: true,
          max_order: -1,
          min_order: -1,
          name: 'Carryout',
          occasion_id: 'CARRYOUT',
          service_time: 15,
          service_time_pos_code: ''
        },
        {
          apply_discount_to_net: true,
          display_order: 1,
          is_min_with_discount: true,
          max_order: -1,
          min_order: 1500,
          name: 'Delivery',
          occasion_id: 'DELIVERY',
          service_time: 18,
          service_time_pos_code: ''
        }
      ],
      token: 'eyJhbGciOiJQUzUxMiIsImtpZCI6Ijk0MTFmYTkzLTk2YWYtNDIxMS1iZmI5LWMzODNkZWQwZTIxMiIsInR5cCI6IkpXVCJ9.eyJhZGRyMSI6IjEyMCBNZW1vcnkgbG4iLCJhdWQiOlsiUEhEQVBJIiwiUEhQQVkiXSwiY2l0eSI6IlBsYW5vIiwiZXhwIjoxNTk5MDYxODgxLCJnZW8iOnRydWUsImlhdCI6MTU5NjQ2OTg4MSwiaXNzIjoiUEggQ29ubmVjdCIsIm5iZiI6MTU5NjQ2OTg4MSwib2NjIjoiREVMSVZFUlkiLCJzbiI6Ijk5NSIsInN0YXRlIjoiVFgiLCJzdWIiOiJDbGllbnQiLCJ6aXAiOiI3NTAyNCJ9.OS8-Hb7jhQE5vGrdYP-ECvlmuzaFwzHXoggfdKVgO8w3orFqzEhQ69kTLlE29Xs7vbZ-YZ-k8gklPRx13-OPqLq42fkazx3srZyMjSifTWaqsCiOwr2Yut9mHoOZOILCFbnhQpsWPnLLBdUsFRXZjm77gzFwu33B0MunKRotFMrn2LqklqydTHRnmQnewsUUq5scBQeUK_HxOKTpUM01eRhJSvkNm_0DhHGeOCCb6N3H0R81ntlfjbbh4syt4Xuao9HwUGg1rD1sARwAKX4koim03gGO72D4pGrnsA1ONPMxymovSYBUM1Ipq2INAUamabgvPaRpG9b5eLc5PjrwLw'
    }
  ]
};
