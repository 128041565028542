const Routes = {
  HOME: '/',
  ACCESSIBILITY: '/accessibility',
  DEALS: '/deals',
  UPSELL: '/upsell',
  MENU: {
    MENU: '/menu',
    PIZZA: '/menu/pizza',
    DESSERTS: '/menu/desserts',
    DEALS: '/link.php?menu=deals',
    DEALSW2: '/deals',
    PIZZA_BUILDER: '/menu/pizza/builder',
    SALADS: '/menu/salads'
  },
  CHECKOUT: '/link.php?f=order',
  CHECKOUTW2: '/checkout',
  CONFIRMATION: '/confirmation',
  LINEUP: '/lineup',
  EDIT_BUILDER: '/edit/builder',
  ACCOUNT: {
    CREATE_ACCOUNT: '/account/create-account',
    REWARDS: '/account/rewards',
    JOIN: '/account/join'
  }
};

export default Routes;
