import getConfig from 'next/config';
import { X_SERVER_ENV_OVERRIDE_KEY } from '@/configuration/constants';
import isClientSide from '@/common/isClientSide';

type PublicRuntimeConfig = {
  phdApiUrl: string;
  phdApiKey: string;
  phdApiServerEnv: string;
  phdApiAuthChannel: string;
};

export const getPublicRuntimeConfig = (): PublicRuntimeConfig => {
  const { publicRuntimeConfig } = getConfig();
  let xServerEnv = publicRuntimeConfig.PHD_API_SERVER_ENV;
  if (isClientSide()) {
    xServerEnv = localStorage.getItem(X_SERVER_ENV_OVERRIDE_KEY) || publicRuntimeConfig.PHD_API_SERVER_ENV;
  }

  return {
    phdApiUrl: publicRuntimeConfig.PHD_API_URL,
    phdApiKey: publicRuntimeConfig.PHD_API_KEY,
    phdApiServerEnv: xServerEnv,
    phdApiAuthChannel: publicRuntimeConfig.PHD_API_AUTH_CHANNEL
  };
};
