import createClientMethod from '@/api/createClientMethod';
import { includeCredentials, phdapiDelete } from '@/api/phdApiClient/base';
import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import AccountManagementEndpoints from '@/api/phdApiClient/accountManagement/endpoints';

const fetch = async (addressId: string) => phdapiDelete(
  getUrl(`${AccountManagementEndpoints.CUSTOMER_ADDRESS}/${addressId}`),
  includeCredentials
);

const transformer = async (response: Response): Promise<ClientResult<void>> => ({
  error: !response.ok
});

const deleteAddress = createClientMethod(fetch, transformer);

export default deleteAddress;
