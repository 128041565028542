import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import useYumAddedCoupons, { YumAddedCouponProps } from './hooks/useYumAddedCoupons';
import styles from './styles';
import { RemoveCouponIcon } from '../../../../common/RemoveCouponIcon';
import { WarningIcon } from '../../../../common/WarningIcon';

export const YumAddedCoupons = ({ promotionOrWarning }: YumAddedCouponProps): JSX.Element => {
  const classes = styles.yumAddedCoupons();

  const [{ name, warningMessage, shouldDisplay }, handlers] = useYumAddedCoupons({ promotionOrWarning });
  const {
    handleRemoveYumPromoCode
  } = handlers;

  if (!shouldDisplay) {
    return <></>;
  }

  return (
    <>
      <Grid className={classes.container}>
        {name
          ? <Typography className={classes.couponName}>{name}</Typography>
          : <Skeleton style={{ marginLeft: 10 }} width={150} />}
        <Button
          onClick={() => handleRemoveYumPromoCode()}
          className={classes.removeButton}
        >
          <RemoveCouponIcon />
        </Button>
      </Grid>
      {warningMessage
        ? (
          <Grid
            container
            alignItems="flex-start"
            className={classes.warningContainer}
          >
            <div className={classes.warningIcon}>
              <WarningIcon />
            </div>
            <Typography className={classes.warningText}>
              {warningMessage}
            </Typography>
          </Grid>
        )
        : <></>}
    </>
  );
};

export default YumAddedCoupons;
