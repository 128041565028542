/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CartStateTypes } from '@/clientCore/redux/cart/types';

const initialState: CartStateTypes = {
  isCartLoading: true,
  showCartInfoAndWariningView: false,
  cartInfoAndWarningViewType: ''
};

export const CartStateSlice = createSlice({
  initialState,
  name: 'CartState',
  reducers: {
    toggleCartLoadingStatus: (
      state: CartStateTypes,
      {
        payload: { loadingState }
      }: {
        payload: {
          loadingState: boolean;
        };
      }
    ) => {
      state.isCartLoading = loadingState;
    },
    setCartInfoAndWarningViewType: (
      state: CartStateTypes,
      {
        payload: { cartInfoOrWarningViewType }
      }: {
        payload: {
          cartInfoOrWarningViewType: string;
        };
      }
    ) => {
      state.cartInfoAndWarningViewType = cartInfoOrWarningViewType;
    },
    toggleCartInfoAndWarningView: (
      state: CartStateTypes,
      {
        payload: { displayState }
      }: {
        payload: {
          displayState: boolean;
        };
      }
    ) => {
      state.showCartInfoAndWariningView = displayState;
    }
  }
});

export const presentationalCartStateReducer = CartStateSlice.reducer;

export const {
  toggleCartLoadingStatus,
  toggleCartInfoAndWarningView,
  setCartInfoAndWarningViewType
} = CartStateSlice.actions;
