import { QueryHookOptions } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Occasion } from '../../../localization/constants';
import {
  convertOccasionToGqlOccasion,
  convertStoreToGqlStoreFormat
} from '../../gqlDataManipulation';
import useGqlVersion from '../useGqlVersion';
import { RootState } from '@/rootStateTypes';

interface LocalizationReduxState {
  localizedStore: string;
  localizedOccasion: Occasion;
  timestamp: string;
}

export default function useLocalizationGqlVariables(
  hasNationalOverrides = false,
  newStoreFormat = false
): QueryHookOptions {
  const { localizedStore, localizedOccasion, timestamp } = useLocalizationReduxState();
  const gqlOccasion = convertOccasionToGqlOccasion(localizedOccasion, hasNationalOverrides);

  const queryVersion = useGqlVersion();
  const gqlStoreId = newStoreFormat ? localizedStore
    : convertStoreToGqlStoreFormat(localizedStore, hasNationalOverrides, queryVersion);

  return {
    variables: {
      storeID: gqlStoreId,
      occasions: [gqlOccasion],
      time: timestamp
    }
  };
}

function useLocalizationReduxState() : LocalizationReduxState {
  return useSelector((state : RootState) => state.domain.localization);
}
