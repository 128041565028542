import React from 'react';

export default function Svg(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(5 12)">
          <rect width="21" height="15" x=".5" y=".5" fill="#FFF" stroke="#131313" rx="1" />
          <circle cx="5" cy="4" r="1" fill="#131313" />
          <circle cx="13" cy="4" r="1" fill="#131313" />
          <rect width="16" height="7" x="3" y="6" fill="#CFCECC" rx="1" />
          <rect width="18" height="1" x="2" y="7" fill="#131313" rx=".5" />
          <circle cx="9" cy="4" r="1" fill="#131313" />
          <circle cx="17" cy="4" r="1" fill="#131313" />
        </g>
        <g stroke="#131313" strokeLinecap="round">
          <path d="M.61 0c.585.797 1.123 1.643.585 2.696C.658 3.748.493 3.523.123 4.579c-.246.704-.123 1.372.37 2.004M8.61 0c.585.797 1.123 1.643.585 2.696-.537 1.052-.702.827-1.072 1.883-.246.704-.123 1.372.37 2.004M4.61 0c.585.797 1.123 1.643.585 2.696-.537 1.052-.702.827-1.072 1.883-.246.704-.123 1.372.37 2.004" transform="translate(12 4)" />
        </g>
      </g>
    </svg>
  );
}
