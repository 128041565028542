import { SingleOrder } from '@/account/orders/orderHistory/order/Order.types';
import { OrderHistoryType } from '@/account/orders/orderHistory/OrderHistory.types';
import {
  OrderHistoryClientResult,
  OrderHistoryResponse
} from '@/api/phdApiClient/accountManagement/orders/getOrders.types';
import { Occasion, OccasionApi } from '@/localization/constants';

export const orderHistoryApiResponse: OrderHistoryResponse = {
  count: 1,
  orders: [
    {
      delivery_address: {
        address1: '100 MEMORY LN',
        address2: '1419',
        city: 'PLANO',
        postal_code: '75024',
        state: 'TX'
      },
      delivery_fee: 499,
      delivery_instructions: '',
      discounts: [],
      forms_of_payment: [
        {
          name: 'DI-1117',
          type: 'creditcard'
        }
      ],
      store_number: '045031',
      occasion_id: OccasionApi.D,
      order_id: '3211021185824001488034131',
      order_time: '2021-10-21T18:58:00.000-05:00',
      points_earned: 530,
      products: [
        {
          cart_discount_id: '',
          discount_id: '',
          discount_step_id: '',
          image:
            'https://www.pizzahut.com/assets/w/tile/thor/pizzaBuilderPull.png',
          modifiers: [
            {
              modifier: 'Black Olives',
              modifier_id: 'V~BO~034131',
              quantities: [1]
            },
            {
              modifier: 'Classic Marinara',
              modifier_id: 'S~PS~034131',
              quantities: [1]
            }
          ],
          nutrition: {
            calories: 280,
            subitems: [],
            container: 'Slices',
            servings: '8',
            unit: 'Slice'
          },
          product: 'Create Your Own',
          product_id: '10495~P^B~034131',
          quantity: 15,
          size: 'Large Hand Tossed Pizza',
          special_instructions: ''
        }
      ],
      source: 'web',
      subtotal: 26048,
      tax: 28540,
      total: 29401
    }, {
      delivery_address: {
        address1: '124 MAIN ST',
        address2: '1419',
        city: 'PLANO',
        postal_code: '75024',
        state: 'TX'
      },
      delivery_fee: 499,
      delivery_instructions: '',
      discounts: [],
      forms_of_payment: [
        {
          name: 'DI-1117',
          type: 'creditcard'
        }
      ],
      occasion_id: OccasionApi.C,
      order_id: '3255021185824501488534131',
      order_time: '2021-10-21T18:58:00.000-05:00',
      points_earned: 530,
      products: [
        {
          cart_discount_id: '',
          discount_id: '',
          discount_step_id: '',
          image:
            'https://www.pizzahut.com/assets/w/tile/thor/pizzaBuilderPull.png',
          modifiers: [
            {
              modifier: 'Black Olives',
              modifier_id: 'V~BO~034131',
              quantities: [1]
            },
            {
              modifier: 'Classic Marinara',
              modifier_id: 'S~PS~034131',
              quantities: [1]
            }
          ],
          nutrition: {
            calories: 280,
            subitems: [],
            container: 'Slices',
            servings: '8',
            unit: 'Slice'
          },
          product: 'Create Your Own',
          product_id: '10495~P^B~034131',
          quantity: 15,
          size: 'Large Hand Tossed Pizza',
          special_instructions: ''
        }
      ],
      source: 'web',
      store_number: '034131',
      subtotal: 26048,
      tax: 28540,
      total: 29401
    }
  ],
  stores: [
    {
      address1: '124 MAIN ST',
      address2: '1419',
      address3: '',
      address4: '',
      city: 'PLANO',
      country: 'US',
      landmark: 'North of W Wellington Ave',
      phone: '773-549-8993',
      postal_code: '75024',
      state: 'TX',
      store_number: '034131',
      timezone: ''
    },
    {
      address1: '234 Main Street',
      address2: '',
      address3: '',
      address4: '',
      city: 'Newark',
      country: 'US',
      phone: '315-666-1234',
      postal_code: '14513',
      state: 'NY',
      store_number: '000001',
      timezone: ''
    }
  ]
};

export const transformedOrderHistoryStub: OrderHistoryType = {
  orders: [
    {
      address: {
        address1: '100 MEMORY LN',
        address2: '1419',
        city: 'PLANO',
        zipcode: '75024',
        state: 'TX'
      },
      paymentTypes: [
        {
          name: 'DI-1117',
          type: 'creditcard'
        }
      ],
      storeNumber: '045031',
      occasionId: Occasion.DELIVERY,
      orderId: '3211021185824001488034131',
      orderTime: '2021-10-21T18:58:00.000-05:00',
      pointsEarned: 530,
      products: [
        {
          image:
            'https://www.pizzahut.com/assets/w/tile/thor/pizzaBuilderPull.png',
          modifiers: [
            {
              name: 'Black Olives',
              id: 'V~BO~034131',
              quantities: [1]
            },
            {
              name: 'Classic Marinara',
              id: 'S~PS~034131',
              quantities: [1]
            }
          ],
          nutrition: {
            calories: 280,
            subitems: [],
            container: 'Slices',
            servings: '8',
            unit: 'Slice'
          },
          name: 'Create Your Own',
          id: '10495~P^B~034131',
          quantity: 15,
          size: 'Large Hand Tossed Pizza'
        }
      ],
      total: 29401
    },
    {
      address: {
        address1: '124 MAIN ST',
        address2: '1419',
        city: 'PLANO',
        zipcode: '75024',
        state: 'TX'
      },
      paymentTypes: [
        {
          name: 'DI-1117',
          type: 'creditcard'
        }
      ],
      storeNumber: '034131',
      occasionId: Occasion.CARRYOUT,
      orderId: '3255021185824501488534131',
      orderTime: '2021-10-21T18:58:00.000-05:00',
      pointsEarned: 530,
      products: [
        {
          image:
            'https://www.pizzahut.com/assets/w/tile/thor/pizzaBuilderPull.png',
          modifiers: [
            {
              name: 'Black Olives',
              id: 'V~BO~034131',
              quantities: [1]
            },
            {
              name: 'Classic Marinara',
              id: 'S~PS~034131',
              quantities: [1]
            }
          ],
          nutrition: {
            calories: 280,
            subitems: [],
            container: 'Slices',
            servings: '8',
            unit: 'Slice'
          },
          name: 'Create Your Own',
          id: '10495~P^B~034131',
          quantity: 15,
          size: 'Large Hand Tossed Pizza'
        }
      ],
      total: 29401
    }
  ]
};

export const transformedDeliveryStub = (): SingleOrder => transformedOrderHistoryStub.orders[0];
export const transformedCarryoutStub = (): SingleOrder => transformedOrderHistoryStub.orders[1];

export const orderHistoryStub: OrderHistoryClientResult = {
  result: transformedOrderHistoryStub,
  error: false
};

export const getUniqueOrders = (size: number): SingleOrder[] => Array(size)
  .fill(transformedOrderHistoryStub.orders[0])
  .map((order, index) => ({ ...order, orderId: `${order.orderId}${index}` }));

export const getOrdersResponseWithNoStoreNumber = (
  totalSize: number,
  sizeOfInvalidOrders: number
): OrderHistoryResponse => ({
  count: totalSize,
  orders: Array(totalSize)
    .fill(orderHistoryApiResponse.orders[0])
    .map((order, index) => ({
      ...order,
      order_id: `${order.order_id}${index}`,
      store_number: index < sizeOfInvalidOrders ? undefined : order.store_number
    })),
  stores: orderHistoryApiResponse.stores
});
