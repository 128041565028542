import loggingFetch from '../../../common/loggingFetch';
import { getPublicRuntimeConfig } from './getPublicRuntimeConfig';
import { shouldMockAPIResponse } from '@/testUtils/shouldMockAPIResponse';

export const getWithHeaders = (
  url: string,
  headers?: HeadersInit,
  fetchOptions?: RequestInit,
  mockApiResponse?: () => Promise<Response>
): Promise<Response> => {
  const { phdApiServerEnv } = getPublicRuntimeConfig();

  if (mockApiResponse && shouldMockAPIResponse()) {
    return mockApiResponse();
  }

  return loggingFetch(url, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'x-server-env': phdApiServerEnv,
      ...headers
    },
    ...fetchOptions
  });
};
