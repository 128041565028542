export const BeaconIds = {
  GLOBAL: {
    MENU_DROPDOWN_CATEGORY_CLICK: '10.01.02',
    DEALS_CLICK: '10.02.02',
    SIGN_IN_SIGN_OUT_CLICK: '10.03.02',
    CHANGE_LOCATION: '11.01.02',
    CART_CLICK: '11.02.02',
    FOOTER_LINKS: '12.01.02'
  },
  SIGN_IN_RAIL: {
    SIGN_IN: {
      PAGE_VIEW: '56.01.02',
      SUCCESS: '56.11.02',
      CREATE_ACCOUNT_FORGOT_PASS_CLICK: '56.03.02'
    },
    RESET_PASSWORD: {
      PAGE_VIEW: '58.01.02',
      EMAIL_SUBMITTED_ERROR: '58.02.02',
      EMAIL_SUBMITTED_SUCCESS: '58.03.02'
    }
  },
  BUILDER: {
    INITIAL_CASE: '29.01.02',
    EXPAND_SECTION: '29.02.02',
    COLLAPSE_SECTION: '30.04.02',
    X_BUTTON: '29.03.02',
    ADD_TO_ORDER_BUTTON_VIEW: '29.04.02',
    TOPPINGS: {
      ADD: '31.01.02',
      DEFAULT: '31.02.02',
      CONTINUE: '31.04.02',
      SEE_ALL: '31.03.02'
    },
    SCCF: {
      BACK: '30.03.02',
      CONTINUE: '32.03.02',
      EDIT: '32.01.02'
    },
    CRUST: {
      CONTINUE: '30.05.02'
    },
    CRUST_FLAVOR: '32.06.02',
    CRUST_TYPE: '30.02.02',
    CHEESE: '32.05.02',
    SAUCE: '32.04.02',
    SIZE: '30.01.02',
    SELECTOR: '29.05.02'
  },
  DEAL: {
    MULTI_STEP: {
      RENDER: '37.05.02'
    },
    CLICK_STEP: '37.02.02',
    BACK_ARROW: '38.04.02',
    INITIAL_LOAD: '37.01.02',
    MENU_TILE_CLICK: '16.02.02',
    PARENT_DEAL_PAGE_LOAD: '16.03.02',
    CHILD_DEAL_TILE_CLICK: '16.04.02',
    ADD_TO_DEAL: '39.04.02',
    ADD_NON_PIZZA_PRODUCT_TO_DEAL: '38.03.02',
    ADD_NON_PIZZA_PRODUCT_TO_DEAL_FROM_RAIL: '38.06.02',
    ON_MULTI_STEP_CLICK_ADD_TO_ORDER: '37.04.02',
    ON_MULTI_STEP_SUCCESS_ADD_TO_ORDER: '37.03.02',
    PIZZA_MENU_PAGE_LOAD: '39.05.02',
    ON_PRICING_TERMS_AND_CONDITIONS_CLICK: '48.17.02',
    LOCALIZED_INFO_BUTTON: {
      CLICK: '38.02.02'
    },
    NON_PIZZA_MENU_PAGE_LOAD: '38.01.02',
    PIZZA_MENU_TILE_CLICK: '39.06.02',
    TOAST: {
      CHECKOUT_CLICK: '15.09.02'
    }
  },
  MENU: {
    CATEGORIES_PAGE_VIEW: '14.01.02',
    CATEGORY_CLICK: '14.02.02',
    NATIONAL_GET_STARTED: '14.03.02',
    NATIONAL_SEE_MORE: '14.04.02',
    NATIONAL_TILE_CLICK: '14.05.02',
    INITIAL_PAGE_LOAD: '15.01.02',
    LOCALIZED_INFO_BUTTON: {
      CLICK: '15.02.02'
    },
    ADD_TO_CART: {
      TILE_CLICK: '15.03.02',
      TILE_SUCCESS: '15.04.02',
      INFO_RAIL_CLICK: '15.10.02',
      INFO_RAIL_SUCCESS: '15.07.02'
    },
    MODIFIER_CLICK: '15.05.02',
    INFO_RAIL_MODIFIER_CLICK: '15.11.02',
    LOCALIZE_RAIL_VIEW: '15.06.02',
    PIZZA_TILE: {
      CLICK: '15.03.02'
    },
    TOAST: {
      INITIAL_LOAD: '15.08.02',
      CHECKOUT_CLICK: '15.09.02'
    },
    DEALS: {
      INITIAL_LOAD: '16.01.02',
      NATIONAL_DEAL_MENU: '16.08.02'
    }
  },
  HOMEPAGE: {
    INITIAL_LOAD: '9.01.02',
    LOAD_PROMO: '9.02.02',
    PROMO_CLICK: '9.04.02',
    PAGE_SCROLL_TO_HUT_FAVORITES: '9.05.02',
    HUT_FAVORITE_CLICK: '9.06.02',
    RIBBON: {
      INITIAL_LOAD: '6.11',
      CLICK: '6.12'
    }
  },
  MODALS: {
    ERROR: '9.11',
    CLICK_ERROR: '9.12'
  },
  STORE: {
    SEARCH: {
      FORM: '80.02',
      SUCCESS: '80.03',
      NO_RESULT: '80.04',
      NO_RESULT_ACTION: '80.05',
      RESULTS: '80.06'
    },
    OPEN_LOCALIZATION: '80.01',
    TILE_CTA: '80.07',
    BUTTON: '80.08'
  },
  CHECKOUT: {
    CART: {
      DELIVERY: {
        DELIVERY_MINIMUM_ALERT: '48.09.02',
        CLOSE_BACK_TO_CART_BUTTON_MINIMUM: '48.10.02'
      },
      RAIL: '48.01.02',
      ORDER_ALERT_VIEW: '48.14.02',
      SERVICE_FEE_MESSAGE_DISPLAYED: '48.16.02',
      START_YOUR_ORDER: '48.18.02',
      GO_TO_CHECKOUT: '48.06.02',
      EDIT_LINKS: '48.02.02',
      NON_PIZZA_PRODUCT: {
        BACK_TO_CART_CLICK: '46.03.02',
        SAVE_CLICK: '46.02.02',
        EDIT_CLICK: '48.03.02',
        CLOSE_CLICK: '46.01.02'
      }
    },
    INITIAL_LOAD: '50.01.02',
    TIP: {
      REMOVE: '51.14.02',
      ADD: '51.11.02'
    },
    PAYMENT_OPTION: {
      SELECT: '51.02.02'
    },
    YUM_PAYMENT: {
      PROMO_IMPRESSION: '51.19.02',
      IFRAME_LOAD_FAIL: '51.17.02'
    },
    ACTION_CLICK: '51.07.02',
    EDIT_LINK_CLICK: '51.03.02',
    APPLY_COUPON_CLICK: '51.04.02',
    OCCASION_SECTION_ACTION_CLICK: '51.07.02',
    CHANGE_OCCASION: {
      OPEN_MODAL: '48.11.02',
      CLICK_CONTINUE_OR_CANCEL: '48.12.02'
    },
    PLACE_ORDER_ERROR: '51.16.02',
    PLACE_ORDER_SUCCESS: '51.08.02',
    HUT_REWARDS: {
      ENROLL: '51.10.02',
      ENROLL_ORDER_CONFIRMATION: '53.02.02',
      SUCCESS: '50.15.02'
    },
    GIFT_CARD: {
      APPLIED: '51.13.02',
      ERROR: '51.12.02'
    },
    CONTACT_INFO: {
      SIGNIN_FORM: '49.01.02'
    },
    DEAL_LOVERS: {
      ADD_ITEM: '15.13.02',
      REMOVE_ITEM: '15.14.02',
      RAIL_VIEW: '15.12.02'
    }
  },
  UPSELL: {
    INITIAL_LOAD: '21.01.02',
    CTA: '21.02.02',
    ADD_TO_CART_CLICK: '21.04.02',
    ADD_TO_CART_SUCCESS: '21.05.02',
    ADD_TO_CART_CLICK_FROM_RAIL: '21.10.02',
    ADD_TO_CART_SUCCESS_FROM_RAIL: '21.09.02',
    TOAST: {
      INITIAL_LOAD: '21.11.02',
      CHECKOUT_CLICK: '21.12.02'
    },
    MODIFIER_CLICK: '21.13.02'
  },
  LINEUP: {
    LINEUP_PAGE_LOAD: '33.01.02',
    TOAST: {
      CHECKOUT_CLICK: '15.09.02'
    },
    BUILD_PIZZA_CTA_LINK: '33.02.02',
    ADDED_TO_CART: '33.05.02',
    INFO_RAIL_CLICK: '15.02.02',
    PIZZA_BUILDER: {
      BACK_BUTTON_CLICK: '30.03.02',
      CONTINUE_BUTTON_CLICK: '32.03.02'
    }
  },
  ACCOUNT_MANAGEMENT: {
    MY_ACCOUNT: {
      PAGE_VIEW: '61.01.02'
    },
    HUT_REWARDS: {
      PAGE_VIEW: '62.01.02',
      CLICK_REDEEM: '63.03.02',
      OPT_OUT: '62.09.02',
      ACCOUNT_JOIN_PAGE_VIEW: '102.01.02',
      JOIN_NOW_CLICK: '102.02.02',
      SIGN_IN_CLICK: '102.03.02'
    },
    ORDERS: {
      PAGE_VIEW: '23.01.02',
      FAVORITES: {
        REORDER: '23.02.02',
        RENAME_SAVE: '24.07.02',
        RENAME_CANCEL: '24.08.02',
        DELETE: '23.03.02',
        DELETE_CONFIRM: '23.04.02',
        DELETE_CANCEL: '23.05.02'
      },
      ORDER_HISTORY: {
        VIEWMORE: '24.01.02',
        REORDER: '24.03.02'
      },
      ERROR: '24.09.02'
    },
    PROFILE: {
      PAGE_VIEW: '67.01.02',
      CLICK_ADD_NEW_DEBIT_CREDIT_CARD: '69.02.02',
      CLICK_CONFIRM_ON_MODAL_DELETE_DEBIT_CREDIT_CARD: '69.03.02',
      CLICK_CANCEL_ON_MODAL_DELETE_DEBIT_CREDIT_CARD: '69.04.02',
      CLICK_CONFIRM_ON_MODAL_DELETE_GIFT_CARD: '69.05.02',
      CLICK_CANCEL_ON_MODAL_DELETE_GIFT_CARD: '69.06.02',
      CLICK_TOGGLE_SEND_EMAIL_PROMOTIONS: '70.03.02'
    },
    CREATE_ACCOUNT: {
      PAGE_VIEW: '55.01.02',
      TEXT_ME_DEALS_CLICK: '55.02.02',
      REGISTRATION_FORM_SUCCESS: '55.06.02',
      CANCEL_CLICK: '55.08.02',
      CREATE_ACCOUNT_CLICK: ' 55.09.02'
    }
  },
  ORDER_CONFIRMATION: {
    INITIAL_LOAD: '53.01.02',
    ROKT_LOAD: '53.12.02',
    ROKT_COMPLETED: '53.14.02'
  }
};

export interface CustomWindowWithAnalytics {
  ph_analytics?: BaseAnalytics[];
  innerWidth?: number;
}

/* This session could be move to a pizza specific helper */
export enum ToppingsPlacementAnalytics {
  RIGHT = 'Right Half',
  LEFT = 'Left Half',
  WHOLE = 'Whole'
}

export enum ToppingsExtraAnalytics {
  EXTRA = 'Extra',
  UNEXTRA = 'Unextra'
}

export enum ToppingsSelectionAnalytics {
  ADD = 'Add',
  UNCHECK = 'Uncheck'
}

export type ToppingsAction =
  | ToppingsPlacementAnalytics
  | ToppingsExtraAnalytics
  | ToppingsSelectionAnalytics;

export enum BuilderSection {
  CRUST = 'Crust',
  TOPPINGS = 'Toppings',
  SCCF = 'Sauce & Cheese',
  SPECIAL_INSTRUCTIONS = 'Special Instructions'
}

export type BuilderSectionAction =
  | 'Crust'
  | 'Toppings'
  | 'Sauce & Cheese'
  | 'Special Instructions';

export enum SCCFSection {
  SAUCE = 'sauce',
  CHEESE = 'cheese',
  FLAVOR = 'flavor'
}

export enum SCCFSectionAction {
  SAUCE = 'Sauce',
  CHEESE = 'Cheese',
  FLAVOR = 'Crust Flavor'
}

/*  this should be replace by a domain type */

export interface PromoInputAnalytics {
  promoName: string;
  promoCreative: string;
  promoType: string;
  promoPosition: string;
  promoID?: string;
}

export interface ProductInputAnalytics {
  id: string;
  name: string;
  quantity: number;
  price: number;
  sauce: string;
  cheese: string;
  crust: string;
  size: string;
  finisher: string;
  veggies: string;
  meats: string;
}

export interface ItemInputAnalytics {
  itemIndex: number;
  itemList: string;
  itemPrice: number;
  itemName: string;
  itemCategory: string;
  itemID: string;
  itemPosition: string;
  itemAction?: string;
  outOfStock: boolean;
}

/*  Basic types */

export interface ScreenLevelAnalytics {
  screen_name: string | undefined;
  screen_level1: string | undefined;
  screen_level2: string | undefined;
  screen_level3: string | undefined;
  screen_level4: string | undefined;
  level2_breadcrumb?: string | undefined;
  level3_aggregation?: string | undefined;
  deal_id?: string | undefined;
  deal_name?: string | undefined;
}

interface EventAnalytics {
  event_action: string;
  event_category: string;
  event_label: string;
  event_name?: string;
  event_interaction?: string;
  event_interaction_location?: string;
}

export type PageViewWithItemsAnalytics = Omit<
BaseItemsAnalytics,
'event_action' | 'event_category' | 'event_label'
>;

export type PageViewWithNationalItemsAnalytics = Omit<
BaseNationalItemsAnalytics,
'event_action' | 'event_category' | 'event_label'
>;

export interface PageViewAnalytics
  extends Omit<
  BaseAnalytics,
  'event_action' | 'event_category' | 'event_label'
  > {
  event_category?: string;
  event_label?: string;
  event_action?: string;
}

export interface CommonBaseAnalytics extends ScreenLevelAnalytics {
  event: string;
}

export interface BaseAnalytics extends CommonBaseAnalytics, EventAnalytics {
  beacon_id: string;
  form_name?: string;
  product_name?: string;
  product_name_hit?: string | null;
  product_action?: string;
  session_remember_occasion?: string;
  page_name?: string;
}

export interface CommonPizzaBulderAnalytics extends CommonBaseAnalytics {
  form_name: string;
}

export interface NoScreenLevelAnalytics extends EventAnalytics {
  event: string;
  beacon_id: string;
  form_name?: string;
  product_name_hit?: string;
  product_action?: string;
}

/*  Error types */

export interface ErrorAnalytics extends BaseAnalytics {
  error_message: string;
  error_code?: string;
}

/*  Item types */

export interface BaseItemsAnalytics extends BaseAnalytics {
  items: (ItemAnalytics | undefined)[];
  deal_id?: string;
  deal_name?: string;
}

export interface ItemsAnalytics extends PageViewAnalytics {
  items: (ItemAnalytics | undefined)[];
}

export interface BaseNationalItemsAnalytics extends BaseAnalytics {
  items: (NationalItemAnalytics | undefined)[];
}

export interface ItemAnalytics {
  item_index: number;
  item_list: string;
  item_price: number;
  item_name: string;
  item_category: string;
  item_position: string;
  item_action?: string;
  item_id?: string;
  item_stock?: string;
  item_size?: string;
  item_modifier?: string | null;
}

export interface NationalItemAnalytics
  extends Omit<ItemAnalytics, 'item_price'> {
  item_price: number | null;
}

/*  Store Search types */

export interface BaseStoreSearchAnalytics extends BaseAnalytics {
  store_search_term: string | undefined;
  store_search_number_results: string;
  delivery_initial_promise_time_range?: string;
  store_search_results_store_id?: string;
}

/*  Site types */

export interface BaseSiteAnalytics extends BaseAnalytics {
  site_type: string;
  code_version: string;
}

/* Third Party types */

export interface OptimizelyAnalytics extends Partial<BaseAnalytics> {
  optimizely_experiment_name: string;
  optimizely_variation_name: string;
  event: string;
  beacon_id: string;
  optimizely_flag_name: string | null;
  optimizely_variation_id?: number;
}

/*  Promo types */

export interface BasePromosAnalytics extends BaseAnalytics {
  promos: PromoAnalytics[];
  tile_alignment?: string; // check why appear only once
}

export interface PromoAnalytics {
  // rootStateTypes also has this definition
  promo_id?: string;
  promo_name: string;
  promo_creative: string;
  promo_type: string;
  promo_position: string;
}

export interface BaseProductsAnalytics<T extends ProductAnalytics>
  extends BaseAnalytics {
  product_action?: string;
  products: T[];
}

export interface LineupProductsAnalytics<T extends ProductAnalytics>
  extends Omit<
  BaseAnalytics,
  'event_action' | 'event_category' | 'event_label'
  > {
  event_category?: string;
  event_label?: string;
  event_action?: string;
  product_action?: string;
  products: T[];
}

export interface ProductAnalytics {
  product_price: number | null;
  product_name: string;
  product_index: number;
  product_category: string;
  product_position?: string;
  product_added_location?: string;
  product_deal_id?: string;
  product_deal_name?: string | null;
}

export interface MenuProductAnalytics extends ProductAnalytics {
  product_id: string;
  product_quantity: number;
  product_action_list: string;
  product_modifier: string | null;
  product_size: string | undefined | null;
  product_sauce?: string | undefined;
  product_cheese?: string | undefined;
  product_crust?: string | undefined;
  product_finisher?: string | undefined;
  product_veggies?: string | undefined;
  product_meats?: string | undefined;
}

export interface PizzaProductAnalytics extends ProductAnalytics {
  product_size?: string;
  product_sauce: string;
  product_cheese: string;
  product_crust: string;
  product_finisher: string;
  product_veggies: string;
  product_meats: string;
  product_id: string;
  product_quantity?: number;
}

export interface ItemAsProductAnalytics extends ProductAnalytics {
  product_list: string;
}

export interface BaseWarningCart extends BaseAnalytics {
  modal_message: string;
  deal_id?: string;
  deal_name?: string;
}

export interface DealBaseAnalytics extends BaseAnalytics {
  deal_id?: string;
  deal_name?: string;
}

export interface CheckoutBaseAnalytics extends BaseAnalytics {
  checkout_step: string;
  order_coupon_code?: string;
  order_coupon_name?: string;
  cart_views?: string;
  products?: MenuProductAnalytics[];
}

export interface OrderConfirmedAnalytics extends CheckoutBaseAnalytics {
  service_fee: string;
  delivery_fee?: string;
  driver_tip_percentage: string | null;
  driver_tip_amount: number | null;
  transaction_affiliation?: string;
  transaction_id: string;
  transaction_revenue: string;
  transaction_discount?: string;
  transaction_tax: string;
  payment_method?: string;
  order_items_count: number;
  preorder_orders: string;
  order_type_preorder: string;
  card_saved_status: string;
  contactless: string | boolean;
  products_sold_count?: number;
  pizzas_sold_count?: number;
  order_coupon_redemptions?: number;
  currency_code?: string;
}

export interface DragonTailIframeAnalytics {
  beacon_id: string;
  event: string;
  stage_name?: string;
  stage_range?: string;
}

export interface SearchResultsAnalytics extends BaseAnalytics {
  localization_version: string;
}
