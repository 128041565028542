import { Action, combineReducers } from 'redux';
import {
  CARRYOUT_SEARCH_SUCCESS,
  DELIVERY_SEARCH_SUCCESS,
  LOCALIZATION_INITIALIZED,
  OCCASION_CHANGED,
  OCCASION_RESET,
  SELECT_STORE,
  SELECT_STORE_FAILED,
  STORE_CHANGED,
  STORE_RESET
} from './actionTypes';
import {
  CarryoutSearchSuccess,
  DeliverySearchSuccess,
  StoreInfo
} from './localizeActions';
import { SET_TIMESTAMP } from '@/ReduxApplication/actionTypes';
import Time from '../common/time';

const stores = (state = [], action: Action) => {
  switch (action.type) {
    case DELIVERY_SEARCH_SUCCESS: {
      return (action as DeliverySearchSuccess).deliverySearchResults.stores || [];
    }
    case CARRYOUT_SEARCH_SUCCESS: {
      return (action as CarryoutSearchSuccess).carryoutSearchResults;
    }
    default: {
      return state;
    }
  }
};

const localizedStore = (state = 'loading', action: Action) => {
  switch (action.type) {
    case SELECT_STORE: {
      return (action as StoreInfo).storeNumber;
    }
    case SELECT_STORE_FAILED: {
      return (action as StoreInfo).storeNumber;
    }
    default: {
      return state;
    }
  }
};

const localizedStoreDetails = (state: StoreDetail | null = null, action: Action) => {
  switch (action.type) {
    case SELECT_STORE: {
      return (action as StoreInfo).store || null;
    }
    case SELECT_STORE_FAILED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const localizedOccasion = (state: string | null = null, action: Action) => {
  switch (action.type) {
    case SELECT_STORE: {
      return (action as StoreInfo).occasion || null;
    }
    case SELECT_STORE_FAILED: {
      return (action as StoreInfo).occasion;
    }
    default:
      return state;
  }
};

const localizationInitialized = (state = false, action: Action) => {
  switch (action.type) {
    case LOCALIZATION_INITIALIZED:
      return true;
    default:
      return state;
  }
};

const timestamp = (state: string | null = null, action : Action) => {
  switch (action.type) {
    case SET_TIMESTAMP:
      return (action as any).timestamp;
    case SELECT_STORE:
      if ((action as any).storeTimezone) {
        return Time.getCurrentTimestampForTimezone(
          (action as any).storeTimezone,
          new Date()
        ).toString();
      }
      return state;
    default:
      return state;
  }
};

const isStoreChanged = (state = false, action: Action) => {
  switch (action.type) {
    case STORE_CHANGED:
      return true;
    case STORE_RESET:
      return false;
    default:
      return state;
  }
};

const isOccasionChanged = (state = false, action: Action) => {
  switch (action.type) {
    case OCCASION_CHANGED:
      return true;
    case OCCASION_RESET:
      return false;
    default:
      return state;
  }
};

const localizationDomain = combineReducers({
  stores,
  localizedStore,
  localizedStoreDetails,
  localizedOccasion,
  timestamp,
  localizationInitialized,
  isStoreChanged,
  isOccasionChanged
});

export default localizationDomain;
