import gql from 'graphql-tag';
import { MenuRecipeV3, PizzaMenuQuery, PizzaMenuQueryResultV3 } from '../../types/PizzaMenu';
import AvailabilityFragment from '../fragments/availability';
import checkAvailability from '@/graphql/helpers/checkAvailability';
import { CheckAvailabilityVariables } from '@/graphql/hooks/variables/useCheckAvailabilityVariables';
import { IsCyo, PizzaMenu } from '@/menu/pizza/pizzaMenuTypes';
import filterExists from '@/graphql/helpers/filterExists';

const rawQuery = gql`
  query getPizzas($envEnvironmentID: String!, $storeID: String!) {
    Homepage(envID: $envEnvironmentID, storeID: $storeID) {
      menu: category(categoryID: "pizza") {
        items: products {
          priority: displayOrder
          item {
            id
            name
            price
            imageURL
            exists
            outOfStock
            sodiumWarning
            description
            isCYO
            availability {
              ...AvailabilityFragment
            }
          }
        }
      }
    }
  }
  ${AvailabilityFragment}
`;

const parser = (
  data: PizzaMenuQueryResultV3,
  { occasion, storeTimeZone }: CheckAvailabilityVariables
): PizzaMenu => {
  const transformed = ({
    menu: {
      items: data?.Homepage?.menu?.items.map(({ item, priority }) => {
        const { available, ...rest } = checkAvailability(item, occasion, storeTimeZone);
        return {
          ...rest as MenuRecipeV3,
          isCYO: rest?.isCYO ? IsCyo.TRUE : IsCyo.FALSE,
          imageAltText: 'Pizza Image',
          staticLink: '',
          hidden: false,
          priority,
          available
        };
      })
        .filter((item) => item.available)
        .filter(filterExists)
    }
  });
  if (!transformed?.menu?.items?.length) {
    throw new Error('no valid pizzas found (either all are unavialable, or have exists = false)');
  }
  return transformed;
};

const query: PizzaMenuQuery = { query: rawQuery, parser };

export default query;
