import { ProductModifier } from '@/domain/product/types';

export const extractVariantCodeFromModifiers = (modifiers: ProductModifier[]): string | undefined => {
  // Finding variant code from bottom to fix issue for product modifiers,
  // which have variant code for types other than sizes. Ex: fries
  const modifierWithVariantCode = [...modifiers].reverse().find(
    (modifier) => modifier?.variantCode !== undefined
  );
  return modifierWithVariantCode?.variantCode;
};
