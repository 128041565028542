import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '@/utils';
import CartToastContent from './CartToastContent';
import { closeCartToast, selectors } from './slices/Toast.slice';
import {
  onToastCheckoutClick,
  onToastView
} from '@/dataAnalytics/dataAnalyticsHelper';
import telemetry from '../../telemetry';
import { AnalyticsDataModel } from '@/rootStateTypes';
import Toast from '@/coreComponents/Toast';
import { DEAL } from '@/deals/constants';
import { localizationSelectors } from '@/localization/localizationSelectors';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

type PersistedAnalyticsSingleton = Readonly<{
  get: () => AnalyticsDataModel | null;
  set: (value: AnalyticsDataModel) => AnalyticsDataModel | null;
  reset: () => AnalyticsDataModel | null;
}>;

const persistedAnalyticsSingleton: PersistedAnalyticsSingleton = (() => {
  let analyticsModel: AnalyticsDataModel | null = null;

  return Object.freeze({
    get: () => analyticsModel,
    set: (value: AnalyticsDataModel) => {
      analyticsModel = value;
      return analyticsModel;
    },
    reset: () => {
      analyticsModel = null;
      return analyticsModel;
    }
  });
})();

const hasDealAnalytics = (
  analytics?: AnalyticsDataModel | null
): analytics is AnalyticsDataModel => !isEmpty(analytics?.deal.deal_id)
  && !isEmpty(analytics?.deal.deal_name);

const CartToast = (): JSX.Element => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectors.showCartToast);
  const content = useSelector(selectors.content);
  const itemAnalytics = useSelector(selectors.itemAnalytics);
  const analytics = useAnalytics();
  const { analyticsDataModel } = analytics;
  const hasLoadingBar = useSelector(localizationSelectors.hasLoadingBar);
  const hasLoadingQuery = useSelector(localizationSelectors.hasLoadingQuery);
  const hasLoadingState = hasLoadingBar || hasLoadingQuery;

  const [categoryName, setCategoryName] = useState('Pizza');
  const [hasSentOnViewToastAnalytics, setHasSentOnViewToastAnalytics] = useState(false);

  useEffect(() => {
    const itemsCategory = itemAnalytics?.item_category;

    if (itemsCategory) {
      setCategoryName(itemsCategory);
    }
    setHasSentOnViewToastAnalytics(false);
  }, [itemAnalytics]);

  useEffect(() => {
    if (hasDealAnalytics(analyticsDataModel)) {
      persistedAnalyticsSingleton.set(analyticsDataModel);
      setCategoryName(DEAL);
    }
  }, [analyticsDataModel]);

  const closeToast = () => dispatch(closeCartToast());

  const handleOnGoToCart = () => {
    const persistedAnalytics = persistedAnalyticsSingleton.get();
    const lowerCaseCategory = categoryName?.toLowerCase();

    analytics.push(() => onToastCheckoutClick(
      (categoryName),
      hasDealAnalytics(persistedAnalytics),
      persistedAnalytics?.deal
    ));

    telemetry.addCustomEvent(`localized-menu-${lowerCaseCategory}-toast-CTA-click`);

    closeToast();
    persistedAnalyticsSingleton.reset();
  };

  useEffect(() => {
    if (!hasSentOnViewToastAnalytics && isOpen && !hasLoadingState) {
      const persistedAnalytics = persistedAnalyticsSingleton.get();
      const isDeal = categoryName === DEAL;

      analytics.push(() => onToastView(
        isDeal ? DEAL : itemAnalytics?.item_category || categoryName,
        itemAnalytics,
        isDeal ? persistedAnalytics?.deal.deal_id : undefined,
        isDeal ? persistedAnalytics?.deal.deal_name : undefined
      ));
      setHasSentOnViewToastAnalytics(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, hasLoadingState, hasSentOnViewToastAnalytics]);

  return (
    <Toast
      testId="cartToast"
      id="added-to-order-toast"
      onClose={closeToast}
      displayTimeout={2000}
      isOpen={(isOpen && !hasLoadingState)}
      isKeyboardClick={content.isKeyboardClick}
    >
      <CartToastContent onGoToCart={handleOnGoToCart} content={content} />
    </Toast>
  );
};

export default CartToast;
