import { CategoryProductV3, ItemWithModifier } from '@/graphql/types/Category';
import {
  DisplayableAdditionalOptions,
  DisplayableModifier,
  DisplayableNutritionInfo,
  isPizzaProduct,
  DisplayableMenuItem,
  isOneClickDefaultsProduct,
  ProductModifier, DisplayableProduct
} from '@/domain/product/types';
import NutritionFragment, {
  NutritionNestedFragment
} from '@/graphql/types/fragment/Nutrition';
import checkAvailability from './checkAvailability';
import { OccasionApi } from '@/localization/constants';
import decodeEntities from './decodeEntities';
import { UPSELL } from '@/deals/constants';
import { CHEESE, SAUCE, SIZES } from '@/domain/constants';

interface ItemWithSelectedOption extends ItemWithModifier {
  defaultSelectedOption: string;
}
export interface DefaultOption {
  id: string;
  name: string;
  type: string;
  price: number;
  priority: undefined;
  modifiers: [];
  nutrition: Partial<NutritionFragment>[];
}
const isNonSizeGroup = (modifier: ItemWithModifier) => modifier.type === 'GROUP' && modifier.name !== 'Sizes';

const isSizeGroup = (modifier: ItemWithModifier) => modifier.type === 'GROUP' && modifier.name === 'Sizes';

const getDefaultOption = (key: string): DefaultOption => ({
  id: `default-${key}`,
  name: key,
  type: 'DEFAULT',
  price: 0,
  priority: undefined,
  modifiers: [],
  nutrition: []
});

const transformNutrition = (
  nutrition: Partial<NutritionNestedFragment>
): DisplayableNutritionInfo => ({
  unit: nutrition.unit ?? '',
  calories: nutrition.calories ?? 0,
  servings: nutrition.servings ?? '',
  name: nutrition.name ?? ''
});

const filterInvalidNutrition = (
  nutritionInfo: Partial<NutritionNestedFragment>
) => nutritionInfo?.name
  && nutritionInfo?.calories !== null
  && nutritionInfo?.calories !== undefined
  && nutritionInfo?.unit
  && nutritionInfo?.servings;

const transformSingleAdditionalOption = (
  modifier: ItemWithModifier,
  sizeNutritionInfoNames: string[]
): DisplayableModifier => {
  const hasNutrition = modifier.nutrition && modifier.nutrition.length > 0;
  let nutrition: DisplayableNutritionInfo[] = [];

  if (hasNutrition) {
    const additionalNutrition = modifier?.nutrition?.flatMap((nutritionInfo) => (nutritionInfo.additionalNutrition?.length
      ? nutritionInfo.additionalNutrition
      : []));

    const flattenedNutrition = [
      ...(modifier.nutrition ?? []),
      ...(additionalNutrition ?? [])
    ];

    nutrition = flattenedNutrition
      .filter(filterInvalidNutrition)
      .filter(
        (nutritionInfo) => nutritionInfo.name
          && !sizeNutritionInfoNames.includes(nutritionInfo.name)
      )
      .map(transformNutrition);
  }

  return {
    id: modifier.id || '',
    name: decodeEntities(modifier.name),
    priority: modifier.priority,
    isOutOfStock: modifier.outOfStock,
    sodiumWarning: modifier.sodiumWarning,
    type: modifier.type || '',
    price: modifier?.price ?? undefined,
    nutrition,
    modifiers: []
  };
};

export const priceExistsFor = (itemWithModifier: ItemWithModifier): boolean => !!itemWithModifier?.modifiers?.[0]?.modifiers?.[0].price;

export const extractSizes = (
  modifiers: ItemWithModifier[]
): ItemWithModifier[] => modifiers
  .filter(isSizeGroup)
  .flatMap((sizeGroup) => sizeGroup.modifiers ?? []);

export const extractAdditionalOptions = (
  modifiers: ItemWithModifier[],
  sizes: Partial<DisplayableModifier>[]
): ItemWithModifier[][] => {
  const additionalOptions = modifiers.filter(isNonSizeGroup);
  if (additionalOptions.length) {
    const nestedOptions = additionalOptions.map(
      (additionalOptionsGroup) => additionalOptionsGroup.modifiers ?? []
    );

    sizes.forEach(() => {
      if (nestedOptions.length < sizes.length) {
        nestedOptions.push(nestedOptions[0]);
      }
    });

    return nestedOptions;
  }

  return extractSizes(modifiers)
    .filter((size) => size.modifiers?.length)
    .map((sizeGroup) => sizeGroup.modifiers ?? []);
};

export const transformAdditionalOptionModifiers = (
  modifiers: ItemWithModifier[][],
  sizeNutritionInfoNames: string[],
  sizes: Partial<DisplayableModifier>[]
): DisplayableAdditionalOptions => {
  const additionalOptions: DisplayableAdditionalOptions = {};

  Object.entries(sizes).forEach(([index, size]) => {
    if (size.name) {
      const key = index as keyof typeof modifiers;
      const modifierArray = Array.isArray(modifiers[key])
        ? (modifiers[key] as ItemWithModifier[])
        : [];
      additionalOptions[size.name] = modifiers[key]
        ? modifierArray.map((modifier: ItemWithModifier) => transformSingleAdditionalOption(modifier, sizeNutritionInfoNames))
        : [];
    }
  });
  return additionalOptions;
};

export const transformSizeModifiers = (
  modifiers: ItemWithModifier[],
  defaultSelectedOptionName: string
) => modifiers?.map((modifier) => {
  const hasNutrition = modifier.nutrition && modifier.nutrition.length > 0;
  let nutrition: DisplayableNutritionInfo[] = [];

  if (hasNutrition) {
    const additionalNutrition = modifier?.nutrition?.flatMap(
      (nutritionInfo) => nutritionInfo.additionalNutrition?.filter(
        (additionalNutritionInfo) => additionalNutritionInfo.name !== defaultSelectedOptionName
      ) ?? []
    );
    const flattenedNutrition = [
      ...(modifier.nutrition ?? []),
      ...(additionalNutrition ?? [])
    ];

    nutrition = flattenedNutrition
      .filter(filterInvalidNutrition)
      .map(transformNutrition);
  }

  return {
    id: modifier.id || '',
    name: modifier.name || '',
    priority: modifier.priority,
    isOutOfStock: modifier.outOfStock,
    sodiumWarning: modifier.sodiumWarning,
    type: modifier.type || '',
    price: modifier?.price ?? undefined,
    nutrition,
    modifiers: []
  };
});

export const hasDefaultOption = (
  itemWithModifier: ItemWithModifier
): itemWithModifier is ItemWithSelectedOption => !!itemWithModifier.defaultSelectedOption;

export const insertDefaultOption = (
  item: ItemWithSelectedOption
): {
  defaultModifier: ItemWithModifier;
  item: ItemWithSelectedOption;
} => {
  const sizesGroup = item.modifiers?.find(isSizeGroup);
  const additionalOptionsGroup = item.modifiers?.find(isNonSizeGroup);
  const defaultModifier = getDefaultOption(item.defaultSelectedOption);

  const defaultItemNutrition = item.modifiers
    ?.find(isSizeGroup)
    ?.modifiers?.[0].nutrition?.[0].additionalNutrition?.find(
      (nutrition) => nutrition.name === defaultModifier.name
    );

  if (defaultItemNutrition) {
    defaultModifier.nutrition = [defaultItemNutrition];
  }

  return {
    defaultModifier,
    item: {
      ...item,
      modifiers: [
        ...(sizesGroup ? [sizesGroup] : []),
        {
          ...additionalOptionsGroup,
          modifiers: [
            defaultModifier,
            ...(additionalOptionsGroup?.modifiers
              ? additionalOptionsGroup.modifiers
              : [])
          ]
        }
      ]
    }
  };
};

const getDefaultAdditionalOption = (
  item: ItemWithModifier,
  defaultSelectedOption: string
) => {
  const additionalOptionsGroup = item.modifiers?.find(isNonSizeGroup);
  return additionalOptionsGroup?.modifiers?.find(
    (modifier) => modifier.name === defaultSelectedOption
  );
};

const getDefaultOptionValues = (item: ItemWithModifier) => {
  let defaultSelectedOptionName = '';
  let defaultAdditionalOption;
  let itemsWithAddedDefaultModifier;

  if (hasDefaultOption(item)) {
    defaultSelectedOptionName = item.defaultSelectedOption;
    defaultAdditionalOption = getDefaultAdditionalOption(
      item,
      defaultSelectedOptionName
    );
    if (!defaultAdditionalOption) {
      const { defaultModifier, item: updatedItemWithModifiers } = insertDefaultOption(item);
      itemsWithAddedDefaultModifier = updatedItemWithModifiers;
      defaultAdditionalOption = defaultModifier;
    }
  }

  return {
    defaultAdditionalOption,
    itemsWithAddedDefaultModifier,
    defaultSelectedOptionName
  };
};

const getPreSelectedAdditionalOption = (
  sizeNutritionInfoNames: string[],
  defaultAdditionalOption?: ItemWithModifier
) => {
  let preSelectedAdditionalOption;
  if (defaultAdditionalOption) {
    preSelectedAdditionalOption = transformSingleAdditionalOption(
      defaultAdditionalOption,
      sizeNutritionInfoNames
    );
  }
  return preSelectedAdditionalOption;
};

const extractDefaultModifiers = (modifiers: DisplayableModifier[]) => {
  if (!modifiers?.length) return [];
  return modifiers.reduce((previous: ProductModifier[], current) => {
    if (!current?.modifiers.length) return previous;
    const currentMods = current?.modifiers;
    currentMods?.forEach((modifier) => {
      if (modifier.selected) {
        // Transforms Modifiers With Multiple Levels
        if (current.name === SAUCE || current.name === CHEESE) {
          const { modifiers: childMods } = modifier;
          if (!childMods?.length) return previous;
          const selectedChildMod = childMods?.find((mod) => mod.selected);
          if (!selectedChildMod) return previous;
          const { name, id, type } = selectedChildMod;
          previous.push({
            id,
            name,
            type,
            quantities: [1]
          });
          return previous;
        }

        const { name, id, type } = modifier;
        const builtDefaultModifier = {
          id,
          name,
          type,
          quantities: [1]
        };

        previous.push(builtDefaultModifier);
      }
      return previous;
    });

    // Sizes currently have no selected modifiers waiting on changes from CT
    // this is temp fix to be dealt with as soon as size is provided defaults
    if (current.name === SIZES) {
      const sizeMod = current?.modifiers[0];
      if (sizeMod) {
        const {
          name, id, type, modifiers: sizeModifiers
        } = sizeMod;

        const transformedSizeMod: ProductModifier = {
          id,
          name,
          type,
          quantities: [1],
          modifiers: []
        };

        if (sizeModifiers.length) {
          const { name: modName, id: modId, type: modType } = sizeModifiers[0];
          const transformedCrustMod = {
            id: modId,
            name: modName,
            type: modType,
            quantities: [1]
          };
          transformedSizeMod?.modifiers?.push(transformedCrustMod);
        }
        previous.push(transformedSizeMod);
      }
    }

    return previous;
  }, []);
};

export const getOneClickDefaults = (product: DisplayableModifier) => {
  const { modifiers } = product;
  // extract default modifiers from all modifiers.
  return extractDefaultModifiers(modifiers);
};

export const transformItemToDisplayableProduct = (
  item: ItemWithModifier
): DisplayableMenuItem | DisplayableProduct => {
  let itemModified = item;
  const isPizza = isPizzaProduct(item);

  const {
    defaultAdditionalOption,
    itemsWithAddedDefaultModifier,
    defaultSelectedOptionName
  } = getDefaultOptionValues(item);

  if (itemsWithAddedDefaultModifier) {
    itemModified = itemsWithAddedDefaultModifier;
  }

  const commonProps = {
    id: itemModified.id || '',
    name: decodeEntities(itemModified.name),
    type: itemModified.type || '',
    description: decodeEntities(itemModified.description),
    imageUrl: itemModified.imageURL || '',
    isOutOfStock: itemModified.outOfStock,
    sodiumWarning: itemModified.sodiumWarning
  };

  if (isPizza) {
    return {
      ...commonProps,
      price: itemModified.price ?? undefined
    };
  }

  const modifiers = itemModified.modifiers ? itemModified.modifiers : [];

  const sizes: DisplayableModifier[] = transformSizeModifiers(
    extractSizes(modifiers),
    defaultSelectedOptionName
  );

  const sizeNutritionInfoNames: string[] = sizes.flatMap(
    (size) => size.nutrition?.map((nutrition) => nutrition.name) || []
  );

  const additionalOptions = transformAdditionalOptionModifiers(
    extractAdditionalOptions(modifiers, sizes),
    sizeNutritionInfoNames,
    sizes
  );

  const preSelectedAdditionalOption = getPreSelectedAdditionalOption(
    sizeNutritionInfoNames,
    defaultAdditionalOption
  );

  return {
    ...commonProps,
    sizes,
    additionalOptions,
    preSelectedAdditionalOption
  };
};

export const transformCategoryProductToDisplayableProduct = (
  categoryProduct: CategoryProductV3,
  occasion: OccasionApi,
  storeTimeZone: string,
  page?: string
): DisplayableMenuItem => {
  let product = categoryProduct.item;
  const isPizza = isPizzaProduct(product);
  // isOneClickDefaultsProduct uses default modifiers for oneclick product post to cart.
  const isOneClickDefaults = isOneClickDefaultsProduct(product);

  const {
    defaultAdditionalOption,
    itemsWithAddedDefaultModifier,
    defaultSelectedOptionName
  } = getDefaultOptionValues(categoryProduct.item);

  if (itemsWithAddedDefaultModifier) {
    product = itemsWithAddedDefaultModifier;
  }

  const commonProps = {
    available: checkAvailability(product, occasion, storeTimeZone).available,
    id: product.id || '',
    name: product.name || '',
    type: product.type || '',
    description: product.description || '',
    imageUrl: product.imageURL || '',
    isOutOfStock: product.outOfStock,
    sodiumWarning: product.sodiumWarning,
    displayOrder: categoryProduct.displayOrder,
    priority: product.priority
  };

  if (isPizza) {
    return {
      ...commonProps,
      price: product.price ?? undefined
    };
  }

  const modifiers = product.modifiers ? product.modifiers : [];

  const sizes = transformSizeModifiers(
    extractSizes(modifiers),
    defaultSelectedOptionName
  );
  const sizeNutritionInfoNames: string[] = sizes.flatMap((size) => size.nutrition.map((nutrition) => nutrition.name));

  const additionalOptions = transformAdditionalOptionModifiers(
    extractAdditionalOptions(modifiers, sizes),
    sizeNutritionInfoNames,
    sizes
  );

  const preSelectedAdditionalOption = getPreSelectedAdditionalOption(
    sizeNutritionInfoNames,
    defaultAdditionalOption
  );

  if (isOneClickDefaults) {
    const oneClickDefaults = getOneClickDefaults(
      product as DisplayableModifier
    );

    return {
      ...commonProps,
      sizes,
      additionalOptions: {},
      preSelectedAdditionalOption,
      oneClickDefaults // data is cart post ready
    };
  }

  return {
    ...commonProps,
    sizes,
    additionalOptions,
    preSelectedAdditionalOption
  };
};
