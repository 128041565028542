enum AngularEndpoints {
  CLEAR_CART = '/api.php/site/api_ajax/order/clearsession',
  GET_SUMMARY_DATA = '/api.php/site/api_pages/api_order_summary/getSummaryData',
  REMOVE_ALCOHOL = '/api.php/site/api_ajax/order/removeAlcohol',
  GET_WEB2_TOKEN = '/api.php/site/api_pages/api_lokenizer/get_web2_token',
  MERCHANT_VALIDATION = '/apple_pay_comm2.php',
  CONTACT_US = '/api.php/site/ajax/contact/storeFeedback',
  SCHOOL_LUNCH = '/api.php/site/api_pages/api_registration/schoolLunchSignup'
}

export default AngularEndpoints;
