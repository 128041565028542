import gql from 'graphql-tag';
import { HeaderParserOptions, HeaderQuery, HeaderResult } from '@/graphql/types/Header';
import ImageFragmentV3 from '../fragments/image.v3';
import LinkFragmentV3 from '../fragments/link.v3';
import { HeaderParsedResponse } from '@/header/HeaderResponseTypes';
import NationalCategoryFragmentV3 from '../fragments/nationalCategory.v3';
import { matchingCustomerLocalization, sortByIndex } from '../categories';
import { categoryIsNotLineup } from '@/graphql/helpers/filterCategories';
import { OrEmptyObj } from '@/utils';

const rawQuery = gql`
  query getHeader(
    $envEnvironmentID: String!
    $storeID: String!
    $occasions: [OccasionV3!]
  ) {
    Homepage(
      envID: $envEnvironmentID
      storeID: $storeID
      occasions: $occasions
    ) {
      header {
        logoImage {
          ...ImageFragmentV3
        }
        mainNav {
          mainNavMenuLinkText: menuLinkText
          mainNavDealsLink: dealsLink {
            ...LinkFragmentV3
          }
        }
        profileIcons {
          guest {
            ...ImageFragmentV3
          }
          guestHeading
          signedIn {
            ...ImageFragmentV3
          }
        }
        legacyUserConverter {
          joinText
          ctaLink {
            ...LinkFragmentV3
          }
          hutRewardsLogo {
            ...ImageFragmentV3
          }
        }
        accountSublinks: accountSubLinks {
          signIn {
            ...LinkFragmentV3
          }
          rewards {
            ...LinkFragmentV3
          }
          orders {
            ...LinkFragmentV3
          }
          profile {
            ...LinkFragmentV3
          }
          signOut {
            ...LinkFragmentV3
          }
        }
        cartImage {
          ...ImageFragmentV3
        }
        cartLinkTitle
      }
      categories {
        ...NationalCategoryFragmentV3
      }
    }
  }
  ${ImageFragmentV3}
  ${LinkFragmentV3}
  ${NationalCategoryFragmentV3}
`;

const parser = (
  data: HeaderResult,
  { customerIsNational }: HeaderParserOptions
): OrEmptyObj<HeaderParsedResponse> => {
  if (!data?.Homepage?.header) {
    return {};
  }

  const {
    logoImage,
    mainNav,
    profileIcons,
    legacyUserConverter,
    accountSublinks,
    cartImage,
    cartLinkTitle
  } = data.Homepage.header;

  return {
    header: {
      logoImage,
      ...mainNav,
      profileIcons,
      legacyUserConverter,
      accountSublinks,
      cartImage,
      cartLinkTitle
    },
    categories: data.Homepage.categories
      .filter(matchingCustomerLocalization(customerIsNational))
      .filter((category) => categoryIsNotLineup(category?.link?.link))
      .sort(sortByIndex)
  };
};

const query: HeaderQuery = { query: rawQuery, parser };
export default query;
