import { maxGuestTokenLength } from '@/auth/authTokenHelpers';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import jwtDecode, { JwtPayload } from 'jwt-decode';

export enum AuthState {
  GUEST = 'guest',
  LOGGED_IN = 'loggedIn',
  EXPIRED = 'expired',
  UNKNOWN = 'unknown'
}

export function authState(uAToken?: AuthToken, uATokenExpirationDate?: AuthTokenExpiration) {
  if (userIsGuest(uAToken)) {
    return AuthState.GUEST;
  }

  if (userIsLoggedIn(uAToken, uATokenExpirationDate)) {
    return AuthState.LOGGED_IN;
  }
  
  if (authSessionExpired(uATokenExpirationDate!)) {
    return AuthState.EXPIRED;
  }
  
  return AuthState.UNKNOWN;
}

function userIsGuest(uAToken: AuthToken | undefined) {
  return !uAToken || isGuestToken(uAToken);
}

function authSessionExpired(uATokenExpirationDate: AuthTokenExpiration | undefined) {
  if (!uATokenExpirationDate) return false;

  const expiration = new Date(uATokenExpirationDate).getTime();
  const now = new Date().getTime();

  return expiration <= now;
}

function userIsLoggedIn(uAToken: AuthToken | undefined, uATokenExpirationDate: AuthTokenExpiration | undefined) {
  if (isGuestToken(uAToken) || !uATokenExpirationDate) {
    return false;
  }

  const expiration = new Date(uATokenExpirationDate).getTime();
  const now = new Date().getTime();

  return now < expiration;
}

function isGuestToken(token?: AuthToken) {

  try {
    const { ext } = jwtDecode<JwtPayload>(token);
    if (!ext) {
      throw new Error();
    }
    return ext.is_guest;
  } catch (_) {
    // Note: guest tokens are currently stored in the same cookie as a logged-in user token, so
    // we need to check the length    
    return token ? token.length <= maxGuestTokenLength : false;
  }
}
