import React from 'react';
import {
  createStyles, makeStyles, Theme, CircularProgress
} from '@material-ui/core';
import colors from '@/common/colors';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative'
  },
  bottom: {
    color: `${colors.red}20`
  },
  top: {
    color: colors.red,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0
  },
  circle: {
    strokeLinecap: 'round'
  }
}));

type SpinnerProps = {
  size?: number;
};

function Spinner({ size = 30 }: SpinnerProps) {
  const classes = useStyles();
  return (
    <div className={classes.root} data-testid="indeterminate-spinner">
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={size}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle
        }}
        size={size}
        thickness={4}
      />
    </div>
  );
}

export default Spinner;
