import { includeCredentials, phdapiGet } from '@/api/phdApiClient/base';
import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import AccountManagementEndpoints from '@/api/phdApiClient/accountManagement/endpoints';
import createClientMethod from '@/api/createClientMethod';
import { CustomerLoyalty } from '@/domain/loyalty/CustomerLoyalty';

export interface CustomerLoyaltyResponse {
  coupons: CouponResponse[];
}
export interface CouponResponse {
  code: string;
  coupon_association_key: number;
  points: number;
}
const fetch = async () => phdapiGet(
  getUrl(AccountManagementEndpoints.CUSTOMER_LOYALTY),
  includeCredentials
);

const transformLoyalty = (res: CustomerLoyaltyResponse): CustomerLoyalty => ({
  coupons: res.coupons.map((coupon) => ({
    code: coupon.code,
    rewardsRedemptionCode: coupon.coupon_association_key,
    points: coupon.points
  }))
});

const transformer = async (
  response: Response
): Promise<ClientResult<CustomerLoyalty>> => (
  response.json()
).then((res) => ({
  error: false,
  result: transformLoyalty(res)
}));

const customerLoyalty = createClientMethod(fetch, transformer);

export default customerLoyalty;
