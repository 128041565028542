import { createStyles, makeStyles } from '@material-ui/core';
import fontStyles from '@/common/fontStyles';

const addedCoupons = makeStyles(() => createStyles({
  root: {
    padding: '0px 16px'
  },
  container: {
    paddingTop: '17px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'baseline'
  },
  icon: {
    width: '24px',
    height: '24px',
    position: 'relative',
    bottom: '-7px'
  },
  infoIcon: {
    backgroundColor: 'transparent',
    padding: '0 0 0 16px',
    '&:hover': {
      backgroundColor: 'transparent',
      '& svg > g g': {
        fill: '#131313'
      }
    }
  },
  link: {
    ...fontStyles.textLink,
    textTransform: 'none',
    paddingLeft: '16px'
  },
  title: {
    padding: '0px 2px 0 9px',
    fontFamily: 'open_sans',
    fontWeight: 600
  },
  name: {
    fontSize: '12px'
  },
  nameContainer: {
    padding: '0px',
    marginBottom: '24px'
  }
}));

const styles = { addedCoupons };

export default styles;
