/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AccountInfo, ContactMethods, Profile, ProfileAddress
} from '@/domain/Profile';
import { StoreState } from '@/store';
import {
  CardIdentifier,
  CreditCardInfo,
  EditedCreditCard,
  GiftCardInfo,
  isCreditCard,
  isGiftCard,
  PaymentType,
  SavedCreditCard
} from '@/domain/payments/types';
import { getCardTypeShort } from '@/api/phPayApiClient/helpers/getCardType';
import { HUT_REWARDS_PROMOTION_ID, PROMOTION_EMAIL_TYPE } from './contants';
import PrimarySortable from '@/domain/Sortables';

type Loading = {
  state: 'loading';
};
type NotLoaded = {
  state: 'not_loaded';
};
type Success = {
  state: 'success';
} & Profile;
type Error = {
  state: 'error';
};
type ProfileState = Loading | Success | Error | NotLoaded;

type UpdatePromotionType = { contactMethods: ContactMethods[]; promotionId: string };

export interface CouponsByCouponCode {
  [key: string]: Profile['coupons'];
}

const isSuccess = (state: ProfileState): state is Success => state.state === 'success';
const cardToState = ({
  primary, paymentId, name, cardNumber, expirationDate, postalCode
}: SavedCreditCard): CreditCardInfo => ({
  isDefault: primary,
  paymentId,
  name,
  paymentType: PaymentType.creditcard,
  cardInfo: {
    lastFour: cardNumber.slice(-4),
    cardType: getCardTypeShort(cardNumber),
    expiration: expirationDate,
    postalCode
  }
});

const editedCardToState = ({
  isDefault, paymentId, name, cardInfo
}:EditedCreditCard):EditedCreditCard => ({
  isDefault,
  paymentId,
  name,
  paymentType: PaymentType.creditcard,
  cardInfo: {
    lastFour: cardInfo.lastFour,
    cardType: cardInfo.cardType,
    expiration: cardInfo.expiration,
    postalCode: cardInfo.postalCode
  }
});

const removePrimary = (payments: Profile['payments']): Profile['payments'] => payments.map((payment) => ({
  ...payment,
  isDefault: false
}));

const addCreditCardToPayments = (
  previousPayments: Profile['payments'], newCard: SavedCreditCard
): Profile['payments'] => {
  const shouldRemovePrimary = newCard.primary;
  const newPayments = shouldRemovePrimary ? removePrimary(previousPayments) : previousPayments;
  return [...newPayments, cardToState(newCard)];
};

const editCreditCardToPayments = (
  previousPayments: Profile['payments'], editedCard : EditedCreditCard
) => {
  const shouldRemovePrimary = editedCard.isDefault;
  const allPayments = shouldRemovePrimary ? removePrimary(previousPayments) : previousPayments;
  const uneditedPayments = allPayments.filter(({ paymentId }) => paymentId !== editedCard.paymentId);
  // need to remove card and add edited card to complete collection

  return [...uneditedPayments, editedCardToState(editedCard)];
};

const deleteCardFromPayments = (
  previousPayments: Profile['payments'],
  { id, type }: CardIdentifier
): Profile['payments'] => previousPayments
  .filter((payment) => payment.paymentId !== id || payment.paymentType !== type);

const updatePromotions = (
  previousPromotions: Profile['promotions'],
  { contactMethods, promotionId }: UpdatePromotionType
): Profile['promotions'] => previousPromotions
  .filter((promotion) => promotion.promotionId === promotionId)
  .map((promotion) => ({
    ...promotion,
    contactMethods
  }));

const deleteAddressFromCustomerAddresses = (
  previousAddresses: Profile['customerAddresses'],
  { addressId }: { addressId: string }
): Profile['customerAddresses'] => previousAddresses
  .filter((address) => address.addressId !== addressId);

const addGiftCardToPayments = (
  previousPayments: Profile['payments'], newCard: GiftCardInfo
): Profile['payments'] => [...previousPayments, newCard];

export const addCustomerAddress = (
  previousAddresses: Profile['customerAddresses'], newAddress: ProfileAddress
): Profile['customerAddresses'] => {
  if (newAddress.isDefault) {
    const nonPrimaryAddresses = previousAddresses.map((address) => ({
      ...address,
      isDefault: false
    }));
    return [...nonPrimaryAddresses, newAddress];
  }

  return [...previousAddresses, newAddress];
};

export const editCustomerAddress = (
  previousAddresses: Profile['customerAddresses'], newAddress: ProfileAddress
): Profile['customerAddresses'] => {
  const editedAddresses = previousAddresses.map((address) => {
    if (address.addressId === newAddress.addressId) {
      return newAddress;
    }
    return newAddress.isDefault ? { ...address, isDefault: false } : address;
  });

  return [...editedAddresses];
};

const { actions, reducer } = createSlice({
  name: 'user',
  initialState: { state: 'not_loaded' } as ProfileState,
  reducers: {
    loading: (): Loading => ({ state: 'loading' }),
    logout: (): NotLoaded => ({ state: 'not_loaded' }),
    success: (state, action: PayloadAction<Profile>): Success => ({
      state: 'success',
      ...action.payload
    }),
    error: (): Error => ({ state: 'error' }),
    editLoginAndSecurity: (state, action: PayloadAction<AccountInfo>): Success => {
      if (!isSuccess(state)) {
        throw Error('Tried to update login and security without a profile');
      }
      return {
        ...state,
        ...action.payload
      };
    },
    addCreditCard: (state, action: PayloadAction<SavedCreditCard>): Success => {
      if (!isSuccess(state)) {
        throw Error('Tried to add a credit card without a profile');
      }
      return {
        ...state,
        payments: addCreditCardToPayments(state.payments, action.payload)
      };
    },
    editCreditCard: (state, action: PayloadAction<EditedCreditCard>): Success => {
      if (!isSuccess(state)) {
        throw Error('Tried to edit a credit card without a profile');
      }
      return {
        ...state,
        payments: editCreditCardToPayments(state.payments, action.payload)
      };
    },
    deleteCard: (state, action: PayloadAction<CardIdentifier>) => {
      if (!isSuccess(state)) {
        throw Error('Tried to delete a card without a profile');
      }
      return {
        ...state,
        payments: deleteCardFromPayments(state.payments, action.payload)
      };
    },
    deleteAddress: (state, action: PayloadAction<{ addressId: `${string}` }>) => {
      if (!isSuccess(state)) {
        throw Error('Tried to delete an address without a profile');
      }
      return {
        ...state,
        customerAddresses: deleteAddressFromCustomerAddresses(
          state.customerAddresses,
          action.payload
        )
      };
    },
    updateCoupons: (
      state,
      action: PayloadAction<CustomerLoyaltyCoupon[]>
    ) => {
      if (!isSuccess(state)) {
        throw Error('Tried to update coupons without a profile');
      }
      state.coupons = action.payload;
    },
    updatePromotions: (
      state,
      action: PayloadAction<UpdatePromotionType>
    ): Success => {
      if (!isSuccess(state)) {
        throw Error('Tried to add a gift card without a profile');
      }
      return {
        ...state,
        promotions: updatePromotions(state.promotions, action.payload)
      };
    },
    addGiftCard: (
      state,
      action: PayloadAction<GiftCardInfo>
    ): Success => {
      if (!isSuccess(state)) {
        throw Error('Tried to add a gift card without a profile');
      }
      return {
        ...state,
        payments: addGiftCardToPayments(state.payments, action.payload)
      };
    },
    addAddress: (state, action: PayloadAction<ProfileAddress>) => {
      if (!isSuccess(state)) {
        throw Error('Tried to add an address without a profile');
      }
      return {
        ...state,
        customerAddresses: addCustomerAddress(state.customerAddresses, action.payload)
      };
    },
    editAddress: (state, action: PayloadAction<ProfileAddress>) => {
      if (!isSuccess(state)) {
        throw Error('Tried to edit an address without a profile');
      }
      return {
        ...state,
        customerAddresses: editCustomerAddress(state.customerAddresses, action.payload)
      };
    }
    // [CUSTOMER_DETAILS_SUCCESS]: (state, action) => {
    //   console.log(state, action);
    // }
  }
});
const selectThisSlice = (state: StoreState): ProfileState => state.domain.user.profile;

export const sortByPrimary = (a: PrimarySortable, b: PrimarySortable): number => {
  if (a.isDefault) {
    return -1;
  }
  if (b.isDefault) {
    return 1;
  }
  return 0;
};

export const sortByBalance = (
  { cardInfo: { balance: balanceA } }: GiftCardInfo,
  { cardInfo: { balance: balanceB } }: GiftCardInfo
): number => (balanceB || Number.MIN_VALUE) - (balanceA || Number.MIN_VALUE);

const giftCardsFromProfile = (profile: Success): GiftCardInfo[] => (
  profile.payments as GiftCardInfo[]).filter(isGiftCard).sort(sortByBalance);

const isTpgc = (card: GiftCardInfo) => card.paymentType === PaymentType.tpgc;

const selectors = {
  loading: createSelector(selectThisSlice, (profile) => profile.state === 'loading'),
  error: createSelector(selectThisSlice, (profile) => profile.state === 'error'),
  profile: createSelector(selectThisSlice, (state): Profile | null => {
    if (!isSuccess(state)) {
      return null;
    }
    const { state: ignoredState, ...profile } = state;
    return profile;
  }),
  creditCards: createSelector(selectThisSlice, (profile): CreditCardInfo[] => {
    if (profile.state !== 'success') {
      return [];
    }
    return (profile.payments as CreditCardInfo[]).filter(isCreditCard).sort(sortByPrimary);
  }),
  addresses: createSelector(selectThisSlice, (profile): ProfileAddress[] => {
    if (!isSuccess(profile)) {
      return [];
    }
    return profile.customerAddresses.map((address) => address).sort(sortByPrimary);
  }),
  giftCards: createSelector(selectThisSlice, (profile): GiftCardInfo[] => {
    if (profile.state !== 'success') {
      return [];
    }
    return profile.payments.filter(isGiftCard).sort(sortByBalance);
  }),
  hasThirdPartyGiftCards: createSelector(selectThisSlice, (profile): boolean => {
    if (profile.state !== 'success') {
      return false;
    }
    return giftCardsFromProfile(profile).some(isTpgc);
  }),
  loyaltyToken: createSelector(selectThisSlice, (state): Profile['loyaltyToken'] => {
    if (!isSuccess(state)) {
      return '';
    }
    return state.loyaltyToken;
  }),
  getCoupons: createSelector(selectThisSlice, (state): Profile['coupons'] => {
    if (!isSuccess(state)) {
      return [];
    }
    return state.coupons;
  }),
  getCouponsByCode: createSelector(
    selectThisSlice,
    (state): CouponsByCouponCode => {
      if (!isSuccess(state)) {
        return {};
      }
      return state.coupons?.reduce((acc, coupon) => {
        const couponsByCode: CouponsByCouponCode = { ...acc };
        if (!couponsByCode[coupon.code]) {
          couponsByCode[coupon.code] = [coupon];
        } else {
          // eslint-disable-next-line
          // @ts-ignore
          couponsByCode[coupon.code].push(coupon);
        }

        return couponsByCode;
      }, {}) || {};
    }
  ),
  promotionsEmailContact: createSelector(selectThisSlice, (profile) => {
    if (!isSuccess(profile)) {
      return '';
    }

    return profile.email;
  }),
  hutRewardsPromotionsVia: createSelector(selectThisSlice, (profile) => {
    const errorReturn = { email: false };
    if (!isSuccess(profile)) return errorReturn;

    const hutRewardPromo = profile.promotions
      .find((promo) => promo?.promotionId === HUT_REWARDS_PROMOTION_ID);
    const promoStatus = hutRewardPromo?.contactMethods
      .find((promo) => promo.type === PROMOTION_EMAIL_TYPE);

    if (!hutRewardPromo || !promoStatus) return errorReturn;

    return { email: Boolean(promoStatus) };
  })
};

export {
  reducer, actions, selectors
};
