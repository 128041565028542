import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import { includeCredentials, phdapiGet } from '@/api/phdApiClient/base';
import createClientMethod from '@/api/createClientMethod';
import AccountManagementEndpoints from '@/api/phdApiClient/accountManagement/endpoints';
import {
  CustomerAddressResponse,
  CustomerDetailsResponse,
  PaymentResponse, PromotionResponse, transformCustomerDetails
} from '@/api/phdApiClient/transformPHDData';
import {
  ProfileAddress, Profile, Promotions, ProfileWithOldDataStructure
} from '@/domain/Profile';
import {
  CreditCardInfo, GiftCardInfo, PaymentType
} from '@/domain/payments/types';
import { transformAddress } from './addCustomerAddress';

const transformAddresses = (
  res: CustomerAddressResponse[]
): ProfileAddress[] => res?.map(transformAddress);

const transformCreditCard = (
  payment: PaymentResponse
): CreditCardInfo => ({
  isDefault: payment.is_default,
  paymentId: payment.payment_id,
  name: payment.name,
  paymentType: payment.type as PaymentType.creditcard,
  cardInfo: {
    cardType: payment.metadata.type,
    expiration: payment.metadata.expiration,
    lastFour: payment.metadata.last_four || '',
    postalCode: payment.metadata.postal_code
  }
});

const transformGiftCard = (
  payment: PaymentResponse
): GiftCardInfo => ({
  isDefault: payment.is_default,
  paymentId: payment.payment_id,
  paymentType: payment.type as PaymentType.giftcard | PaymentType.tpgc,
  cardInfo: {
    number: payment.metadata.number || '',
    balance: payment.metadata.balance || 0
  }
});

const transformPayments = (
  res: PaymentResponse[]
): (CreditCardInfo | GiftCardInfo
  )[] => res?.map((payment) => (
  payment.type === 'creditcard' ? transformCreditCard(payment) : transformGiftCard(payment)
));

const transformPromotions = (
  res: PromotionResponse[]
): Promotions[] => res?.map((promotion) => ({
  promotionId: promotion.promotion_id,
  birthDate: new Date(promotion.birth_date).toJSON(),
  contactMethods: promotion.contact_methods.map((contact) => ({
    type: contact.type,
    value: contact.value
  }))
}));

const transformLoginSecurity = (
  res: Pick<CustomerDetailsResponse, 'first_name' | 'last_name' | 'email' | 'phone'>
): Omit<Profile, 'loyaltyToken' | 'customerAddresses' | 'payments' | 'promotions'> => ({
  firstName: res.first_name,
  lastName: res.last_name,
  email: res.email,
  phone: res.phone
});

const transformCustomerProfile = (data: CustomerDetailsResponse): Profile => {
  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    first_name,
    last_name,
    email,
    phone,
    customer_addresses,
    payments,
    promotions,
    loyalty_token
  } = data;

  return {
    ...transformLoginSecurity({
      first_name, last_name, email, phone
    }),
    loyaltyToken: loyalty_token,
    customerAddresses: transformAddresses(customer_addresses),
    payments: transformPayments(payments),
    promotions: transformPromotions(promotions)
  };
  /* eslint-enable @typescript-eslint/naming-convention */
};

const fetch = async () => phdapiGet(
  getUrl(AccountManagementEndpoints.GET_CUSTOMER),
  includeCredentials
);

const transformer = async (
  response: Response
): Promise<ClientResult<ProfileWithOldDataStructure>> => (
  response.json()
)
  .then((res: CustomerDetailsResponse) => {
    // Call fails for guest users, but still a happy path?
    if (!res?.first_name && !res?.last_name) {
      return { error: false };
    }

    return ({
      error: false,
      result: {
        new: transformCustomerProfile(res),
        old: transformCustomerDetails({ response: res, status: 200 })
      }
    });
  });

export const getCustomer = createClientMethod(fetch, transformer);
