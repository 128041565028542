import gql from 'graphql-tag';
import CategoryItemFragment from '../fragments/categoryItem';
import { ProductByIdResultQuery } from '@/graphql/types/Product';
import transformer from './transformer';

const GET_PRODUCT_BY_ID = gql`
    query getProductById($storeID: String!, $itemID: ID!) {
        store(storeID: $storeID) {
            menu {
                item(itemID: $itemID) {
                    ...CategoryItemFragment
                }
            }
        }
    }
    ${CategoryItemFragment}
`;

const query = (): ProductByIdResultQuery => (
  { query: GET_PRODUCT_BY_ID, parser: transformer }
);

export default query;
