import React from 'react';

const SvgComponent = (props: any) => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(2.5 2.5)">
      <circle cx={7.5} cy={7.5} r={7.5} fill="#131313" />
      <circle cx={7.5} cy={7.5} r={6.563} fill="#FFF" />
      <path
        fill="#131313"
        d="M7.5 1.875c.259 0 .469.21.469.469v5.36l3.264 2.435c.19.141.228.41.087.598l-.005.008a.475.475 0 01-.647.097L7.03 8.31V2.344c0-.26.21-.469.469-.469z"
      />
    </g>
  </svg>
);

export default SvgComponent;
