import cookie from 'next-cookies';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import jwtDecode from 'jwt-decode';
import { NextPageContext } from 'next';
import { NATIONAL, Occasion } from '../constants';

const cookieName = 'localization-token';
export interface LocalizationDetails {
  expirationDate: number;
  issuedDate: number;
  occasion: Occasion;
  storeNumber: string;
  address: DeliveryAddress;
  boozeId: string;
  timezone?: string;
}

function convertUndefinedToNull<T>(input: T): T {
  const result: any = {};

  Object.keys(input).forEach((key) => {
    const value = (input as any)[key];
    result[key] = (value === undefined) ? null : value;
  });

  return result as T;
}

const parseLocalizationToken = (token: string): LocalizationDetails => {
  const jsonPayload = jwtDecode<any>(token);
  const address = jsonPayload.addr1 && {
    address: jsonPayload.addr1,
    address2: jsonPayload.addr2,
    city: jsonPayload.city,
    state: jsonPayload.state,
    zipcode: jsonPayload.zip
  };

  const occasionKey: keyof typeof Occasion = jsonPayload.occ;
  const parsedToken = {
    expirationDate: new Date(jsonPayload.exp * 1000).getTime(),
    issuedDate: new Date(jsonPayload.iat * 1000).getTime(),
    occasion: Occasion[occasionKey],
    storeNumber: jsonPayload.sn || NATIONAL,
    address: address && convertUndefinedToNull(address),
    boozeId: jsonPayload.bzid,
    timezone: jsonPayload.tz
  };

  // Converting empty fields to null makes parsed token serializable for hydration to client side
  return convertUndefinedToNull(parsedToken);
};

function getLocalizationTokenDetail(token?: string): LocalizationDetails | undefined {
  return token ? parseLocalizationToken(token) : undefined;
}

function getLocalizationToken(ctx: NextPageContext): string | undefined {
  return cookie(ctx)[cookieName];
}

function getUserStateCookie(ctx: NextPageContext): string | undefined {
  return cookie(ctx, { doNotParse: true }).user_state;
}

function getQOSESSIDCookie(ctx: NextPageContext): string | undefined {
  return cookie(ctx).QOSESSID;
}

export {
  getLocalizationToken,
  parseLocalizationToken,
  getUserStateCookie,
  getLocalizationTokenDetail,
  getQOSESSIDCookie
};
