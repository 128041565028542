import React from 'react';

export default function getJsonLDSchema() {
  const jsonldData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Pizza Hut',
    url: 'https://www.pizzahut.com',
    sameAs: [
      'https://www.facebook.com/pizzahutus/',
      'https://twitter.com/pizzahut',
      'https://www.instagram.com/pizzahut/'],
    logo: 'https://www.pizzahut.com/assets/ng/images/homepage/a/desktop-Logo-PH-Full.png'
  };

  return (
    /* eslint-disable react/no-danger */
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonldData) }}
    />
  );
}
