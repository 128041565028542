/* eslint-disable no-param-reassign */
import { compose, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/rootStateTypes';

interface Modal {
  hasPanCrustToppingModalShown: boolean;
}

// Initial state config
const initialModalState: Modal = {
  hasPanCrustToppingModalShown: false
};

const domain = (state: RootState): boolean => state.presentational.modal;

const isPanCrustToppingModalShown = (modal: Modal) => Boolean(modal.hasPanCrustToppingModalShown);

// Reducer

const modalSlice = createSlice({
  name: 'modal',
  initialState: initialModalState,
  reducers: {
    updatePanCrustToppingModalShown: (
      state: Modal,
      action: PayloadAction<boolean>
    ) => {
      state.hasPanCrustToppingModalShown = action.payload;
    }
  }
});

export const selectors = {
  isPanCrustToppingModalShown: compose(isPanCrustToppingModalShown, domain)
};

export const {
  updatePanCrustToppingModalShown
} = modalSlice.actions;

export default modalSlice.reducer;
