import { createStyles, makeStyles } from '@material-ui/core';

const styles = makeStyles(() => createStyles({
  root: {
    padding: '19px 16px',
    justifyContent: 'center',
    textAlign: 'center'
  },
  title: {
    paddingBottom: '13px'
  },
  subtitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    paddingBottom: '41px'
  }
}));

export default styles;
