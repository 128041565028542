import v3_query from './localizedFooter.v3';
import { FooterQuery } from '../../types/Footer';
import { GqlVersion } from '../../hooks/useGqlVersion/GqlVersion';
import national_v3_query from './nationalFooter.v3';
import cc_localized_v3_query from './ccGQLPartialLocalizedFooter.v3';

function footerNationalQuery(version?: GqlVersion): FooterQuery {
  switch (version) {
    default:
      return national_v3_query;
  }
}

function footerLocalizedQuery(version?: GqlVersion): FooterQuery {
  switch (version) {
    default:
      return v3_query;
  }
}

function ccGQLPartialFooterLocalizedQuery() {
  return cc_localized_v3_query;
}

export {
  footerNationalQuery as FOOTER_NATIONAL,
  footerLocalizedQuery as FOOTER_LOCALIZED,
  ccGQLPartialFooterLocalizedQuery as CC_GQL_PARTIAL_FOOTER_LOCALIZED
};
