export default {
  id: '12050609089824045607478584',
  firstName: 'JANE',
  hutRewardsMember: true,
  savedAddresses: [
    {
      address: '3333 PRESTON RD',
      addressTwo: '',
      city: 'FRISCO',
      index: 0,
      name: '',
      state: 'TX',
      zipcode: '75034'
    }
  ],
  hutLoversMarketingOptIn: false,
  birthdaySent: false,
  textOptIn: false
};
