import gql from 'graphql-tag';

const RulesFragment = gql`
  fragment RulesFragment on Item {
    rules {
      action
      actionItem {
        type
        name
        id
        price
      }
    }
  }
`;
export default RulesFragment;
