import { Cart, CartItem } from '@/domain/cart/types';
import { AnyAction, Dispatch } from 'redux';
import { OfSelectorsAndSuch } from '@/common/logout/actions';
import { StoreState } from '@/store';

interface CompleteAdress {
  streetNumber: number | undefined,
  streetAddress: string | undefined,
  city: string | undefined,
  state: string | undefined,
  zipcode: string | undefined
}

export function getCityStateZipFromCarryoutInput(input: string) {
  const stringHasComma = /,/.test(input);
  if (stringHasComma) {
    const [city, state] = input.split(',');
    return { city, state: state.trim() };
  }
  if (/^\d*$/.test(input)) {
    return { zipcode: input };
  }
  return { city: input };
}

export const carryoutToString = (carryoutParams: CarryoutSearchDetails | null) => {
  if (carryoutParams && carryoutParams.zipcode) {
    return carryoutParams.zipcode;
  }
  if (carryoutParams && carryoutParams.city && carryoutParams.state != null) {
    return `${carryoutParams.city}, ${carryoutParams.state}`;
  }
  if (carryoutParams && carryoutParams.city) {
    return carryoutParams.city;
  }
  return '';
};

export function getInputsFromGooglePlace(placeDetails: any): CompleteAdress {
  let streetNumber: number | undefined; let streetAddress: string | undefined; let city: string | undefined; let state: string | undefined; let
    zipcode: string | undefined;

  /* eslint no-unused-expressions: off */
  /* eslint camelcase: ["error", {allow: ["address_components"]}] */
  placeDetails?.address_components?.forEach((component: any) => {
    if (component.types[0] === 'street_number') {
      streetNumber = component.short_name;
    } else if (component.types[0] === 'route') {
      streetAddress = component.short_name;
    } else if (component.types[0] === 'locality' || (component.types[0] === 'neighborhood')) {
      /*
        For some places Google maps doesn't return locality
        instead it returns neighborhood that can be used as state.
      */
      city = component.short_name;
    } else if (component.types[0] === 'administrative_area_level_1') {
      state = component.short_name;
    } else if (!state
      && component.types[0] === 'country'
      && component.short_name !== 'US') {
      /*
        for Guam & Saipan - if there is no 'administrative_area_level_1' type,
        assign country as state
      */
      state = component.short_name;
    } else if (component.types[0] === 'postal_code') {
      zipcode = component.short_name;
    }
  });

  return {
    streetNumber, streetAddress, city, state, zipcode
  };
}

interface CartHelperProps {
  isAuthenticated: boolean;
  localizationToken?: string;
  currentItems: CartItem[];
  updateCart: (cart: Cart) => AnyAction;
  updateCartFail: () => AnyAction;
  selectors: OfSelectorsAndSuch;
  dispatch: Dispatch<StoreState>;
}
