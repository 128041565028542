import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import styles from '../styles';
import { SPECIAL_INSTRUCTIONS } from '@/clientCore/cart/constants';

interface SpecialInstructionsProps {
  specialInstructions: string;
}

const SpecialInstructions = ({ specialInstructions }: SpecialInstructionsProps) => {
  const classes = styles.cartItem();

  return (
    <Grid container className={classes.specialInstructionsSpacing} justifyContent="space-between">
      <Grid item xs={10}>
        <Typography component="span" className={classes.span}>
          {`${SPECIAL_INSTRUCTIONS}: ${specialInstructions}`}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography>&nbsp;</Typography>
      </Grid>
    </Grid>
  );
};

export default SpecialInstructions;
