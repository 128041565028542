const getCaloriesPerUnitText = (nutrition: Partial<Nutrition> | undefined | null, prefix = 'add ', suffix = ''): string | null => {
  if (!nutrition) return null;

  const { calories, unit } = nutrition;

  if (!calories && calories !== 0) return '';

  const units = unit || 'slice';

  return `${prefix}${calories} cal/${units}${suffix}`.toLowerCase();
};

export default getCaloriesPerUnitText;
