import { UnexpectedEscalatePrivilegesError } from '@/api/phdApiClient/accountManagement/escalatePrivileges';

const escalatePrivileges = typeof jest === 'undefined' ? Promise.resolve({}) : jest.fn();

export const success = (token: string): void => {
  (escalatePrivileges as jest.Mock).mockResolvedValue({ token });
};

export const invalidPassword = (): void => {
  (escalatePrivileges as jest.Mock).mockResolvedValue({ reason: 'incorrect password' });
};

export const unexpectedError = (): void => {
  (escalatePrivileges as jest.Mock).mockResolvedValue(new UnexpectedEscalatePrivilegesError());
};

export default escalatePrivileges;
