const deleteAddress = typeof (jest) === 'undefined' ? new Promise(() => {}) : jest.fn();

const successfulDeleteAddress = (): ClientResult<void> => {
  const mockDeleteAddress = deleteAddress as jest.Mock;
  const value = { error: false };
  mockDeleteAddress.mockResolvedValue(value);
  return value;
};

const failedDeleteAddress = (): ClientResult<void> => {
  const mockDeleteAddress = deleteAddress as jest.Mock;
  const value = { error: true };
  mockDeleteAddress.mockResolvedValue(value);
  return value;
};

export default deleteAddress;

export {
  successfulDeleteAddress,
  failedDeleteAddress
};
