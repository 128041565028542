import createClientMethod from '@/api/createClientMethod';
import { includeCredentials, phdapiPost } from '@/api/phdApiClient/base';
import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import AccountManagementEndpoints from '@/api/phdApiClient/accountManagement/endpoints';
import { PromotionsPayload } from '@/services/accountManagementService/phdApiAccountManagementService/types';

const fetch = async (promotion: PromotionsPayload) => phdapiPost(
  getUrl(`${AccountManagementEndpoints.UPDATE_PROMOTIONS}`),
  includeCredentials,
  JSON.stringify(promotion)
);

const transformer = async (response: Response): Promise<ClientResult<void>> => ({
  error: !response.ok
});

const updatePromotionsContact = createClientMethod(fetch, transformer);

export default updatePromotionsContact;
