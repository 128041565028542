import {
  CartItemInput,
  CartModifierInput,
  ItemType
} from '@pizza-hut-us-development/client-core';
import { Product, ProductModifier } from '@/domain/product/types';
import { extractVariantCodeFromModifiers } from '@/clientCore/helper/extractVariantCodeFromModifiers';

export const transformModifiers = (
  modifiers?: ProductModifier[],
  variantCode = ''
): CartModifierInput[] => (modifiers && modifiers.length > 0
  ? modifiers.reduce((allMods: CartModifierInput[], mod) => {
    // If there is NO variantCode that means its non Yum so push all modifiers.
    // OR if a modifier does have weight code OR slot code,
    // they are supposed to be part of selectedModifiers for YUM stores.
    if (!variantCode || mod.weightCode || mod.slotCode) {
      const newModifier = {
        id: mod.id,
        name: mod.name,
        type: mod.type as ItemType,
        modifiers: transformModifiers(mod.modifiers),
        quantities: mod.quantities,
        weightCode: mod.weightCode,
        slotCode: mod.slotCode
      };
      return [...allMods, newModifier];
    }
    return allMods;
  }, [])
  : []);

export const legacyProductTransformer = (item: Product): CartItemInput => {
  const variantCode = extractVariantCodeFromModifiers(item.selectedModifiers) || item.variantCode;
  const transformedItem: CartItemInput = {
    id: item.id ?? '',
    name: item.name,
    description: item.description,
    modifiers: transformModifiers(item.selectedModifiers, variantCode),
    type: item.type as ItemType,
    quantity: item.quantity,
    variantCode
  };
  return transformedItem;
};
