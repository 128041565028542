import gql from 'graphql-tag';
import { categoryIsNotLineup } from '@/graphql/helpers/filterCategories';
import { CategoriesQuery, CategoriesQueryResult, Category } from '../../types/Category';

const GET_CATEGORIES_LOCALIZED = gql`
    query getCategories($storeID: String, $envID: String!) {
        Homepage(envID: $envID, storeID: $storeID) {
            categories {
                name
                mainImage {
                    altText
                    desktop
                    mobile
                }
                displayName
                isNational
                products {
                    item {
                        id
                        exists
                    }
                }
            }
        }
    }
`;

export const parser = (data: CategoriesQueryResult): Category[] => {
  const categories = data?.Homepage?.categories || [];
  return categories.filter(
    (category) => category.products?.some(
      (categoryProduct) => categoryProduct.item.exists
    )
  )
    .filter((category) => categoryIsNotLineup(category?.displayName));
};

const query = (): CategoriesQuery => ({ query: GET_CATEGORIES_LOCALIZED, parser });

export default query;
