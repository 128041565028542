import { breakPointsValues } from '../../materialUi/theme';
import isClientSide from '@/common/isClientSide';

/* eslint-disable @typescript-eslint/no-explicit-any */
const getResponsiveLayout = () => {
  const width = isClientSide() ? (window as any)?.visualViewport?.width : undefined;

  switch (true) {
    case (width < breakPointsValues.sm):
      return 'Extra Small';
    case (width < breakPointsValues.md):
      return 'Small';
    case (width < breakPointsValues.lg):
      return 'Medium';
    case (width < breakPointsValues.xl):
      return 'Large';
    case (width >= breakPointsValues.xl):
      return 'Extra Large';
    default:
      return 'Invalid';
  }
};

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
const getUserAnalytics = (dataModel?: any): any => ({
  user_login_status: dataModel?.user?.loginStatus,
  user_id: dataModel?.user?.userId,
  user_promiseTime_carryout_initial: dataModel?.store?.initialCarryoutPromiseTime?.toString(),
  user_promiseTime_delivery_initial: dataModel?.store?.initialDeliveryPromiseTime?.toString(),
  user_localized: dataModel?.store?.localized,
  birthday_sent: dataModel?.user?.birthdaySent,
  text_optin: dataModel?.user?.textOptIn,
  store_id: dataModel?.store?.storeId,
  store_POS_type: dataModel?.store?.posType,
  store_city: dataModel?.store?.city,
  store_state: dataModel?.store?.state,
  store_zip: dataModel?.store?.zip,
  session_remember_occasion: dataModel?.user?.sessionRememberOccasion,
  session_remember_cart: dataModel?.user?.sessionRememberCart,
  user_rewards_member: dataModel?.user?.rewardsMember,
  user_hutLovers_marketing_opt_in: dataModel?.user?.hutLoversMarketingOptIn,
  page_responsive_layout: getResponsiveLayout()
});

export default getUserAnalytics;
