import { ProfileAddress } from '@/domain/Profile';
import { AddCustomerAddressPayload } from '@/services/accountManagementService/phdApiAccountManagementService/types';

const addCustomerAddress = typeof (jest) === 'undefined' ? new Promise(() => {}) : jest.fn();

const successfulAddCustomerAddress = (
  address: AddCustomerAddressPayload
): ClientResult<AddCustomerAddressPayload> => {
  const mockAddCustomerAddress = addCustomerAddress as jest.Mock;
  const value = {
    error: false,
    result: address
  };
  mockAddCustomerAddress.mockResolvedValue(value);
  return value;
};

const failedAddCustomerAddress = (): ClientResult<ProfileAddress> => {
  const mockAddCustomerAddress = addCustomerAddress as jest.Mock;
  const value = {
    error: true
  };
  mockAddCustomerAddress.mockResolvedValue(value);
  return value;
};

export default addCustomerAddress;

export {
  successfulAddCustomerAddress,
  failedAddCustomerAddress
};
