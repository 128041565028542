export const formatCCOpenTime = (time: string | undefined): string => {
  const dateString = `${new Date().toISOString().split('T')[0]}T${time}`;
  const date = new Date(dateString);
  const formattedTime = date
    .toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    })
    .replace(/ /g, '');
  if (formattedTime[0] === '0') return formattedTime.substring(1);
  return formattedTime;
};

export const convertIsoToEpoch = (isoStr: string): number => {
  const date = new Date(isoStr);
  return date.getTime();
};

export const minuteDifference = (
  firstTime: number,
  futureTime: number
): number => Math.floor((futureTime - firstTime) / 60);

export const calculateMinutesFromNow = (time: string | number): number => {
  const parsedTime = typeof time === 'number' ? time / 1000 : convertIsoToEpoch(time);
  const currentEpochTimeSeconds = new Date().getTime() / 1000;

  const minutesFromNow = minuteDifference(currentEpochTimeSeconds, parsedTime);

  return minutesFromNow;
};

export const convertIsoToDisplayableDate = (isoDate: string): string => {
  if (isoDate.length === 0) return '';
  const epochDate = convertIsoToEpoch(isoDate);
  return Intl.DateTimeFormat('en-US', {
    dateStyle: 'full',
    timeStyle: 'short'
  })
    .format(epochDate)
    .split(' at ')
    .join(', ');
};

export const convertToDisplayableDate = (time: string | number, timezone?: string): string => {
  const parsedTime = typeof time === 'number' ? time : convertIsoToEpoch(time);
  const parsedDate = new Date(parsedTime);
  // Shape Time
  const timeString = parsedDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: timezone ?? undefined
  });

  const weekdayString = parsedDate.toLocaleString('en-US', {
    weekday: 'short',
    timeZone: timezone ?? undefined
  });

  const dateString = parsedDate.toLocaleString('en-US', {
    dateStyle: 'medium',
    timeZone: timezone ?? undefined
  });

  return `${timeString} on ${weekdayString} ${dateString}`;
};
