import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import { includeCredentials, phdapiEdit } from '@/api/phdApiClient/base';
import createClientMethod from '@/api/createClientMethod';
import AccountManagementEndpoints from '@/api/phdApiClient/accountManagement/endpoints';

const fetch = async (
  id: string, name: string
) => phdapiEdit(
  getUrl(`${AccountManagementEndpoints.EDIT_FAVORITE_NAME}/${id}`),
  includeCredentials,
  JSON.stringify({ name })
);

const transformer = async (response: Response): Promise<ClientResult<void>> => ({
  error: !response.ok
});
export const editFavorite = createClientMethod(fetch, transformer);
