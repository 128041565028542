import React from 'react';

type Props = {
  className?: string;
};

export const CheckIcon = ({ className }: Props): JSX.Element => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM6.4 12L2.4 8.16123L3.528 7.07869L6.4 9.82726L12.472 4L13.6 5.09021L6.4 12Z" fill="#46983D" />
    <mask id="mask0_3383_4618" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM6.4 12L2.4 8.16123L3.528 7.07869L6.4 9.82726L12.472 4L13.6 5.09021L6.4 12Z" fill="white" />
    </mask>
    <g mask="url(#mask0_3383_4618)">
      <rect x="-4" y="-4" width="24" height="24" fill="#238618" />
    </g>
  </svg>

);
