import getConfig from 'next/config';
import { CreateLogger, Logger } from '../types';

const createLogger: CreateLogger = () => {
  function isClientSideLoggingEnabled() {
    const environment = getConfig()?.publicRuntimeConfig.ENVIRONMENT_NAME?.toLowerCase() || '';
    return environment === 'dev' || environment === 'local';
  }

  const consoleLogger: Logger = {
    /* eslint-disable no-console */
    info: (msg, ...extra) => console.log(msg, ...extra),
    error: (msg, ...extra) => console.error(msg, ...extra),
    warn: (msg, ...extra) => console.warn(msg, ...extra),
    debug: (msg, ...extra) => console.debug(msg, ...extra)
    /* eslint-enable no-console */
  };

  const defaultLogger: Logger = {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    info: (msg, ...extra) => {
    },
    error: (msg, ...extra) => {
    },
    warn: (msg, ...extra) => {
    },
    debug: (msg, ...extra) => {
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
  };

  return isClientSideLoggingEnabled() ? consoleLogger : defaultLogger;
};

export default createLogger;
