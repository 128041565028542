import v3_query from './hero.v3';
import { HeroQuery } from '../../types/Hero';
import { GqlVersion } from '../../hooks/useGqlVersion/GqlVersion';

function useQuery(version?: GqlVersion): HeroQuery {
  switch (version) {
    default:
      return v3_query;
  }
}

export default useQuery;
