import getConfig from 'next/config';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import authService from '@/services/authService';
import { userDomainSelectors } from '@/header/userDomainSelectors';
import { YUM_ID_TOKEN_COOKIE_KEY } from '@/configuration/constants';

const getLogoutUrl = async () => {
  const { publicRuntimeConfig } = getConfig();
  const cookieValue = await authService.getCookieValue(YUM_ID_TOKEN_COOKIE_KEY);
  if (cookieValue === null) {
    return null;
  }
  const logoutUrl = new URL(`${publicRuntimeConfig.YUM_BASE_URL}/oidc/session/end`);
  logoutUrl.searchParams.set('id_token_hint', cookieValue);
  return logoutUrl;
};

const YumLogoutHandler = (): ReactElement => {
  const [logoutUrl, setLogoutUrl] = useState<URL | null>(null);
  const isAuthenticated = useSelector(userDomainSelectors.isAuthenticated);

  const [{ enabled: yumLogoutFixEnabled }] = useDecision('fr-web-3642-yum_logout_fix');

  useEffect(() => {
    if (yumLogoutFixEnabled) {
      return;
    }

    const handleYumLogout = async () => {
      const url = await getLogoutUrl();
      if (url) {
        setLogoutUrl(url);
      }
    };
    if (!isAuthenticated) {
      handleYumLogout();
    }
  }, [isAuthenticated, yumLogoutFixEnabled]);

  return logoutUrl ? (
    <iframe
      data-testid="yum-logout-iframe"
      src={String(logoutUrl)}
      height={0}
      width={0}
      title="Yum Logout"
    />
  ) : <></>;
};

export default YumLogoutHandler;
