import { compact } from 'remeda';
import { isEmpty, Maybe, Nullable } from '@/utils';
import RuleAction from '../../../../common/RuleAction';
import findRuleBySizeAndCrust from '../../../../common/findRuleBySizeAndCrust';
import toIngredient from '../../dataTransformers/toIngredient';
import SelectedBy from '../../../../common/SelectedBy';
import {
  IngredientOptionWithPortions,
  Pizza,
  PizzaIngredient,
  PizzaIngredientOption,
  RuleItem
} from '../../dataTransformers/builderTypes';

type PizzaIngredientOrNull = Nullable<PizzaIngredient>;

const ingredientSelectedByUser = (pizzaIngredient: PizzaIngredientOrNull): boolean => {
  const { selectedBy } = pizzaIngredient ?? { selectedBy: SelectedBy.SYSTEM };
  return selectedBy !== SelectedBy.USER;
};

const selectedCrustAddRules = (pizza: Pizza): RuleItem[] => {
  const { pizzaOptions, size, crust } = pizza;

  if (!pizzaOptions?.rules) return [];

  const rules = pizzaOptions?.rules ?? { [RuleAction.ADD]: [] };
  return rules?.ADD ? (rules.ADD ?? []).filter(findRuleBySizeAndCrust(size?.id, crust?.id)) : [];
};

const shouldUpdateToppingIngredients = (
  ingredientsToAdd: PizzaIngredient[] = [],
  existingIngredients: PizzaIngredient[] = []
): boolean => {
  const alreadyAddedToPizza = (r: PizzaIngredient) => existingIngredients
    .map(({ id }) => id)
    .includes(r.id);

  const updatingExisting = ingredientsToAdd
    .filter(alreadyAddedToPizza)
    .filter(ingredientSelectedByUser);

  return ingredientsToAdd && isEmpty(updatingExisting);
};

const availableInIngredient = (modifierId: string) => (ingredient: PizzaIngredientOption) => (ingredient.id ? ingredient.id.includes(modifierId) && !ingredient.outOfStock : false);

const findCheeseOrSauceToAdd = (
  ingredients: IngredientOptionWithPortions[],
  modifierId: string,
  addRuleId: string
): Maybe<PizzaIngredient> => {
  const ingredientToAdd = ingredients
    .find(availableInIngredient(modifierId))
    ?.portions
    .find((ingredient) => ingredient.id === addRuleId);

  return ingredientToAdd && toIngredient(ingredientToAdd, SelectedBy.SYSTEM);
};

const findFinisherOrToppingsToAdd = (
  ingredients: PizzaIngredientOption[],
  addRuleId: string
): PizzaIngredient[] => compact(
  ingredients.filter(availableInIngredient(addRuleId)).map((ingredient) => toIngredient(ingredient, SelectedBy.SYSTEM))
);

const checkAddException = (addRules: RuleItem[] = [], defaultSauce: PizzaIngredientOrNull) : boolean => {
  // If default modifier does not match any ADD rule modifiers
  // and the default modifier is not restricted by a RESTRICT rule,
  // then all ADD rules will be ignored
  const exceptions = addRules.filter((addRule) => addRule.id === defaultSauce?.id);
  return exceptions.length > 0;
};

const updateAddRuleSauce = (pizza: Pizza, parentModifierId: string, addRuleItemId: string): PizzaIngredientOrNull => {
  const sauces = pizza.pizzaOptions?.sauces ?? [];
  const sauceToAdd = findCheeseOrSauceToAdd(sauces, parentModifierId, addRuleItemId);
  if (sauceToAdd
      && ingredientSelectedByUser(pizza.sauce)
      && checkAddException(pizza.pizzaOptions?.rules?.ADD, pizza.sauce)) {
    return sauceToAdd;
  }
  return null;
};

const updateAddRuleCheese = (pizza: Pizza, parentModifierId: string, addRuleItemId: string): PizzaIngredientOrNull => {
  const cheeses = pizza.pizzaOptions?.cheeses ?? [];
  const cheeseToAdd = findCheeseOrSauceToAdd(cheeses, parentModifierId, addRuleItemId);
  if (cheeseToAdd && ingredientSelectedByUser(pizza.cheese)) {
    return cheeseToAdd;
  }
  return null;
};

const updateAddRuleFinisher = (pizza: Pizza, addRuleItemId: string): PizzaIngredientOrNull => {
  const finishers = pizza.pizzaOptions?.finishers ?? [];
  const [finisherToAdd] = findFinisherOrToppingsToAdd(finishers, addRuleItemId);
  if (finisherToAdd && ingredientSelectedByUser(pizza.finisher)) {
    return finisherToAdd;
  }
  return null;
};

const updateAddRuleMeatToppings = (pizza: Pizza, addRuleItemId: string): PizzaIngredient[] => {
  const meatToppings = pizza.pizzaOptions?.meatToppings ?? [];
  const meatToppingsToAdd = findFinisherOrToppingsToAdd(meatToppings, addRuleItemId);
  if (shouldUpdateToppingIngredients(meatToppingsToAdd, pizza.meatToppings)) {
    return meatToppingsToAdd;
  }
  return [];
};

const updateAddRuleVeggieToppings = (pizza: Pizza, addRuleItemId: string): PizzaIngredient[] => {
  const veggieToppings = pizza.pizzaOptions?.veggieToppings ?? [];
  const veggieToppingsToAdd = findFinisherOrToppingsToAdd(veggieToppings, addRuleItemId);
  if (shouldUpdateToppingIngredients(veggieToppingsToAdd, pizza.veggieToppings)) {
    return veggieToppingsToAdd;
  }
  return [];
};

export {
  selectedCrustAddRules,
  updateAddRuleSauce,
  updateAddRuleCheese,
  updateAddRuleFinisher,
  updateAddRuleVeggieToppings,
  updateAddRuleMeatToppings
};
