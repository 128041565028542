import { createStyles, makeStyles } from '@material-ui/core';
import colors from '@/common/colors';
import {
  mobileStartBreakpoint,
  smallMobileStartBreakpoint
} from '@/materialUi/theme';
import fontStyles from '@/common/fontStyles';
import borders from '@/common/borders';

const coupon = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '4px'
  },
  container: {
    padding: '0px 16px',
    marginBottom: '16px'
  },
  borderTop: {
    borderTop: borders.gray400Border
  },
  borderBottom: {
    paddingTop: '7px',
    borderBottom: borders.gray400Border
  },
  link: {
    ...fontStyles.textLink,
    textTransform: 'none',
    paddingLeft: '16px',
    marginTop: '4px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      marginTop: '12px'
    }
  },
  title: {
    padding: '0px 2px 0 9px',
    color: colors.blue,
    fontFamily: 'open_sans_semi'
  },
  couponFieldLabel: {
    marginBottom: '8px',

    '& span': {
      display: 'inline-block',
      marginLeft: 0,
      lineHeight: 'normal'
    },

    '& span:first-child': {
      marginRight: '8px'
    }
  },
  couponInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      width: '220px'
    }
  },
  couponLeftSide: {
    paddingRight: '11px',
    flexGrow: 1
  },
  couponButton: {
    '& button': {
      width: '100%'
    },
    paddingLeft: '0px',
    paddingTop: '11px',
    [theme.breakpoints.up(smallMobileStartBreakpoint)]: {
      paddingLeft: '11px',
      paddingTop: '0px'
    }
  },
  warningContainer: {
    padding: '5px 10px',
    marginBottom: '5px'
  },
  warningIcon: {
    marginTop: '1px'
  },
  warningText: {
    marginLeft: '8px',
    fontSize: '12px',
    lineHeight: '18px',
    flex: '1'
  }
}));

const styles = { coupon };

export default styles;
