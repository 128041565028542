import { ProductDetails } from '@/account/orders/favorites/Favorites.types';

export enum RailType {
  SIGN_IN = 'signIn',
  GIFT_CARD_INFO = 'giftCardInfo',
  EDIT_FAV = 'editFavorite',
  ADD_FAV = 'addFavorite',
  FAV_DETAILS = 'favDetails',
  EDIT_LOGIN_INFO = 'editLoginInfo',
  MANAGE_ADDRESS = 'manageAddress',
  CONFIRM_LOCATION = 'confirmLocation'
}

export interface FavDetailsRailType {
  railType: RailType;
  products: ProductDetails[];
}

export interface RailSliceType {
  signIn: {
    options: {
      isOpen: boolean;
    };
  };
  giftCardInfo: {
    options: {
      isOpen: boolean;
    };
  };
  editFavorite: {
    options: {
      isOpen: boolean;
    };
  };
  addFavorite: {
    options: {
      isOpen: boolean;
    };
  };
  favDetails: {
    options: {
      isOpen: boolean;
      products: ProductDetails[];
    };
  };
  editLoginInfo: {
    options: {
      isOpen: boolean;
    };
  };
  manageAddress: {
    options: {
      isOpen: boolean;
    };
  };
  confirmLocation: {
    options: {
      isOpen: boolean;
      isShown: boolean;
    };
  };
}
