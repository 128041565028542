const removeDecimalDFromPrice = (dollarPrice): string => dollarPrice.split('.')[0];

const formattedPrice = (
  price?: number | null,
  removeDecimal = false,
  style = 'currency'
): string => {
  const dollarPrice = new Intl.NumberFormat('en-US',
    { style, currency: 'USD' })
    .format(price / 100);

  return removeDecimal ? removeDecimalDFromPrice(dollarPrice) : dollarPrice;
};

export default formattedPrice;
