import createClientMethod from '@/api/createClientMethod';
import { includeCredentials, phdapiDelete } from '@/api/phdApiClient/base';
import { getUrl } from '@/api/phdApiClient/helpers/getUrl';
import AccountManagementEndpoints from '@/api/phdApiClient/accountManagement/endpoints';

const fetch = async (id: string, type: string) => phdapiDelete(
  getUrl(`${AccountManagementEndpoints.CUSTOMER_PAYMENTS}/${id}/${type}`),
  includeCredentials
);

const transformer = async (response: Response): Promise<ClientResult<void>> => ({
  error: !response.ok
});

const deleteCard = createClientMethod(fetch, transformer);

export default deleteCard;
