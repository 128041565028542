import {
  FavoritesResponse
} from '@/api/phdApiClient/accountManagement/favorites/getFavorites.types';
import { Favorite } from '@/account/orders/favorites/Favorites.types';
import { Occasion } from '@/localization/constants';

const spookyHalloweenProduct = {
  product: 'Spooky halloween pizza',
  productId: '10495~P^B~034132',
  quantity: 15,
  nutrition: {
    calories: 300,
    subitems: [],
    container: 'Slices',
    servings: '8',
    unit: 'Slice'
  },
  modifiers: [
    {
      name: 'Hand Tossed Pizza',
      id: 'C~H~034131',
      quantities: [1]
    },
    {
      name: 'Pepperoni',
      id: 'M~P~034131',
      quantities: [1]
    },
    {
      name: 'Classic Marinara',
      id: 'S~PS~034131',
      quantities: [1]
    }
  ]
};
const largeHandTossedProduct = {
  product: 'Large Hand Tossed Pizza Create Your Own Pizza',
  productId: '10495~P^B~034131',
  quantity: 25,
  nutrition: {
    calories: 290,
    subitems: [],
    container: 'Slices',
    servings: '8',
    unit: 'Slice'
  },
  modifiers: [
    {
      name: 'Hand Tossed Pizza',
      id: 'C~H~034131',
      quantities: [1]
    },
    {
      name: 'Classic Marinara',
      id: 'S~PS~034131',
      quantities: [1]
    }
  ]
};
export const getFavoritesApiResponse: FavoritesResponse = {
  count: 1,
  favorites: [
    {
      delivery_address: {
        company: 'punking records',
        address1: '123 Ruby Soho',
        address2: '999',
        address3: '',
        address4: '',
        city: 'soho',
        state: 'NY',
        postal_code: '60606',
        country: 'USA',
        phone: '123-321-1324',
        phone_extension: '',
        delivery_instructions: '',
        type: '',
        name: '',
        is_default: true
      },
      discounts: [],
      favorite_id: '0',
      form_of_payment: {
        name: 'MC-0011',
        payment_id: '1',
        type: 'creditcard'
      },
      is_default: true,
      name: '$$$Big Money$$$',
      occasion_id: 'CARRYOUT',
      products: [
        {
          cart_discount_id: '',
          discount_id: '',
          discount_step_id: '',
          image:
            'https://www.pizzahut.com/assets/w/tile/thor/pizzaBuilderPull.png',
          modifiers: [
            {
              modifier: 'Hand Tossed Pizza',
              modifier_id: 'C~H~034131',
              quantities: [1]
            },
            {
              modifier: 'Classic Marinara',
              modifier_id: 'S~PS~034131',
              quantities: [1]
            }
          ],
          nutrition: {
            calories: 290,
            subitems: [],
            container: 'Slices',
            servings: '8',
            unit: 'Slice'
          },
          product: largeHandTossedProduct.product,
          product_id: largeHandTossedProduct.productId,
          quantity: largeHandTossedProduct.quantity,
          size: 'Large',
          size_id: 'P~L',
          special_instructions: ''
        },
        {
          cart_discount_id: '',
          discount_id: '',
          discount_step_id: '',
          image:
            'https://www.pizzahut.com/assets/w/tile/thor/pizzaBuilderPull.png',
          modifiers: [
            {
              modifier: 'Hand Tossed Pizza',
              modifier_id: 'C~H~034131',
              quantities: [1]
            },
            {
              modifier: 'Pepperoni',
              modifier_id: 'M~P~034131',
              quantities: [1]
            },
            {
              modifier: 'Classic Marinara',
              modifier_id: 'S~PS~034131',
              quantities: [1]
            }
          ],
          nutrition: {
            calories: 300,
            subitems: [],
            container: 'Slices',
            servings: '8',
            unit: 'Slice'
          },
          product: spookyHalloweenProduct.product,
          product_id: spookyHalloweenProduct.productId,
          quantity: spookyHalloweenProduct.quantity,
          size: 'Large',
          size_id: 'P~L',
          special_instructions: ''
        }
      ],
      store_number: '034131'
    }
  ],
  stores: [
    {
      address1: '3009 N. Clark St.',
      address2: '',
      city: 'Chicago',
      country: 'US',
      landmark: '',
      phone: '(773) 549-8993',
      postal_code: '60657',
      state: 'IL',
      store_number: '034131',
      timezone: ''
    }
  ]
};

export const favoriteStub: Favorite = {
  id: '0',
  isPrimary: true,
  occasionAddress: '3009 N. Clark St., Chicago, IL, 60657',
  paymentDetails: {
    name: 'MC-0011',
    paymentId: '1',
    type: 'creditcard'
  },
  name: '$$$Big Money$$$',
  occasion: Occasion.CARRYOUT,
  localizationAddress: {
    address1: '3009 N. Clark St.',
    city: 'Chicago',
    state: 'IL',
    zipcode: '60657'
  },
  products: [{ ...largeHandTossedProduct }, { ...spookyHalloweenProduct }],
  storeNumber: '034131'
};
export const transformedFavoritesDataStub: Favorite[] = [{ ...favoriteStub }];

export const favoritesStub = {
  result: transformedFavoritesDataStub,
  error: false
} as ClientResult<Favorite[]>;
