export const HEADER_QUERY_ERROR = 'HEADER_QUERY_ERROR';
export const HERO_QUERY_ERROR = 'HERO_QUERY_ERROR';
export const SIDEKICK_QUERY_ERROR = 'SIDEKICK_QUERY_ERROR';
export const FOOTER_QUERY_ERROR = 'FOOTER_QUERY_ERROR';
export const PIZZA_MENU_QUERY_ERROR = 'PIZZA_MENU_QUERY_ERROR';
export const DEAL_QUERY_ERROR = 'DEAL_QUERY_ERROR';
export const DEAL_MENU_QUERY_ERROR = 'DEAL_MENU_QUERY_ERROR';
export const DEAL_PRODUCTS_BY_ID_QUERY_ERROR = 'DEAL_PRODUCTS_BY_ID_QUERY_ERROR';
export const CLEAR_DEAL_ERROR = 'CLEAR_DEAL_ERROR';
export const PIZZA_MENU_QUERY_DATA_ERROR = 'PIZZA_MENU_QUERY_DATA_ERROR';
export const PIZZA_BUILDER_QUERY_ERROR = 'PIZZA_BUILDER_QUERY_ERROR';
export const CLEAR_PIZZA_ERROR = 'CLEAR_PIZZA_ERROR';
export const GET_PRODUCTS_QUERY_ERROR = 'GET_PRODUCTS_QUERY_ERROR';
export const GET_CATEGORIES_QUERY_ERROR = 'GET_CATEGORIES_QUERY_ERROR';
export const CLEAR_GET_CATEGORIES_QUERY_ERROR = 'CLEAR_GET_CATEGORIES_QUERY_ERROR';
export const RESET_GET_PRODUCTS_QUERY_ERROR = 'RESET_GET_PRODUCTS_QUERY_ERROR';
export const RESET_ALL_GQL_ERRORS = 'RESET_ALL_GQL_ERRORS';
