import { createStyles, makeStyles } from '@material-ui/core';
import colors from '@/common/colors';

const styles = makeStyles(() => createStyles({
  root: {
    padding: '19px 16px 19px 16px',
    position: 'sticky',
    bottom: '0',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: colors.white
  },
  title: {
    textTransform: 'none',
    paddingBottom: '12px'
  },
  content: {
    paddingBottom: '31px'
  },
  add7DLItemButton: {
    paddingBottom: '15px'
  }
}));

export default styles;
