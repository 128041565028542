const rankFavorites = typeof (jest) === 'undefined' ? new Promise(() => {}) : jest.fn();

const successfulMakePrimary = (): void => {
  const mockRankFavorites = rankFavorites as jest.Mock;
  mockRankFavorites.mockResolvedValue({ error: false });
};

const failedMakePrimary = (): void => {
  const mockRankFavorites = rankFavorites as jest.Mock;
  mockRankFavorites.mockResolvedValue({ error: true });
};

export default rankFavorites;

export {
  successfulMakePrimary,
  failedMakePrimary
};
