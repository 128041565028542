/* eslint-disable no-param-reassign */
import { compose, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ItemAnalytics } from '../../../dataAnalytics/analyticsTypes';
import { RootState } from '@/rootStateTypes';

export type Content = {
  heading?: string;
  productName?: string;
  productPrice?: string;
  productQuantity?: number;
  isKeyboardClick?: boolean;
};

interface Toast {
  showCartToast: boolean;
  content: Content;
  itemAnalytics?: ItemAnalytics;
}

export type ToastPayload = {
  content: Content;
  itemAnalytics?: ItemAnalytics;
};

const initialToastState: Toast = {
  showCartToast: false,
  content: {}
};

const domain = (state: RootState): boolean => state.presentational.toast;
const showCartToast = (toast: Toast) => toast.showCartToast;
const content = (toast: Toast): Content => toast.content;
const itemAnalytics = (toast: Toast): ItemAnalytics | undefined => toast.itemAnalytics;

const toastSlice = createSlice({
  name: 'toast',
  initialState: initialToastState,
  reducers: {
    openCartToast: (
      state: Toast
    ) => {
      state.showCartToast = true;
    },
    closeCartToast: (
      state: Toast
    ) => {
      state.showCartToast = false;
      state.content = {};
      state.itemAnalytics = undefined;
    },
    updateToast: (
      state: Toast,
      action: PayloadAction<ToastPayload>
    ) => {
      state.itemAnalytics = action.payload.itemAnalytics;
      state.content = action.payload.content;
    }
  }
});

export const selectors = {
  showCartToast: compose<boolean>(showCartToast, domain),
  content: compose<Content>(content, domain),
  itemAnalytics: compose<ItemAnalytics>(itemAnalytics, domain)
};

export const {
  openCartToast,
  closeCartToast,
  updateToast
} = toastSlice.actions;

export default toastSlice.reducer;
