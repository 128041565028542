/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import {
  selectedCrustAddRules,
  updateAddRuleCheese,
  updateAddRuleFinisher,
  updateAddRuleMeatToppings,
  updateAddRuleSauce,
  updateAddRuleVeggieToppings
} from './addRules';
import {
  isCheese, isFinisher, isMeat, isSauce, isVeggie
} from '../../identifiers';
import { isReachedToppingsLimit } from '../selectors/toppingSelectors';
import {
  restrictedPizzaOptions,
  selectRestrictRulesBySizeAndCrust
} from '../selectors/rulesSelectors';
import toIngredient from '../../dataTransformers/toIngredient';
import SelectedBy from '../../../../common/SelectedBy';
import {
  PizzaIngredient,
  PizzaIngredientOption,
  PizzaOptions,
  Pizza,
  RuleItem, IngredientOptionWithPortions
} from '../../dataTransformers/builderTypes';
import { getGlobalId } from '@/cart/helpers/getGlobalId';
import { BNY_CYO_ID } from '../../constants';

export type PizzaCrustAndSizeOption = {
  size: PizzaIngredient | null;
  crust: PizzaIngredient | null;
};

export type PizzaPayload = PizzaIngredientOption | PizzaCrustAndSizeOption | PizzaOptions;

interface WithCrustRulesProps<T> {
  (pizza: Pizza, action: PayloadAction<T>): void;
}

const extractParentModifierGlobalId = (itemId: string) => itemId
  .split('/modifiers/').slice(-2)[0];

const getFirstAvailableIngredient = (
  rules: RuleItem[],
  ingredients?: IngredientOptionWithPortions[] | PizzaIngredientOption[]
) => (ingredients?.find((ingredient) => (
  !ingredient.outOfStock && !rules.find((ruleItem) => restrictedPizzaOptions(ingredient)(ruleItem))
)));

const applyAddRulesForSelectedCrustType = (pizza: Pizza) => {
  const addRules: RuleItem[] = selectedCrustAddRules(pizza);
  addRules?.forEach((ruleItem: RuleItem) => {
    const addRuleItemId = ruleItem.id;
    const parentModifierId = addRuleItemId ? extractParentModifierGlobalId(addRuleItemId) : null;

    if (addRuleItemId) {
      if (isSauce(ruleItem) && parentModifierId) {
        const sauceToAdd = updateAddRuleSauce(pizza, parentModifierId, addRuleItemId);
        if (sauceToAdd) pizza.sauce = sauceToAdd;
      }

      if (isCheese(ruleItem) && parentModifierId) {
        const cheeseToAdd = updateAddRuleCheese(pizza, parentModifierId, addRuleItemId);
        if (cheeseToAdd) pizza.cheese = cheeseToAdd;
      }

      if (isFinisher(ruleItem)) {
        const finisherToAdd = updateAddRuleFinisher(pizza, addRuleItemId);
        if (finisherToAdd) pizza.finisher = finisherToAdd;
      }

      if (!isReachedToppingsLimit(pizza)) {
        if (isMeat(ruleItem)) {
          const meatToAdd = updateAddRuleMeatToppings(pizza, addRuleItemId);
          if (meatToAdd) {
            pizza.meatToppings = (pizza.meatToppings)
              ? [...pizza.meatToppings, ...meatToAdd] : [...meatToAdd];
          }
        }

        if (isVeggie(ruleItem)) {
          const veggieToAdd = updateAddRuleVeggieToppings(pizza, addRuleItemId);
          if (veggieToAdd) {
            pizza.veggieToppings = (pizza.veggieToppings)
              ? [...pizza.veggieToppings, ...veggieToAdd] : [...veggieToAdd];
          }
        }
      }
    }
  });
};

const applyRestrictRulesForSelectedCrustType = (pizza: Pizza) => {
  if (!(pizza.size || pizza.crust)) return false;

  const includesSelectedTopping = (
    toppings: PizzaIngredient[], ruleItem: RuleItem
  ) => !!toppings.find(({ id }) => id === ruleItem.id);

  const unRestrictedToppings = (
    toppings: PizzaIngredient[], ruleItem: RuleItem
  ) => toppings.filter(({ id }) => id !== ruleItem.id);

  const ruleItems: RuleItem[] = selectRestrictRulesBySizeAndCrust(pizza)() ?? [];
  let restrictRuleApplied = false;
  let isSelectedSauceUnavailable = false;
  let isSelectedFinisherUnavailable = false;

  const ruleItemsByModifier: { sauces: RuleItem[]; finishers: RuleItem[] } = {
    sauces: [], finishers: []
  };

  ruleItems?.forEach((ruleItem: RuleItem) => {
    if (isSauce(ruleItem)) {
      ruleItemsByModifier.sauces.push(ruleItem);
      if (ruleItem.id === pizza.sauce?.id) isSelectedSauceUnavailable = true;
    }
    if (isFinisher(ruleItem)) {
      ruleItemsByModifier.finishers.push(ruleItem);
      if (ruleItem.id === pizza.finisher?.id) isSelectedFinisherUnavailable = true;
    }

    if (isMeat(ruleItem) && pizza.meatToppings
      && includesSelectedTopping(pizza.meatToppings, ruleItem)) {
      pizza.meatToppings = unRestrictedToppings(pizza.meatToppings, ruleItem);
      restrictRuleApplied = true;
    }

    if (isVeggie(ruleItem) && pizza.veggieToppings
      && includesSelectedTopping(pizza.veggieToppings, ruleItem)) {
      pizza.veggieToppings = unRestrictedToppings(pizza.veggieToppings, ruleItem);
      restrictRuleApplied = true;
    }
  });

  if (isSelectedSauceUnavailable && pizza.pizzaOptions?.sauces?.length) {
    const usePortionAsId = getGlobalId(pizza.id) === BNY_CYO_ID;
    pizza.sauce = toIngredient(
      getFirstAvailableIngredient(ruleItemsByModifier.sauces, pizza.pizzaOptions?.sauces),
      SelectedBy.SYSTEM,
      usePortionAsId
    );
    restrictRuleApplied = true;
  }

  if (isSelectedFinisherUnavailable && pizza.pizzaOptions?.finishers?.length) {
    pizza.finisher = toIngredient(
      getFirstAvailableIngredient(ruleItemsByModifier.finishers, pizza.pizzaOptions?.finishers),
      SelectedBy.SYSTEM
    );
    restrictRuleApplied = true;
  }

  return restrictRuleApplied;
};

const withCrustRules = <T extends PizzaPayload | null>(reducer: WithCrustRulesProps<T>) => (
  pizza: Pizza,
  action: PayloadAction<T>
): void => {
  reducer(pizza, action);
  if (pizza?.crust && pizza?.size) {
    const restrictRuleApplied = applyRestrictRulesForSelectedCrustType(pizza);
    if (!restrictRuleApplied) applyAddRulesForSelectedCrustType(pizza);
  }
};

export default withCrustRules;
