import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useDecision } from '@optimizely/react-sdk';
import {
  Cart,
  CartFee,
  DiningOccasion,
  OccasionDetail,
  PhdCart
} from '@pizza-hut-us-development/client-core';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import formattedPrice from '@/common/formattedPrice';
import {
  DELIVERY_MINIMUM,
  FORMATTED_DISCOUNT_AMOUNT,
  SERVICE_FEE
} from '@/clientCore/cart/constants';
import { presentationalCartSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/cart/presentationalCartSelectors';
import Routes from '@/router/routes';
import { closeCartRail } from '@/clientCore/redux/rail/CartRailSlice';
import {
  setCartInfoAndWarningViewType,
  toggleCartInfoAndWarningView
} from '@/clientCore/redux/cart/CartSlice';
import { OccasionApi } from '@/localization/constants';
import { CartCheckoutProps, UseCartCheckoutReturn } from '../types';
import { onRailGoToCheckoutClick } from '@/checkout/checkout.analytics';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { cartItemsToLegacy } from '@/clientCore/cart/helpers';
import { RootState } from '@/rootStateTypes';

export const useCartCheckout = (
  props: CartCheckoutProps
): UseCartCheckoutReturn => {
  // const analytics = useAnalytics();
  const router = useRouter();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const cart = useSelector(orderSelectors.cart);
  const isCartLoading = useSelector(presentationalCartSelectors.isCartLoading);
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );
  const [enforce7DLPick2Decision] = useDecision(
    'fr-web-1339-vlu-enforcing-pick-2'
  );

  const [summaryExpanded, setSummaryExpanded] = useState(false);

  // TEMP workaround - Should be removed when Cart type gets updated
  const discountedAmount = (cart as Cart & PhdCart).discountedAmount ?? 0;

  const {
    fees = [],
    total = 0,
    subtotal = 0,
    tax = [],
    occasionId = ''
  } = cart ?? {
    fees: [],
    total: 0,
    discountedAmount: 0,
    subtotal: 0,
    tax: [],
    occasionId: ''
  };

  const { occasions } = useSelector(orderSelectors.store) ?? {
    occasions: [] as OccasionDetail[]
  };

  const filteredFees = occasionId === 'CARRYOUT'
    ? fees.filter((fee: CartFee) => fee.name !== 'Delivery fee')
    : fees;

  const handleSummaryExpanded = () => {
    if (isCartLoading) return null;
    return setSummaryExpanded(!summaryExpanded);
  };

  const deliveryOccasionInfo = occasions.find(
    (occasion: OccasionDetail) => occasion.occasionId === OccasionApi.D
  );
  const handleGoToCheckoutButtonClick = () => {
    if (cart?.items) {
      analytics.push(() => onRailGoToCheckoutClick(cartItemsToLegacy(cart.items)));
    }
    // Under minimum delivery amount
    if (
      occasionId === DiningOccasion.DELIVERY
      && (subtotal ?? 0) < (deliveryOccasionInfo?.minOrder ?? 0)
    ) {
      dispatch(
        setCartInfoAndWarningViewType({
          cartInfoOrWarningViewType: DELIVERY_MINIMUM
        })
      );
      dispatch(toggleCartInfoAndWarningView({ displayState: true }));
      // Not enough 7DL items in cart
    } else if (
      enforce7DLPick2Decision.enabled
      && !(cart as Cart & PhdCart).lineupReady
    ) {
      props.setShow7DLCheckoutWarning(true);
    } else {
      dispatch(closeCartRail());
      router.push({ pathname: Routes.CHECKOUTW2 });
    }

    // ANALYTICS TICKET
    // analytics.push(() => onRailGoToCheckoutClick(cartItems));
    // telemetry.addCustomEvent('visit-checkout-from-cart');
  };

  const handleFeeClick = (): void => {
    dispatch(
      setCartInfoAndWarningViewType({
        cartInfoOrWarningViewType: SERVICE_FEE
      })
    );
    dispatch(toggleCartInfoAndWarningView({ displayState: true }));
  };

  const isOverDeliveryLimit = () => {
    const selectedOccasion = occasions?.find(
      (occasion) => occasion.occasionId === occasionId
    );

    return (
      subtotal - discountedAmount > (selectedOccasion?.maxOrder ?? 0)
      || isCartLoading
    );
  };

  const totalTax = tax.reduce(
    (runningTotal, currentTax) => runningTotal + currentTax.amount,
    0
  );

  const displayableSubTotal = isYumEcomm
    ? formattedPrice(subtotal + discountedAmount) : formattedPrice(subtotal);

  return [
    {
      isCheckoutButtonDisabled: isOverDeliveryLimit(),
      isCartLoading,
      summaryExpanded,
      fees: filteredFees,
      total: formattedPrice(total),
      discountedAmount: discountedAmount
        ? FORMATTED_DISCOUNT_AMOUNT(discountedAmount)
        : '',
      subtotal: displayableSubTotal,
      // hardcode for now to 1st index will see how looks via yum
      tax: formattedPrice((totalTax ?? 0))
    },
    {
      handleGoToCheckoutButtonClick,
      handleSummaryExpanded,
      handleFeeClick
    }
  ];
};
