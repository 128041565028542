import connectionConfig from '../../services/connectionConfig';

export class PromiseTimeout extends Error {}

const promiseWithTimeout = <T>(
  promise: Promise<T>,
  timeout: number = connectionConfig.requestTimeoutMs
): Promise<T> => {
  const timeoutPromise = new Promise((_, reject) => setTimeout(() => {
    reject(new PromiseTimeout(connectionConfig.requestTimeoutErrorMessage));
  }, timeout));

  return (Promise.race([promise, timeoutPromise]) as Promise<T>);
};

export default promiseWithTimeout;
