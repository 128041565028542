import { Dispatch } from 'redux';
import customerService from '../services/customerService';
import {
  CUSTOMER_DETAILS_FAILURE,
  CUSTOMER_DETAILS_SUCCESS,
  LOGIN_STATUS_FALSE,
  SESSION_REMEMBER_CART
} from './actionTypes';
import { loadSavedAddresses } from '../localization/actions';
import telemetry from '../telemetry';
import { AuthState } from '@/auth/userStates';

interface AsyncDispatch {
  (dispatch: Dispatch, getState?: () => any): Promise<void>
}

export const customerDetailsSuccess = (customerDetails: CustomerDetails) => ({
  type: CUSTOMER_DETAILS_SUCCESS,
  customerDetails
});

export const customerDetailsFailure = () => ({
  type: CUSTOMER_DETAILS_FAILURE
});

export const rememberCart = (sessionRememberCart: boolean) => ({
  type: SESSION_REMEMBER_CART,
  sessionRememberCart
});

export const setLogInStatusToFalse = () => ({
  type: LOGIN_STATUS_FALSE
});

export const setAuthState = (authState: AuthState) => ({
  type: authState
});

export const getCustomerDetails = (): AsyncDispatch => async (dispatch: Dispatch) => {
  try {
    const {
      savedAddresses,
      ...customerDetails
    } = await customerService.getCustomerDetails();

    dispatch(customerDetailsSuccess(customerDetails));
    if (savedAddresses) dispatch(loadSavedAddresses(savedAddresses));
  } catch (err) {
    telemetry.addNoticeError(err as Error);
    dispatch(customerDetailsFailure());
  }
};
