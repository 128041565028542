import { mapKeys } from 'remeda';

interface ObjectProps {
  [key: string]: any
}

const computeKey = (prefix: string, suffix: string | number): string => `${ prefix }_${ suffix }`;

function mapObjectToAddPrefix<T>(objectToMap: ObjectProps, prefix: string): T {
  return mapKeys(objectToMap, (key, value) => computeKey(prefix, key)) as T;
}

export default mapObjectToAddPrefix;
