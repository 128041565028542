export enum CardType {
  VI = 'VI',
  MC = 'MC',
  DI = 'DI',
  AM = 'AM'
}

export enum CardTypeFullName {
  VI = 'VISA',
  MC = 'MASTER CARD',
  DI = 'DISCOVER',
  AM = 'AMERICAN EXPRESS'
}

export enum CardTypeSentenceCaseName {
  VI = 'Visa',
  MC = 'Mastercard',
  DI = 'Discover',
  AM = 'American Express'
}
