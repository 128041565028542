import v3_query from './seo.v3';
import TypedQuery from '../../types/TypedQuery';
import { GqlVersion } from '../../hooks/useGqlVersion/GqlVersion';

function useQuery(version?: GqlVersion): TypedQuery<unknown> {
  switch (version) {
    default:
      return v3_query;
  }
}

export default useQuery;
