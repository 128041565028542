import {
  createStyles, makeStyles, Theme
} from '@material-ui/core';
import colors from '@/common/colors';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    background: colors.gray100,
    padding: '12px',
    borderBottom: `1px solid ${colors.gray400}`,
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start'
    }
  },
  icon: {
    paddingRight: '8px',
    display: 'flex',
    color: 'red'
  },
  textContainer: {
    flexGrow: 1,
    fontSize: 12
  }
}));
