import angularApiClient from '../../api/angularApiClient';
import { CartInformation } from '../../cart/legacyCart/legacyCartType';

const standaloneCartService: CartServiceLegacy = {
  removeAlcoholFromCart(): Promise<ClientResult<void>> {
    return angularApiClient.removeAlcoholFromCart();
  },
  getCartInfo(): Promise<ClientResult<CartInformation>> {
    return angularApiClient.getCartInfo();
  },
  clear(): Promise<ClientResult<void>> {
    return angularApiClient.clearCart();
  }
};

export default standaloneCartService;
