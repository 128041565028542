import React from 'react';
import colors from '@/common/colors';

export const RemoveCouponIcon = (): JSX.Element => (

  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 3L3 17" stroke={colors.red} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 3L17 17" stroke={colors.red} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
