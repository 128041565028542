import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DealData } from '@/builders/deals/slice/dealTypes';

interface DealAnalytics {
  deal_id: string;
  deal_name: string;
}

const initialDeal = {
  deal_id: '',
  deal_name: ''
};

const formatDealAnalytics = (deal: DealData): DealAnalytics => ({
  deal_id: deal.id,
  deal_name: deal.name
});

const dealAnalyticsSlice = createSlice({
  name: 'deal',
  initialState: initialDeal,
  reducers: {
    updateDeal: (state, action: PayloadAction<DealData>) => {
      const dealAnalytics = formatDealAnalytics(action.payload);
      Object.assign(state, dealAnalytics);
    },
    clearDeal: () => initialDeal
  }
});

export const {
  updateDeal,
  clearDeal
} = dealAnalyticsSlice.actions;

export default dealAnalyticsSlice.reducer;
