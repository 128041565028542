import { useEffect, useState } from 'react';

interface ImageDimensions {
  height?: number;
  width?: number;
  onLoad: () => void;
  imageLoaded: boolean;
}

export const util = {
  isComplete(ref): boolean {
    return !!(ref.current?.complete);
  }
};

export default function useImageDimensionsOnLoad(
  ref: React.MutableRefObject<any>
): ImageDimensions {
  const [imageDimensions, setImageDimensions] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(util.isComplete(ref));
  });

  const onLoad = () => {
    const { scrollHeight: height, scrollWidth: width } = ref.current;
    setImageDimensions({ height, width });
    setImageLoaded(true);
  };

  return { ...imageDimensions, onLoad, imageLoaded };
}
