// TODO: getCustomerDetails.stub.ts; migrate over? combine?
import { CustomerDetailsResponse, PaymentResponse } from '@/api/phdApiClient/transformPHDData';
import { PaymentType } from '@/domain/payments/types';
import { Profile } from '@/domain/Profile';

export const getCustomerApiResponse = ({
  loyaltyToken = '123abc'
}): CustomerDetailsResponse => ({
  crm_id: '406823d0-87c3-11e9-b852-a3946e1685e7',
  customer_addresses: [
    {
      address1: '1234 Fake St.',
      address2: 'Apt 3',
      address3: 'string',
      address4: 'string',
      city: 'Chicago',
      company: 'QuikOrder',
      postal_code: '60611',
      state: 'Illinois',
      customer_address_id: '1',
      is_default: false,
      name: 'My House',
      type: 'test',
      delivery_instructions: 'test',
      phone: '2222222222'
    }
  ],
  loyalty_token: loyaltyToken,
  customer_id: '15090000132680512002157578',
  do_not_sell_flag: true,
  email: 'JUST.PEACHY@STAYPEACHY.COM',
  first_name: 'JUST',
  last_name: 'PEACHY',
  payments: [
    {
      is_default: true,
      metadata: {
        balance: 1000,
        expiration: '08/99',
        gateway: 'AUTHORIZE_NET',
        is_expired: true,
        last_four: '9999',
        number: '610344433768',
        pin: '4049',
        postal_code: '12345',
        type: 'MC'
      },
      name: 'Main card',
      type: 'creditcard',
      payment_id: '1'
    }
  ],
  phone: '2145555555',
  phone_extension: '321',
  programs: [
    {
      program_id: '1'
    }
  ],
  promotions: [
    {
      promotion_id: '1',
      postal_code: '45678',
      birth_date: new Date('1995-10-10'),
      contact_methods: [{
        type: 'EMAIL',
        value: 'JUST.PEACHY@STAYPEACHY.COM'
      }]
    }
  ]
});

export const getCustomerApiResponseWithNoBalanceGiftCards: CustomerDetailsResponse = {
  ...getCustomerApiResponse({}),
  payments: [
    {
      is_default: true,
      metadata: {
        balance: undefined,
        number: '7768'
      },
      name: 'Main card',
      type: 'giftcard',
      payment_id: '1'
    } as unknown as PaymentResponse
  ]
};

export const customerStub: Profile = {
  email: 'JUST.PEACHY@STAYPEACHY.COM',
  firstName: 'JUST',
  lastName: 'PEACHY',
  phone: '2145555555',
  loyaltyToken: '123abc',
  customerAddresses: [
    {
      addressId: '1',
      name: 'My House',
      address1: '1234 Fake St.',
      address2: 'Apt 3',
      city: 'Chicago',
      postalCode: '60611',
      state: 'Illinois',
      isDefault: false,
      locationType: 'test',
      deliveryInstructions: 'test',
      phone: '2222222222'
    }
  ],
  payments: [
    {
      cardInfo: {
        cardType: 'MC',
        expiration: '08/99',
        lastFour: '9999',
        postalCode: '12345'
      },
      paymentId: '1',
      isDefault: true,
      name: 'Main card',
      paymentType: 'creditcard'
    }
  ],
  promotions: [
    {
      promotionId: '1',
      birthDate: new Date('1995-10-10').toJSON(),
      contactMethods: [{
        type: 'EMAIL',
        value: 'JUST.PEACHY@STAYPEACHY.COM'
      }]
    }
  ]
};

export const customerWithMultiplePaymentsStub: Profile = {
  ...customerStub,
  payments: [
    customerStub.payments[0],
    {
      cardInfo: {
        cardType: 'VI',
        expiration: '09/99',
        lastFour: '9991',
        postalCode: '12345'
      },
      paymentId: '2',
      isDefault: true,
      name: 'Another Card',
      paymentType: 'creditcard'
    }
  ]
};

export const customerWithGiftCardsWithNoBalanceStub: Profile = {
  ...customerStub,
  payments: [
    {
      cardInfo: {
        number: '7768',
        balance: 0
      },
      paymentId: '1',
      isDefault: true,
      paymentType: PaymentType.giftcard
    }
  ]
};

export const customerWithGiftCardAndDebitCard: Profile = {
  email: 'JUST.PEACHY@STAYPEACHY.COM',
  firstName: 'JUST',
  lastName: 'PEACHY',
  phone: '2145555555',
  loyaltyToken: '123abc',
  customerAddresses: [
    {
      addressId: '1',
      name: 'My House',
      address1: '1234 Fake St.',
      address2: 'Apt 3',
      city: 'Chicago',
      postalCode: '60611',
      state: 'Illinois',
      isDefault: false,
      locationType: 'test',
      deliveryInstructions: 'test',
      phone: '2222222222'
    }
  ],
  payments: [
    {
      cardInfo: {
        cardType: 'MC',
        expiration: '08/99',
        lastFour: '9999',
        postalCode: '12345'
      },
      paymentId: '1',
      isDefault: true,
      name: 'Main card',
      paymentType: 'creditcard'
    },
    {
      cardInfo: {
        number: '1232000',
        balance: 12000
      },
      isDefault: false,
      paymentId: '2',
      paymentType: PaymentType.giftcard
    }
  ],
  promotions: [
    {
      promotionId: '1',
      birthDate: new Date('1995-10-10').toJSON(),
      contactMethods: [{
        type: 'EMAIL',
        value: 'JUST.PEACHY@STAYPEACHY.COM'
      }]
    }
  ]
};
