import { Action } from 'redux';
import {
  CustomerDetailsAction,
  SessionRememberCartAction,
  SessionRememberOccasionAction
} from '../../header/headerTypes';
import { CUSTOMER_DETAILS_SUCCESS, SESSION_REMEMBER_CART } from '../../header/actionTypes';
import { SESSION_REMEMBER_OCCASION } from '../../localization/actionTypes';
import { LOGOUT } from '../../common/logout/actions';
import { AUTH_TOKEN_EXPIRED, AUTH_TOKEN_REFRESH_FAILURE } from '../../auth/actions';

export const initialState = {
  userId: 'Guest',
  loginStatus: 'Guest',
  rewardsMember: 'Guest',
  hutLoversMarketingOptIn: 'Guest',
  birthdaySent: 'Guest',
  textOptIn: 'Guest',
  sessionRememberCart: false,
  sessionRememberOccasion: undefined
};

const user = (state: any = { ...initialState }, action: Action) => {
  switch (action.type) {
    case CUSTOMER_DETAILS_SUCCESS: {
      const { customerDetails } = action as CustomerDetailsAction;
      return {
        ...state,
        userId: customerDetails.id,
        loginStatus: 'Registered - Logged In',
        rewardsMember: customerDetails.hutRewardsMember ? 'Yes' : 'No',
        hutLoversMarketingOptIn: customerDetails.hutLoversMarketingOptIn ? 'Yes' : 'No',
        birthdaySent: customerDetails.birthdaySent ? 'Yes' : 'No',
        textOptIn: customerDetails.textOptIn ? 'Yes' : 'No'
      };
    }
    case SESSION_REMEMBER_CART: {
      return {
        ...state,
        sessionRememberCart: (action as SessionRememberCartAction).sessionRememberCart
      };
    }
    case SESSION_REMEMBER_OCCASION: {
      return {
        ...state,
        sessionRememberOccasion: (action as SessionRememberOccasionAction).sessionRememberOccasion
      };
    }
    case AUTH_TOKEN_REFRESH_FAILURE:
    case AUTH_TOKEN_EXPIRED:
    case LOGOUT: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default user;
