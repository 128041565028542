import { Action, combineReducers } from 'redux';
import { LOGOUT } from '../common/logout/actions';
import {
  CUSTOMER_DETAILS_FAILURE,
  CUSTOMER_DETAILS_SUCCESS
} from './actionTypes';
import { CustomerDetailsAction } from './headerTypes';
import { AUTH_TOKEN_EXPIRED, AUTH_TOKEN_REFRESH_FAILURE } from '../auth/actions';

const defaultCustomerDetails = null;
const customerDetails = (state: CustomerDetails | null = defaultCustomerDetails,
  action: Action) => {
  switch (action.type) {
    case CUSTOMER_DETAILS_SUCCESS:
      return (action as CustomerDetailsAction).customerDetails;
    case CUSTOMER_DETAILS_FAILURE: {
      return {};
    }
    case AUTH_TOKEN_REFRESH_FAILURE:
    case AUTH_TOKEN_EXPIRED:
    case LOGOUT: {
      return defaultCustomerDetails;
    }
    default:
      return state;
  }
};

const headerUi = combineReducers({
  customerDetails
});

export default headerUi;
