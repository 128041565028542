import React from 'react';

const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <defs>
      <path
        id="erbneqdp0a"
        d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm0 15c-3.859 0-7-3.141-7-7s3.141-7 7-7 7 3.141 7 7-3.141 7-7 7zM7 4.5c0 .583.404 1 1 1 .583 0 1-.417 1-1 0-.584-.417-1-1-1-.584 0-1 .416-1 1zm.25 8h1.5v-6h-1.5v6z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="egsr52givb" fill="#fff">
        <use xlinkHref="#erbneqdp0a" />
      </mask>
      <use fill="#276DCC" fillRule="nonzero" xlinkHref="#erbneqdp0a" />
      <g fill="#717170" mask="url(#egsr52givb)">
        <path d="M-4-4h24v24H-4z" />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
