export const CHECKOUT_SUBMIT_LABEL = 'GO TO CHECKOUT';
export const BACK_TO_CART_LABEL = 'BACK TO CART';
export const WARNING_CONTENT_TITLE_DELIVERY_MINIMUM = 'You have not met the delivery minimum';
export const WARNING_CONTENT_TITLE_PRICE_TC = 'Pricing terms & conditions for this deal';
export const WARNING_CONTENT = (deliveryMinimum: string): string => `The minimum delivery subtotal is ${deliveryMinimum}. Please change your selection to carryout or continue shopping.`;
export const TIP_INITIAL_VALUE = null;
export const EMPTY_CART_TITLE = 'Your cart is hungry';
export const EMPTY_CART_SUBTITLE = 'Fill it with Pizza!';
export const START_YOUR_ORDER = 'Start your order';
export const BACK_TO_CART_BUTTON = 'Back to cart';
export const CLOSE_BUTTON = 'Close';
export const SERVICE_FEE_TITLE = 'Service fee';
export const GOT_IT_BUTTON = 'GOT IT';
export const SERVICE_FEE_INFO_CONTENT = 'The service fee partially offsets the increased cost of operations in the state of California.';
export const ITEMS_REMOVED_WARNING = 'Item(s) removed from cart';
export const APPROACHING_MAX_ORDER_LIMIT_WARNING = (maxOrderLimit: string): string => `You are about to reach the ${maxOrderLimit} maximum order limit for this store`;
export const MAX_ORDER_LIMIT_REACHED_WARNING = 'The maximum order limit for this store has been reached';
export const ORDER_PRICE_CHANGE_WARNING = (previousPrice: string, updatedPrice: string): string => `The price of your order has changed from ${previousPrice} to ${updatedPrice}`;
export const MAX_ORDER_LIMIT_EXCEEDED_WARNING = (maxOrderLimit: string): string => `You have exceeded the ${maxOrderLimit} maximum order limit for this store. Please remove items before proceeding to checkout.`;
export const EDIT_ITEM_ERROR_TITLE = 'We’re sorry';
export const EDIT_ITEM_ERROR_MESSAGE = 'We’re not able to edit that item at this time.';
export const LOADING_ITEM = 'Loading product name';
export const LOADING_MODIFIER = 'Loading product details';
export const GENERIC_PRODUCT_NAME = 'Product name';
export const GENERIC_MODIFIER_NAME = 'Product details';
export const SPECIAL_INSTRUCTIONS = 'Special Instructions';
export const ROKT_ACCOUNT_ID = '2646161524817932499';
export const VALUE_LINEUP_MINIMUM_WARNING = 'Please add another Deal Lover\'s Menu item to meet the 2 item deal minimum.';
export const VALUE_LINEUP_MINIMUM_WARNING_ADD_ITEM = 'Add item';
