export class SalesExperienceUrl extends URL {
  constructor(public readonly originalUrl: string, base?: string) {
    super(originalUrl, base);
  }

  get prefix(): string {
    if (this.originalUrl.includes(`//${this.host}/`)) {
      // Absolute URL
      const { protocol, host } = this;
      return `${protocol}//${host}/`;
    }
    if (this.originalUrl[0] === '/') {
      return '/';
    }
    return '';
  }
}
