import { ApplePayResult, GiftCardBalance } from '@/checkout/types';
import {
  CreditCard, GiftCardFormFields, GiftCardInfo, SavedCreditCard, ThirdPartyGiftCard
} from '@/domain/payments/types';
import { EditCreditCard } from '@/services/accountManagementService/phdApiAccountManagementService/types';

export enum PaymentTypes {
  Cash = 'cash',
  Card = 'creditcard',
  SavedCard = 'savedpayment',
  GiftCard = 'giftcard',
  TPGC = 'tpgc',
  ApplePay = 'applepay'
}

type CashPayment = {
  type: PaymentTypes.Cash;
  amount: number;
};

export type PhPayCashPaymentType = Array<CashPayment>;

export type PhPayGiftCardPaymentType =
  PhPayLegacyGiftCardPaymentType | PhPayThirdPartyGiftCardPaymentType;

export interface PhPayLegacyGiftCardPaymentType {
  type: string;
  name: string;
  amount: number;
  metadata: {
    number?: string;
    pin?: string;
    payment_id?: string;
  };
}

interface ThirdPartyGiftCardMetadata {
  type: string;
  card_code: string;
  card_mask: string;
  expiration?: string;
  token?: string;
  postal_code: string;
}

interface SavedThirdPartyGiftCardMetadata {
  payment_id: string;
  card_code: string;
}

export interface PhPayThirdPartyGiftCardPaymentType {
  type: string;
  name: string;
  amount: number;
  metadata: ThirdPartyGiftCardMetadata | SavedThirdPartyGiftCardMetadata;
}

export interface PhPayThirdPartyGiftCardBody {
  accountNumber: string;
  accountType: 'GIFT_CARD';
  cvv2?: string;
  expirationDate?: string;
  pin?: string;
}

export interface PhPayGiftCardResponse {
  balance?: number;
  error_code?: string;
  message?: string;
}

export interface PhPayLegacyGiftCardBody {
  number: string;
  pin: string;
  payment_id?: string;
}

export interface PhPayLegacyGiftCardResponse extends PhPayGiftCardResponse {
  last_four?: string;
}

export interface PhPayTokenizeCardBody {
  expiration_month: number;
  expiration_year: number;
  pan: string;
}

export interface PhPayTokenizeCardArgs {
  cardNumber: string;
  expirationDate: string;
}

export interface PhPayTokenizeCardResult {
  token: string;
}

type ApplePayPayment = {
  type: string;
  name: string;
  amount: number;
  metadata: {
    postal_code: string;
    token: string;
  };
};

export type PhPayApplePayPaymentType = Array<ApplePayPayment>;

export type AppleFormOfPayment = {
  applePayResult: ApplePayResult;
  amount: number;
};

type CardPayment = {
  type: string;
  name: string;
  amount: number;
  metadata: {
    type?: string;
    card_code?: string;
    card_mask?: string;
    postal_code?: string;
    expiration?: string;
    gateway: string;
    token: string;
  };
};

export type PhPayCardPaymentType = Array<CardPayment>;

type SavedCardPayment = {
  type: string;
  name: string;
  amount: number;
  metadata: {
    payment_id: string;
    card_code: string;
    postal_code: string;
  };
};

export type PhPaySavedCardPaymentType = Array<SavedCardPayment>;

export type TokenizeCard = ({
  cardNumber,
  expirationDate
}: PhPayTokenizeCardArgs) => Promise<ClientResult<string>>;

export type GetThirdPartyGiftCardBalance = (
  body: PhPayThirdPartyGiftCardBody,
  mockFunction: () => Promise<Response>
) => Promise<ClientResult<GiftCardBalance>>;

export interface TokenizationError { reason: 'tokenization' }
export interface ExpiredCardError { reason: 'expired' }
export interface UnsupportedCardError { reason: 'unsupported' }
export type AddCardError = TokenizationError | ExpiredCardError | UnsupportedCardError;

export type ApiPaymentId = { paymentId: string };
export type AddCreditCard = (
  card: SavedCreditCard
) => Promise<ClientResult<SavedCreditCard | AddCardError>>;

export type AddThirdPartyGiftCard = (
  card: ThirdPartyGiftCard
) => Promise<ClientResult<GiftCardInfo | AddCardError>>;

export type AddGiftCard = (
  giftcard: GiftCardFormFields
) => Promise<ClientResult<GiftCardInfo>>;

export interface PhPayApiClient {
  tokenizeCard: TokenizeCard;
  getThirdPartyGiftCardBalance: GetThirdPartyGiftCardBalance;
  addCreditCard: AddCreditCard;
  editCreditCard: EditCreditCard;
  addGiftCard: AddGiftCard;
  addThirdPartyGiftCard: AddThirdPartyGiftCard;
}
