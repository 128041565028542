import { padNumber } from './determineCloseTime';

export default function convertTo12HourClock(timeString: string) {
  const timeInParts = timeString.split(':').map((value) => parseInt(value, 10));
  const time = new Date(0, 0, 0,
    timeInParts[0],
    timeInParts[1],
    timeInParts[2]);
  const hour = time.getHours();


  if (hour >= 12) {
    const hours = hour === 12 ? hour : hour - 12;
    return `${padNumber(hours)}:${padNumber(time.getMinutes())}PM`;
  }

  if (hour === 0) return `12:${padNumber(time.getMinutes())}AM`;

  return `${padNumber(hour)}:${padNumber(time.getMinutes())}AM`;
}
