import gql from 'graphql-tag';
import LinkFragmentV3 from './link.v3';

const NationalCategoryFragmentV3 = gql`
  fragment NationalCategoryFragmentV3 on Category {
      index
      isNational
      link {
        ...LinkFragmentV3
      }
  }
  ${LinkFragmentV3}
`;

export default NationalCategoryFragmentV3;
