import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { Product } from '@pizza-hut-us-development/client-core';
import { OneClickUpsellCartRailRowSkeleton } from '../OneClickUpsellCartRailRow/OneClickUpsellCartRailRowSkeleton';
import OneClickUpsellCartRailRow from '../OneClickUpsellCartRailRow/OneClickUpsellCartRailRow';

import { useOneClickUpsellCart } from '../../hooks/useOneClickUpsellCart';
import useOneClickUpsellCartRailListStyles from './styles';

const OneClickUpsellCartRailList = (): JSX.Element | null => {
  const classes = useOneClickUpsellCartRailListStyles();
  const {
    products,
    isUpsellQueryLoading
  } = useOneClickUpsellCart();

  const hasProducts = products && products.length > 0;

  if (!isUpsellQueryLoading && !hasProducts) {
    return null;
  }

  return (
    <Grid
      container
      data-testid="one-click-upsell-cart-rail-list"
      justifyContent="space-between"
      alignItems="center"
      className={hasProducts ? classes.root : ''}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.headerItemContainer}
      >
        <Grid item className={classes.titleContainer}>
          <Typography variant="h2" className={classes.title}>
            Recommended for you
          </Typography>
        </Grid>
        <Grid container direction="column" spacing={3} component="ul" className={classes.upsellsContainer}>
          {
            isUpsellQueryLoading
              ? [1, 2, 3].map((_, idx) => (
                <li key={`one-click-upsell-skeleton-${idx}`} data-testid="one-click-upsell-cart-skeleton">
                  <OneClickUpsellCartRailRowSkeleton />
                  <Divider className={classes.divider} aria-hidden="true" />
                </li>
              ))
              : products.map((product, index) => (
                <li key={product.id}>
                  <OneClickUpsellCartRailRow index={index} product={product as Product} />
                  <Divider className={classes.divider} aria-hidden="true" />
                </li>
              ))
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OneClickUpsellCartRailList;
