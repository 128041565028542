const KEYBOARD_ENTER_KEYCODE = 13;
const KEYBOARD_ARROW_RIGHT_KEYCODE = 39;
const KEYBOARD_ARROW_LEFT_KEYCODE = 37;
const ENTER_KEY_EVENT_CODE = 'Enter';

export {
  KEYBOARD_ARROW_RIGHT_KEYCODE,
  KEYBOARD_ARROW_LEFT_KEYCODE,
  KEYBOARD_ENTER_KEYCODE,
  ENTER_KEY_EVENT_CODE
};
