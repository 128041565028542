import { StoreStatus } from '@/localization/localizationRail/StoreTile/constants';
import { OccasionString } from '@/localization/constants';
import { Metadata } from '@/localization/common/getContactlessStatus';

export type PHDApiStoreOccasion = 'DELIVERY' | 'CARRYOUT' | 'UNKNOWN_OCCASION';
export type PHDApiStorePaymentTypeName = 'CASH' | 'APPLEPAY' | 'CREDITCARD';

export type PHDApiStorePaymentType = {
  name: PHDApiStorePaymentTypeName;
  occasions: PHDApiStoreOccasion[];
  metadata?: PHDApiStorePaymentMetadata;
};

type PHDApiStorePaymentMetadata = {
  accepted_cc_types: AcceptedCCTypes[];
};

export type PhdApiStoreOccasions = {
  allow_tip: boolean;
  apply_discount_to_net: boolean;
  interval_start_time: string;
  max_order: number;
  name: string;
  occasion_id: OccasionString;
  service_time: number;
  service_time_pos_code: string;
  min_order?: number;
};

export type PhdApiStore = {
  status: StoreStatus;
  occasions: PhdApiStoreOccasions[];
  hours: PHDApiStoreHourAvailability[];
  online_status: string;
  state: string;
  store_number: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  postal_code: string;
  phone_number: string;
  landmark: string;
  token: string;
  payment_types: PHDApiStorePaymentType[];
  metadata: Metadata;
  allows_future_orders: boolean;
  status_message: string;
  latitude: string;
  longitude: string;
  timezone: string;
  dragontail?: boolean;
  base_delivery_charge?: number;
};

export enum PhdApiStoreDays {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7
}

export type PhdApiCouponCheck = {
  discount_id?: string;
  master_discount_id?: string;
  error_code?: string;
  message?: string;
};

export type PHDApiStoreHourAvailability = {
  days: PhdApiStoreDays[];
  occasion_id: PHDApiStoreOccasion;
  interval_start_time: string;
  duration: string;
};

export type PHDApiStore = {
  payment_types: PHDApiStorePaymentType[];
  hours: PHDApiStoreHourAvailability[];
};

export type GetOrder = {
  order_id: string;
  message?: string;
  estimated_transmission_time?: string;
  promised_time: string;
  status: string;
};

type DeliveryAddress = {
  address1: string;
  address2: string;
  city: string;
  postal_code: string;
  state: string;
};

export type DeliveryStatus = {
  estimatedDeliveryTime: number;
  steps: DeliverySteps;
  storeNumber: string;
};

export type DeliverySteps = {
  inOven: DeliveryStepStatus;
  outForDelivery: DeliveryStepStatus;
  received: DeliveryStepStatus;
};

export type CarryoutStatus = {
  estimated_transmission_time?: string;
  message?: string;
  order_id: string;
  promised_time: string;
  status: string;
};

export type DeliveryOrderStatusResponse = {
  estimatedDeliveryTime: number;
  steps: DeliverySteps;
  storeNumber: string;
};

export type CarryoutOrderStatusResponse = {
  estimated_transmission_time?: string;
  message?: string;
  order_id: string;
  promised_time: string;
  status: string;
};

export type DeliveryStepStatus = {
  lastChangeTime: string;
  smsStatus: string;
  status: string;
  stepOrder: number;
};

export enum SmsStatus {
  success = 'success',
  fail = 'fail',
  none = 'none'
}

export enum StepStatus {
  STEP_COMPLETE = 'STEP_COMPLETE',
  STEP_PENDING = 'STEP_PENDING',
  STEP_IN_PROGRESS = 'STEP_IN_PROGRESS'
}

export type GetOrderStatus = {
  balanceDue: number;
  checkIns: number;
  checkedIn: boolean;
  customerFirstName: string;
  customerLastName: string;
  customerPhone: string;
  deliveryAddress: DeliveryAddress;
  deliveryInstructions: string;
  description: string;
  estimatedDeliveryTime: number;
  estimatedTime: number;
  lastStatusChange: string;
  orderItemDescription: [];
  orderItemQuantity: [];
  orderNumber: string;
  orderTime: string;
  orderTotal: number;
  queryTime: string;
  status: number;
  steps: DeliverySteps;
  storeNumber: string;
  storePhone: string;
};

export interface CustomerResponse {
  auth_token: string;
  customer_id: string;
  email: string;
  first_name: string;
  last_name: string;
}

export type ContactType = 'EMAIL' | 'TEXT';
