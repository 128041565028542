import gql from 'graphql-tag';
import ItemFragment from './item';

const CategoryItemFragment = gql`
  fragment CategoryItemFragment on Item {
    ...ItemFragment
    modifiers {
      name
      type
      id
      priority
      modifiers {
        ...ItemFragment
        modifiers {
          name
          type
          id
          priority
          ...ItemFragment
        }
      }
    }
  }
  ${ItemFragment}
`;

export default CategoryItemFragment;
