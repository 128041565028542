export const CHECKOUT_HEADER_TEXT = 'Checkout';

export const PHONE_MASK = '(___) ___-____';
export const PHONE_LABEL = 'Phone number';
export const INVALID_PHONE_NUMBER = 'Invalid phone number';

export const PHONE_ERRORS = {
  required: INVALID_PHONE_NUMBER,
  minLength: INVALID_PHONE_NUMBER
};

export const INVALID_PAYMENT_MODAL_TITLE = 'Invalid payment';
export const INVALID_PAYMENT_MODAL_BODY = 'Your payment details are invalid. Please review and try again.';
export const INVALID_CVV_OR_PAYMENT_API_RESPONSE_CODE = '0518';
export const INVALID_CART_ID_API_RESPONSE_CODE = '0905';
export const INVALID_CREDIT_CARD_BRAND_API_RESPONSE_CODE = '0532';
export const INVALID_CREDIT_CARD_BRAND_MODAL_BODY = "We're sorry, we do not accept this card type. Please choose a new form of payment and try again.";
export const INVALID_CREDIT_CARD_BRAND_API_RESPONSE_MESSAGE = 'type of card is not accepted at this location';
export const DELIVERY_MINIMUM_ORDER_TITLE = 'Order is below delivery minimum';
export const DELIVERY_MINIMUM_NOT_MET_RESPONSE_MESSAGE = 'the minimum delivery subtotal is';

export const DIFFERENT_FORM_OF_PAYMENT_RESPONSE_MESSAGE = 'try a different form of payment';
export const INVALID_CVV_CODE_RESPONSE_MESSAGE = 'invalid CVV code';

export const ORDER_FAILED_MODAL_TITLE = 'Order placement failed';
export const ORDER_FAILED_MODAL_BODY = 'Your order was not placed successfully. Please try again.';

export const CART_EXPIRED_MODAL_TITLE = 'Order error';
export const CART_EXPIRED_MODAL_BODY = "We don't like sharing bad news, but something went wrong with your order and you'll need to start over. Don't give up, we're sorry!";

export const CLEAR_FORM_MODAL_TITLE = 'Personal info cleared';
export const CLEAR_FORM_MODAL_BODY = 'For your security, we cleared your personal information due to idle time. Please re-enter it when you\'re ready to order.';
export const CLEAR_FORM_MODAL_CTA = 'OK';

export const RETRY_TOKENIZATION_FAILED_MODAL_TITLE = 'Something went wrong';
export const RETRY_TOKENIZATION_FAILED_MODAL_BODY = 'Please re-enter your card information and try again';

export const STORE_UNAVAILABLE_MODAL_TITLE = 'Store unavailable';
export const STORE_UNAVAILABLE_MODAL_BODY = 'We’re sorry, this store is no longer available. Please choose a new store and try again.';
export const STORE_UNAVAILABLE_API_RESPONSE_CODE = '0517';

export const CHANGE_OCCASION_TITLE = 'Change Occasion';
