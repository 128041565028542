import { GqlVersion } from '../../hooks/useGqlVersion/GqlVersion';
import v3_query from './pizzas.v3';

function useQuery(version?: GqlVersion): any {
  switch (version) {
    default:
      return v3_query;
  }
}

export default useQuery;
