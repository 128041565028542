import phdApiV2Endpoints from '../endpoints';
import { getV2Url } from '../helpers/getV2Url';
import loggingFetch from '@/common/loggingFetch';
import { ParsedResponse } from '../../clientHelpers';
import telemetry from '@/telemetry';
import logger from '@/common/logger';
import { getPublicRuntimeConfig } from '@/api/phdApiClient/helpers/getPublicRuntimeConfig';
import { StoreSearchException } from '@/services/localizationService/phdapiLocalizationService';
import { StoreSearchApiResult } from '@/api/phdApiClient';
import { onSearchCarryoutAnalytics } from '@/dataAnalytics/dataAnalyticsHelper';
import dataAnalytics from '@/dataAnalytics';
import { LOCALIZATION_SERVICE_X_VARIANT, X_VARIANT } from '@/localization/constants';
import isClientSide from '@/common/isClientSide';

interface RequestBody {
  [key: string]: any;
}

export const parseJson = async (rawResponse: Response): Promise<ParsedResponse> => {
  let response;
  try {
    response = await rawResponse.clone().json();
  } catch (error) {
    // explicitly check for invalid JSON error
    response = await rawResponse.text();
  }
  return { response, status: rawResponse.status, headers: rawResponse.headers };
};

const postV2 = async (body: RequestBody, endpoint: phdApiV2Endpoints, headers?: any): Promise<any> => {
  const url = getV2Url(endpoint);
  const { phdApiServerEnv } = getPublicRuntimeConfig();

  return loggingFetch(url, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'x-server-env': phdApiServerEnv,
      ...headers
    },
    body: JSON.stringify(body),
    redirect: 'follow'
  }).then(parseJson);
};

const isStatus2xxOr4xx = (status: number, response: any) => status === 200 || (status === 404 && (response.possible_matches || response.message === 'dta exception'));

const findStoresV2 = async (body: any): Promise<StoreSearchApiResult> => {
  const endpoint = phdApiV2Endpoints.STORE_SEARCH;

  return postV2(body, endpoint).then(({ status, response, headers }: { status: number; response: any; headers: any }) => {
    if (status !== 200 && status !== 404) {
      const error = new StoreSearchException(`Unexpected response for store status, ${status}`);
      telemetry.addNoticeError(error, { endpoint });
      logger.withoutTelemetry.error(error.message);
      throw error;
    }

    const headerObj = Object.fromEntries(headers);
    if (isClientSide()) localStorage.setItem(LOCALIZATION_SERVICE_X_VARIANT, headerObj?.[X_VARIANT]);

    return {
      found: status === 200 && response?.length !== 0,
      errorCode: response?.error_code,
      results: isStatus2xxOr4xx(status, response) && response
    };
  });
};

export { findStoresV2 };
