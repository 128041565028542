import { AnyAction, Dispatch } from 'redux';
import {
  LOAD_CART_INFORMATION,
  CLEAR_CART_INFORMATION,
  LOAD_CART_INFORMATION_FAILED
} from './legacyActionTypes';
import cartServiceLegacy from '../../services/cartServiceLegacy';
// eslint-disable-next-line import/no-cycle
import { openModal } from '../../localization/actions';
import { AsyncDispatch } from '../../localization/localizeActions';
import { CartInformation } from './legacyCartType';

export const loadCartInformation = (cart: CartInformation): AnyAction => ({
  type: LOAD_CART_INFORMATION,
  cart
});

export const loadCartInformationFailed = (): AnyAction => ({
  type: LOAD_CART_INFORMATION_FAILED
});

export const clearCartInformation = (): AnyAction => ({
  type: CLEAR_CART_INFORMATION
});

const showErrorModal = (callback?: () => void, errorCode?: string) => {
  const title = 'We\'re sorry';
  const body = 'Something went wrong. Please try again.';
  const text = 'OK';

  return openModal({
    title,
    body,
    cta: {
      text,
      callback: () => {
        if (callback) {
          callback();
        }
      }
    },
    analyticsModalData: {
      errorCode
    }
  });
};

export const fetchCartInformation = (): AsyncDispatch => async (dispatch: AsyncDispatch) => {
  const { result, error } = await cartServiceLegacy.getCartInfo();
  if (error) {
    dispatch(loadCartInformationFailed());
  } else {
    dispatch(loadCartInformation(result));
  }
};

export const clearStoreCart = (
  selectOccasionStore: () => void
): AsyncDispatch => async (dispatch: Dispatch) => {
  const clearCartAndLocalizeToNewStore = async () => {
    dispatch(clearCartInformation());
    selectOccasionStore();
  };

  const clearCart = async () => {
    const { error, errorCode } = await cartServiceLegacy.clear();
    if (!error) await clearCartAndLocalizeToNewStore();
    if (error) {
      dispatch(showErrorModal(clearCart, errorCode));
    }
  };

  await clearCart();
};

export const removeAlcoholFromCart = (
  selectOccasionStore: () => void
): AsyncDispatch => async (dispatch: AsyncDispatch) => {
  const removeAlcoholAndLocalizeToNewStore = async () => {
    await dispatch(fetchCartInformation());
    selectOccasionStore();
  };

  const removeAlcohol = async () => {
    const { error, errorCode } = await cartServiceLegacy.removeAlcoholFromCart();

    if (!error) await removeAlcoholAndLocalizeToNewStore();
    if (error) {
      dispatch(showErrorModal(removeAlcohol, errorCode));
    }
  };

  await removeAlcohol();
};
