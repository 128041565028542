import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cart } from '@pizza-hut-us-development/client-core';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { presentationalRailSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/rail/presentationalRailSelectors';
import { setCartChangedAlert } from '@/clientCore/redux/rail/CartRailSlice';
import { CartAlert } from '@/domain/cart/types';
import { calculateCartQuantity } from '@/clientCore/cart/helpers';

// Returns appropriate selected item for both legacy PHD and Client Core stores based on existance of CartContext (PHD) or Redux state (CC)
const useCCCartState = () => {
  const isLocalized = Boolean(useSelector(orderSelectors.store));

  const cart = useSelector(orderSelectors.cart);
  const editCartItemId = useSelector(presentationalRailSelectors.editCartItemId);
  const dispatch = useDispatch();
  const cachedCart = useRef<Cart>();

  const isCartRailOpen = useSelector(
    presentationalRailSelectors.isCartRailOpen
  );

  const selectedItem = useMemo(() => cart?.items?.find((item) => item.cartItemId === editCartItemId), [cart?.items, editCartItemId]);

  const isEditingFlow = typeof editCartItemId === 'string';

  const isEmptyCart = useMemo(
    () => !cart?.items || cart.items.length === 0,
    [cart]
  );

  useEffect(() => {
    const orderItemQuantityChanged = cachedCart?.current && cart && calculateCartQuantity(cart.items) !== calculateCartQuantity(cachedCart.current.items);
    const orderTotalPriceChanged = cachedCart?.current && cart && cachedCart.current.total !== cart.total;

    if (orderItemQuantityChanged || orderTotalPriceChanged) {
      const cartAlert: CartAlert = {
        displayAlert: false,
        quantityChanged: orderItemQuantityChanged ?? false,
        previousPrice: cachedCart?.current?.total ?? 0,
        currentPrice: cart.total,
        itemsRemoved: [] // TODO: where does this come from
      };
      dispatch(setCartChangedAlert(cartAlert));
    }
    cachedCart.current = cart;
  }, [cart, dispatch]);

  return {
    cart,
    selectedItem,
    isLocalized,
    isEditingFlow,
    isEmptyCart,
    isCartRailOpen
  };
};

export default useCCCartState;
