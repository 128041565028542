export enum OccasionString {
  C = 'Carryout',
  D = 'Delivery',
  U = 'Unknown'
}

export const MAX_ORDER_LIMIT = {
  warning: {
    approaching: (maxOrderLimit: string): string => `You are about to reach the ${maxOrderLimit} maximum order limit for this store`,
    exceeded: (maxOrderLimit: string): string => `You have exceeded the ${maxOrderLimit} maximum order limit for this store. Please remove items before proceeding to checkout.`,
    reached: 'The maximum order limit for this store has been reached'
  },
  testId: 'max-order-limit-alert'
};

export const CENTS_PER_DOLLAR = 100;
export const EDIT_IN_CART_TYPES = ['PRODUCT', 'WINGS'];
