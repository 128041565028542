import { compose, createSlice, PayloadAction } from '@reduxjs/toolkit';
import undoable, { ActionTypes, includeAction } from 'redux-undo';
import {
  CurrentStep, Deal, DealData, SelectedRecipe, UserSelections
} from '@/builders/deals/slice/dealTypes';
import { dealWithRecipeAdded } from '@/builders/deals/slice/dealMethods';
import { clearDeal, updateDeal } from '@/builders/deals/slice/deal.slice';
import { RootState } from '@/rootStateTypes';
import {
  selectCurrentStep,
  selectCurrentStepDealRecipe,
  selectInitialCurrentStep,
  selectRecipesForCurrentStep,
  selectRedeemLoyalty,
  selectSelectedRecipeByStepId
} from './selectors/userSelectionsSelectors';

const domain = (state: RootState): Deal => state.domain.deal;

export const userSelectionsSelector = {
  selectCurrentStep: compose(selectCurrentStep, domain),
  selectRecipesForCurrentStep: compose(selectRecipesForCurrentStep, domain),
  selectSelectedRecipeByStepId: compose(selectSelectedRecipeByStepId, domain),
  selectCurrentStepDealRecipe: compose(selectCurrentStepDealRecipe, domain),
  selectRedeemLoyalty: compose(selectRedeemLoyalty, domain)
};

export const initialUserSelections: UserSelections = {
  dealToDisplay: { id: null, publicCode: null },
  currentStep: null,
  recipes: [],
  redeemingLoyaltyDeal: false
};

const currentStepDefaults: CurrentStep = {
  type: null,
  recipeId: null,
  recipeType: null,
  id: '',
  name: '',
  required: true,
  description: '',
  recipeOptions: [],
  price: undefined,
  index: 0
};

export const userSelectionsSlice = createSlice({
  name: 'userSelections',
  initialState: initialUserSelections,
  reducers: {
    redeemLoyaltyDeal: (
      userSelections: UserSelections,
      action: PayloadAction<boolean>
    ) => {
      Object.assign(userSelections, {
        redeemingLoyaltyDeal: action.payload
      });
    },
    fromDealsMenu: (userSelections: UserSelections) => {
      Object.assign(userSelections, initialUserSelections);
    },
    updateDealStep: (
      userSelections: UserSelections,
      action: PayloadAction<Partial<CurrentStep>>
    ) => {
      Object.assign(userSelections, {
        currentStep: {
          ...currentStepDefaults,
          ...userSelections.currentStep,
          ...action.payload
        }
      });
    },
    updateDealRecipes: (
      userSelections: UserSelections,
      action: PayloadAction<SelectedRecipe[]>
    ) => {
      Object.assign(userSelections, {
        recipes: dealWithRecipeAdded(userSelections.recipes, action.payload)
      });
    },
    clearDealRecipes: (userSelections: UserSelections) => {
      Object.assign(userSelections, { recipes: [] });
    },
    clearCurrentStep: (userSelections: UserSelections) => {
      Object.assign(userSelections, {
        currentStep: initialUserSelections.currentStep
      });
    },
    setDealToDisplay: (
      userSelections: UserSelections,
      action: PayloadAction<{ dealId: string; rewardsRedemptionCode?: number; publicCode?: string }>
    ) => {
      Object.assign(userSelections, {
        ...initialUserSelections,
        dealToDisplay: {
          id: action.payload.dealId,
          rewardsRedemptionCode: action.payload.rewardsRedemptionCode,
          publicCode: action.payload.publicCode
        }
      });
    },
    clearDealToDisplay: (userSelections: UserSelections) => {
      Object.assign(userSelections, initialUserSelections);
    }
  },
  extraReducers: {
    [updateDeal.type]: (
      userSelections: UserSelections,
      action: PayloadAction<DealData>
    ) => {
      const newDeal = action.payload;
      if (newDeal.steps && newDeal.type) {
        const currentStep = selectInitialCurrentStep(newDeal.steps, newDeal.type);
        const updatedUserSelections = {
          ...userSelections,
          currentStep,
          recipes: initialUserSelections.recipes
        };
        Object.assign(userSelections, updatedUserSelections);
      } else {
        Object.assign(userSelections, newDeal);
      }
    }
  }
});

export const {
  updateDealStep,
  updateDealRecipes,
  clearCurrentStep,
  clearDealToDisplay,
  setDealToDisplay,
  clearDealRecipes,
  redeemLoyaltyDeal,
  fromDealsMenu
} = userSelectionsSlice.actions;

const actionsThatCreatePast = [
  updateDealStep.type,
  clearCurrentStep.type,
  clearDealRecipes.type,
  updateDeal.type,
  fromDealsMenu.type
];

export default undoable(
  userSelectionsSlice.reducer,
  {
    ignoreInitialState: true,
    filter: includeAction(actionsThatCreatePast),
    clearHistoryType: [ActionTypes.CLEAR_HISTORY, clearDeal.type]
  }
);
