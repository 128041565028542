import { compose } from '@reduxjs/toolkit';
import { RootState, UserDomain } from '@/rootStateTypes';
import { AuthState } from '@/auth/userStates';

const domain = (state: RootState): UserDomain => state.domain.user;
const loginStatus = (user: UserDomain) => user.loginStatus;
const isLoading = (user: UserDomain) => user.loginStatus === AuthState.UNKNOWN;
const isAuthenticated = (user: UserDomain) => user.loginStatus === AuthState.LOGGED_IN;
const isNotAuthenticated = (user: UserDomain) => user.loginStatus !== AuthState.LOGGED_IN
&& user.loginStatus !== AuthState.UNKNOWN;
const isGuest = (user:UserDomain) => user.loginStatus === AuthState.GUEST;
const shouldRefreshAuth = (user: UserDomain) => user.shouldRefreshAuth;

// Updated selectors for checking auth state, dont rely on customer lookup
const authState = (user: UserDomain): AuthState => user.authState;
const isAuthenticatedUser = (user: UserDomain): boolean => user.authState === AuthState.LOGGED_IN;
const isGuestUser = (user: UserDomain): boolean => user.authState === AuthState.GUEST;

export const userDomainSelectors = {
  loginStatus: compose(loginStatus, domain),
  isLoading: compose(isLoading, domain),
  isAuthenticated: compose(isAuthenticated, domain),
  isNotAuthenticated: compose(isNotAuthenticated, domain),
  isGuest: compose(isGuest, domain),
  shouldRefreshAuth: compose(shouldRefreshAuth, domain),
  authState: compose(authState, domain),
  isAuthenticatedUser: compose(isAuthenticatedUser, domain),
  isGuestUser: compose(isGuestUser, domain)
};
