import { isEmpty } from '@/utils';
import RuleAction from '../../../../common/RuleAction';
import findRuleBySizeAndCrust from '../../../../common/findRuleBySizeAndCrust';
import {
  Pizza,
  PizzaIngredient,
  RuleItem,
  Item,
  PizzaIngredientOption
} from '../../dataTransformers/builderTypes';

const allRestrictRules = (pizza: Pizza): RuleItem[] | undefined => pizza.pizzaOptions?.rules
  && pizza.pizzaOptions?.rules[RuleAction.RESTRICT];

const allPricingRules = (pizza: Pizza): RuleItem[] | undefined => pizza.pizzaOptions?.rules
  && pizza.pizzaOptions?.rules[RuleAction.PRICING];

function restrictedPizzaOptions(pizzaOption: PizzaIngredientOption) {
  return (ruleItem: RuleItem): boolean | undefined => ruleItem.id?.includes(pizzaOption.id || '');
}

const selectRestrictRulesBySizeAndCrust = (pizza: Pizza): (
sizeId?: PizzaIngredient['id'],
crustId?: PizzaIngredient['id']) => RuleItem[] | undefined => {
  const rules = allRestrictRules(pizza);
  return (sizeId?: PizzaIngredient['id'], crustId?: PizzaIngredient['id']): RuleItem[] => {
    const pizzaSizeId = sizeId || pizza.size?.id;
    const pizzaCrustId = crustId || pizza.crust?.id;

    if (!(pizzaSizeId && pizzaCrustId)) return [];

    return rules?.filter(findRuleBySizeAndCrust(pizzaSizeId, pizzaCrustId)) || [];
  };
};

function restrictRulesBySizeAndCrust(
  pizza: Pizza, ingredientType: (item: Item) => boolean
): (options: PizzaIngredientOption) => boolean {
  return (pizzaOption: PizzaIngredientOption) => {
    if (!(pizza.size && pizza.crust)) return true;

    const allRestrictedOptions = selectRestrictRulesBySizeAndCrust(pizza)()
      ?.filter(ingredientType)
      ?.filter(restrictedPizzaOptions(pizzaOption));

    return isEmpty(allRestrictedOptions);
  };
}

// #Pricing Rules selectors
const selectCrustPricingRules = (pizza: Pizza): RuleItem[] | undefined => {
  const selectedSizeAndCrustRules = findRuleBySizeAndCrust(
    pizza.size?.id || '',
    pizza.crust?.id || ''
  );
  const hasSizeAndCrustSelected = Boolean(pizza?.size?.id && pizza?.crust?.id);

  if (hasSizeAndCrustSelected) {
    return allPricingRules(pizza)?.filter(selectedSizeAndCrustRules);
  }

  return [];
};

export {
  selectRestrictRulesBySizeAndCrust,
  restrictRulesBySizeAndCrust,
  restrictedPizzaOptions,
  selectCrustPricingRules
};
