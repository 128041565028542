import { setPromisedTime } from './getOrderStatusForCarryout.stub';
import combine from '@/common/combine';
import {
  SmsStatus, DeliverySteps, DeliveryStepStatus, StepStatus, DeliveryStatus
} from '@/api/phdApiClient/types';

// Endpoint: /orderStatus/{orderNumber}

const date = new Date(Date.now());

export const stubDeliveryStepStatus = (
  override: Partial<DeliveryStepStatus> = {}
): DeliveryStepStatus => combine<DeliveryStepStatus>({
  status: StepStatus.STEP_PENDING,
  lastChangeTime: setPromisedTime(date, 10).toISOString(),
  smsStatus: SmsStatus.success,
  stepOrder: 0
}, override);

export const stubDeliverySteps = (
  override: Partial<DeliverySteps> = {}
): DeliverySteps => combine<DeliverySteps>({
  inOven: stubDeliveryStepStatus({
    status: StepStatus.STEP_IN_PROGRESS,
    lastChangeTime: setPromisedTime(date, 10).toISOString()
  }),
  outForDelivery: stubDeliveryStepStatus({
    status: StepStatus.STEP_PENDING,
    lastChangeTime: setPromisedTime(date, 30).toISOString()
  }),
  received: stubDeliveryStepStatus({
    status: StepStatus.STEP_COMPLETE,
    lastChangeTime: date.toISOString()
  })
}, override);

export const stubDeliveryStatus = (
  override: Partial<DeliveryStatus> = {}
): DeliveryStatus => combine<DeliveryStatus>({
  estimatedDeliveryTime: 1646674953,
  steps: {
    inOven: stubDeliveryStepStatus({
      status: StepStatus.STEP_IN_PROGRESS,
      lastChangeTime: setPromisedTime(date, 10).toISOString()
    }),
    outForDelivery: stubDeliveryStepStatus({
      status: StepStatus.STEP_PENDING,
      lastChangeTime: setPromisedTime(date, 30).toISOString()
    }),
    received: stubDeliveryStepStatus({
      status: StepStatus.STEP_COMPLETE,
      lastChangeTime: date.toISOString()
    })
  },
  storeNumber: '032456'
}, override);

export const deliveryOrderStatusResult = {
  balanceDue: 0,
  checkIns: 0,
  checkedIn: true,
  customerFirstName: 'Mary',
  customerLastName: 'Doe',
  customerPhone: '11 11 11111111',
  deliveryAddress: {
    address1: '111 Memory Lane',
    address2: '',
    address3: '',
    address4: '',
    city: 'Plano',
    country: 'USA',
    phone: '11 11 11111111',
    state: 'TX',
    zipCode: '75024'
  },
  deliveryInstructions: 'leave at the door',
  description: 'delicious pizza',
  estimatedDeliveryTime: 1646674953,
  estimatedTime: 40,
  lastStatusChange: date.toISOString(),
  orderItemDescription: [
    'Pizza'
  ],
  orderItemQuantity: [
    1
  ],
  orderNumber: '12345',
  orderTime: date.toISOString(),
  orderTotal: 20.00,
  queryTime: date.toISOString(),
  status: 1,
  steps: stubDeliverySteps(),
  storeNumber: '395',
  storePhone: '11 11 22222222'
};
