import PhdApiEndpoints from '../endpoints';
import carryoutSearchResultsStub from './carryout/carryoutSearchResults.stub';
import deliverySearchResultsStub from './delivery/deliverySearchResults.stub';
import getStoreResultsStub from './getStoreResults.stub';
import customerDetailsStub from './getCustomerDetails.stub';
import transformedCustomerInfoStub from './transformedCustomerInfo.stub';
import customerLoyaltyStub from './getCustomerLoyalty.stub';
import { GetOrder, PHDApiStore } from '../types';
import getStoreStub from './getStore.stub';
import { mockFunction } from '@/testUtils/mockFunction';
import getFavorites from './favorites/getFavorites';
import editFavorite from './favorites/editFavorite';
import rankFavorites from './favorites/rankFavorites';
import deleteFavorite from './favorites/deleteFavorite';
import { deliveryOrderStatusResult } from '@/api/phdApiClient/__mocks__/orders/getOrderStatusForDelivery.stub';
import { carryoutOrderStatusResult } from '@/api/phdApiClient/__mocks__/orders/getOrderStatusForCarryout.stub';
import { orderHistoryApiResponse } from '@/api/phdApiClient/__mocks__/accountManagement/orders/getOrderHistory.stub';
import { DealPrivateCodeResult } from '@/api/phdApiClient';
import getCustomer from "./myAccount/getCustomer";
import updateCustomer from './myAccount/updateCustomer';
import addCustomerAddress from './myAccount/addCustomerAddress';
import deleteCard from './myAccount/deleteCard';
import deleteAddress from './myAccount/deleteAddress';
import { deleteCoupon } from './myAccount/deleteCoupon';
import escalatePrivileges from './accountManagement/escalatePrivileges';
import updatePassword from './myAccount/updatePassword';
import updatePromotionsContact from './myAccount/updatePromotionsContact';
import customerLoyalty from './myAccount/customerLoyalty';
import { DoesStoreOfferDealResult } from '@/services/dealService/types';
import editCustomerAddress from '@/api/phdApiClient/__mocks__/myAccount/editCustomerAddress';

const phdApiClientMock = {
  post: () => mockResolvedValue(undefined)(),
  get: (endpoint: PhdApiEndpoints) => mockGet(endpoint),
  getStoreDetails: (endpoint: PhdApiEndpoints) => mockGetStoreDetails(endpoint),
  getStore: mockFunction(getStore),
  getCustomerInfo: () => mockCustomerInfo(),
  getCustomerLoyaltyPoints: () => mockCustomerLoyaltyPoints(),
  findStores: (body: any) => mockFindStores(body),
  getPrivateCode: () => mockGetPrivateCode(),
  checkCouponCode: () => mockCheckCouponCode(),
  getOrders: mockGetOrders,
  favorites: {
    getFavorites,
    editFavorite,
    rankFavorites,
    deleteFavorite
  },
  myAccount: {
    getCustomer,
    updateCustomer,
    addCustomerAddress,
    deleteCard,
    deleteAddress,
    deleteCoupon,
    escalatePrivileges,
    updatePassword,
    updatePromotionsContact,
    customerLoyalty,
    editCustomerAddress
  },
  clearSession: () => mockClearSession(),
  getOrdersId: () => mockGetOrdersId(),
  getOrderStatus: () => mockGetOrderStatus(),
  postCustomer: () => mockResolvedValue(undefined)()
};

function getStore(): Promise<PHDApiStore> {
  return Promise.resolve(getStoreStub);
}

function mockGetPrivateCode(): Promise<DealPrivateCodeResult> {
  return mockResolvedValue({ found: true, privateCode: 'dealID' })();
}

function mockCheckCouponCode(): Promise<DoesStoreOfferDealResult> {
  return mockResolvedValue({ storeOffersDeal: true, switchOccasion: true })();
}

function mockFindStores(body: any): Promise<any> {
  if (body.occasion_id === 'CARRYOUT') {
    return mockResolvedValue(carryoutSearchResultsStub)();
  }
  if (body.occasion_id === 'DELIVERY') {
    return mockResolvedValue(deliverySearchResultsStub)();
  }

  return mockResolvedValue(undefined)();
}

function mockCustomerInfo(): Promise<CustomerDetails> {
  return mockResolvedValue(transformedCustomerInfoStub)();
}

function mockCustomerLoyaltyPoints(): Promise<any> {
  return mockResolvedValue(customerLoyaltyStub)();
}

function mockGet(endpoint: string): Promise<any> {
  if (endpoint === PhdApiEndpoints.GET_CUSTOMER_DETAILS) {
    return mockResolvedValue(customerDetailsStub)();
  }

  return mockResolvedValue(undefined)();
}

function mockGetStoreDetails(endpoint: string): Promise<any> {
  if (endpoint === PhdApiEndpoints.GET_STORE) {
    return mockResolvedValue(getStoreResultsStub)();
  }

  return mockResolvedValue(undefined)();
}

export function mockGetOrders(): Promise<Response> {
  return Promise.resolve(new Response(JSON.stringify(orderHistoryApiResponse)));
}

function mockGetOrdersId(): Promise<GetOrder> {
  return mockResolvedValue(carryoutOrderStatusResult)();
}

function mockGetOrderStatus(): Promise<Response> {
  return Promise.resolve(new Response(JSON.stringify(deliveryOrderStatusResult)));
}

function mockClearSession(): Promise<Response> {
  return mockResolvedValue({})();
}

function mockResolvedValue(value: any) {
  if (typeof (jest) === 'undefined') {
    // When using this mock in our dev server, jest doesn't exist.
    // Handroll a mock instead.
    return () => new Promise((resolve) => resolve(value));
  }

  return jest.fn().mockResolvedValue(value);
}

export default phdApiClientMock;
