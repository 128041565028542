import {
  useEffect,
  useRef,
  EffectCallback,
  DependencyList
} from 'react';
import { equals } from 'remeda';

const useDeepEffect = (effectFn: EffectCallback, deps: DependencyList): void => {
  const initial = useRef(true);
  const previousDeps = useRef(deps);

  useEffect(() => {
    const isDeepEqual = (obj: any, index: number) => equals(obj, deps[index]);
    const depsAreDeepEqual = previousDeps.current.every(isDeepEqual);

    if (initial.current || !depsAreDeepEqual) {
      effectFn();
    }

    initial.current = false;
    previousDeps.current = deps;
  }, [effectFn, deps]);
};

export default useDeepEffect;
