import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { mobileStartBreakpoint } from '@/materialUi/theme';

export const railStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    width: 'calc(100vw - 48px)',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      width: '446px'
    }
  }
}));
