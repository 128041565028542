import React, { KeyboardEventHandler } from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import styles from '../styles';
import { ENTER_KEY_EVENT_CODE, KEYBOARD_ENTER_KEYCODE } from '@/common/constants';

type QuantityPickerProps = {
  quantity: number;
  increaseItemQuantity?: () => Promise<void>;
  decreaseItemQuantity: () => Promise<void>;
};

const keydownWrapper = (fn?: () => Promise<void>) => {
  const handleOnKeyDown: KeyboardEventHandler<HTMLDivElement> = (event): void => {
    if ((event.key === ENTER_KEY_EVENT_CODE || event.keyCode === KEYBOARD_ENTER_KEYCODE) && fn) {
      fn();
    }
  };
  return handleOnKeyDown;
};

export default function QuantityPicker({
  quantity,
  increaseItemQuantity,
  decreaseItemQuantity
}
: QuantityPickerProps): JSX.Element {
  const classes = styles.quantityPicker();
  const keyDown = keydownWrapper(increaseItemQuantity);
  const isDisabledIncreaseItemQuantity = !increaseItemQuantity;
  return (
    <div className={classes.quantityPicker}>
      <div
        role="button"
        tabIndex={0}
        className={classes.outerDiv}
        onClick={decreaseItemQuantity}
        onKeyDown={keydownWrapper(decreaseItemQuantity)}
      >
        <Button
          aria-controls="qty-selector"
          aria-label="Decrement"
          className={classes.button}
          tabIndex={-1}
          data-testid="decrease-quantity-button"
        >
          -
        </Button>
      </div>

      <span
        aria-label="Quantity"
        className={classes.quantityButton}
      >
        {quantity}
      </span>

      <div
        role="button"
        tabIndex={0}
        className={clsx(classes.outerDiv, isDisabledIncreaseItemQuantity && classes.defaultCursor)}
        onClick={increaseItemQuantity}
        onKeyDown={keyDown}
        style={{ cursor: 'default' }}
      >
        <Button
          aria-controls="qty-selector"
          aria-label="Increment"
          className={classes.button}
          tabIndex={-1}
          disabled={isDisabledIncreaseItemQuantity}
          data-testid="increase-quantity-button"
        >
          +
        </Button>
      </div>
    </div>
  );
}
