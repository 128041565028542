import query, { Category } from './categoryById';
import { CategoryByIdResult } from '../../types/Category';
import { GqlVersion } from '@/graphql/hooks/useGqlVersion/GqlVersion';
import { TypedQueryWithParser } from '@/graphql/types/TypedQuery';

export type CategoryByIdQuery = TypedQueryWithParser<
Category,
CategoryByIdResult,
unknown
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useQuery(version?: GqlVersion): CategoryByIdQuery {
  return query;
}
export default useQuery;
