// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as getConfig from 'next/config';

export type HiddenProducts = {
  toppings: string[];
  crusts: string[];
  sauces: string[];
  products: string[];
  deals: string[];
};

type HutFavorites = {
  productCodes: {
    yum: string[];
    nonYum: string[];
  };
};

export const hideProductsAPI = createApi({
  reducerPath: 'rtkq/hideProducts',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://storage.googleapis.com' }),
  endpoints: (builder) => ({
    getProductsToHide: builder.query<HiddenProducts, void>({
      query: () => {
        const { publicRuntimeConfig } = getConfig.default();
        const env = publicRuntimeConfig.DEPLOY_ENVIRONMENT === 'local' ? 'leda' : publicRuntimeConfig.DEPLOY_ENVIRONMENT;
        return `phus-web-filter-lists-${env}/productsToFilter.json`;
      }
    }),
    getHutFavorites: builder.query<HutFavorites, void>({
      query: () => {
        const { publicRuntimeConfig } = getConfig.default();
        const env = publicRuntimeConfig.DEPLOY_ENVIRONMENT === 'local' ? 'leda' : publicRuntimeConfig.DEPLOY_ENVIRONMENT;
        return `phus-web-filter-lists-${env}/hutFavorites.json`;
      }
    })
  })
});

export const { useGetProductsToHideQuery, useGetHutFavoritesQuery } = hideProductsAPI;
