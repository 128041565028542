import { Action } from 'redux';
import { SELECT_STORE, SELECT_STORE_FAILED } from '../../localization/actionTypes';
import { StoreInfo } from '../../localization/localizeActions';
import { NATIONAL } from '../../localization/constants';

const store = (state: any = {}, action: Action) => {
  switch (action.type) {
    case SELECT_STORE: {
      const storeInfo = action as StoreInfo;
      const isValidStoreNumber = storeInfo.storeNumber && storeInfo.storeNumber !== NATIONAL;

      return {
        storeId: storeInfo.store?.storeNumber,
        posType: storeInfo.store?.posType,
        city: storeInfo.store?.city,
        state: storeInfo.store?.state,
        zip: storeInfo.store?.zipcode,
        localized: isValidStoreNumber ? 'Yes' : 'No',
        initialCarryoutPromiseTime: storeInfo.store?.carryoutPromiseTime,
        initialDeliveryPromiseTime: storeInfo.store?.deliveryPromiseTime,
        dragonTail: storeInfo.store?.dragonTail,
      };
    }
    case SELECT_STORE_FAILED: {
      const storeInfo = action as StoreInfo;
      const isValidStoreNumber = storeInfo.storeNumber && storeInfo.storeNumber !== NATIONAL;

      return {
        storeId: storeInfo.storeNumber,
        localized: isValidStoreNumber ? 'Yes' : 'No'
      };
    }
    default:
      return state;
  }
};

export default store;
