/* eslint-disable @typescript-eslint/no-explicit-any */
type LogDeprecated = (msg: string, ...meta: any[]) => void;
type Log = ((msg: Error, meta?: Record<string, any>) => void) & LogDeprecated;

/* eslint-enable @typescript-eslint/no-explicit-any */

export interface Logger {
  info: Log;
  error: Log;
  warn: Log;
  debug: Log;
}

export interface CustomAttributes {
  isClientSide: boolean;
  uuid?: string;
  cartId?: string;
}

export type CreateLogger = () => Logger;

export interface LogContextGlobal {
  env: string;
  serviceName: string;
}

export interface LogContextMessage {
  conversationID: string;
  ts: string;
}

export interface LogContext extends LogContextGlobal, LogContextMessage {
}

export class LoggerError extends Error {
  name = 'ErrorLogger';
}
