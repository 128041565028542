export const CARRYOUT_SEARCH_SUCCESS = 'CARRYOUT_SEARCH_SUCCESS';
export const DELIVERY_SEARCH_SUCCESS = 'DELIVERY_SEARCH_SUCCESS';
export const OPEN_LOCALIZATION_RAIL = 'OPEN_LOCALIZATION_RAIL';
export const CLOSE_LOCALIZATION_RAIL = 'CLOSE_LOCALIZATION_RAIL';
export const SHOW_SEARCH_IN_RAIL = 'SHOW_SEARCH_IN_RAIL';
export const SWITCH_TO_DELIVERY = 'SWITCH_TO_DELIVERY';
export const SWITCH_TO_CARRYOUT = 'SWITCH_TO_CARRYOUT';
export const SHOW_LOADING_INDICATOR = 'SHOW_LOADING_INDICATOR';
export const HIDE_LOADING_INDICATOR = 'HIDE_LOADING_INDICATOR';
export const CLOSE_MODAL = 'CLOSE_ERROR_MODAL';
export const OPEN_MODAL = 'OPEN_ERROR_MODAL';
export const LOAD_SAVED_ADDRESSES = 'LOAD_SAVED_ADDRESSES';
export const SELECT_STORE = 'SELECT_STORE';
export const SET_OCCASION_SEARCH_ADDRESS = 'SET_PREVIOUS_OCCASION_SEARCH_ADDRESS';
export const USING_SAVED_ADDRESSES = 'USING_SAVED_ADDRESSES';
export const SESSION_REMEMBER_OCCASION = 'SESSION_REMEMBER_OCCASION';
export const SELECT_STORE_FAILED = 'SELECT_STORE_FAILED';
export const LOCALIZATION_INITIALIZED = 'LOCALIZATION_INITIALIZED';
export const SHOW_LOADING_BAR = 'SHOW_LOADING_BAR';
export const HIDE_LOADING_BAR = 'HIDE_LOADING_BAR';
export const STORE_CHANGED = 'STORE_CHANGED';
export const STORE_RESET = 'STORE_RESET';
export const OCCASION_CHANGED = 'OCCASION_CHANGED';
export const OCCASION_RESET = 'OCCASION_RESET';
export const SHOW_LOADING_QUERY = 'SHOW_LOADING_QUERY';
export const HIDE_LOADING_QUERY = 'HIDE_LOADING_QUERY';
export const SHOULD_PREFILL_INPUT = 'SHOULD_PREFILL_INPUT';
export const RESET_PREFILL_INPUT = 'RESET_PREFILL_INPUT';
