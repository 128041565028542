import { OrderHistoryType } from '@/account/orders/orderHistory/OrderHistory.types';
import {
  CommonAddress,
  OrderPaymentType, Product, ProductModifier,
  SingleOrder
} from '@/account/orders/orderHistory/order/Order.types';
import {
  OrderHistoryResponse,
  PaymentResponse,
  ProductModifierResponse,
  ProductResponse,
  SingleOrderResponse,
  StoreResponse
} from './getOrders.types';
import { Occasion, OccasionApi } from '@/localization/constants';

const transformPaymentTypes = (
  paymentTypes: PaymentResponse[]
): OrderPaymentType[] => paymentTypes.map(({ name, type }) => ({ name, type }));

const transformModifiers = (
  modifiers: ProductModifierResponse[]
): ProductModifier[] => modifiers.map(
  ({ modifier, modifier_id, quantities }) => ({ name: modifier, id: modifier_id, quantities })
);

const transformProducts = (products: ProductResponse[]): Product[] => products.map((product) => ({
  image: product.image,
  modifiers: transformModifiers(product.modifiers),
  nutrition: product.nutrition,
  name: product.product,
  id: product.product_id,
  quantity: product.quantity,
  size: product.size
}));

const transformDeliveryAddress = (
  order: SingleOrderResponse
): CommonAddress => ({
  address1: order.delivery_address.address1,
  address2: order.delivery_address.address2,
  city: order.delivery_address.city,
  state: order.delivery_address.state,
  zipcode: order.delivery_address.postal_code
});

const transformStoreAddress = (
  order: SingleOrderResponse,
  stores: StoreResponse[]
): CommonAddress => {
  const storeAddress = stores.find((store) => store.store_number === order.store_number);

  return {
    address1: storeAddress?.address1 || '',
    address2: storeAddress?.address2 || '',
    city: storeAddress?.city || '',
    state: storeAddress?.state || '',
    zipcode: storeAddress?.postal_code || ''
  };
};

const transformOccasion = (occasionId: PHDApiStoreOccasion): Occasion => {
  if (occasionId.toLowerCase() === OccasionApi.C.toLowerCase()) {
    return Occasion.CARRYOUT;
  }
  return Occasion.DELIVERY;
};

const transformAddress = (
  order: SingleOrderResponse,
  stores: StoreResponse[]
): CommonAddress => (order.occasion_id === OccasionApi.D
  ? transformDeliveryAddress(order) : transformStoreAddress(order, stores));

const transformOrders = (
  orders: SingleOrderResponse[],
  stores: StoreResponse[]
): SingleOrder[] => orders
  .filter((order) => order.store_number)
  .map((order) => ({
    address: transformAddress(order, stores),
    paymentTypes: transformPaymentTypes(order.forms_of_payment),
    storeNumber: order.store_number,
    occasionId: transformOccasion(order.occasion_id),
    orderId: order.order_id,
    orderTime: order.order_time,
    pointsEarned: order.points_earned,
    products: transformProducts(order.products),
    total: order?.total || 0
  }));

export const transformOrdersData = (
  { orders, stores }: OrderHistoryResponse
): OrderHistoryType => ({
  orders: transformOrders(orders, stores)
});
