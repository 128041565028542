import {
  flatMap, groupBy, pipe, reduce
} from 'remeda';
import DealType from '../../../builders/deals/DealTypes';
import {
  DealRecipe,
  DealRuleItem,
  DealRuleOption,
  DealStep,
  ChildDealStep,
  ParentDeal,
  DealData
} from '@/builders/deals/slice/dealTypes';
import { AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';
import {
  DealItem,
  Step,
  RecipeItem
} from '../../types/Deal';
import { IsCyo } from '@/menu/pizza/pizzaMenuTypes';
import decodeEntities from '@/graphql/helpers/decodeEntities';

const getDealType = (steps: DealStep[]): DealType | null => {
  const isSingleStep = steps.length === 1;
  const isMultiStep = steps.length > 1;
  const isOrderLevel = !steps.length;

  switch (true) {
    case isOrderLevel: return DealType.ORDER_LEVEL;

    case isSingleStep: {
      return DealType.SINGLE_STEP;
    }

    case isMultiStep: {
      return DealType.MULTI_STEP;
    }

    default:
      return null;
  }
};

export const parseRecipes = (
  recipes: RecipeItem[]
): DealRecipe[] => recipes.map(
  (recipe: RecipeItem) => ({
    ...recipe,
    isCYO: recipe.isCYO ? IsCyo.TRUE : IsCyo.FALSE,
    id: recipe.id.split('/').pop() || '',
    imageAltText: '',
    staticLink: '',
    availability: [],
    customizationsIncluded: 1,
    fullId: recipe.id,
    hidden: false,
    price: recipe.price
  })
);

const transformDealSteps = (
  steps: Step[],
  isParent: boolean
): DealStep[] | ChildDealStep[] => {
  if (!steps.length) return [];
  return steps
    .filter((step) => isParent || step.recipes.length > 0)
    .filter((step) => isParent || step.required)
    .map(({
      id,
      name,
      description = '',
      required,
      recipes,
      price,
      outOfStock,
      allergenDisclaimer,
      imageURL
    }) => ({
      id,
      name: decodeEntities(name),
      required,
      description: decodeEntities(description),
      recipeOptions: parseRecipes(recipes),
      price: price || undefined,
      outOfStock,
      allergenDisclaimer,
      image: imageURL
    }));
};

const getDealSummary = (responseData: DealItem, isParent: boolean) => ({
  id: responseData.id,
  name: decodeEntities(responseData.name),
  image: responseData.imageURL,
  type:
    isParent
      ? DealType.PARENT
      : getDealType(transformDealSteps(responseData.steps, isParent))
});

const transformDealRules = (steps: Step[]): DealRuleOption => pipe(
  steps,
  flatMap((step) => ({ rules: step.rules, stepId: step.id })),
  reduce((accumulator, { rules, stepId }) => {
    const stepRules: DealRuleItem[] = rules.flatMap((
      { actionItem, action: rule }
    ) => ({ ...actionItem, rule, stepId }));

    return [...accumulator, ...stepRules];
  }, [] as DealRuleItem[]),
  groupBy((item) => item.rule)
);

const transformDealData = (responseData: DealItem): DealData | ParentDeal => {
  const { steps: stepsOptions } = responseData;
  const { outOfStock, available, availableInOtherOccasion } = responseData;
  const isParent = responseData.type === DealType.PARENT;

  const dealSummary = getDealSummary(responseData, isParent);
  const validSteps = transformDealSteps(stepsOptions, isParent);
  const rules = transformDealRules(stepsOptions);

  if (responseData.steps.length > 0 && validSteps.length === 0) throw new Error('Invalid deal data: no valid steps');

  return {
    ...dealSummary,
    kind: AddableCartItemKinds.DEAL,
    steps: validSteps,
    outOfStock,
    available,
    availableInOtherOccasion,
    rules,
    combinable: responseData.combinableDiscount,
    legalText: typeof responseData.legalText !== 'string' ? '' : responseData.legalText.trim()
  };
};

export default transformDealData;
