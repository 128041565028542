import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { CartItem } from '@pizza-hut-us-development/client-core';
import { useSelector } from 'react-redux';
import { RootState } from '@pizza-hut-us-development/client-core/lib/esm/ReduxStore';
import SpecialInstructions from './SpecialInstructions';
import styles from '../styles';
import parseItemDescription from '../helpers/parseItemDescription';

interface DealItemsProps {
  item: CartItem;
}

const DealItems = ({ item }: DealItemsProps): JSX.Element => {
  const classes = styles.cartItem();
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  const itemsInDeal = (item.modifiers ?? [])
    .filter((modifier) => modifier.type === 'STEP')
    .map((product) => (product.modifiers?.[0].type === 'PIZZA'
      ? {
        ...product.modifiers[0],
        name: product.modifiers[0].name,
        description: parseItemDescription(product.modifiers[0] as CartItem, isYumEcomm)
      }
      : {
        ...product,
        name: product.modifiers?.[0].name ?? product.name,
        description: parseItemDescription(product as CartItem, isYumEcomm)
      }));

  return (
    <Grid container classes={{ root: classes.dealItemsContainer }}>
      {itemsInDeal.map((dealItem, index) => (
        <Grid container direction="column" className={classes.dealItemSpacing} key={index}>
          <Grid item>
            <Typography className={classes.span}>
              <strong>{dealItem.name}</strong>
            </Typography>
          </Grid>
          {dealItem.description ? (
            <Grid item>
              <Typography className={classes.span}>{dealItem.description}</Typography>
            </Grid>
          ) : null}
          {dealItem.specialInstructions ? (
            <SpecialInstructions specialInstructions={dealItem.specialInstructions} />
          ) : null}
        </Grid>
      ))}
    </Grid>
  );
};

export default DealItems;
