import React from 'react';

const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="14"
    viewBox="0 0 11 14"
    {...props}
  >
    <path
      fill="#131313"
      fillRule="evenodd"
      d="M.68 9.014L10.983.512 8.327 13.43 6.555 8.165z"
    />
  </svg>
);

export default SvgComponent;
