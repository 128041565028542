export enum DefaultImage {
  DESKTOP_LARGE_IMAGE = '/images/desktop-large-default-sidekick.jpg',
  DESKTOP_SMALL_IMAGE = '/images/desktop-small-default-sidekick.jpg',
  MOBILE_IMAGE = '/images/mobile-default-sidekick.jpg'
}

export const MAX_SIDEKICK_FOR_FIRST_ROW = 3;
export const MAX_SIDEKICK_IMAGES = 5;
export const SMALL_IMAGE_GRID_SIZE = 4;
export const LARGE_IMAGE_GRID_SIZE = 6;
