import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppliedPromotion, CartItem } from '@pizza-hut-us-development/client-core';
import { CheckIcon } from '@/checkout/icons/CheckIcon';
import styles from './styles';
import {
  ADDED_COUPON_ID,
  COUPON_TITLE,
  FORMATTED_DISCOUNT_AMOUNT,
  REMOVE_COUPON,
  REMOVE_COUPON_ID
} from '@/clientCore/cart/constants';
import { LinkButton } from '@/clientCore/cart/components/common/LinkButton';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { useCartDeleteItem } from '@/clientCore/cart/hooks/useDeleteItem';
import useCoupon from '../Coupon/hooks/useCoupon';
import { RootState } from '@/rootStateTypes';

interface AddedCouponsProps {
  item: CartItem | AppliedPromotion;
  yumPromotion?: AppliedPromotion;
}

export const AddedCoupons = ({ item, yumPromotion }: AddedCouponsProps) => {
  const { handleDeleteCartItem } = useCartDeleteItem();
  const [, handlers] = useCoupon();
  const {
    handleRemoveYumPromoCode
  } = handlers;

  const classes = styles.addedCoupons();
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const discountedAmount = useSelector(orderSelectors?.cart)?.discountedAmount ?? 0;

  const handleDeleteCartItemOrAppliedPromotion = (cartItem: CartItem | AppliedPromotion) => {
    if ('cartItemId' in cartItem) {
      return handleDeleteCartItem(cartItem);
    }
    return () => {};
  };

  return (
    <div className={classes.root} data-testid={ADDED_COUPON_ID}>
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <CheckIcon className={classes.icon} />
          <Typography className={classes.title}>{COUPON_TITLE}</Typography>
          <LinkButton
            testId={REMOVE_COUPON_ID}
            className={classes.link}
            onClick={() => ((isYumEcomm && yumPromotion) ? handleRemoveYumPromoCode(yumPromotion.code ?? '') : handleDeleteCartItemOrAppliedPromotion(item))}
            ariaLabel={REMOVE_COUPON}
          >
            {REMOVE_COUPON}
          </LinkButton>
        </div>
        <div>
          <Typography>
            {FORMATTED_DISCOUNT_AMOUNT(discountedAmount)}
          </Typography>
        </div>
      </div>
      <div className={classes.nameContainer}>
        <Typography className={classes.name}>
          {item.name}
          {/* TODO: Add info icon logic, not currently seeing legal text come through, which is used here in legacy */}
        </Typography>
      </div>
    </div>
  );
};
