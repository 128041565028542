export const cartErrorModalDetails = {
  title: 'Item not added to cart',
  body: 'Something went wrong and the item you selected was not added to cart. Please try again.',
  cta: {
    text: 'OK'
  }
};

export const dealNotAvailableModalDetails = {
  title: 'Deal not available',
  body: 'Oh shoot, that deal isn\'t available. There are other great deals to choose from.',
  cta: {
    text: 'OK'
  }
};

export const dealDataOOSModalDetails = {
  title: 'So good, it\'s gone',
  body: 'Oh shoot, this deal is no longer available. There are other great deals to choose from.',
  cta: {
    text: 'OK'
  }
};

export const gqlErrorModalDetails = {
  title: 'We’re sorry',
  body: 'Something went wrong. Continue shopping our other delicious options.',
  cta: {
    text: 'OK'
  },
  redirect: true
};

export const getDealErrorModalDetails = (title:string, body: string) => ({

  title,
  body,
  cta: {
    text: 'OK'
  }

});

export const NOT_COMBINABLE_DEALS_TITLE = "Some deals just don't get along";

export const RETURN_TO_DEALS = 'RETURN TO DEALS';
