interface Fetch<FetchOptions, Response> {
  (...options: FetchOptions[]): Promise<Response>;
}

interface Transformer<Response, DomainType> {
  (response: Response): Promise<ClientResult<DomainType>>;
}

// When this function returns errors, the errors are awkward to work with. Check out the
// improved version below, createClientMethodImproved() - see existing usages for examples.
function createClientMethod<FetchOptions, Response, DomainType>(
  fetch: Fetch<FetchOptions, Response>,
  transformer: Transformer<Response, DomainType>
): (...options: FetchOptions[]) => Promise<ClientResult<DomainType>> {
  return async (...options) => {
    let response;
    try {
      response = await fetch(...options);
    } catch (e) {
      return { error: true };
    }
    return transformer(response);
  };
}

type CreateClientMethod = <Parameters extends unknown[], DomainType>(
    fetch: (...options: Parameters) => Promise<Response>,
    transformer: (response: Response) => Promise<DomainType>
) => (...options: Parameters) => Promise<DomainType | FetchError>;

class FetchError extends Error {}
export const createClientMethodImproved: CreateClientMethod = (
  fetch,
  transformer
) => async (...options) => {
  let response;
  try {
    response = await fetch(...options);
  } catch (e) {
    return new FetchError();
  }
  return transformer(response);
};

export default createClientMethod;
