import gql from 'graphql-tag';
import { SidekicksData } from '../../../homepage/HomepageTypes';
import { SidekicksQuery, SidekicksQueryResultV3 } from '../../types/Sidekicks';
import ImageFragmentV3 from '../fragments/image.v3';
import LinkFragmentV3 from '../fragments/link.v3';
import PriceRequirementV3 from '../fragments/priceRequirement.v3';

const rawQuery = gql`
    query getHomepageSidekick($envEnvironmentID: String!, $storeID: String!, $occasions: [OccasionV3!]) {
        Homepage(envID: $envEnvironmentID, storeID: $storeID, occasions: $occasions) {
            storeID
            sidekicks {
                sidekickID: name
                CTALinkText: ctaLinkText
                imageSmall {
                    ...ImageFragmentV3
                }
                imageLarge {
                    ...ImageFragmentV3
                }
                link {
                    ...LinkFragmentV3
                }
                item: requiresPrice {
                    ...PriceRequirementV3
                }
                displayPrice
                badgeText
                subTitle: subtitle
                title
            }
            dealsheading: dealsHeading {
              heading
              ctaLink: callToActionLink {
                ...LinkFragmentV3
              }
            }
        }
    }
    ${ImageFragmentV3}
    ${PriceRequirementV3}
    ${LinkFragmentV3}
`;

const parser = (data: SidekicksQueryResultV3) : SidekicksData => ({
  sidekicks: data?.Homepage?.sidekicks,
  dealsheading: data?.Homepage?.dealsheading
});
const query: SidekicksQuery = { query: rawQuery, parser };
export default query;
