import {
  useGetDiscountFromMarketingCodeQuery
} from '@pizza-hut-us-development/client-core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { OccasionApi } from '@/localization/constants';
import { selectOccasion } from '@/localization/selectors';
import { transformGetDiscountFromMarketingCodeData } from './transformGetDiscountFromMarketingCodeData';
import { RootState } from '@/rootStateTypes';
import { transformationDebugLogging } from '@/clientCore/debuggerLogging';
import { debugModeSelectors } from '@/clientCore/redux/selectors/debugSelectors';
import { DebugModeOptions } from '@/clientCore/redux/debug/types';
import {
  DealBuilderWithAvailability
} from '@/clientCore/temporaryTransformationalHooks/useCCGetDealBuilderQuery/transformGetDealBuilderData';

export const useCCGetDiscountFromMarketingCodeQuery = ({ marketingCode = '', skip = false }) => {
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const storeOccasion = useSelector(selectOccasion) as keyof typeof OccasionApi;
  const occasion = OccasionApi[storeOccasion] as OccasionApi;
  const [storeNumber, setStoreNumber] = useState<string>('');
  const [storeTimezone, setStoreTimeZone] = useState<string | undefined>(undefined);
  const debugEnabled = useSelector(debugModeSelectors.enabled);
  const debugMode = useSelector(debugModeSelectors.mode);

  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  useEffect(() => {
    if (storeDetails?.storeNumber) {
      setStoreNumber(storeDetails.storeNumber);
    }
    if (storeDetails?.storeTimezone) setStoreTimeZone(storeDetails.storeTimezone);
  }, [storeDetails]);

  const {
    data, isLoading, isFetching, error
  } = useGetDiscountFromMarketingCodeQuery(
    { storeNumber, marketingCode },
    { skip: !storeNumber || skip }
  );

  if (skip || error) {
    return {
      data: null,
      loading: false,
      error: null,
      storeID: null
    };
  }

  if (isLoading || isFetching || !storeNumber) {
    return {
      data: undefined,
      loading: true,
      error: null,
      storeID: null
    };
  }

  const transformedData = data && storeTimezone
    ? transformGetDiscountFromMarketingCodeData(data as DealBuilderWithAvailability, occasion, storeTimezone || '', isYumEcomm)
    : undefined;

  if (debugEnabled) {
    transformationDebugLogging('useGetDiscountFromMarketingCodeQuery', useGetDiscountFromMarketingCodeQuery.name, data, transformedData, debugMode, [
      DebugModeOptions.TRANSFORMATIONS,
      DebugModeOptions.MENU,
      DebugModeOptions.DEALS
    ]);
  }

  return {
    data: transformedData,
    loading: isLoading,
    storeID: storeNumber
  };
};
