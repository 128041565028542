import { DebugModeOptions, DebugTypes } from '../redux/debug/types';

export function transformationDebugLogging(
  transformationalHookName: string,
  origin: string,
  nonTransformedData: any,
  transformedData: any,
  debugMode: DebugTypes,
  debugModesApplied: DebugTypes[]
) {
  /* eslint-disable no-console */
  //  Checks if debug mode is enabled and if the mode being used currently matches what's used for the log.
  const logMessage = () => console.log(`TRANSFORMATIONAL_HOOK: ${transformationalHookName} \nORIGIN/CC_HOOK: ${origin}`, '\n', {
    nonTransformedData,
    transformedData
  }, '\n Modes Applicable:', debugModesApplied);

  if (debugMode === DebugModeOptions.ALL || Boolean(debugModesApplied.find((mode) => mode === debugMode))) logMessage();
}
