export const deleteCoupon = typeof (jest) === 'undefined' ? new Promise(() => {}) : jest.fn();

export const successfulDeleteCoupon = (): ClientResult<void> => {
  const mockDeleteCoupon = deleteCoupon as jest.Mock;
  const value = { error: false };
  mockDeleteCoupon.mockResolvedValue(value);
  return value;
};

export const failedDeleteCoupon = (): ClientResult<void> => {
  const mockDeleteCoupon = deleteCoupon as jest.Mock;
  const value = { error: true };
  mockDeleteCoupon.mockResolvedValue(value);
  return value;
};
