/* eslint-disable no-console */
import { ApolloLink } from 'apollo-link';
import logger from '../../common/logger';

let numberActive = 0;
const logLink = new ApolloLink((operation, forward) => {
  numberActive += 1;
  logger.withoutTelemetry.debug(`[graphql] starting GraphQL query: ${operation.operationName} (${numberActive} queries running)`);
  return forward(operation).map((result) => {
    numberActive -= 1;
    return result;
  });
});

export default logLink;
