// eslint-disable-next-line no-restricted-imports
import { useQuery, QueryResult } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { OperationDefinitionNode } from 'graphql/language/ast';
import isClientSide from '../../../common/isClientSide';
import { logCacheError } from '@/common/logger/logGraphqlError';

function queryResultsDoNotExistInCache(result: QueryResult) {
  return !isClientSide() && result.loading;
}

export default function useSSRQuery(
  query: DocumentNode, params: any
) : QueryResult {
  const result = useQuery(query, { ...params, errorPolicy: 'none' });
  // Queries should be made and cached from a getServerSideProps method
  // A successfully cached query will not have a loading state on the server side
  if (queryResultsDoNotExistInCache(result)) {
    const queryDefinition = query.definitions[0] as OperationDefinitionNode;
    logCacheError(queryDefinition?.name?.value ?? '');
  }

  return { ...result };
}
