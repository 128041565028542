import getUserAnalytics from './getUserAnalytics';
import { DataAnalytics } from './dataAnalytics.d';
import { BaseAnalytics, CustomWindowWithAnalytics } from '@/dataAnalytics/analyticsTypes';
import isClientSide from '@/common/isClientSide/isClientSide';
import logger from '@/common/logger';

interface StringMap { [key: string]: string; }

function getPhAnalytics(analytics: StringMap): BaseAnalytics {
  return Object.keys(analytics)
    .filter((key) => analytics[key] !== undefined)
    .reduce((result, key) => Object.assign(result, { [key]: analytics[key] }), {}) as BaseAnalytics;
}

const getDefaultAnalytics = () => ({
  site_type: 'React',
  code_version: 'v1.00',
  'window.is_app': 'No'
});

const Window: CustomWindowWithAnalytics = isClientSide() ? window : { ph_analytics: [] };

const dataAnalytics : DataAnalytics = {
  push(eventAnalytics: {}, dataModel?: {}) : void {
    try {
      const analytics = getUserAnalytics(dataModel);
      const defaultAnalytics = getDefaultAnalytics();
      Window.ph_analytics = Window.ph_analytics || [];
      const newAnalytics = getPhAnalytics({
        ...analytics,
        ...defaultAnalytics,
        ...eventAnalytics
      })
      Window.ph_analytics.push(newAnalytics);
    } catch (e) {
      const error = e as Error;
      logger.error(error);
    }
  },
  pushEventAnalytics(eventAnalytics:{}) : void {
    try {
      const defaultAnalytics = getDefaultAnalytics();
      Window.ph_analytics = Window.ph_analytics || [];
      Window.ph_analytics.push(getPhAnalytics({
        ...defaultAnalytics,
        ...eventAnalytics
      }));
    } catch (e) {
      const error = e as Error;
      logger.error(error);
    }
  }
};

export default dataAnalytics;
