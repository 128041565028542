import telemetry from '../../../../telemetry';
import AccountManagementEndpoints from '../endpoints';
import { Favorite } from '@/account/orders/favorites/Favorites.types';
// eslint-disable-next-line import/no-cycle
import { transformFavoritesData } from './transformFavoritesData';
import { getWithHeaders } from '../../helpers/getWithHeaders';
import { getUrl } from '../../helpers/getUrl';
import logger from '../../../../common/logger';

export const getFavorites = (): Promise<ClientResult<Favorite[]>> => {
  const endpoint = AccountManagementEndpoints.GET_FAVORITES;
  return getWithHeaders(
    getUrl(endpoint),
    {},
    { credentials: 'include' }
  )
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.error_code) throw new Error(responseJson.message);
      return responseJson;
    })
    .then((responseJson) => ({
      error: false,
      result: transformFavoritesData(responseJson)
    }))
    .catch((error) => {
      telemetry.addNoticeError(error, { endpoint });
      logger.withoutTelemetry.error('Could not fetch favorites', error);
      return { error: true };
    });
};
