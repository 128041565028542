import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LinkButton from '@/common/LinkButton';
import styles from '../styles';
import { setEditCartItemId } from '@/clientCore/redux/rail/CartRailSlice';
import { BACK_TO_CART_BUTTON } from '@/clientCore/cart/constants';
import { EDIT_ITEM_ERROR } from './constants';

const EditCartError = () => {
  const classes = styles();
  const dispatch = useDispatch();

  return (
    <div data-testid={EDIT_ITEM_ERROR.testId.container}>
      <div className={classes.goBack}>
        <LinkButton
          testId={EDIT_ITEM_ERROR.testId.back}
          onClick={() => dispatch(setEditCartItemId(null))}
        >
          <ArrowBackIcon className={classes.goBackIcon} />
          <div className={classes.goBackText}>{BACK_TO_CART_BUTTON}</div>
        </LinkButton>
      </div>
      <div className={classes.root}>
        <Typography className={classes.title}>
          {EDIT_ITEM_ERROR.title}
        </Typography>
        <Typography>{EDIT_ITEM_ERROR.message}</Typography>
      </div>
    </div>
  );
};

export default EditCartError;
