import { compose } from '@reduxjs/toolkit';
import { Cart, CartUi } from '@/domain/cart/types';
import { RootState } from '@/rootStateTypes';

const domain = (state: RootState): Cart => state.domain.cart;

const quantity = (cart: Cart) => cart.quantity;
const items = (cart: Cart) => cart?.items;
const loading = (cart: Cart) => cart?.loading;

const presentational = (state: RootState): CartUi => state.presentational.cart;
const openCartRailAfterRedirect = (
  cartUi: CartUi
): boolean => cartUi.rail.openCartRailAfterRedirect;

// eslint-disable-next-line import/prefer-default-export
export const cartSelectors = {
  quantity: compose(quantity, domain),
  items: compose(items, domain),
  loading: compose(loading, domain),
  openCartRailAfterRedirect: compose(openCartRailAfterRedirect, presentational)
};
