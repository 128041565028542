import { CreateLogger } from '../types';

const createLogger: CreateLogger = () => ({
  info: () => {},
  error: () => {},
  warn: () => {},
  debug: () => {}
});

export default createLogger;
