import React, { CSSProperties } from 'react';
import Svg from './svg';

// this is to adjust for some padding added to the image;
// remove this once we have the new image from UX
const style: CSSProperties = {
  position: 'relative',
  top: '6px',
  left: '-5px'
};

export default function Index() {
  return <Svg aria-hidden style={style} />;
}
