import { RuleActionTuple } from '@/builders/pizza/dataTransformers/builderTypes';

const RuleAction: RuleActionTuple = {
  ADD: 'ADD',
  DELETE: 'DELETE',
  PRICING: 'PRICING',
  RESTRICT: 'RESTRICT',
  INCLUDE: 'INCLUDE'
};

export default RuleAction;
