import { add, max, parse } from 'date-fns';
import { getUserTimezoneDiffToStore } from '@/localization/common/getOpeningHours/getUserTimezoneDiffToStore';
import { roundToNearestMinutes } from '@/localization/common/getOpeningHours/roundToNearestMinutes';

export const getStartAndEndTimes = (
  storeOpenTime: string,
  duration: string,
  promiseTime: number,
  isToday: boolean,
  storeTimezone: string
): Array<Date> => {
  const currentTime = Date.now();
  const hoursDiff = getUserTimezoneDiffToStore(storeTimezone);
  let currentTimePlusPromiseTime = add(currentTime, { hours: hoursDiff, minutes: promiseTime });
  currentTimePlusPromiseTime = roundToNearestMinutes(currentTimePlusPromiseTime);

  const openingHour = parse(storeOpenTime, 'HH:mm:ss', currentTime);
  let openingHourPlusPromiseTime = add(openingHour, { minutes: promiseTime });
  openingHourPlusPromiseTime = roundToNearestMinutes(openingHourPlusPromiseTime);

  const firstTimeAvailable = isToday
    ? max([openingHourPlusPromiseTime, currentTimePlusPromiseTime])
    : openingHourPlusPromiseTime;

  const [hours, minutes, seconds] = duration.split(':').map((val) => parseInt(val, 10));
  const lastTimeAvailable = add(openingHour, { hours, minutes, seconds });

  return [firstTimeAvailable, lastTimeAvailable];
};
