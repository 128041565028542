import { getTimezoneOffset } from 'date-fns-tz';

const minutesToMilliseconds = (num: number) => num * 60 * 1000;

/* This method produces timestamps that reference localized time.
  A timestamp five hours in the past represents a timezone five hours behind GMT.
  This format is used to query GQL for correctly-timed hero+sidekick promos, i.e. lunch specials */
const getCurrentTimestampForTimezone = (timezone: string, date: Date) => {
  const offset = getTimezoneOffset(timezone);

  return date.getTime() + offset;
};

const Time = {
  minutesToMilliseconds,
  getCurrentTimestampForTimezone
};

export default Time;
