import { useSelector } from 'react-redux';
import { QueryHookOptions } from '@apollo/client';
import { RootState } from '@/rootStateTypes';

export default function useEnvQueryVariables(migrated: boolean = true) : QueryHookOptions {
  const cmsEnvId = useSelector((state: RootState) => state.domain.cmsEnv.id);

  return {
    variables: {
      cmsEnvironmentID: cmsEnvId,
      envEnvironmentID: cmsEnvId
    }
  };
}